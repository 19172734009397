import React, { useState } from "react";
import { Drawer, IconButton, List, makeStyles } from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import Avatar from "@mui/material/Avatar";
import firebase from "../../config/FirebaseConfig";
import { GET_SIGNUP_SUCCESS } from "../../redux/actionTypes/SignUpActionTypes";
import { GET_SIGNIN_SUCCESS } from "../../redux/actionTypes/SignInActionTypes";
import { MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { GET_TOKEN_SUCCESS } from "../../redux/actionTypes/AuthActionTypes";
import { generateAbbreviation, removePersistData } from "../../helper";
import ShareModal from "../../pages/settings/ShareEmail";
import { inviteOthers } from "../../redux/actions/SettingsAction";

const useStyles = makeStyles(() => ({
  icon: {
    color: "white",
  },
  link: {
    textDecoration: "none",
    color: "#000000",
    fontSize: "18px",
    fontWeight: "600",
    fontFamily: "Poppins",
    display: "block",
    padding: "12px 20px",
  },
  bloodTestButton: {
    borderRadius: "33px",
    padding: "10px 22px 9px 23px",
    fontWeight: 500,
    color: " #fff",
    textTransform: "capitalize",
    justifyContent: "space-around",
    backgroundColor: "#122d5b",
    fontFamily: "Poppins",
    border: "none",
    marginLeft: "20px",
    marginRight: "36px",
    marginBlock: "15px",
    "@media (min-width: 1024px) and (max-width: 1100px)": {
      fontSize: "12px",
    },
    "@media (min-width: 1101px) and (max-width: 1199px)": {
      fontSize: "12px",
    },
    "@media (min-width: 1200px) and (max-width: 1329px)": {
      marginRight: "20px",
    },
    "@media (min-width: 1330px) and (max-width: 1476px)": {
      marginRight: "24px",
    },
    cursor: "pointer",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#122d5b",
    },
  },
  profile: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBlockStart: "24px",
  },
  profileData: {
    display: "block",
    marginLeft: "12px",
    paddingRight: "15px",
  },
  profileName: {
    color: "#122D5B",
    fontSize: "18px",
    fontWeight: "500",
    fontFamily: "Poppins",
    "@media (min-width: 1024px) and (max-width: 1329px)": {
      fontSize: "14px",
    },
  },
  readProfile: {
    color: "#8A8A8A",
    fontFamily: "Poppins",
    fontSize: "14px",
    textAlign: "initial",
    "@media (min-width: 1024px) and (max-width: 1329px)": {
      fontSize: "12px",
    },
  },
  headerDropdown: {
    "&:hover": {
      background: "none !important",
    },
    "&:active": {
      background: "none !important",
    },
    "&:focus": {
      background: "none !important",
      outline: "none !important",
    },
    "&:focus-visible": {
      outline: "none !important",
      background: "none !important",
    },
    "& > .MuiIconButton-label": {
      backgroundColor: "white !important",
    },
  },
  menuItem: {
    display: "block !important",
    padding: "12px 20px !important",
    "& > a": {
      textDecoration: "none",
      color: "#000000",
      fontSize: "18px",
      fontWeight: "600",
      fontFamily: "Poppins",
      display: "block",
    },
  },
}));

function DrawerComponent({ showNavigations, bloodTest }) {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [AccordionOpen, SetAccordionOpen] = useState(false);
  const { userInfo } = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = (event) => {
    SetAccordionOpen((prev) => !prev);
  };
  const handleClose = () => {
    SetAccordionOpen(false);
  };
  const [openShareModal, setOpenShareModal] = useState(false);
  const [emails, setEmails] = useState();
  const handleShareClose = () => {
    setOpenShareModal(false);
  };
  const handleChangeEmail = (e) => {
    const { name, value } = e.target;
    setEmails({ ...emails, [name]: value });
  };
  const handleShareEmail = () => {
    const emailValues = Object.values(emails);
    const cleanedEmailValues = emailValues.map((email) =>
      email.replace(/"/g, "")
    );
    const formattedEmails = cleanedEmailValues.join(", ");

    dispatch(
      inviteOthers({
        emails: formattedEmails,
      })
    );
    setOpenShareModal(false);
  };
  const handleShareClick = (pid) => {
    setOpenShareModal(true);
  };
  const handleLogout = async () => {
    try {
      await firebase.auth().signOut();
      window.location.replace("https://www.ahealthacademy.com/");
      removePersistData(dispatch);
    } catch (error) {
      // Handle error
    }
  };

  const checkDisabled = (bloodTest) => {
    if (bloodTest?.length) {
      if (
        bloodTest?.filter((eachTest) => {
          return eachTest.newValue.length;
        })?.length
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  return (
    <div id="drawerComponent">
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <IconButton
          onClick={handleClick}
          size="small"
          // sx={{ ml: 2 }}
          aria-controls={AccordionOpen ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={AccordionOpen ? "true" : undefined}
          sx={{
            ml: 2,
          }}
          className={classes.headerDropdown}
        >
          <div className={classes.profile}>
            <Avatar
              sx={{
                width: 39,
                height: 39,
                color: "#FF9900",
                background: "#122D5B",
                fontSize: "19px",
                fontFamily: "Poppins",
                fontWeight: "500",
              }}
            >
              {generateAbbreviation(userInfo?.user?.FullName?.S)}
            </Avatar>
            <div className={classes.profileData}>
              <div className={classes.profileName}>
                {" "}
                {userInfo?.user?.FullName?.S}
              </div>
              <div className={classes.readProfile}>See Profile</div>
            </div>
          </div>
        </IconButton>

        <div className="menuItem">
          <MenuItem
            onClick={handleClose}
            sx={{
              color: "#000000",
              fontSize: "18px",
              fontWeight: "600",
              fontFamily: "Poppins",
              padding: "6px 16px !important",
            }}
            className={classes.menuItem}
          >
            <Link to="/settings">Settings</Link>
          </MenuItem>
          <a
            style={{ textDecoration: "none", color: "#000000" }}
            href="https://www.ahealthacademy.com/"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            <MenuItem
              sx={{
                color: "#000000",
                fontSize: "18px",
                fontWeight: "600",
                fontFamily: "Poppins",
              }}
              className={classes.menuItem}
            >
              AHA
            </MenuItem>
          </a>
          <MenuItem
            onClick={handleShareClick}
            sx={{
              color: "#000000",
              fontSize: "18px",
              fontWeight: "600",
              fontFamily: "Poppins",
            }}
            className={classes.menuItem}
            disabled={bloodTest ? checkDisabled(bloodTest) : true}
          >
            Invite Others
          </MenuItem>
          <MenuItem
            sx={{
              color: "#000000",
              fontSize: "18px",
              fontWeight: "600",
              fontFamily: "Poppins",
            }}
            className={classes.menuItem}
            onClick={handleLogout}
          >
            Logout
          </MenuItem>
        </div>

        <List>
          {showNavigations && (
            <>
              <Link
                to="/"
                className={classes.link}
                onClick={() => setOpenDrawer(false)}
              >
                Home
              </Link>
              <a
                href="#reviewBloodTest"
                className={classes.link}
                onClick={() => setOpenDrawer(false)}
              >
                Review Blood Test
              </a>
              <div className={classes.navHeader}>
                <button
                  variant="contained"
                  onClick={() => {
                    navigate("/get-blood-test-interpreted");
                  }}
                  className={classes.bloodTestButton}
                >
                  + New Blood Test
                </button>
              </div>
            </>
          )}
        </List>
        <ShareModal
          title={"invite"}
          openModal={openShareModal}
          handleClose={handleShareClose}
          handleSubmit={handleShareEmail}
          handleChangeEmail={handleChangeEmail}
          emails={emails}
          openShareModal={openShareModal}
        />
      </Drawer>
      <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
        <MenuIcon />
      </IconButton>
    </div>
  );
}
export default DrawerComponent;
