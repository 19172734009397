import { toast } from "react-toastify";
import axiosInstance from "../../axios/axiosInstance";
import { toastStyle } from "../../helper";
import { GET_PROFILE_SUCCESS } from "../actionTypes/GetProfileActionTypes";
import {
  CLOSE_ACCOUNT_SUCCESS,
  DELETE_PROFILE_SUCCESS,
  GET_SETTINGS_FAILURE,
  GET_SETTINGS_REQUEST,
  LIST_PROFILE_SUCCESS,
  REMOVE_PROFILE_ACCESS_SUCCESS,
  PROFILE_ACCESS_SUCCESS,
  RESTORE_PROFILE_SUCCESS,
  SHARE_LIST_PROFILE_SUCCESS,
  SHARE_PROFILE_SUCCESS,
  SEND_FEEDBACK_SUCCESS,
  GET_PAYMENT_DATA,
  GET_SUBSCRIPTION_PLAN,
  SET_SUBSCRIPTION_PLAN,
  INVITE_OTHERS,
  GET_FAIL_PAYMENT,
  GET_PAYMENT_DATA_FOR_PROVIDER,
} from "../actionTypes/SettingsActionTypes";
import axios from "axios";
import { useStripe } from "@stripe/react-stripe-js";
import { fetchProfileData } from "./GetProfileAction";
import firebase from "../../config/FirebaseConfig";
import { GET_TOKEN_SUCCESS } from "../actionTypes/AuthActionTypes";

export const fetchShareList = () => async (dispatch) => {
  dispatch({ type: GET_SETTINGS_REQUEST });
  try {
    const response = await axiosInstance("/v1/getProfiles", "POST", null);
    dispatch({
      type: GET_PROFILE_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({ type: GET_SETTINGS_FAILURE, payload: error.message });
  }
};

export const closeAccount = (setOpenModal) => async (dispatch, getState) => {
  dispatch({ type: GET_SETTINGS_REQUEST });
  try {
    const response = await axiosInstance("/v1/cancelwebSubscription", "POST", {
      status: "close",
    });
    if (response.data.success) {
      const response = await axiosInstance("/v1/closeAccount", "POST", null);
      dispatch({
        type: CLOSE_ACCOUNT_SUCCESS,
        payload: response.data.data,
      });
      setOpenModal(false);
      const user = firebase.auth().currentUser;
      if (user) {
        const deletedUser = await user.delete();
        if (deletedUser) {
          console.log("Account deleted");
        } else {
          console.log("Account deleted error", deletedUser);
        }
      }
      dispatch({
        type: GET_TOKEN_SUCCESS,
        payload: null,
      });
    } else {
      dispatch(getSubscription());
    }
  } catch (error) {
    dispatch({ type: GET_SETTINGS_FAILURE, payload: error.message });
  }
};

export const listRestoreProfile = () => async (dispatch) => {
  dispatch({ type: GET_SETTINGS_REQUEST });
  try {
    const response = await axiosInstance(
      "/v1/listRestorableProfiles",
      "POST",
      null
    );
    dispatch({
      type: LIST_PROFILE_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({ type: GET_SETTINGS_FAILURE, payload: error.message });
  }
};

export const restoreProfile =
  (pid, setNotificationData) => async (dispatch) => {
    dispatch({ type: GET_SETTINGS_REQUEST });
    try {
      const response = await axiosInstance("/v1/restoreProfile", "POST", pid);
      dispatch({
        type: RESTORE_PROFILE_SUCCESS,
        payload: response.data.data,
      });
      dispatch(listRestoreProfile());
      dispatch(fetchShareList());
      setNotificationData({
        openModel: true,
        message: "Profile Restore Successfully!",
        upgradePlan: false,
        status: "",
        isFirstStep: false,
        paymentFailed: false,
      });
    } catch (error) {
      dispatch({ type: GET_SETTINGS_FAILURE, payload: error.message });
    }
  };

export const shareProfile = (payload) => async (dispatch) => {
  dispatch({ type: GET_SETTINGS_REQUEST });
  try {
    const response = await axiosInstance(
      "/v1/addProfileAccess",
      "POST",
      payload
    );
    dispatch({
      type: SHARE_PROFILE_SUCCESS,
      payload: response.data.data,
    });
    dispatch(fetchShareList());
    toast.success(
      "Emails have been sent to the emails you provided.",
      toastStyle
    );
  } catch (error) {
    dispatch({ type: GET_SETTINGS_FAILURE, payload: error.message });
  }
};

export const inviteOthers = (payload) => async (dispatch) => {
  dispatch({ type: GET_SETTINGS_REQUEST });
  try {
    const response = await axiosInstance("/v1/inviteOthers", "POST", payload);
    dispatch({
      type: INVITE_OTHERS,
      payload: response.data.data,
    });
    toast.success(
      "Invite sent! Please follow up with the recipient",
      toastStyle
    );
  } catch (error) {
    dispatch({ type: GET_SETTINGS_FAILURE, payload: error.message });
  }
};

export const contactUsFeedback = (payload) => async (dispatch) => {
  dispatch({ type: GET_SETTINGS_REQUEST });
  try {
    const response = await axiosInstance("/v1/contactUs", "POST", payload);
    dispatch({
      type: SEND_FEEDBACK_SUCCESS,
      payload: response.data.data,
    });
    // toast.success(
    //   "Thank you for contacting AHA! We have received your message.",
    //   toastStyle
    // );
  } catch (error) {
    dispatch({ type: GET_SETTINGS_FAILURE, payload: error.message });
  }
};

export const deleteProfile = (pId) => async (dispatch) => {
  dispatch({ type: GET_SETTINGS_REQUEST });
  try {
    const response = await axiosInstance("/v1/closeProfile", "POST", pId);
    dispatch({
      type: DELETE_PROFILE_SUCCESS,
      payload: response.data.data,
    });
    dispatch(fetchProfileData());
    dispatch(fetchShareList());
    dispatch(listRestoreProfile());
  } catch (error) {
    dispatch({ type: GET_SETTINGS_FAILURE, payload: error.message });
  }
};

// export const shareListProfile = (pId) => async (dispatch) => {
//   dispatch({ type: GET_SETTINGS_REQUEST });
//   try {
//     if (!pId.includes("DELETED")) {
//       const response = await axiosInstance("/v1/listProfileAccess", "POST", {
//         profileId: pId,
//       });
//       dispatch({
//         type: SHARE_LIST_PROFILE_SUCCESS,
//         payload: { [pId]: response.data.data },
//       });
//     }
//   } catch (error) {
//     dispatch({ type: GET_SETTINGS_FAILURE, payload: error.message });
//   }
// };

export const removeProfileAccess = (payload) => async (dispatch) => {
  dispatch({ type: GET_SETTINGS_REQUEST });
  try {
    const response = await axiosInstance(
      "/v1/removeProfileAccess",
      "POST",
      payload
    );
    dispatch({
      type: REMOVE_PROFILE_ACCESS_SUCCESS,
      payload: response.data.data,
    });
    dispatch(fetchShareList());
  } catch (error) {
    dispatch({ type: GET_SETTINGS_FAILURE, payload: error.message });
  }
};

export const getPaymentData = () => async (dispatch) => {
  dispatch({ type: GET_SETTINGS_REQUEST });
  try {
    const response = await axiosInstance("/v1/paymentDataWeb", "POST");
    dispatch({
      type: GET_PAYMENT_DATA,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({ type: GET_SETTINGS_FAILURE, payload: error.message });
  }
};

export const getPaymentDataForProvider = () => async (dispatch) => {
  dispatch({ type: GET_SETTINGS_REQUEST });
  try {
    const response = await axiosInstance("/v1/paymentDataForProvider", "POST");
    dispatch({
      type: GET_PAYMENT_DATA_FOR_PROVIDER,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({ type: GET_SETTINGS_FAILURE, payload: error.message });
  }
};

export const getFailedPayment = () => async (dispatch) => {
  dispatch({ type: GET_SETTINGS_REQUEST });
  try {
    const response = await axiosInstance("/v1/updateFailPayment", "POST");
    dispatch({
      type: GET_FAIL_PAYMENT,
      payload: response.data.data,
    });
    const res = await axiosInstance("/v1/getSubscriptionPlan", "POST");
    dispatch({
      type: GET_SUBSCRIPTION_PLAN,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({ type: GET_SETTINGS_FAILURE, payload: error.message });
  }
};
export const getSubscription = (payload) => async (dispatch) => {
  // dispatch({ type: GET_SETTINGS_REQUEST });
  try {
    const response = await axiosInstance("/v1/getSubscriptionPlan", "POST");
    dispatch({
      type: GET_SUBSCRIPTION_PLAN,
      payload: response.data.data,
    });
  } catch (error) {
    console.log("Get Subscription Plan", error.message);
    // dispatch({ type: GET_SETTINGS_FAILURE, payload: error.message });
  }
};

// export const setSubscription =
//   (payload, stripe, setNotificationData) => async (dispatch) => {
//     dispatch({ type: GET_SETTINGS_REQUEST });
//     try {
//       const response = await axiosInstance(
//         "/v1/createwebSubscription",
//         "POST",
//         payload
//       );
//       if (response?.data?.error?.message && !response?.data?.success) {
//         let msg = "";
//         if (response?.data?.error?.display_message) {
//           msg = response?.data?.error?.display_message;
//         } else {
//           msg = response?.data?.error?.message;
//         }
//         setNotificationData({
//           message: msg,
//           openModel: true,
//           upgradePlan: false,
//           status: "",
//           isFirstStep: false,
//           paymentFailed: true,
//         });
//         dispatch({
//           type: GET_SETTINGS_FAILURE,
//           payload: msg,
//         });
//       } else {
//         dispatch({
//           type: SET_SUBSCRIPTION_PLAN,
//           payload: response.data.data,
//         });
//         const isConfirmPayment = await stripe?.confirmCardPayment(
//           response.data.data?.status?.clientSecret
//         );
//         console.log("confirm",isConfirmPayment,response.data.data?.status?.clientSecret)
//         if (isConfirmPayment?.error) {
//           setNotificationData({
//             message: isConfirmPayment?.error?.message,
//             openModel: true,
//             upgradePlan: false,
//             status: "",
//             isFirstStep: false,
//             paymentFailed: true,
//           });
//           dispatch(getFailedPayment());
//         } else {
//           setNotificationData({
//             message: "Success! Your subscription has been activated.",
//             openModel: true,
//             upgradePlan: false,
//             status: "",
//             isFirstStep: false,
//             paymentFailed: false,
//           });
//         }
//       }
//       dispatch(getSubscription());
//     } catch (error) {
//       console.log("errrrrrr",error)
//       dispatch({ type: GET_SETTINGS_FAILURE, payload: error.message });
//       setNotificationData({
//         message: error.message,
//         openModel: true,
//         upgradePlan: false,
//         status: "",
//         isFirstStep: false,
//         paymentFailed: true,
//       });
//     }
//   };

export const setSubscription = (payload, stripe, setNotificationData) => async (dispatch) => {
  dispatch({ type: GET_SETTINGS_REQUEST });
  try {
    const response = await axiosInstance("/v1/createwebSubscription", "POST", payload);

    if (response?.data?.error?.message && !response?.data?.success) {
      // Handle error response from the backend
      let msg = response?.data?.error?.display_message || response?.data?.error?.message;
      setNotificationData({
        message: msg,
        openModel: true,
        upgradePlan: false,
        status: "",
        isFirstStep: false,
        paymentFailed: true,
      });
      dispatch({
        type: GET_SETTINGS_FAILURE,
        payload: msg,
      });
    } else {
      dispatch({
        type: SET_SUBSCRIPTION_PLAN,
        payload: response.data.data,
      });
      console.log("statusss",response.data.data?.status)

      // Check if client secret is available in the response
      const clientSecret = response.data.data?.status?.clientSecret;

      if (clientSecret) {
        // Use the correct client secret value in confirmCardPayment
        const isConfirmPayment = await stripe?.confirmCardPayment(clientSecret);

        if (isConfirmPayment?.error) {
          setNotificationData({
            message: isConfirmPayment?.error?.message,
            openModel: true,
            upgradePlan: false,
            status: "",
            isFirstStep: false,
            paymentFailed: true,
          });
          dispatch(getFailedPayment());
        } else {
          // Handle regular subscription success
          setNotificationData({
            message: "Success! Your subscription has been activated.",
            openModel: true,
            upgradePlan: false,
            status: "",
            isFirstStep: false,
            paymentFailed: false,
          });
        }
      }else if(response.data.data?.status.trialStatus == "trialing" && payload?.paymentMethod) {
        // No client secret for trial subscriptions, consider it successful
        setNotificationData({
          message: "Success! Your trial subscription has started.",
          openModel: true,
          upgradePlan: false,
          status: "",
          isFirstStep: false,
          paymentFailed: false,
        });
      }
    }

    dispatch(getSubscription());
  } catch (error) {
    console.error("Error:", error);
    dispatch({ type: GET_SETTINGS_FAILURE, payload: error.message });
    setNotificationData({
      message: error.message,
      openModel: true,
      upgradePlan: false,
      status: "",
      isFirstStep: false,
      paymentFailed: true,
    });
  }
};



export const updateSubscription =
  (payload, setNotificationData) => async (dispatch) => {
    dispatch({ type: GET_SETTINGS_REQUEST });
    try {
      const response = await axiosInstance(
        "/v1/updatewebSubscription",
        "POST",
        payload
      );
      if (response?.data?.error?.message && !response?.data?.success) {
        let msg = "";
        if (response?.data?.error?.display_message) {
          msg = response?.data?.error?.display_message;
        } else {
          msg = response?.data?.error?.message;
        }
        setNotificationData({
          message: msg,
          openModel: true,
          upgradePlan: false,
          status: "",
          isFirstStep: false,
          paymentFailed: true,
        });
        dispatch({
          type: GET_SETTINGS_FAILURE,
          payload: msg,
        });
      } else {
        setNotificationData({
          message: response?.data?.data?.status,
          openModel: true,
          upgradePlan: false,
          status: "",
          isFirstStep: false,
          paymentFailed: false,
        });
      }
      dispatch(getSubscription());
    } catch (error) {
      dispatch({ type: GET_SETTINGS_FAILURE, payload: error.message });
      setNotificationData({
        message: error.message,
        openModel: true,
        upgradePlan: false,
        status: "",
        isFirstStep: false,
        paymentFailed: true,
      });
    }
  };
export const cancelSubscriptionAction =
  (payload, setNotificationData) => async (dispatch) => {
    dispatch({ type: GET_SETTINGS_REQUEST });
    try {
      const response = await axiosInstance(
        "/v1/cancelwebSubscription",
        "POST",
        payload
      );
      if (response?.data?.success) {
        setNotificationData({
          message: response.data.data.status,
          openModel: true,
          upgradePlan: false,
          status: "",
          isFirstStep: false,
          paymentFailed: false,
        });
        // toast.success(response.data.data.status, toastStyle);
      } else {
        setNotificationData({
          message: response.data.error.message,
          openModel: true,
          upgradePlan: false,
          status: "",
          isFirstStep: false,
          paymentFailed: true,
        });
        // toast.error(response.data.error.message, toastStyle);
      }
    } catch (error) {
      // toast.error(error.message, toastStyle);
      setNotificationData({
        message: error.message,
        openModel: true,
        upgradePlan: false,
        status: "",
        isFirstStep: false,
        paymentFailed: true,
      });
    }
    dispatch(getSubscription());
  };
