import { useEffect, useRef, useState } from "react";
import dropdownStyles from "./dropdown.module.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export default function CommonDropdown({
  dropDownValues,
  handleDropdownChange,
  dropDownValuesUnits,
  selectedFeet,
  handleChangeUnits,
  name,
}) {
  const [isActive, setIsActive] = useState(false);
  const dropdownRef = useRef(null);
  const [selected, setIsSelected] = useState();
  const [unitsData, setUnitsData] = useState();
  const toggleDropdown = () => {
    setIsActive((prev) => !prev);
  };
  useEffect(() => {
    if (dropDownValuesUnits) {
      const filteredArray = [...new Set(dropDownValuesUnits)].filter(
        (item) => item !== undefined
      );
      setUnitsData(filteredArray);
      setIsSelected(filteredArray[0]);
    }
  }, []);
  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsActive(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  const getLIstyles = (eachListValue) => {
    if (eachListValue === selected) {
      return `${dropdownStyles.isListValueSelectedUnit}`;
    } else {
      return dropdownStyles.item;
    }
  };

  return (
    <div className="App">
      <div className={dropdownStyles.dropdown} ref={dropdownRef}>
        <div onClick={toggleDropdown} className={dropdownStyles.dropdown_btn}>
          <div className={dropDownValuesUnits ? dropdownStyles.menuTitle : ""}>
            {selected ? selected : selectedFeet}
          </div>

          {isActive ? (
            <div className={dropdownStyles.arrowIcon}>
              <KeyboardArrowUpIcon />
            </div>
          ) : (
            <div className={dropdownStyles.arrowIcon}>
              {" "}
              <KeyboardArrowDownIcon />
            </div>
          )}
        </div>
        <div
          className={dropdownStyles.dropdown_content}
          style={{ display: isActive ? "block" : "none" }}
        >
          {unitsData?.length > 0 &&
            !dropDownValues &&
            unitsData.map((eachDropDownValue, index) => {
              return (
                <>
                  <div
                    key={index}
                    className={getLIstyles(eachDropDownValue)}
                    onClick={() =>
                      handleChangeUnits(
                        eachDropDownValue,
                        name,
                        setIsActive,
                        setIsSelected,
                        setUnitsData,
                        unitsData
                      )
                    }
                  >
                    {eachDropDownValue}
                  </div>
                </>
              );
            })}

          {dropDownValues?.length > 0 &&
            !dropDownValuesUnits &&
            dropDownValues.map((eachDropDownValue, index) => {
              return (
                <>
                  <div
                    key={index}
                    className={dropdownStyles.item}
                    onClick={() => handleDropdownChange(eachDropDownValue.name)}
                  >
                    {eachDropDownValue.name}
                  </div>
                </>
              );
            })}
        </div>
      </div>
    </div>
  );
}
