import { Typography } from "@material-ui/core";
import React, { useCallback } from "react";
import biomarkerStyle from "./biomarker.module.scss";
import { ReactComponent as ExpandedIcon } from "../../../../assets/svg/trendsReport/biomarkerReport.svg";
import ReportData from "../reportData";
import {
  replacePercentage,
  calculateGraphArea,
  getPoints,
  stepSize,
} from "../../utils";
const BiomarkerReport = ({
  biomarkerName,
  biomarkerReport,
  biomarkerData,
  selected,
  openAccordion,
  setOpenAccordion,
}) => {
  const toggleAccordion = () => {
    setOpenAccordion((prev) => {
      if (prev.includes(biomarkerName)) {
        return prev.filter((name) => name !== biomarkerName);
      } else {
        return [...prev, biomarkerName];
      }
    });
  };
  return (
    <div className={biomarkerStyle.accordionWrapper}>
      <div className={biomarkerStyle.accordion} onClick={toggleAccordion}>
        <div className={biomarkerStyle.accordionHeadings}>
          <Typography className={biomarkerStyle.biomarkerName}>
            {biomarkerName}
          </Typography>
        </div>
        <button
          className={`${biomarkerStyle.expandedIcon} 
          ${
            openAccordion.includes(biomarkerName)
              ? biomarkerStyle.expanded
              : biomarkerStyle.closed
          }`}
        >
          <ExpandedIcon />
        </button>
      </div>
      <div className={biomarkerStyle.AccordionDetailsContainer}>
        <div
          className={`${biomarkerStyle.AccordionDetails} 
          ${
            openAccordion.includes(biomarkerName)
              ? biomarkerStyle.openAccordion
              : biomarkerStyle.closeAccordion
          }`}
        >
          {biomarkerReport &&
            biomarkerData &&
            biomarkerData.map((e, i) => {
              const xLabel = biomarkerReport[i].xLabel.map((e) => e?.S);
              const unit = e["Conventional Units"]?.S;
              const steps = stepSize[biomarkerName][i];
              const yLabels = biomarkerReport[i].yLabels.map((num) =>
                parseFloat(num)
              );
              const [
                min,
                abnormalLower,
                optimalLower,
                optimalUpper,
                abnormalUpper,
                max,
              ] = yLabels;

              const obj = {
                R2: calculateGraphArea(
                  max - abnormalUpper,
                  2,
                  10,
                  abnormalUpper,
                  "#FD4E51",
                  true
                ),
                Y2: calculateGraphArea(
                  abnormalUpper - optimalUpper,
                  2,
                  8,
                  optimalUpper,
                  "rgb(193,175,10)",
                  false
                ),
                G: calculateGraphArea(
                  optimalUpper - optimalLower,
                  4,
                  4,
                  optimalLower,
                  "rgb(43 ,147 ,6)",
                  false
                ),
                Y1: calculateGraphArea(
                  optimalLower - abnormalLower,
                  2,
                  2,
                  abnormalLower,
                  "rgb(193,175,10)",
                  false
                ),
                R1: calculateGraphArea(
                  abnormalLower - min,
                  2,
                  0,
                  min,
                  "#FD4E51",
                  true
                ),
                Lower: calculateGraphArea(0, 0, 0, min, "#FD4E51", true),
                Higher: calculateGraphArea(0, 0, 0, max, "#FD4E51", true),
              };
              const oldPoints = biomarkerReport[i].points
                ?.map((e, i) => {
                  if (!e?.value) return null;

                  const num = parseFloat(e?.value);
                  let ans;
                  if (num >= min && num < abnormalLower) {
                    ans = getPoints(obj["R1"], num);
                  }
                  if (num >= abnormalLower && num < optimalLower) {
                    ans = getPoints(obj["Y1"], num);
                  }
                  if (num >= optimalLower && num < optimalUpper) {
                    ans = getPoints(obj["G"], num);
                  }
                  if (num >= optimalUpper && num < abnormalUpper) {
                    ans = getPoints(obj["Y2"], num);
                  }
                  if (num >= abnormalUpper && num < max) {
                    ans = getPoints(obj["R2"], num);
                  }
                  if (num <= min) {
                    ans = getPoints(obj["Lower"], num, true);
                  }
                  if (num >= max) {
                    ans = getPoints(obj["Higher"], num, true);
                  }
                  return ans;
                })
                ?.reverse();
              const convertedPoints = oldPoints
                ?.map((e) => {
                  if (e == null) return null;
                  return e?.points;
                })
                ?.reverse();

              return (
                <ReportData
                  key={i}
                  steps={steps}
                  unit={unit}
                  yLabels={yLabels}
                  setOpenAccordion={setOpenAccordion}
                  openAccordion={openAccordion}
                  Biomarker_Name={e?.Biomarker_Name?.S}
                  description={e?.Biomarker_Explanation.S}
                  xLabels={xLabel}
                  oldPoints={oldPoints}
                  convertedPoints={convertedPoints}
                  selected={selected}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default BiomarkerReport;
