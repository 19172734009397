import {
  GET_SIGNUP_REQUEST,
  GET_SIGNUP_SUCCESS,
  GET_SIGNUP_FAILURE,
} from "../actionTypes/SignUpActionTypes";

const INITIAL_STATE = {
  signUp: {},
  loading: false,
};

const SignUpReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SIGNUP_REQUEST:
      return { ...state, loading: true, signUp: null, error: null };
    case GET_SIGNUP_SUCCESS:
      return { ...state, loading: false, signUp: action.payload, error: null };
    case GET_SIGNUP_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default SignUpReducer;
