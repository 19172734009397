import React from "react";
import codeStyle from './icdCode.module.scss'

const CodePage = ({ highCode, highDesc, lowCode, lowDesc }) => {
    return (
        <>
            <table className={`${codeStyle.table} no-border-table`}>
                <thead>
                    <tr>
                        <th className={codeStyle.codeTitle}>ICD-10 Code</th>
                        <th className={codeStyle.codeTitle}>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {highCode && [...new Set(highCode)].map((code, index) => (
                        highDesc[index] ? (
                            <tr key={index}>
                                <td className={codeStyle.subTitle}>{code}</td>
                                <td className={codeStyle.subTitle}>{highDesc[index]}</td>
                            </tr>
                        ) : null
                    ))}
                    {lowCode && [...new Set(lowCode)].map((code, index) => (
                        lowDesc[index] ? (
                            <tr key={index + highCode.length}>
                                <td className={codeStyle.subTitle}>{code}</td>
                                <td className={codeStyle.subTitle}>{lowDesc[index]}</td>
                            </tr>
                        ) : null
                    ))}
                </tbody>
            </table>
        </>
    )
}

export default CodePage;
