import { Box, CircularProgress, Dialog, DialogContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import otps from "./otp.module.scss";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ReactComponent as OrangeLockIcon } from "../../assets/svg/orangeLockIcon.svg";
import { ReactComponent as BlueStarIcon } from "../../assets/svg/blueStarIcon.svg";
import { ReactComponent as BlueDashIcon } from "../../assets/svg/blueDashIcon.svg";
import firebase from "../../config/FirebaseConfig";
import { fetchSignUpData } from "../../redux/actions/SignUpAction";
import { useDispatch } from "react-redux";
import { getErrorMessage } from "../../helper";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  grid: {
    backgroundColor: "grey",
    textAlign: "center",
    borderColor: "#122D5B",
  },
  paper: {
    marginTop: "60px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  lockAndStars: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    alignItems: "center",
  },
  AllStarsAndDashes: {
    display: "flex",
    gap: "10px",
  },
  EachStarAndDash: {
    display: "flex",
    flexDirection: "column",
    gap: "7px",
  },
  EmailFromText: {
    marginBlockStart: "40px",
  },
  OtpBoxesContainer: {
    display: "flex",
    gap: "22px",
  },
}));

function OtpScreen({
  checkIsDisabled,
  numberOfDigits = 6,
  handleSubmit,
  verify,
  openData,
  handleCloseOtp,
  otp,
  handleChangeOtp,
  handleKeyDown,
  otpInputRefs,
  handleOpenOtp,
  verificationCode,
  verificationId,
  setOpenOtp,
  signUpApi,
  setSignupLoading,
  setOtpLoading,
  otpLoading,
  phoneNumberForOTP,
  otpErrors,
  setOtpErrors,
  handleSendOTP,
}) {
  const { token } = useSelector((state) => state.accessToken);

  useEffect(() => {
    if (setSignupLoading) {
      setSignupLoading(false);
    }
  }, [setSignupLoading]);
  const [otpError, setOtpError] = useState("");
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleVerifyOTP = async () => {
    setOtpLoading(true);
    try {
      const credential = firebase.auth.PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );
      const userCredential = await firebase
        .auth()
        .signInWithCredential(credential);

      const user = userCredential.user;
      setOpenOtp(false);
      const userCredentialData = await firebase
        .auth()
        .createUserWithEmailAndPassword(signUpApi?.email, signUpApi?.password);
      signUpApi.userToken =
        userCredentialData?.user?.multiFactor?.user.accessToken;

      if (userCredentialData?.user?.multiFactor?.user.accessToken) {
        const signUpToken =
          userCredentialData?.user?.multiFactor?.user.accessToken;
        const providerId = "Sign In Button";
        const firstName =
          signUpApi.name.charAt(0).toUpperCase() + signUpApi.name.slice(1);
        const lastName =
          signUpApi.lName.charAt(0).toUpperCase() + signUpApi.lName.slice(1);
        const payload = {
          ...signUpApi,
          name: `${firstName} ${lastName}`,
        };
        dispatch(fetchSignUpData(payload, signUpToken, providerId));
      }

      setOtpLoading(false);
      // navigate("/home");
      console.log("Successfully authenticated:", user);
    } catch (error) {
      setOtpLoading(false);
      setOtpError(getErrorMessage(error.message));
      console.error("Error authenticating with OTP:", error);
    }
  };

  useEffect(() => {
    if (token) {
      navigate("/home");
    }
  }, [navigate, token]);

  // const handleResendOtp = async () => {
  //   console.log("resend otp");
  //   handleSendOTP(phoneNumberForOTP);
  // };
  return (
    <div>
      {!checkIsDisabled ? (
        <Navigate to={"/sign-in"} />
      ) : (
        <Dialog
          open={handleOpenOtp}
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: "20px",
            },
          }}
        >
          <DialogContent
            sx={{
              padding: "38px 44px",
            }}
          >
            <Box className={otps.otpBox}>
              <div className={classes.lockAndStars}>
                <OrangeLockIcon />
                <div className={classes.AllStarsAndDashes}>
                  <div className={classes.EachStarAndDash}>
                    <BlueStarIcon />
                    <BlueDashIcon />
                  </div>
                  <div className={classes.EachStarAndDash}>
                    <BlueStarIcon />
                    <BlueDashIcon />
                  </div>
                  <div className={classes.EachStarAndDash}>
                    <BlueStarIcon />
                    <BlueDashIcon />
                  </div>
                  <div className={classes.EachStarAndDash}>
                    <BlueStarIcon />
                    <BlueDashIcon />
                  </div>
                </div>
              </div>
              <p className={classes.EmailFromText}>
                A verification code has been sent
              </p>
              <p className={otps.verification}>to mobile number</p>
              <p className={otps.mail}>{phoneNumberForOTP}</p>
            </Box>
            <CssBaseline />
            <div className={classes.paper}>
              <div className={classes.OtpBoxesContainer}>
                {otp &&
                  otp.map((digit, index) => (
                    <input
                      key={index}
                      type="text"
                      maxLength="1"
                      value={digit}
                      pattern="[0-9]+"
                      onChange={(e) => {
                        if (/^[0-9]*$/.test(e.target.value)) {
                          handleChangeOtp(index, e.target.value);
                        }
                        setOtpErrors("");
                        setOtpError("");
                      }}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      ref={(ref) => (otpInputRefs.current[index] = ref)}
                      className={otps.otpInputNumber}
                    />
                  ))}
              </div>
              {(otpError || otpErrors) && (
                <p className={otps.otpErrorMessage}>{otpError || otpErrors}</p>
              )}

              <button
                disabled={checkIsDisabled() ? checkIsDisabled() : false}
                className={otps.submitBtn}
                onClick={!checkIsDisabled() && handleVerifyOTP}
                style={{
                  opacity: checkIsDisabled() ? "0.6" : 1,
                  cursor: checkIsDisabled() ? "not-allowed" : "pointer",
                }}
              >
                {otpLoading ? (
                  <>
                    Please wait... &nbsp;&nbsp;&nbsp;
                    <CircularProgress size={20} sx={{ color: "white" }} />
                  </>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

export default OtpScreen;
