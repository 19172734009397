import React, { useCallback, useEffect, useState } from "react";
import Header from "../../components/header/Header";
import { Container } from "@mui/material";
import Loader from "../../components/loader/Loader";
import trendsReportStyles from "./trendsReport.module.scss";
import { Box, MenuItem, Select } from "@material-ui/core";
import OrganTrendsAccordion from "./Accordions/organTrendsAccordion";
import {
  getOrganReport,
  getBiomarkerReport,
  getBiomarker,
} from "../../redux/actions/trendsReportAction";
import { ReactComponent as CalenderIcon } from "../../assets/svg/trendsReport/calender.svg";
import Breadcrumbs from "../../components/breadcrumbs/BreadCrumbs";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import BiomarkerReport from "./Accordions/biomarkerReports";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { getProfileInfo } from "../../redux/actions/GetBloodTestFirstAction";
import PatientInfo from "../../components/patient-info/PatientInfo";
import { breadCrumbsArr, biomarkerReportList } from "./utils/constants";
const TrendsReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    organReport,
    loading,
    biomarkerReport,
    biomarker,
    pageNumber,
    pageSize,
    profileInfo,
  } = useSelector((state) => state.trendsReport);
  const { parentProfileId } = useSelector((state) => state.dashboard);

  const location = useLocation();

  const [biomarkerDates, setBiomarkerDates] = useState([]);
  const [selected, setSelected] = useState("");
  const [trendsData, setTrendsData] = useState([]);
  const [openAccordion, setOpenAccordion] = useState(() =>
    Object.keys(biomarkerReportList).map((e) => biomarkerReportList[e])
  );
  const menuItemClasses = ["Optimized", "Grid", "Graph"];

  const stringFromValueColor = (status) => {
    switch (status) {
      case "Problematic":
        return { color: "rgba(255,140,93,1)" };
      case "Stressed":
        return { color: "rgba(193,175,10,1)" };
      case "Good":
        return { color: "rgba(43,147,6,1)" };
      case "unknown":
        return { color: "rgba(155,172,186,1)" };
      case "Dysfunctional":
        return { color: "rgba(255,4,4,1)" };
      default:
        return { color: "rgba(255,4,4,1)" };
    }
  };

  const handlePagination = (reportType, direction) => {
    const report = reportType === "organ" ? organReport : biomarkerReport;
    const totalRecords = report?.totalRecords;
    const totalPages = Math.ceil(totalRecords / pageSize);
    if (direction === "previous" && totalPages > pageNumber) {
      const next = {
        pageNumber: pageNumber + 1,
        pageSize,
        profileId: location.state.profileId,
      };
      dispatch(getOrganReport(next));
      dispatch(getBiomarkerReport(next));
    }

    if (direction === "next" && pageNumber > 1) {
      const previous = {
        pageNumber: pageNumber - 1,
        pageSize,
        profileId: location.state.profileId,
      };
      dispatch(getOrganReport(previous));
      dispatch(getBiomarkerReport(previous));
    }
  };

  const handleSelectedChange = (e) => {
    setSelected(e.target.value);
    const nameArray = [];
    Object.keys(biomarkerReportList)?.forEach((value) => {
      nameArray.push(biomarkerReportList[value]);
    });
    if (e.target.value === "Optimized" || e.target.value === "Grid") {
      const organName = trendsData.filter((e) => e.status === "Dysfunctional");
      setOpenAccordion([...nameArray, ...organName]);
    }
    if (e.target.value === "Graph") {
      biomarkerReport &&
        biomarkerReport?.data?.forEach((e) => {
          nameArray.push(e?.Biomarker_Name?.S);
        });
      trendsData &&
        trendsData?.forEach((value) => {
          nameArray.push(value?.name);
        });
      setOpenAccordion(nameArray);
    }
  };
  const getNewMaxLimit = (reportUpper, abnormalUpper, points) => {
    const newPoints = points
      ?.map((e) => {
        if (Number(e?.value) >= 0) return Number(e.value);
        return null;
      })
      .filter(Boolean);

    const maxValue = Math.max(...newPoints);
    if (newPoints.length && maxValue > Number(abnormalUpper)) {
      const halfPoint = maxValue / 2;
      const newReportUpperLimit = maxValue + halfPoint;

      if (newReportUpperLimit >= Number(reportUpper)) {
        return reportUpper;
      } else if (
        newReportUpperLimit < Number(reportUpper) &&
        Number(abnormalUpper) < newReportUpperLimit
      ) {
        return newReportUpperLimit.toFixed(1);
      } else {
        return reportUpper;
      }
    }

    return reportUpper;
  };

  const generateYLabels = (data, gender) => {
    const getLimitKey = (suffix) =>
      `${gender === "Male" ? "Man" : gender} ${suffix}`;
    const labels = [
      data["Report Lower Limit"]?.S,
      data[getLimitKey("Abnormal Lower Limit")]?.S,
      data[getLimitKey("Optimal Lower Limit")]?.S,
      data[getLimitKey("Optimal Upper Limit")]?.S,
      data[getLimitKey("Abnormal Upper Limit")]?.S,
      getNewMaxLimit(
        data["Report Upper Limit"]?.S,
        data[getLimitKey("Abnormal Upper Limit")]?.S,
        data.trendsReportData
      ),
    ];
    return labels;
  };
  useEffect(() => {
    if (!organReport?.data) return;

    let array = [];
    organReport.data.forEach((ele, index) => {
      let status;
      let val = ele?.lastFourReportData[0]?.finalScore;
      let count = ele?.lastFourReportData[0]?.count;
      if (val === 0 && count === 0) {
        status = "unknown";
      } else if (val >= 0 && val < 5) {
        status = "Good";
      } else if (val >= 5 && val < 7) {
        status = "Stressed";
      } else if (val >= 7 && val < 9) {
        status = "Problematic";
      } else {
        status = "Dysfunctional";
      }

      array.push({
        ...ele,
        status: status,
      });
    });

    const order = {
      Dysfunctional: 0,
      Problematic: 1,
      Stressed: 2,
      Good: 3,
      unknown: 4,
    };

    array.sort((a, b) => order[a.status] - order[b.status]);
    setTrendsData(array);
  }, [organReport]);
  useEffect(() => {
    if (!biomarkerReport?.date) return;
    const biomarkerDatesCopy =
      biomarkerReport?.date &&
      [...biomarkerReport?.date].map((e) => e?.S).reverse();
    setBiomarkerDates(biomarkerDatesCopy);
  }, [biomarkerReport]);
  useEffect(() => {
    let payload;
    if (location?.state?.profileId) {
      if (location.state.isShared === true) {
        payload = {
          profileId: location?.state?.profileId,
          parentProfileId: parentProfileId ? parentProfileId : "",
        };
      } else {
        payload = {
          profileId: location?.state?.profileId,
          parentProfileId: "",
        };
      }
      setSelected("Optimized");
      dispatch(getBiomarker());
      dispatch(
        getOrganReport({
          pageNumber: pageNumber,
          pageSize,
          profileId: location.state.profileId,
        })
      );
      dispatch(
        getBiomarkerReport({
          pageNumber: pageNumber,
          pageSize,
          profileId: location?.state?.profileId,
        })
      );
      dispatch(getProfileInfo(payload));
    }
  }, [location?.state?.profileId]);

  const patientInfoProps = {
    name: profileInfo?.profile?.FullName?.S,
    dob: profileInfo?.profile?.DateOfBirth?.S,
    gen: profileInfo?.profile?.Sex?.S,
    latestTestDateText: "Latest Test Date",
    testDate:
      location.state?.date &&
      location.state?.date[0] &&
      location.state?.date[0]?.BloodTestDate?.S,
    // inter: `Results are based on inputted values and interpreted for functionally optimal levels on ${moment(
    //   location.state?.date &&
    //     location.state?.date[0] &&
    //     location.state?.date[0]?.BloodTestStartDate?.S
    // )?.format("ll")} by Advanced Health Academy`,
    inter: `Results are based on inputted values and interpreted on ${moment(
      location.state?.date &&
        location.state?.date[0] &&
        location.state?.date[0]?.BloodTestStartDate?.S
    )?.format("ll")}`,
    oldestTestDateText: "Oldest Test Date",
    oldestTestDate:
      location.state?.date &&
      location.state?.date[location.state?.date?.length - 1] &&
      location.state?.date[location.state?.date?.length - 1]?.BloodTestDate?.S,
    totalReportsText: "Total Report",
    totalReports: location.state?.date && location.state?.date?.length,
  };

  return (
    <div>
      {location.state === null ? (
        <Navigate to={"/home"} />
      ) : (
        <>
          <Header showNavigations={false} />
          <div className={trendsReportStyles.contaninerBg}>
            <div className={trendsReportStyles.breadcrumbs}>
              <Breadcrumbs
                breadCrumbsArr={breadCrumbsArr}
                isBackBtnVisible={true}
                isNextBtnVisible={false}
                handleBack={() => {
                  setBiomarkerDates([]);
                  setTrendsData([]);
                  setOpenAccordion([]);
                  setSelected("");
                  navigate("/home");
                }}
                backButtonText={"Back to homepage"}
              />
            </div>
            <Loader loading={loading} />
            <div className={trendsReportStyles.profileName}>
              <PatientInfo {...patientInfoProps} />
            </div>
            <Container
              maxWidth="xl"
              className={trendsReportStyles.container}
              style={{
                opacity: loading ? 0.4 : 1,
                pointerEvents: loading ? "none" : "all",
              }}
            >
              <>
                <Box className={trendsReportStyles.trendsGraphReport}>
                  <Box className={trendsReportStyles.reportBox}>
                    <div className={trendsReportStyles.reportsHeading}>
                      <h3> Organ Trends</h3>
                    </div>
                    <div className={trendsReportStyles.accordionContainer}>
                      {trendsData &&
                        trendsData?.map((graph, index) => {
                          const finalScore = graph?.lastFourReportData
                            ?.map((e) => {
                              if (e?.finalScore == 0 && e?.count === 0)
                                return null;
                              return e?.finalScore;
                            })
                            ?.reverse();
                          const formattedDates = organReport?.date
                            .map((e) => {
                              const formattedDate =
                                moment(e).format("MMM DD YYYY");
                              const [mmm, dd, yyyy] = formattedDate.split(" ");
                              const mmm_dd = [`${mmm} `, dd].join("");
                              return [mmm_dd, yyyy];
                            })
                            ?.reverse();
                          return (
                            <OrganTrendsAccordion
                              key={index}
                              organName={graph?.name}
                              selected={selected}
                              openAccordion={openAccordion}
                              setOpenAccordion={setOpenAccordion}
                              reportLevelColor={
                                stringFromValueColor(graph.status)?.color
                              }
                              // reportLevelColor={
                              //   trendsData && stringFromValue(index)?.color
                              // }
                              reportLevel={
                                graph.status === undefined
                                  ? "Dysfunctional"
                                  : graph?.status
                              }
                              handlePagination={handlePagination}
                              description={graph?.content}
                              heading={`${graph?.name}`}
                              points={finalScore}
                              xLabel={formattedDates}
                            />
                          );
                        })}
                    </div>
                  </Box>
                  <Box className={trendsReportStyles.reportBox}>
                    <Box className={trendsReportStyles.BiomarkerContainer}>
                      <Box className={trendsReportStyles.header}>
                        <div className={trendsReportStyles.reportsHeading}>
                          <h3> Biomarker Trends</h3>
                        </div>

                        <Box className={trendsReportStyles.select}>
                          <label htmlFor="biomarker-dropdown">view:</label>
                          <Select
                            className={trendsReportStyles.dropDown}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selected}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                            onChange={handleSelectedChange}
                          >
                            {menuItemClasses.map((value) => (
                              <MenuItem key={value} value={value}>
                                {value}
                              </MenuItem>
                            ))}
                          </Select>
                        </Box>
                      </Box>
                      <Box className={trendsReportStyles.dateContainer}>
                        <Box className={trendsReportStyles.calenderIcon}>
                          <CalenderIcon />
                        </Box>
                        <Box className={trendsReportStyles.datesWrapper}>
                          <button
                            onClick={() =>
                              handlePagination("biomarker", "previous")
                            }
                          >
                            {"<<"}
                          </button>
                          <span></span>
                          {biomarkerReport &&
                            biomarkerDates?.map((e, index) => {
                              const [mmm, dd, yyyy] = moment(e)
                                .format("MMM DD YYYY")
                                .split(" ");
                              return (
                                <Box
                                  key={index}
                                  className={trendsReportStyles.date}
                                >
                                  <span>{`${mmm} ${dd}`}</span>
                                  <span>{yyyy}</span>
                                </Box>
                              );
                            })}
                          <span></span>
                          <button
                            onClick={() =>
                              handlePagination("biomarker", "next")
                            }
                          >
                            {">>"}
                          </button>
                        </Box>
                      </Box>
                      <Box className={trendsReportStyles.reportsContainer}>
                        {biomarkerReport &&
                          profileInfo &&
                          biomarker?.biomarker &&
                          Object?.keys(biomarker?.biomarker).map((key) => {
                            const value = biomarker?.biomarker[key];
                            const report = biomarkerReport?.data
                              ?.filter((e) => e?.Category?.S === key)
                              ?.map((e) => {
                                const gender =
                                  profileInfo?.profile?.Gender?.S || "Woman";
                                const yLabels = generateYLabels(e, gender);
                                return {
                                  yLabels,
                                  xLabel: biomarkerReport?.date,
                                  points: e["trendsReportData"],
                                  biomarkerName: e["Biomarker_Name"],
                                  unit: e["Conventional Units"]?.S,
                                };
                              });
                            return (
                              <BiomarkerReport
                                key={key}
                                openAccordion={openAccordion}
                                setOpenAccordion={setOpenAccordion}
                                selected={selected}
                                biomarkerName={biomarkerReportList[key]}
                                biomarkerData={value}
                                biomarkerReport={report}
                              />
                            );
                          })}
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <div className={trendsReportStyles.goToHomeButtonWrapper}>
                  <button
                    onClick={() => {
                      setBiomarkerDates([]);
                      setTrendsData([]);
                      setOpenAccordion([]);
                      setSelected("");
                      navigate("/home");
                    }}
                  >
                    Back to homepage
                  </button>
                </div>
              </>
            </Container>
          </div>
        </>
      )}
    </div>
  );
};

export default TrendsReport;
