import { GET_PROFILE_SUCCESS } from "../actionTypes/GetProfileActionTypes";
import {
  CLOSE_ACCOUNT_SUCCESS,
  DELETE_PROFILE_SUCCESS,
  GET_SETTINGS_FAILURE,
  GET_SETTINGS_REQUEST,
  LIST_PROFILE_SUCCESS,
  REMOVE_PROFILE_ACCESS_SUCCESS,
  RESTORE_PROFILE_SUCCESS,
  SHARE_LIST_PROFILE_SUCCESS,
  PROFILE_ACCESS_SUCCESS,
  SEND_FEEDBACK_SUCCESS,
  GET_PAYMENT_DATA,
  GET_SUBSCRIPTION_PLAN,
  SET_SUBSCRIPTION_PLAN,
  INVITE_OTHERS,
  GET_FAIL_PAYMENT,
  GET_PAYMENT_DATA_FOR_PROVIDER,
} from "../actionTypes/SettingsActionTypes";

const INITIAL_STATE = {
  restore: null,
  loading: false,
  listProfile: {},
  contactUs: {},
  paymentData: {},
  paymentDataForProvider: {},
  subsciptionPlan: {},
  setSubscription: {},
  failpaymentData: {},
};

const SettingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SETTINGS_REQUEST:
      return { ...state, loading: true, error: null };
    case CLOSE_ACCOUNT_SUCCESS:
      return { ...state, loading: false, error: null };
    case RESTORE_PROFILE_SUCCESS:
      return { ...state, loading: false, restore: action.payload, error: null };
    case LIST_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        listProfile: action.payload,
        error: null,
      };
    case DELETE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case REMOVE_PROFILE_ACCESS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case SEND_FEEDBACK_SUCCESS:
      return {
        ...state,
        loading: false,
        contactUs: action.payload,
        error: null,
      };
    case GET_PAYMENT_DATA:
      return {
        ...state,
        loading: false,
        paymentData: action.payload,
        error: null,
      };
      case GET_PAYMENT_DATA_FOR_PROVIDER:
        return {
          ...state,
          loading: false,
          paymentData: action.payload,
          error: null,
        };
    case GET_SUBSCRIPTION_PLAN:
      return {
        ...state,
        loading: false,
        subsciptionPlan: action.payload,
        error: null,
      };
    case GET_FAIL_PAYMENT:
      return {
        ...state,
        loading: false,
        failpaymentData: action.payload,
        error: null,
      };
    case SET_SUBSCRIPTION_PLAN:
      return {
        ...state,
        loading: false,
        setSubscription: action.payload,
        error: null,
      };
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case INVITE_OTHERS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case GET_SETTINGS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default SettingReducer;
