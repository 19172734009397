import {
  GET_SUGGESTIONS_SUCCESS,
  GET_USER_SUCCESS,
} from "../actionTypes/GetUserActionTypes";
import axiosInstance from "../../axios/axiosInstance";
import {
  GET_DASHBOARD_FAILURE,
  GET_DASHBOARD_REQUEST,
} from "../actionTypes/DashboardActionTypes";

export const fetchUserData = () => async (dispatch) => {
  try {
    const response = await axiosInstance("/v1/getUser", "POST", null);
    dispatch({
      type: GET_USER_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    console.log("error fetch user data", error);
  }
};

export const getSuggestions = (searchInfo, loader) => async (dispatch) => {
  loader(true);
  // dispatch({ type: GET_DASHBOARD_REQUEST });
  try {
    const response = await axiosInstance(
      "/v1/getSuggestions",
      "POST",
      searchInfo,
      null
    );
    dispatch({
      type: GET_SUGGESTIONS_SUCCESS,
      payload: response.data.data,
    });
    loader(false);
  } catch (error) {
    loader(false);
    dispatch({ type: GET_DASHBOARD_FAILURE, payload: error.message });
  }
};
