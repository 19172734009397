import React, { useEffect, useRef, useState } from "react";
import SignInModal from "../../components/modal/sign-in/SignInModal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import firebase from "../../config/FirebaseConfig";
import { fetchSignInData, fetchSocialData, setSocialDataLogin } from "../../redux/actions/SignInAction";
import { getErrorMessage } from "../../helper";
import SocialLogin from "../../components/modal/social-login/SocialLogin";
import { store } from "../../redux/store";

function SignInPage() {
  const [openModal, setOpenModal] = useState(false);
  const [socialModal, setSocialModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [signinLoading, setSigninLoading] = useState(false);
  const [signInError, setSignInError] = useState("");
  const data = useSelector((state) => state.signIn)
  const {flag} = useSelector((state) => state.signIn)
  const timer = useRef();
  const [signInData, setSignIn] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    password: "",
    locationPointer: "",
    notificationId: "",
    deviceSpecifications: "",
    provider: "",
  });
  const [Uid, setUid] = useState()
  const [socialData, setSocialData] = useState({
    mobileNumber: "",
  })

  const [payloadDataSet,setPayloadDataSet] = useState()
  const [providerIdSet,setProviderIdSet] = useState()
  // const [openSubmit,setOpenSubmit] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  useEffect(() => {
    setOpenModal(true);
    // setSocialModal(true)
    // return () => {
    //   clearTimeout(timer.current);
    // };
  }, []);

  // handle sign in button
  const handleSubmitSignIn = async (values) => {
    setSigninLoading(true);
    try {
      const firebaseUser = await firebase
        .auth()
        .signInWithEmailAndPassword(values.email, values.password);

      const payload = {
        ...values,
        userToken: firebaseUser?.user?.multiFactor?.user?.accessToken,
        deviceId: "12345",
      };
      // if (!loading) {
      //   setLoading(true);
      //   timer.current = window.setTimeout(() => {
      //     setLoading(false);
      //   }, 1000);
      // }
      const providerId = "Sign In Button";
      dispatch(fetchSignInData(payload, providerId));
      navigate("/home");
      setSigninLoading(false);
    } catch (error) {
      setSigninLoading(false);
      setSignInError(getErrorMessage(error.message));
    }
  };
  const handleSubmitSocial = async (values) => {
  
    try {
      const payload = {
        locationPointer: values.locationPointer,
        MobNumber: values.phoneNumberWithoutCountryCode
      }
      // console.log("valuess", values)
      // dispatch(setSocialDataLogin(payload))
    
      dispatch(fetchSignInData(payloadDataSet, providerIdSet,values,payload,flag));
      setSocialModal(false)
    } catch (error) {
      console.log(error)
    }
  };
  const handleOpen = () => {
    navigate("/sign-up", { replace: true });
  };
  const handleOpenPassModal = () => {
    navigate("/forgot-password");
  };
  useEffect(() => {
    if(flag && flag?.isFirstLogin === true && flag?.isFirstLogin !== undefined) {
      setSocialModal(true)
     }
  }, [flag?.isFirstLogin])
  // handle google login
  const handleGoogleLogin = async () => {
    try {
      var provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope("profile");
      provider.addScope("email");
      const response = await firebase.auth().signInWithPopup(provider);
      const payload = {
        email: response.user?.multiFactor?.user?.email
          ? response.user?.multiFactor?.user?.email
          : response.additionalUserInfo?.profile?.email,
        mobileNumber: response?.user.multiFactor?.user?.phoneNumber
          ? response.user.multiFactor?.user?.phoneNumber
          : "",
        locationPointer: "",
        notificationId: "",
        deviceSpecifications: "",
        provider: response.user.multiFactor?.user?.providerId
          ? response.user.multiFactor?.user?.providerId
          : response.additionalUserInfo?.providerId,
        userToken: response.user?.multiFactor?.user?.accessToken,
        deviceId: "12345",
        name: response.user.multiFactor?.user?.displayName
          ? response.user.multiFactor?.user?.displayName
          : response.additionalUserInfo?.profile?.name,
      };
      const providerId = response?.additionalUserInfo?.providerId;
      setPayloadDataSet(payload)
      setProviderIdSet(providerId)
      setUid(response?.user?.multiFactor?.user?.uid)
      dispatch(fetchSignInData(payload, providerId));
      
   } catch ({ code, email }) {
      if (code === "auth/account-exists-with-different-credential") {
        firebase
          .auth()
          .fetchSignInMethodsForEmail(email)

          .then((providers) => {
            if (providers.includes("google.com")) {
              handleGoogleLogin();
            }
          });
      }
    }
  };

  // handle facebook login
  const handleFacebookLogin = async () => {
    try {
      const provider = new firebase.auth.FacebookAuthProvider();
      provider.setCustomParameters({
        display: "popup",
      });
      provider.addScope("email");
      const result = await firebase.auth().signInWithPopup(provider);
      const payload = {
        email: result.user?.multiFactor?.user?.providerData[0]?.email,
        mobileNumber: result?.user.multiFactor?.user?.phoneNumber
          ? result.user.multiFactor?.user?.phoneNumber
          : "",
        locationPointer: "",
        notificationId: "",
        deviceSpecifications: "",
        provider: result.user.multiFactor?.user?.providerId,
        userToken: result.user?.multiFactor?.user?.accessToken,
        deviceId: "12345",
        name: result.user.multiFactor?.user?.displayName,
      };
      const providerId = result?.additionalUserInfo?.providerId;
      setPayloadDataSet(payload)
      setProviderIdSet(providerId)
      dispatch(fetchSignInData(payload, providerId));
    } catch (error) {
      console.log("facebook", error);
      // toast.error(error.message, toastStyle);
    }
  };

  // handle apple login
  const handleAppleLogin = async () => {
    try {
      const provider = new firebase.auth.OAuthProvider("apple.com");
      provider.addScope("email");
      provider.addScope("name");
      const result = await firebase.auth().signInWithPopup(provider);
      const payload = {
        email: result?.additionalUserInfo.profile?.email,
        mobileNumber: result?.user.multiFactor?.user?.phoneNumber
          ? result.user.multiFactor?.user?.phoneNumber
          : "",
        locationPointer: "",
        notificationId: "",
        deviceSpecifications: "",
        provider: result.additionalUserInfo?.providerId,
        userToken: result.user?.multiFactor?.user?.accessToken,
        deviceId: "12345",
        name: result.user.multiFactor?.user?.displayName,
      };
      const providerId = result?.additionalUserInfo?.providerId;
      setPayloadDataSet(payload)
      setProviderIdSet(providerId)
      dispatch(fetchSignInData(payload, providerId));
    } catch (error) {
      // toast.error(error.message, toastStyle);
      console.log("apple", error);
    }
  };
  return (
    <div id="login-page-BgImage">
      
      <SignInModal
        setSigninLoading={setSigninLoading}
        signinLoading={signinLoading}
        open={openModal}
        onClose={handleCloseModal}
        onClick={handleCloseModal}
        onSubmit={handleSubmitSignIn}
        onSignUpClick={handleOpen}
        loading={loading}
        disabled={loading}
        initialData={signInData}
        onPassClick={handleOpenPassModal}
        handleGoogleLogin={handleGoogleLogin}
        onFacebookLogin={handleFacebookLogin}
        onAppleLogin={handleAppleLogin}
        signInError={signInError}
        setSignInError={setSignInError}
      />
      <SocialLogin
        open={socialModal}
        onSubmit={handleSubmitSocial}
        initialData={socialData}
      />
    </div>
  );
}

export default SignInPage;