import React, { useEffect, useState } from "react";
import Loader from "../../../components/loader/Loader";
import Header from "../../../components/header/Header";
import BreadCrumbs from "../../../components/breadcrumbs/BreadCrumbs";
import ThirdStepStyles from "./thirdStep.module.scss";
import { Container } from "@mui/system";
import {
  NextButton,
  BackButton,
} from "../../../components/breadcrumbs/BreadCrumbs";
import { Grid } from "@mui/material";
import CustomDropdown from "./CustomDropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  getBloodTestValues,
  submitBloodTest,
  updateTestValues,
} from "../../../redux/actions/GetBloodTestFirstAction";
import { getNumberVal } from "../../../helper";

const ThirdStep = ({ setCurrentStep }) => {
  const breadCrumbsArr = [
    {
      title: "Home",
      link: "/home",
    },
    {
      title: "Get Blood test Interpreted",
      link: null,
    },
  ];
  const { firstPage, loading } = useSelector(
    (state) => state.getBloodTestFirst
  );

  const { bloodTestValues } = useSelector((state) => state.getBloodTestFirst);

  const [biomarkerData, setBiomarkerData] = useState({});
  const [units, setUnits] = useState({});
  const [confidenceValue, setConfidenceValue] = useState({});
  const { biomarkerInfo, reviewBloodTestData, parentProfileId } = useSelector(
    (state) => state.dashboard
  );
  const dispatch = useDispatch();
  const [loadingTimer, setLoadingTimer] = useState(false);
  useEffect(() => {
    const getData =
      firstPage?.startBloodTestData?.profileId &&
      reviewBloodTestData?.map((iterator) => {
        if (
          iterator?.profile?.ProfileID?.S ===
          firstPage?.startBloodTestData?.profileId &&
          iterator.isShared === true
        ) {
          return {
            profileId: firstPage?.startBloodTestData?.profileId,
            parentProfileId: parentProfileId,
            bloodTestId: firstPage?.startBloodTestData?.bloodTestId,
          };
        } else {
          return {
            profileId: firstPage?.startBloodTestData?.profileId,
            parentProfileId: "",
            bloodTestId: firstPage?.startBloodTestData?.bloodTestId,
          };
        }
      });
    const sharedProfile = getData?.find((e) => e?.parentProfileId);
    const unSharedProfile = getData?.find((e) => !e?.parentProfileId);
    setLoadingTimer(true);
    if (sharedProfile) {
      dispatch(getBloodTestValues(sharedProfile));
    } else {
      dispatch(getBloodTestValues(unSharedProfile));
    }
    const timer = setTimeout(() => {
      setLoadingTimer(false);
    }, 8000); 

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (
      bloodTestValues &&
      bloodTestValues.bloodTestData &&
      bloodTestValues.bloodTestData.BloodTestValues
    ) {
      setBiomarkerData(
        JSON.parse(bloodTestValues.bloodTestData.BloodTestValues.S)
      );
    }
    if (
      bloodTestValues &&
      bloodTestValues.bloodTestData &&
      bloodTestValues.bloodTestData.BloodTestUnitValues
    ) {
      setUnits(JSON.parse(bloodTestValues.bloodTestData.BloodTestUnitValues.S));
    }
    if (
      bloodTestValues &&
      bloodTestValues.bloodTestData &&
      bloodTestValues.bloodTestData.BloodTestConfidenceValues
    ) {
      setConfidenceValue(
        JSON.parse(bloodTestValues.bloodTestData.BloodTestConfidenceValues.S)
      );
    }
  }, [bloodTestValues, biomarkerInfo, dispatch]);
  const nextClick = () => {
    let parentProfileIdData;
    for (const iterator of reviewBloodTestData) {
      if (
        iterator?.profile?.ProfileID?.S ===
        firstPage?.startBloodTestData?.profileId &&
        iterator.isShared === true
      ) {
        parentProfileIdData = parentProfileId ? parentProfileId : "";
      }
    }
    const idData = {
      profileId: firstPage?.startBloodTestData?.profileId,
      bloodTestId: firstPage?.startBloodTestData?.bloodTestId,
      parentProfileId: parentProfileIdData,
      bloodTestDate: firstPage?.updateMetadata?.updatedAttributes?.BloodTestDate,
      manualEntry: "true",
    };
    const outputObject = {}
    for (const key in biomarkerData) {
      const value = biomarkerData[key]
      if (value && value?.includes('%')) {
        const num = parseFloat(value) / 100
        outputObject[key] = num.toString()
      } else {
        outputObject[key] = value
      }
    }
    const payload = {
      profileId: firstPage?.startBloodTestData?.profileId,
      bloodTestId: firstPage?.startBloodTestData?.bloodTestId,
      bloodTestValues: JSON.stringify(outputObject),
      bloodTestUnits: JSON.stringify(units),
      parentProfileId: parentProfileIdData,
      manualEntry: "true",
    };
    dispatch(updateTestValues(payload));
    
    dispatch(submitBloodTest(idData));

    setCurrentStep(4);
  };
  const handleChangeBiomarker = (e) => {
    const { name, value } = e.target;
    setBiomarkerData({ ...biomarkerData, [name]: value });
  };
  return (
    <div>
      <Header showNavigations={false} />
      <div
        className={ThirdStepStyles.contaninerBg}
        style={{
          opacity: loading || loadingTimer ? 0.4 : 1,
          pointerEvents: loading || loadingTimer ? "none" : "all",
        }}
      >
        <Loader loading={loading || loadingTimer} />
        <BreadCrumbs
          breadCrumbsArr={breadCrumbsArr}
        // isBackBtnVisible={true}
        // isNextBtnVisible={true}
        // handleBack={() => setCurrentStep(2)}
        // handleNext={nextClick}
        />
        <Container
          maxWidth="xl"
          style={{
            maxWidth: "1440px",
            paddingInline: "30px",
            opacity: loading || loadingTimer ? 0.4 : 1,
            pointerEvents: loading || loadingTimer ? "none" : "all",
          }}
        >
          <div className={ThirdStepStyles.getBlood_main_review}>
            <p className={ThirdStepStyles.getBlood_review_title}>
              Please Review and verify the values below…
            </p>
            <p className={ThirdStepStyles.getBlood_review_desc1}>
              The more information you can provide, the more complete our
              analysis will be:
            </p>
            <p className={ThirdStepStyles.getBlood_review_desc1}>
              Do not worry if parameters are missing or are ordered differently.
            </p>
          </div>

          <Grid className={ThirdStepStyles.getBlood_second_card}>
            <div className={ThirdStepStyles.getBlood_CBC_main}>
              <h1>Complete Blood Count (CBC)</h1>
              <Grid style={{ gap: "30px", display: "grid" }}>
                {biomarkerInfo.biomarker?.CBC.length &&
                  biomarkerInfo.biomarker?.CBC.map((val) => (
                    <CustomDropdown
                      name={val.Biomarker_Name.S}
                      value={
                        biomarkerData[val?.Biomarker_Name?.S] ||
                        biomarkerData[val.Field.S] ||
                        biomarkerData?.Biomarker_Name?.S
                      }
                      dropdown={
                        units[val.Biomarker_Name?.S] || units[val.Field.S]
                      }
                      blankValue={biomarkerData?.Biomarker_Name?.S}
                      convUnit={val["Conventional Units"]?.S}
                      siUnit={val["SI Units"]?.S}
                      altUnit={val["Alt units (conversion)"]?.S}
                      valueShow={
                        confidenceValue[val.Biomarker_Name?.S] ||
                        confidenceValue[val.Field?.S]
                      }
                      lowerLimit={val["Absolute Lower Limit"].S && getNumberVal(val["Absolute Lower Limit"].S)}
                      upperLimit={val["Absolute Upper Limit"].S && getNumberVal(val["Absolute Upper Limit"].S)}
                      handleChangeBiomarker={handleChangeBiomarker}
                      setUnits={setUnits}
                      units={units}
                    // error={val?.error}
                    />
                  ))}
              </Grid>
            </div>
            <div className={ThirdStepStyles.getBlood_CBC_main}>
              <h1>Comprehensive Metabolic Panel (CMP)</h1>
              <Grid style={{ gap: "30px", display: "grid" }}>
                {biomarkerInfo.biomarker?.CMP.length &&
                  biomarkerInfo.biomarker?.CMP.map((val) => (
                    <CustomDropdown
                      name={val.Biomarker_Name.S}
                      value={
                        biomarkerData[val?.Biomarker_Name?.S] ||
                        biomarkerData[val.Field.S]
                      }
                      valueShow={
                        confidenceValue[val.Biomarker_Name?.S] ||
                        confidenceValue[val.Field?.S]
                      }
                      dropdown={
                        units[val.Biomarker_Name?.S] || units[val.Field.S]
                      }
                      convUnit={val["Conventional Units"]?.S}
                      blankValue={biomarkerData?.Biomarker_Name?.S}
                      siUnit={val["SI Units"]?.S}
                      altUnit={val["Alt units (conversion)"]?.S}
                      lowerLimit={val["Absolute Lower Limit"].S && getNumberVal(val["Absolute Lower Limit"].S)}
                      upperLimit={val["Absolute Upper Limit"].S && getNumberVal(val["Absolute Upper Limit"].S)}
                      handleChangeBiomarker={handleChangeBiomarker}
                      setUnits={setUnits}
                      units={units}
                    />
                  ))}
              </Grid>
            </div>
            <Grid className={ThirdStepStyles.getBlood_CBC_main_wrapper}>
              <div className={ThirdStepStyles.getBlood_CBC_main}>
                <h1>Lipid Panel</h1>
                <Grid style={{ gap: "30px", display: "grid" }}>
                  {biomarkerInfo.biomarker?.Lipid.length &&
                    biomarkerInfo.biomarker?.Lipid.map((val) => (
                      <CustomDropdown
                        name={val.Biomarker_Name.S}
                        handleChangeBiomarker={handleChangeBiomarker}
                        value={
                          biomarkerData[val?.Biomarker_Name?.S] ||
                          biomarkerData[val.Field.S]
                        }
                        valueShow={
                          confidenceValue[val.Biomarker_Name?.S] ||
                          confidenceValue[val.Field?.S]
                        }
                        dropdown={
                          units[val.Biomarker_Name?.S] || units[val.Field.S]
                        }
                        blankValue={biomarkerData?.Biomarker_Name?.S}
                        convUnit={val["Conventional Units"]?.S}
                        lowerLimit={val["Absolute Lower Limit"].S && getNumberVal(val["Absolute Lower Limit"].S)}
                        upperLimit={val["Absolute Upper Limit"].S && getNumberVal(val["Absolute Upper Limit"].S)}
                        siUnit={val["SI Units"]?.S}
                        altUnit={val["Alt units (conversion)"]?.S}
                        setUnits={setUnits}
                        units={units}
                      />
                    ))}
                </Grid>
              </div>
              <div className={ThirdStepStyles.getBlood_CBC_main}>
                <h1>Common Other Blood Tests</h1>
                <Grid style={{ gap: "30px", display: "grid" }}>
                  {biomarkerInfo.biomarker?.Other.length &&
                    biomarkerInfo.biomarker?.Other.map((val) => (
                      <CustomDropdown
                        name={val.Biomarker_Name.S}
                        handleChangeBiomarker={handleChangeBiomarker}
                        valueShow={
                          confidenceValue[val.Biomarker_Name?.S] ||
                          confidenceValue[val.Field?.S]
                        }
                        value={
                          biomarkerData[val?.Biomarker_Name?.S] ||
                          biomarkerData[val.Field.S]
                        }
                        dropdown={
                          units[val.Biomarker_Name?.S] || units[val.Field.S]
                        }
                        altUnit={val["Alt units (conversion)"]?.S}
                        lowerLimit={val["Absolute Lower Limit"].S && getNumberVal(val["Absolute Lower Limit"].S)}
                        upperLimit={val["Absolute Upper Limit"].S && getNumberVal(val["Absolute Upper Limit"].S)}
                        blankValue={biomarkerData?.Biomarker_Name?.S}
                        convUnit={val["Conventional Units"]?.S}
                        siUnit={val["SI Units"]?.S}
                        setUnits={setUnits}
                        units={units}
                      />
                    ))}
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Container>
        <div className={ThirdStepStyles.BottomNextBtn}>
          <BackButton onClick={() => setCurrentStep(2)}>Back</BackButton>
          <NextButton onClick={nextClick}>Next</NextButton>
        </div>
      </div>
    </div>
  );
};

export default ThirdStep;
