import React, { useCallback, useEffect, useState } from "react";
import { ReactComponent as UserIcon } from "../../../assets/svg/user.svg";
import { ReactComponent as EmailIcon } from "../../../assets/svg/email.svg";
import { ReactComponent as LockIcon } from "../../../assets/svg/lock.svg";
import { ReactComponent as CloseIcon } from "../../../assets/svg/Close.svg";
import Checkbox from "@mui/material/Checkbox";
import signupCSS from "./SignUp.module.scss";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import libphonenumber from "google-libphonenumber";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import IconTextField from "../../common-textfield/IconTextField";
import CommonButton from "../../button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { Dialog, DialogContent } from "@mui/material";
import Box from "@mui/material/Box";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import axios from "axios";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import styled from "@emotion/styled";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import moment from "moment"; // Import Moment.js
import dayjs from "dayjs"; // Import dayjs
import { ReactComponent as AppleIcon } from "../../../assets/svg/apple.svg";
import { ReactComponent as FacebookIcon } from "../../../assets/svg/facebook.svg";
import { ReactComponent as GoogleIcon } from "../../../assets/svg/google.svg";
import MuiButton from "@mui/material/Button";
import signInCss from '../sign-in/SignIn.module.scss'

// checkbox style
const checkBoxStyles = (theme) => ({
  root: {
    "&$checked": {
      color: "#122D5B !important",
    },
  },
  checked: {},
});

export const ButtonStyle = styled(MuiButton)((props) => ({
  borderRadius: 33,
  padding: "14px 12px",
  width: "100%",
  border: "1px solid #707070",
  fontWeight: "bold",
  color: "black",
  textTransform: "capitalize",
  marginBottom: "10px",
  justifyContent: "space-around",
  paddingRight: "50px",
  fontSize: '0.86rem',

  "&:hover": {
    background: "white",
  },
}));
const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);
const CustomDesktopDatePicker = styled(DesktopDatePicker)(({ theme }) => ({
  borderRadius: "33px",
  minWidth: "284px",
  width: "100%",
  fontFamily: "Poppings",
  "& fieldset.MuiOutlinedInput-notchedOutline": {
    borderColor: "#D1D1D1 !important",
    borderRadius: "33px",
  },

  "& .MuiFormLabel-root": {
    color: "#D1D1D1 !important",
    top: "3px",
    "& ~ .MuiInputBase-root.MuiOutlinedInput-root": {
      height: "55px",

      "@media (max-height: 695px)": {
        height: "50px",
      },
      "@media (min-height: 696px) and (max-height: 764px)": {
        height: "50px",
      },
      "@media (min-height: 765px) and (max-height: 833px)": {
        height: "55px",
      },
    },
    // "&.Mui-error": {
    //   color: "#d32f2f",
    // },
  },
  "&:hover fieldset.MuiOutlinedInput-notchedOutline": {
    borderColor: "#D1D1D1",
  },
}));
const radioStyle = (theme) => ({
  root: {
    "&.Mui-checked": {
      color: "#122D5B !important",
    },
  },
});
const CustomRadio = withStyles(radioStyle)(Radio);
const SignUpModal = (props) => {
  const useStyles = makeStyles((theme) => ({
    dialogContent: {
      "&.MuiDialogContent-root": {
        padding: 0,
        scrollbarWidth: "none",
        overflow: props.titleName !== "Add Other" ? "auto" : "visible",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      },
    },
    inputError: {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "red !important",
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderWidth: "1px !important",
      },
    },
    signupInputContainer: {
      "& .MuiFormControl-root .MuiInputBase-root": {
        width: "350px",
        height: "65px",
        "@media (max-height: 695px)": {
          height: "40px",
        },
        "@media (min-height: 696px) and (max-height: 764px)": {
          height: "40px",
        },
        "@media (min-height: 765px) and (max-height: 833px)": {
          height: "40px",
        },
      },
    },
    signupInputContainerClass: {
      "& .MuiFormControl-root .MuiInputBase-root": {
        width: "414px",
        height: "65px",
        "@media (max-height: 695px)": {
          height: "50px",
        },
        "@media (min-height: 696px) and (max-height: 764px)": {
          height: "50px",
        },
        "@media (min-height: 765px) and (max-height: 833px)": {
          height: "50px",
        },
      },
    },
    socialMediaButtonsWrapper: {
      display: "flex",
      flexFlow: "column",
      gap: "10px",
      marginBottom: '15px',
      "& .MuiButton-startIcon": {
        position: "absolute",
        left: 19,
        "@media (max-height: 695px)": {
          left: 17,
        },
      },
    },
    borderClass: {
      borderRight: '1px solid #C4C4C4',
      paddingRight: '15px',
      width: '370px'
    },
    addOtherClass: {
      display: 'flex',
      // marginBottom: '20px'
    },
    errorMessage: {
      fontSize: "12px",
      color: "red",
      marginLeft: "19px",
      marginTop: "-5px",
    },
    socialMediaClass: {
      paddingLeft: '35px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    }
  }));
  const SignUpSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required("First name is required")
      .max(30, "First name must be at most 30 characters"),
    lName: Yup.string()
      .trim()
      .required("Last name is required")
      .max(30, "Last name must be at most 30 characters"),
    email: Yup.string()
      .email("The email address you entered is invalid.")
      .required("Email is required")
      .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
        "The email address you entered is invalid."
      )
      .trim(),
    password:
      props.titleName !== "Add Other"
        ? Yup.string()
          .required("Password is required")
          .min(6, "Pasword must be 6 or more characters")
          .trim()
        : Yup.string().min(6, "Pasword must be 6 or more characters").trim(),
  });
  const classes = useStyles();

  const [role, setRole] = useState("Consumer");
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [phoneNumberWithoutCountryCode, setPhoneNumberWithoutCountryCode] =
    useState("");
  const [countryCode, setCountryCode] = useState("");
  const [intialCountry, setIntialCountry] = useState("us");
  const [phoneNumberError, setphoneNumberError] = useState({
    isTouch: false,
    isError: false,
    errorMsg: "",
  });

  const [isActive, setIsActive] = useState(false);
  const [selected, setIsSelected] = useState("Current gender identity");
  const [isActiveClass, setIsActiveClass] = useState(false);
  const [selectedClass, setIsSelectedClass] = useState("Sex at birth");
  const [isActiveDescribe, setIsActiveDescribe] = useState(false);
  const [selectedDescribe, setSelectedDescribe] = useState(
    "How do they ethnically describe themself?"
  );
  const [isActiveSecond, setIsActiveSecond] = useState(false);
  const [selectedSecond, setSelectedSecond] = useState("Second Ethnicity");
  const [selectedItemList, setSelectedItemList] = useState();
  const [bdate, setBdate] = useState();

  const nativePlace = [
    "American Indian Or Alaska Native",
    "East Asian",
    "South Asian",
    "Other Asian",
    "African or Black Or Caribbean",
    "Native Hawaiian Or Other Pacific islander",
    "White Or European",
    "Hispanic/Latinx",
    "Decline To State",
  ];
  const checkboxClicked = (event) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);
    setError(!isChecked);
  };
  const changePasswordVisibility = () => setPasswordVisible(!passwordVisible);
  const getPasswordIcon = () => {
    const props = {
      onClick: changePasswordVisibility,
      className: signupCSS.passwordShow,
    };
    const PasswordIcon = passwordVisible
      ? RemoveRedEyeOutlinedIcon
      : VisibilityOffOutlinedIcon;

    return <PasswordIcon {...props} />;
  };
  const validatePhoneNumber = (phonenum, code) => {
    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
    try {
      const parsedNumber = phoneUtil.parse(phonenum, code);
      if (phoneUtil.isValidNumber(parsedNumber)) {
        setphoneNumberError((prev) => {
          return {
            ...prev,
            isError: false,
            errorMsg: "",
          };
        });
      } else {
        setphoneNumberError((prev) => {
          return {
            ...prev,
            isTouch: true,
            isError: true,
            errorMsg: "Invalid phone number.",
          };
        });
      }
    } catch (error) {
      setphoneNumberError((prev) => {
        return {
          ...prev,
          isTouch: true,
          isError: true,
          errorMsg: "Invalid phone number.",
        };
      });
    }
  };
  const handleNumberChange = (value, data, event, formattedValue) => {
    setPhoneNumberWithoutCountryCode(value.slice(data.dialCode.length));
    setCountryCode(data.dialCode);
    setPhoneNumber(value);
    if (value.slice(data.dialCode.length)) {
      validatePhoneNumber(
        value.slice(data.dialCode.length),
        data.countryCode?.toUpperCase()
      );
    } else {
      setphoneNumberError((prev) => {
        return {
          ...prev,
          isError: true,
          errorMsg: "Phone number is required.",
        };
      });
    }
  };
  const handleBlurPhone = () => {
    if (!phoneNumberWithoutCountryCode) {
      setphoneNumberError((prev) => {
        return {
          ...prev,
          isTouch: true,
          isError: true,
          errorMsg: "Phone number is required.",
        };
      });
    }
  };

  const radioButtonClicked = (event) => {
    setRole(event.target.value);
  };
  useEffect(() => {
    setPhoneNumber("");
    setphoneNumberError((prev) => {
      return {
        isTouch: false,
        isError: false,
        errorMsg: "",
      };
    });
    setIsSelected("Current gender identity");
    setIsSelectedClass("Sex at birth");
    setSelectedDescribe("How do they ethnically describe themself?");
    setSelectedSecond("Second Ethnicity");
    setBdate("");
    setIsActive(false);
  }, [props?.onClose, props?.onClick]);

  const isDisabled = (values, errors) => {
    return values.name &&
      values.lName &&
      values.email &&
      values.password &&
      !phoneNumberError.isError &&
      checked &&
      !Object.keys(errors)?.length
      ? false
      : true;
  };

  const checkIsDisabledForAddOther = (values, errors) => {
    return values.name &&
      values.lName &&
      values.email &&
      phoneNumber &&
      (bdate || dayjs("01-01-1982")) &&
      selected !== "Current gender identity" &&
      selectedClass !== "Sex at birth" &&
      selectedDescribe !== "How do they ethnically describe themself?" &&
      !Object.keys(errors)?.length
      ? false
      : true;
  };

  const removeWhiteSpace = (e) => {
    e.target.value = e.target.value.trim();
  };

  const changeBirthday = (newValue) => {
    const formatedDate = newValue?.format("MM/DD/YYYY");
    setBdate(formatedDate);
  };
  const changeNativePlace = (e) => {
    setSelectedDescribe(e.target.textContent);
    setIsActiveDescribe(!isActiveDescribe);
    setSelectedItemList(
      nativePlace.filter((option) => option !== e.target.textContent)
    );
  };

  useEffect(() => {
    const getGeoInfo = async () => {
      try {
        axios.get("https://ipapi.co/json/").then((response) => {
          setIntialCountry(response.data.country_code);
        });
      } catch (error) {
        console.log(error);
      }
    };

    getGeoInfo();
  }, []);
  useEffect(() => {
    if (!props?.open) {
      setChecked(false);
    }
  }, [props?.open]);

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.titleName == "Add Other" ? props.onClose : ""}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "20px",
            padding: "0px 75px",
            overflowY: "scroll",
            maxWidth: 'fit-content',
            "@media (max-height: 695px)": {
              // padding: "0px 47px",
              padding: "0px 47px 30px 47px"
            },
            "@media (min-height: 696px) and (max-height: 764px)": {
              // padding: "0px 50px",
              padding: "0px 50px 30px 50px"
            },
            "@media (min-height: 765px) and (max-height: 833px)": {
              // padding: "0px 65px",
              padding: "0px 65px 30px 65px"
            },
            margin: 0,
          },
        }}
      >
        {props.titleName == "Add Other" && (
          <Box className={signupCSS.closebtn}>
            <CloseIcon size={"17px"} onClick={props.onClick} />
          </Box>
        )}
        <h1 className={signupCSS.signupTitle}>{props.titleName}</h1>
        <DialogContent className={classes.dialogContent}>
          <Formik
            initialValues={props.initialData}
            validationSchema={SignUpSchema}
            onSubmit={(values) => {
              const data = {
                ...values,
                mobileNumber: phoneNumber,
                role: role,
                phoneNumberWithoutCountryCode,
                locationPointer: countryCode,
                dateOfBirth: moment(bdate)?.format("MM/DD/YYYY") || dayjs("01-01-1982"),
                gender: selected,
                sex: selectedClass,
                ethnicity: selectedDescribe,
              };
              props.onSubmit(data);
            }}
          >
            {({
              values,
              handleBlur,
              handleChange,
              touched,
              errors,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className={classes.addOtherClass}>
                  <div className={props.titleName !== "Add Other" ? classes.borderClass : ''}>
                    <Box
                      className="App"
                      sx={{
                        display: "grid",
                        gridGap: 15,
                        position: "relative",
                        top: "1px",
                        "@media (max-height: 695px)": {
                          gridGap: 12,
                        },
                        "@media (min-height: 696px) and (max-height: 765px)": {
                          gridGap: 13,
                        },
                        "@media (min-height: 765px) and (max-height: 833px)": {
                          gridGap: 14,
                        },
                      }}
                    >
                      <div className={props.titleName !== "Add Other" ? classes.signupInputContainer : classes.signupInputContainerClass}>
                        <IconTextField
                          iconStart={<UserIcon />}
                          placeholder="First name"
                          name="name"
                          type="text"
                          onChange={(e) => {
                            handleChange(e);
                            removeWhiteSpace(e);
                          }}
                          onBlur={handleBlur}
                          value={values.name}
                          onPaste={removeWhiteSpace}
                          autoComplete="off"
                          className={
                            touched.name && errors.name ? classes.inputError : ""
                          }
                        />
                        {touched.name && errors.name ? (
                          <div className={signupCSS.errorMessage}>
                            {errors.name}
                          </div>
                        ) : null}
                      </div>
                      <div className={props.titleName !== "Add Other" ? classes.signupInputContainer : classes.signupInputContainerClass}>
                        <IconTextField
                          iconStart={<UserIcon />}
                          placeholder="Last name"
                          name="lName"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.lName}
                          autoComplete="off"
                          className={
                            touched.lName && errors.lName ? classes.inputError : ""
                          }
                        />
                        {touched.lName && errors.lName ? (
                          <div className={signupCSS.errorMessage}>
                            {errors.lName}
                          </div>
                        ) : null}
                      </div>
                      <div className={props.titleName !== "Add Other" ? classes.signupInputContainer : classes.signupInputContainerClass}>
                        <IconTextField
                          iconStart={<EmailIcon />}
                          placeholder="Enter email address"
                          name="email"
                          type="email"
                          onChange={(e) => {
                            handleChange(e);
                            if (props?.setEmailExist) {
                              props.setEmailExist("");
                            }
                          }}
                          onBlur={handleBlur}
                          value={values.email}
                          autoComplete="off"
                          className={
                            (touched.email && errors.email) || props.emailExist
                              ? classes.inputError
                              : ""
                          }
                        />
                        {touched.email && errors.email ? (
                          <div className={signupCSS.errorMessage}>
                            {errors.email}
                          </div>
                        ) : null}
                        {props.emailExist ? (
                          <div className={signupCSS.errorMessage}>
                            {props.emailExist}
                          </div>
                        ) : null}
                      </div>
                      <div>
                        {props.titleName !== "Add Other" ? <PhoneInput
                          country={
                            intialCountry
                              ? intialCountry?.toLowerCase()
                              : intialCountry
                          }
                          enableSearch={true}
                          name="mobileNumber"
                          placeholder="Enter mobile number"
                          onChange={handleNumberChange}
                          onBlur={handleBlurPhone}
                          value={phoneNumber}
                          // error={props.phoneError}
                          countryCodeEditable={false}
                          autocomplete="off"
                          searchNotFound="No matching countries found."
                          className={
                            phoneNumberError.isError && phoneNumberError.isTouch
                              ? signupCSS.phoneInputErrorClass
                              : signupCSS.phoneInputClass
                          }
                        /> : <PhoneInput
                          country={
                            intialCountry
                              ? intialCountry?.toLowerCase()
                              : intialCountry
                          }
                          enableSearch={true}
                          name="mobileNumber"
                          placeholder="Enter mobile number"
                          onChange={handleNumberChange}
                          onBlur={handleBlurPhone}
                          value={phoneNumber}
                          // error={props.phoneError}
                          countryCodeEditable={false}
                          autocomplete="off"
                          searchNotFound="No matching countries found."
                          className={
                            phoneNumberError.isError && phoneNumberError.isTouch
                              ? signupCSS.phoneInputError
                              : signupCSS.phoneInput
                          }
                        />}

                        {phoneNumberError.isError && phoneNumberError.isTouch ? (
                          <div className={signupCSS.errorMessage}>
                            {phoneNumberError.errorMsg}
                          </div>
                        ) : null}
                      </div>
                      {props.titleName !== "Add Other" && (
                        <div className={props.titleName !== "Add Other" ? classes.signupInputContainer : classes.signupInputContainerClass}>
                          <IconTextField
                            iconStart={<LockIcon />}
                            type={passwordVisible ? "text" : "password"}
                            placeholder="Enter password"
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            autocomplete="off"
                            iconEnd={getPasswordIcon()}
                            className={
                              touched.password && errors.password
                                ? classes.inputError
                                : ""
                            }
                          />
                          {touched.password && errors.password ? (
                            <div className={signupCSS.errorMessage}>
                              {errors.password}
                            </div>
                          ) : null}
                        </div>
                      )}
                      {props.titleName === "Add Other" && (
                        <>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <CustomDesktopDatePicker
                              format="MM/DD/YYYY"
                              label="Date of Birth"
                              maxDate={dayjs("12-31-2005")}
                              name="dateOfBirth"
                              onChange={changeBirthday}
                              defaultValue={dayjs("01-01-1982")}
                            />
                          </LocalizationProvider>
                        </>
                      )}
                      {props.titleName === "Add Other" && (
                        <>
                          <div className={signupCSS.dropdown}>
                            <div
                              onClick={(e) => {
                                setIsActiveClass(!isActiveClass);
                              }}
                              className={signupCSS.dropdown_btn}
                            >
                              {selectedClass}
                              {isActiveClass ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </div>
                            <div
                              className={signupCSS.dropdown_content}
                              style={{ display: isActiveClass ? "block" : "none" }}
                            >
                              <div
                                onClick={(e) => {
                                  setIsSelectedClass(e.target.textContent);
                                  setIsActiveClass(!isActiveClass);
                                }}
                                className={signupCSS.item}
                              >
                                Male
                              </div>
                              <div
                                className={signupCSS.item}
                                onClick={(e) => {
                                  setIsSelectedClass(e.target.textContent);
                                  setIsActiveClass(!isActiveClass);
                                }}
                              >
                                Female
                              </div>
                              <div
                                className={signupCSS.item}
                                onClick={(e) => {
                                  setIsSelectedClass(e.target.textContent);
                                  setIsActiveClass(!isActiveClass);
                                }}
                              >
                                Intersex/Other
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {props.titleName === "Add Other" && (
                        <>
                          <div className={signupCSS.dropdown}>
                            <div
                              onClick={(e) => {
                                setIsActive(!isActive);
                              }}
                              className={signupCSS.dropdown_btn}
                            >
                              {selected}
                              {isActive ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </div>
                            <div
                              className={signupCSS.dropdown_content}
                              style={{ display: isActive ? "block" : "none" }}
                            >
                              <div
                                onClick={(e) => {
                                  setIsSelected(e.target.textContent);
                                  setIsActive(!isActive);
                                }}
                                className={signupCSS.item}
                              >
                                Man
                              </div>
                              <div
                                className={signupCSS.item}
                                onClick={(e) => {
                                  setIsSelected(e.target.textContent);
                                  setIsActive(!isActive);
                                }}
                              >
                                Woman
                              </div>
                              <div
                                className={signupCSS.item}
                                onClick={(e) => {
                                  setIsSelected(e.target.textContent);
                                  setIsActive(!isActive);
                                }}
                              >
                                Other
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {props.titleName === "Add Other" && (
                        <>
                          <div className={signupCSS.dropdown}>
                            <div
                              onClick={(e) => {
                                setIsActiveDescribe(!isActiveDescribe);
                              }}
                              className={signupCSS.dropdown_btn}
                            >
                              {selectedDescribe}
                              {isActiveDescribe ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </div>
                            <div
                              className={signupCSS.dropdown_content}
                              style={{
                                display: isActiveDescribe ? "block" : "none",
                              }}
                            >
                              {nativePlace.map((item) => (
                                <div
                                  onClick={(e) => changeNativePlace(e)}
                                  className={signupCSS.item}
                                >
                                  {item}
                                </div>
                              ))}
                            </div>
                          </div>
                        </>
                      )}
                      {props.titleName === "Add Other" &&
                        selectedDescribe !==
                        "How do they ethnically describe themself?" && (
                          <>
                            <div className={signupCSS.dropdown}>
                              <div
                                onClick={(e) => {
                                  setIsActiveSecond(!isActiveSecond);
                                }}
                                className={signupCSS.dropdown_btn}
                              >
                                {selectedSecond}
                                {isActiveSecond ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                )}
                              </div>
                              <div
                                className={signupCSS.dropdown_content}
                                style={{
                                  display: isActiveSecond ? "block" : "none",
                                }}
                              >
                                {selectedItemList.map((item) => (
                                  <div
                                    onClick={(e) => {
                                      setSelectedSecond(e.target.textContent);
                                      setIsActiveSecond(!isActiveSecond);
                                    }}
                                    className={signupCSS.item}
                                  >
                                    {item}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </>
                        )}

                      {props.titleName !== "Add Other" && (
                        <FormControl sx={{ marginLeft: "16px" }}>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={role}
                            onChange={radioButtonClicked}
                          >
                            <div className={signupCSS.radioButton}>
                              <FormControlLabel
                                value="Consumer"
                                defaultChecked
                                control={<CustomRadio />}
                                label="Consumer"
                                sx={{ color: "#8A8A8A" }}
                              />
                              <FormControlLabel
                                value="Provider"
                                control={<CustomRadio />}
                                label="Provider"
                                sx={{ color: "#8A8A8A" }}
                              />
                            </div>
                          </RadioGroup>
                        </FormControl>
                      )}
                    </Box>
                    {props.titleName !== "Add Other" && (
                      <>
                        {/* <div className={signupCSS.policy_link}>
                      <div><a href="https://www.ahealthacademy.com/privacy-policy/" target="_blank">View Privacy Policy</a></div>
                      <div><a href="https://www.ahealthacademy.com/terms-of-service/" target="_blank">View Terms of Service</a></div>
                      </div> */}
                        <div className={`${signupCSS.checkboxContent}`}>
                          <CustomCheckbox
                            sx={{
                              marginLeft: "6px",
                            }}
                            checked={checked}
                            onChange={checkboxClicked}
                          />
                          <h6 className={`${signupCSS.content}`}>
                            I have read and agree to Advanced Health Academy’s<a href="https://www.ahealthacademy.com/terms-of-service/" target="_blank">Terms
                              of Service</a>, HIPAA Authorisation, and <a href="https://www.ahealthacademy.com/privacy-policy/" target="_blank">Privacy Policy</a>
                          </h6>
                        </div>
                        {error ? (
                          <div className={signupCSS.errorMessage}>
                            Please Accept Terms and Conditions
                          </div>
                        ) : null}
                      </>
                    )}
                    <div
                      style={{
                        cursor:
                          props.titleName !== "Add Other"
                            ? isDisabled(values, errors)
                              ? "not-allowed"
                              : "pointer"
                            : checkIsDisabledForAddOther(values, errors)
                              ? "not-allowed"
                              : "pointer",
                      }}
                    >
                      <CommonButton
                        loading={props.signupLoading}
                        disabled={
                          props.titleName !== "Add Other"
                            ? isDisabled(values, errors) || props?.signupLoading
                            : checkIsDisabledForAddOther(values, errors)
                        }
                        title={
                          props?.signupLoading ? "Please wait..." : props.signUpName
                        }
                        className={
                          props.titleName !== "Add Other"
                            ? `${signupCSS.signupBtn}`
                            : `${signupCSS.addOtherBtn}`
                        }
                      />
                    </div>
                  </div>

                  {/* <p className={signInCss.options} style={{marginTop:'10px'}}>OR</p> */}
                  <div className={props.titleName !== "Add Other" && classes.socialMediaClass}>
                    {props.titleName !== "Add Other" && <div className={classes.socialMediaButtonsWrapper}>
                      {/* <BiLogoFacebookCircle size="27px" color="#1976d2" /> */}
                      <ButtonStyle
                        className={signInCss.facebookBtn}
                        sx={{
                          "& svg": {
                            height: "24px",
                            width: "25px",
                          },
                        }}
                        variant="text"
                        onClick={props.handleGoogleLogin}
                        startIcon={<GoogleIcon />}
                      >
                        Continue with Google
                      </ButtonStyle>
                      <ButtonStyle
                        className={signInCss.facebookBtn}
                        sx={{
                          "& svg": {
                            height: "25px",
                            width: "25px",
                          },
                        }}
                        variant="text"
                        startIcon={<FacebookIcon />}
                        onClick={props.onFacebookLogin}
                      >
                        Continue with Facebook
                      </ButtonStyle>

                      <ButtonStyle
                        className={signInCss.facebookBtn}
                        sx={{
                          "& svg": {
                            height: "27px",
                          },
                        }}
                        variant="text"
                        startIcon={<AppleIcon />}
                        onClick={props.onAppleLogin}
                      >
                        Continue with Apple
                      </ButtonStyle>
                    </div>}
                    {props.titleName !== "Add Other" && (
                      <div className={`${signupCSS.haveAcc}`}>
                        Already have an account ?{" "}
                        <span onClick={props.onSignInClick}>Sign In</span>
                      </div>
                    )}

                  </div>

                </div>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SignUpModal;
