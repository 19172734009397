import { Card } from "@mui/material";
import React from "react";
import insightsCss from "./insights.module.scss";
import Rest from "../../assets/svg/enough-sleep.svg";
import Diet from "../../assets/svg/vegetables.svg";
import Hydration from "../../assets/svg/water.svg";
import Exercise from "../../assets/svg/meditation.svg";
import Medical from "../../assets/svg/medical-team.svg";
import { useSelector } from "react-redux";

function Insights({ insightsData }) {
  const {
    userInfo,
  } = useSelector((state) => state.dashboard);
  const userRole = userInfo?.user?.role?.S;
  const handleCategoryIcon = (category) => {
    switch (category) {
      case "InsightsMedical":
        return <img src={Medical} alt="medical" />;

      case "InsightsDiet":
        return <img src={Diet} alt="diet" />;

      case "InsightsHydration":
        return <img src={Hydration} alt="hydration" />;

      case "InsightsExercise":
        return <img src={Exercise} alt="exercise" />;

      case "InsightsRest":
        return <img src={Rest} alt="rest" />;

      default:
        break;
    }
  };
  const filteredInsights = insightsData?.res?.filter((item) => {
    if (item.category === "InsightsMedical" && userRole == "Provider") {
      return false;
    }
    return true;
  });


  const noInsights = () => {
    if (insightsData?.res?.every((e) => e.description === "")) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div>
      <div className={insightsCss.insightsMain}>
        <Card className={insightsCss.insights_main_box}>
          {!noInsights() ? (
            filteredInsights?.length > 0 ? (
              filteredInsights?.map((item) => {
                const textDescription = item?.description;
                const paragraphs = textDescription?.split("\n\n");
                return (
                  <div className={insightsCss.seek_medical} key={item.title}>
                    {textDescription && (
                      <>
                        <div className={insightsCss.insightsImage}>
                          {handleCategoryIcon(item?.category)}
                          {userInfo && userInfo?.user?.role?.S == 'Provider' ?
                          <p>{item?.title == "Exercise More" ? "Exercise" : item?.title == "Enhance Rest" ? "Rest" : item?.title == "Change Diet" ? "Diet" : item?.title == "Increase Hydration" ? "Hydration" : item?.title}</p>
                           :<p>{item?.title}</p>}
                        </div>
                        <div className={insightsCss.seek_info}>
                          {paragraphs?.map((paragraph, index) => (
                            <span key={index}>
                              {paragraph}
                              <br />
                              <br />
                            </span>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                );
              })
            ) : (
              <p className="commonSummaryError">No Insights Found</p>
            )
          ) : (
            <p className="commonSummaryError">No Insights Found</p>
          )}
        </Card>
      </div>
    </div>
  );
}

export default Insights;
