import React, { useEffect, useState } from "react";
import Header from "../../../components/header/Header";
import fifthStepStyles from './fifthstep.module.scss'
import BreadCrumbs, { BackButton, NextButton } from "../../../components/breadcrumbs/BreadCrumbs";
import Loader from "../../../components/loader/Loader";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "@mui/system";
import dashboardCss from '../../dashboard/dashboard.module.scss'
import fourthStepStyles from '../FourthStep/fourthStep.module.scss'
import PatientInfo from "../../../components/patient-info/PatientInfo";
import moment from "moment";
import { Grid } from "@mui/material";
import ReportSummary from "../../../components/report-summary/ReportSummary";
import OrganOverview from "../../../components/organ-overview/OrganOverview";
import { fetchGetBloodOrganData, getBloodTestValues, getProfileInfo } from "../../../redux/actions/GetBloodTestFirstAction";
import { organOverviewMock } from "../../../json/mockData";
import Insights from "../../../components/insighṭs/Insights";
import backArrow from '../../../assets/svg/backArrow.svg'
import nextArrow from '../../../assets/svg/nextArrow.svg'
import ThirdStepStyles from '../ThirdStep/thirdStep.module.scss'


const FifthStep = ({ setCurrentStep, profileId, bloodTestId, resultId }) => {
    const breadCrumbsArr = [
        {
            title: "Home",
            link: "/home",
        },
        {
            title: "Review Blood Test",
            link: null,
        },
    ];
    // const [openModal, setOpenModal] = useState(false)
    const [isCompare, setIsCompare] = useState(false);
    const [array, setArray] = useState([]);
    const { biomarkerInfo, reviewBloodTestData, parentProfileId, userInfo } = useSelector(
        (state) => state.dashboard
    );
    const { fourthPage, bloodTestValues, firstPage, loading } = useSelector(
        (state) => state.getBloodTestFirst
    );
    const navigate = useNavigate()
    const dispatch = useDispatch()
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);
    useEffect(() => {
        let parentProfileIdData;
        if (reviewBloodTestData.length > 0) {
            for (const iterator of reviewBloodTestData) {
                if (
                    iterator?.profile?.ProfileID?.S ===
                    (profileId ? profileId : firstPage?.startBloodTestData?.profileId) &&
                    iterator.isShared === true
                ) {
                    parentProfileIdData = parentProfileId;
                }
            }
        }
        const profilePayload = {
            profileId: profileId
                ? profileId
                : firstPage?.startBloodTestData?.profileId,
            parentProfileId: parentProfileIdData ? parentProfileIdData : "",
        };
        const data = {
            profileId: profileId
                ? profileId
                : firstPage?.startBloodTestData?.profileId,
            bloodTestId: bloodTestId
                ? bloodTestId
                : firstPage?.startBloodTestData?.bloodTestId,
            parentProfileId: parentProfileIdData ? parentProfileIdData : "",
        };
        compareDate();
        dispatch(getBloodTestValues(data));
        dispatch(getProfileInfo(profilePayload));
        if (resultId) {
            const payload = {
                profileId: profileId,
                bloodTestId: bloodTestId,
                resultId: resultId,
                parentProfileId: parentProfileIdData ? parentProfileIdData : "",
            };
            dispatch(fetchGetBloodOrganData(payload));
        }
    }, []);
    useEffect(() => {
        let finalArray = [];
        const organOverview = fourthPage?.organData?.res
            ? fourthPage?.organData?.res?.OrganData?.S &&
            JSON.parse(fourthPage?.organData?.res?.OrganData?.S)
            : "";
        const organ = organOverview && Object.keys(organOverview);
        if (organ) {
            for (const key of organ) {
                const content = organOverviewMock.filter(
                    (val) => val.fieldName === key
                );
                if (key !== "Other") {
                    finalArray.push({
                        name: key,
                        count: organOverview[key].count,
                        finalScore: organOverview[key].finalScore,
                        data: content[0]?.content ? content[0]?.content : "",
                        organImpact: organOverview[key].maxScores,
                    });
                    setArray(finalArray);
                }
            }
        }
    }, [fourthPage?.organData]);
    const compareDate = () => {
        const convertDate = 1683797069;
        const date2 = moment(
            bloodTestValues?.bloodTestData?.BloodTestStartDate?.S
        ).unix();
        if (date2 > convertDate) {
            setIsCompare(true);
        } else {
            setIsCompare(false);
        }
    };
    const nextScreen = () => {
        setCurrentStep(6)
    }

    return (
        <div>
            <Header showNavigations={false} />
            <div className={fifthStepStyles.contaninerBg}>
                <Loader loading={loading} />
                {/* {!resultId && (
                    <BreadCrumbs
                        breadCrumbsArr={breadCrumbsArr}
                        isBackBtnVisible={true}
                        isNextBtnVisible={false}
                        handleBack={() => navigate("/home")}
                        backButtonText={"Back to homepage"}
                    />
                )} */}
                <div className={fifthStepStyles.breadCrumb}>
                    <div>
                        <BreadCrumbs
                            breadCrumbsArr={breadCrumbsArr}
                            // isBackBtnVisible={true}
                            isNextBtnVisible={false}
                            handleBack={() => navigate("/home")}
                        // backButtonText={"Back to homepage"}
                        />
                    </div>
                    <div>
                        <div className={fifthStepStyles.btns}>
                            <div className={fifthStepStyles.btnsArrow} onClick={() => navigate('/home')}>
                                <div>
                                    <img src={backArrow} alt="backarrow" />
                                </div>
                                <div>
                                    Return To Reports
                                </div>
                            </div>
                            <div className={fifthStepStyles.btnsArrow} onClick={nextScreen}>
                                <div>
                                 Proceed to view biomarkers
                                </div>
                                <div>
                                    <img src={nextArrow} alt="backarrow" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <BackButton onClick={() => setCurrentStep(6)}>Next to View Biomarkers</BackButton> */}
                <Container
                    style={{
                        maxWidth: "1440px",
                        paddingInline: "30px",
                        opacity: loading ? 0.4 : 1,
                        paddingTop: resultId ? "1px" : "",
                        pointerEvents: loading ? "none" : "all",
                    }}
                >
                    <div>
                        {resultId ? (
                            <div className={fourthStepStyles.back_button}>
                                <p className={fifthStepStyles.report_Title}>
                                    Blood Test Report
                                </p>
                            </div>
                        ) : (
                            <p className={fourthStepStyles.patientInfo_Title}>
                                Patient Information
                            </p>
                        )}
                        <PatientInfo
                            name={fourthPage?.profileInfo?.profile?.FullName?.S}
                            dob={fourthPage?.profileInfo?.profile?.DateOfBirth?.S}
                            gen={fourthPage?.profileInfo?.profile?.Sex?.S}
                            testDate={bloodTestValues?.bloodTestData?.BloodTestDate?.S}
                            // inter={`Results are based on inputted values and interpreted for functionally optimal levels on ${moment(
                            //     bloodTestValues?.bloodTestData?.BloodTestStartDate?.S
                            // ).format("ll")} by Advanced Health Academy`}
                            inter={`Results are based on inputted values and interpreted on ${moment(
                                bloodTestValues?.bloodTestData?.BloodTestStartDate?.S
                            ).format("ll")}`}
                        />
                    </div>
                    {/* <Grid className={dashboardCss.section}> */}
                    <div className={dashboardCss.sectionWrapper}>
                        <p
                            style={{ flex: " 0 0 32px" }}
                            className={dashboardCss.report_summary_title_consumer}
                        >
                            Summary
                        </p>
                        <div
                            style={{ flex: "1 1 auto" }}
                            className={dashboardCss.section_para}
                        >
                            {fourthPage && fourthPage?.organData && <ReportSummary
                                summary1={fourthPage?.organData?.res?.Summary1?.S}
                                summary2={fourthPage?.organData?.res?.Summary2?.S}
                                summary3={fourthPage?.organData?.res?.Summary3?.S}
                                summary4={fourthPage?.organData?.res?.Summary4?.S}
                                summary5={fourthPage?.organData?.res?.Summary5?.S}
                                summary6={fourthPage?.organData?.res?.Summary6?.S}
                                summary7={fourthPage?.organData?.res?.Summary7?.S}
                                summary8={fourthPage?.organData?.res?.Summary8?.S}
                                SummaryExplanation={
                                    fourthPage?.organData?.res?.SummaryExplanation?.S
                                }
                                SummaryLast={fourthPage?.organData?.res?.SummaryLast?.S}
                                SummaryOpener={fourthPage?.organData?.res?.SummaryOpener?.S}
                                newDate={isCompare}
                                userRole={'Provider'}
                            />}

                        </div>

                    </div>

                    <div className={dashboardCss.sectionWrapper}>
                        <p
                            style={{ flex: " 0 0 32px" }}
                            className={dashboardCss.report_summary_title_consumer}
                        >
                            Insights
                        </p>
                        <div
                            style={{ flex: "1 1 auto" }}
                            className={dashboardCss.section_para}
                        >
                            <Insights insightsData={fourthPage?.insightsData} />
                        </div>
                    </div>

                    <div className={dashboardCss.sectionWrapper}>
                        <p
                            style={{ flex: " 0 0 32px" }}
                            className={dashboardCss.report_summary_title_consumer}
                        >
                            Organ Overview
                        </p>
                        <div
                            style={{ flex: "1 1 auto" }}
                            className={dashboardCss.section_para}
                        >
                            <OrganOverview
                                organData={array}
                                gender={fourthPage?.profileInfo?.profile?.Gender?.S}
                                userRole={'Provider'}
                            />
                        </div>
                    </div>
                    <div className={ThirdStepStyles.BottomNextBtn}>
                        <BackButton onClick={() => navigate('/home')}>Return To Reports</BackButton>
                        <NextButton onClick={nextScreen}> Proceed to view biomarkers</NextButton>
                    </div>

                    {/* </Grid> */}
                </Container>
            </div>
        </div>
    )
}

export default FifthStep