import notificationStyles from "./notification.module.scss";
import settingsStyles from "../../pages/settings/settings.module.scss";
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { Box } from "@material-ui/core";
import { GrFormClose } from "react-icons/gr";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Radio from "@mui/material/Radio";
import { useDispatch, useSelector } from "react-redux";
import {
  getPaymentData,
  getSubscription,
  setSubscription,
  updateSubscription,
} from "../../redux/actions/SettingsAction";
import { useEffect } from "react";
import { useState } from "react";

export default function Notification({
  notificationData,
  setNotificationData,
  setDisableRestoreButton = null,
}) {
  const dispatch = useDispatch();
  const { paymentData, subsciptionPlan } = useSelector(
    (state) => state.settings
  );
  const stripePromise = loadStripe(
    // "pk_test_51NevP2EjLVuRDJw6GTidAJ4ZaHdzWYQK2aV0NOw5zBe36FOoispL06B0KYMV2kXbKowqo1NO5wxhez5Stbl8YtrN00hatUcVuo"
     "pk_live_51NevP2EjLVuRDJw6NokIljm81JWhzqmpzFYXrn82hOf1yiQIc0aznP7HvjWFpSdUbqrbhkPpXCpxjGxIdkvICP8z000xWueXKc"
  );
  const [paymentDetails, setPaymentDetails] = useState({});
  const [userPlan, setUserPlan] = useState(null);
  const { userInfo } = useSelector((state) => state.dashboard)
  const [promoCode, setPromoCode] = useState()
  useEffect(() => {
    let userPlan = null;
    if (
      subsciptionPlan?.subscriptionPlan &&
      subsciptionPlan?.subscriptionPlan?.userPlan
    ) {
      userPlan = Number(subsciptionPlan?.subscriptionPlan?.userPlan);
    } else if (
      subsciptionPlan?.subscriptionPlan &&
      subsciptionPlan?.subscriptionPlan?.productId
    ) {
      let key = subsciptionPlan?.subscriptionPlan?.productId;
      if (key.includes("one")) {
        userPlan = 1;
      } else if (key.includes("two")) {
        userPlan = 2;
      } else if (key.includes("three")) {
        userPlan = 3;
      } else if (key.includes("four")) {
        userPlan = 4;
      } else if (key.includes("five")) {
        userPlan = 5;
      } else if (key.includes("six")) {
        userPlan = 6;
      } else if (key.includes("seven")) {
        userPlan = 7;
      } else if (key.includes("eight")) {
        userPlan = 8;
      } else if (key.includes("nine")) {
        userPlan = 9;
      } else if (key.includes("ten")) {
        userPlan = 10;
      }
    } else {
      userPlan = null;
    }
    setUserPlan(userPlan);
  }, [subsciptionPlan]);

  useEffect(() => {
    const findPaymentDetails = (plan) => {
      return paymentData?.payment?.find((e) => e?.userPlan == plan);
    };
    let defaultPlan;
    if (userInfo?.user?.role?.S == 'Provider') {
      defaultPlan = '10'
    } else {
      defaultPlan = "1";
    }
    let array = [10,20,50,100,500,1000,10000]
    let planToFind;
    if(userInfo?.user?.role?.S == 'Provider'){
      planToFind = userPlan ? (array.includes(userPlan) ? array[array.indexOf(userPlan) + 1] : defaultPlan) : defaultPlan;
    }else{
      planToFind = userPlan ? userPlan + 1 : defaultPlan;
    }
    const paymentDetail = findPaymentDetails(planToFind);
    setPaymentDetails(paymentDetail);
  }, [userPlan, paymentData]);
  const GetCard = () => {
    const stripe = useStripe();
    const elements = useElements();
    const handlePromoChange = (e) => {
       setPromoCode(e.target.value)
    }
    const handleSubscribe = async () => {
      try {
        if (userPlan) {
          dispatch(
            updateSubscription(
              {
                userPlan: paymentDetails?.userPlan,
                status: notificationData?.status,
                region: paymentDetails?.webPriceIdArr?.region,
              },
              setNotificationData
            )
          );
        } else {
          const paymentMethod = await stripe?.createPaymentMethod({
            type: "card",
            card: elements?.getElement(CardElement),
          });

          const payload = {
            paymentMethod: paymentMethod?.paymentMethod?.id,
            userPlan: paymentDetails?.userPlan,
            region: paymentDetails?.webPriceIdArr?.region,
            promoCode: promoCode
          };
          dispatch(setSubscription(payload, stripe, setNotificationData));
        }
      } catch (error) {
        console.log(error);
      }
    };
    return (
      <div className={notificationStyles.paymentMethodLabel}>
        {notificationData?.status == "" && (
          <>
            <input
              type="text"
              placeholder={"Enter Customer Code"}
              value={promoCode}
              onChange={handlePromoChange}
              className={notificationStyles.inputField}
            />
            <label>Credit / Debit card: </label>
            <CardElement />
          </>
        )}
        <button
          className={notificationStyles.paymentButton}
          onClick={handleSubscribe}
        >
          Subscribe
        </button>
      </div>
    );
  };

  const closePopup = () => {
    if (notificationData.paymentFailed) {
      setDisableRestoreButton && setDisableRestoreButton(false);
    }
    setNotificationData({
      message: "",
      openModel: false,
      upgradePlan: false,
      status: "",
      isFirstStep: false,
      paymentFailed: false,
    });
  };
  const SettingsSection = ({
    subHeading,
    children,
    sectionContainerClass,
    style,
  }) => {
    return (
      <div
        className={`${settingsStyles.sectionContainer} ${sectionContainerClass || ""
          }`}
        style={{ ...style }}
      >
        <p
          className={settingsStyles.sectionSubHeading}
          dangerouslySetInnerHTML={{ __html: subHeading || "" }}
        />
        {children}
      </div>
    );
  };
  return (
    <div>
      <Dialog
        open={notificationData?.openModel}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "20px",
            padding: "25px 35px",
          },
        }}
      >
        <Box className={notificationStyles.closeIcon}>
          <GrFormClose size={"30px"} onClick={closePopup} />
        </Box>
        {subsciptionPlan &&
          subsciptionPlan?.subscriptionPlan &&
          subsciptionPlan?.subscriptionPlan?.device &&
          (subsciptionPlan?.subscriptionPlan?.device === "ios" ||
            subsciptionPlan?.subscriptionPlan?.device === "android") &&
          notificationData?.isFirstStep ? (
          <SettingsSection
            // heading="Manage Subscription"
            subHeading={`You initiated the AHA <b>${userPlan || "0"
              } User Plan</b> from your mobile device. Please upgrade your plan there.`}
            style={{
              padding: "0",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <a
              href={
                subsciptionPlan?.subscriptionPlan?.device === "ios"
                  ? "https://apps.apple.com/in/app/advanced-health-academy/id6444776238"
                  : subsciptionPlan?.subscriptionPlan?.device === "android"
                    ? "https://play.google.com/store/search?q=advanced+health+academy&c=apps"
                    : ""
              }
              target="_blank"
            >
              <button className={settingsStyles.getAppButton}>
                Upgrade in AHA App
              </button>
            </a>
          </SettingsSection>
        ) : (
          <>
            <p className={notificationStyles.notificationMessage}>
              {notificationData?.upgradePlan && !paymentDetails
                ? "You have reached the maximum plan limit."
                : notificationData?.message}
            </p>
            {notificationData?.upgradePlan && paymentDetails && (
              <>
                <div className={notificationStyles.user_plan}>
                  <div
                    className={`${notificationStyles.lable_form} ${notificationStyles.selectedPlans}`}
                  >
                    <div className={notificationStyles.radioWrapper}>
                      <Radio checked={true} />
                      <span>{paymentDetails?.title}</span>
                    </div>

                    <p className={notificationStyles.font_normal}>
                      {paymentDetails?.webPriceIdArr?.rate}/month
                    </p>
                  </div>
                </div>
                <Elements stripe={stripePromise}>
                  <GetCard />
                </Elements>
              </>
            )}
            {/* <Elements stripe={stripePromise}>
              <GetCard />
            </Elements> */}
          </>
        )}
      </Dialog>
    </div>
  );
}
