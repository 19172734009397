import React from "react";
import password from "./Password.module.scss";
import { Box, Dialog, DialogContent } from "@mui/material";
import IconTextField from "../../common-textfield/IconTextField";
import { TfiEmail } from "react-icons/tfi";
import CommonButton from "../../button";
import { Formik, Form } from "formik";
import { makeStyles } from "@material-ui/core/styles";

import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  inputError: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "red !important",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px !important",
    },
  },
}));

// forgot schema
const ForgotPassSchema = Yup.object().shape({
  email: Yup.string()
    .email(
      "The email address you entered is invalid. Please re-enter a valid email."
    )
    .required("Email is required")
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      "The email address you entered is invalid. Please re-enter a valid email."
    )
    .trim(),
});
const checkIsDisabled = (values, errors) => {
  return values.email && !Object.keys(errors)?.length ? false : true;
};

const ForgotPasswordModal = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <div>
      <Dialog
        open={props.open}
        // onClose={props.onClose}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "20px",
            padding: "5px 20px 0px 20px",
          },
        }}
      >
        {/* <Box className={password.closebtn}>
          <GrFormClose size={"30px"} onClick={props.onClick} />
        </Box> */}
        <h1 className={password.passwordTitle}>Forgot Password</h1>
        <DialogContent sx={{ width: "375px" }}>
          <Formik
            initialValues={props.initialData}
            validationSchema={ForgotPassSchema}
            onSubmit={(values) => {
              props.onSubmit(values);
            }}
          >
            {({
              values,
              handleBlur,
              handleChange,
              touched,
              errors,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Box
                  className="App"
                  sx={{
                    display: "grid",
                    gridGap: 10,
                  }}
                >
                  <IconTextField
                    iconStart={<TfiEmail sx={{ color: "#8A8A8A" }} />}
                    placeholder="Enter email address"
                    type="email"
                    name="email"
                    onChange={(e) => {
                      handleChange(e);
                      props.setEmailError("");
                    }}
                    onBlur={handleBlur}
                    value={values.email}
                    className={
                      touched.email && errors.email
                        ? classes.inputError
                        : props.emailError
                        ? classes.inputError
                        : ""
                    }
                  />
                  {touched.email && errors.email ? (
                    <div className={password.errorMessage}>{errors.email}</div>
                  ) : null}
                  {props.emailError && (
                    <div className={password.errorMessage}>
                      {props.emailError}
                    </div>
                  )}
                </Box>
             
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                  style={{
                    cursor: checkIsDisabled(values, errors)
                      ? "not-allowed"
                      : "pointer",
                  }}
                >
                  <CommonButton
                    color="primary"
                    loading={props.loading}
                    disabled={checkIsDisabled(values, errors) || props.loading}
                    title={"Submit"}
                    className={`${password.submitBtn}`}
                  />
                </Box>
                <p className={password.backSign}>
                  Back to{" "}
                  <span onClick={() => navigate("/sign-in", { replace: true })}>
                    Sign In
                  </span>
                </p>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ForgotPasswordModal;
