import {
  GET_SIGNIN_FAILURE,
  GET_SIGNIN_REQUEST,
  GET_SIGNIN_SUCCESS,
  GET_SOCIAL_LOGIN,
  SET_SOCIAL_LOGIN,
} from "../actionTypes/SignInActionTypes";
import axiosInstance from "../../axios/axiosInstance";
import { GET_TOKEN_SUCCESS } from "../actionTypes/AuthActionTypes";
import { addProfileData } from "./GetProfileAction";

export const fetchSignInData = (data, providerId, values, payloadItemdata, isMobile) => async (dispatch) => {
  dispatch({ type: GET_SIGNIN_REQUEST });
  try {
    const response = await axiosInstance(`v1/login`, "post", data);
    console.log("response", response);
    // <Navigate to='/home' />
    dispatch({
      type: GET_SIGNIN_SUCCESS,
      payload: { ...response.data.data, providerId: providerId },
    });
    // if (!payloadItemdata) {
    //   dispatch({
    //     type: GET_TOKEN_SUCCESS,
    //     payload: response.data.data.token,
    //   });
    // }
    const payload = {
      userId: response?.data?.data?.userId,
    };
    dispatch(fetchSocialData(payload));

    if (
      providerId !== "Sign In Button" &&
      response?.data?.data?.newUser === true
    ) {
      const profilePayload = {
        name: response.data.data?.user?.FullName.S,
        email: response.data.data?.user?.Email.S,
        mobileNumber: response.data.data?.user?.MobileNumber.S,
        lName: "",
        countryCode: response.data.data?.user?.LocationPointer.S,
        dateOfBirth: "",
        sex: "",
        gender: "",
        ethnicity: "",
        allergies: [],
        illnesses: [],
        medications: [],
        supplements: [],
        height: "",
        weight: "",
        userAccessList: [],
        isDefault: "true",
      };
      const token = response.data.data?.token;
      dispatch(addProfileData(profilePayload, token));
    }
    dispatch(fetchSocialData(payload,providerId,response))
    const payloadItem = {
      userId: response?.data?.data?.userId,
      locationPointer: payloadItemdata.locationPointer,
      MobNumber: payloadItemdata?.MobNumber

    }
    if (payloadItemdata) {
      dispatch(setSocialDataLogin(payloadItem))
      dispatch({
        type: GET_TOKEN_SUCCESS,
        payload: response.data.data.token,
      });
    }
  } catch (error) {
    dispatch({ type: GET_SIGNIN_FAILURE, payload: error.message });
  }
};

export const fetchSocialData = (uid,id,res) => async (dispatch) => {
  dispatch({ type: GET_SIGNIN_REQUEST });
  try {
    const response = await axiosInstance(`v1/getMobileNumber`, "post", uid);
    // <Navigate to='/home' />
    dispatch({
      type: GET_SOCIAL_LOGIN,
      payload: response.data.data,
    });
    console.log("dataaaa",response.data.data.isFirstLogin)
    if (response.data.data.isFirstLogin === false) {
      console.log("dfjbedjfbdj", res.data.data.token)
      dispatch({
        type: GET_TOKEN_SUCCESS,
        payload: res.data.data.token,
      });
    }
  } catch (error) {
    dispatch({ type: GET_SIGNIN_FAILURE, payload: error.message });
  }
};

export const setSocialDataLogin = (data) => async (dispatch) => {
  dispatch({ type: GET_SIGNIN_REQUEST });
  try {
    const response = await axiosInstance(`v1/updateMobNumber`, "post", data);
    // <Navigate to='/home' />
    dispatch({
      type: SET_SOCIAL_LOGIN,
      payload: response.data.datA,
    });
  } catch (error) {
    dispatch({ type: GET_SIGNIN_FAILURE, payload: error.message });
  }
};
