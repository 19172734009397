import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Box } from "@mui/material";
import paymentStyles from "./paymentModal.module.scss";
import AHAlogo from "../../assets/images/AHAAPPLOGO.png";
import { ReactComponent as CloseIcon } from "../../assets/svg/Close.svg";
import { BsInfoCircle } from "react-icons/bs";
import CommonButton from "../../components/button";
import { useDispatch, useSelector } from "react-redux";
import {
  setSubscription,
  updateSubscription,x
} from "../../redux/actions/SettingsAction"; 
import notificationStyles from '../../components/notification/notification.module.scss'
export default function PaymentModal({
  subscriptionKey,
  open,
  setOpen,
  handleClose,
  userPlan,
  setNotificationData,
  setDisableRestoreButton,
}) {
  const stripe = useStripe();
  const dispatch = useDispatch();
  const elements = useElements();
  const [promoCode, setPromoCode] = React.useState()
  const {userInfo} = useSelector((state)=>state.dashboard)
  const handlePromoChange = (e) => {
    setPromoCode(e.target.value)
 }
  const handleSubscribe = async () => {
    setDisableRestoreButton(true);
    setOpen(false);
    try {
      if (subscriptionKey?.status == "downgrade") {
        dispatch(
          updateSubscription(
            {
              userPlan,
              status: "downgrade",
              region: subscriptionKey?.region,
              role: userInfo?.user?.role?.S
            },
            setNotificationData
          )
        );
      } else if (subscriptionKey?.status == "upgrade") {
        dispatch(
          updateSubscription(
            {
              userPlan,
              status: "upgrade",
              region: subscriptionKey?.region,
              role: userInfo?.user?.role?.S
            },
            setNotificationData
          )
        );
      } else {
        const paymentMethod = await stripe?.createPaymentMethod({
          type: "card",
          card: elements?.getElement(CardElement),
        });

        const payload = {
          paymentMethod: paymentMethod?.paymentMethod?.id,
          userPlan,
          region: subscriptionKey?.region,
          promoCode: promoCode,
          role: userInfo?.user?.role?.S
        };
        dispatch(
          setSubscription(
            payload,
            stripe,
            setNotificationData,
            setDisableRestoreButton
          )
        );
        setPromoCode('')
        //   const payload = {
        //   promoCode: promoCode
        // };
        // dispatch(
        //   setSubscription(
        //     payload,
        //     stripe,
        //     setNotificationData,
        //     setDisableRestoreButton
        //   )
        // );
      }
    } catch (error) {
      setNotificationData({
        openModel: true,
        message: error?.message,
        upgradePlan: false,
        status: "",
        isFirstStep: false,
        paymentFailed: true,
      });
      console.log(error);
    }
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            width: "100%",
            borderRadius: "20px",
            padding: "0px 50px",
            maxWidth: "500px",
            cursor: "context-menu",
            "@media (min-height: 580px) and (max-height: 665px)": {
              maxWidth: "420px",
            },
          },
        }}
        className={paymentStyles.closebtn}
      >
        <Box className={paymentStyles.closebtn}>
          <CloseIcon size={"17px"} onClick={() => setOpen(false)} />
        </Box>
        <h1 className={paymentStyles.paymentMethodTitle}>{"Payment Method"}</h1>
        <div className={paymentStyles.userPlanContainer}>
          <img
            className={paymentStyles.AHAlogoImg}
            src={AHAlogo}
            alt="AHA Logo"
          />
          <div className={paymentStyles.userPlan}>
            <p className={paymentStyles.userPlanText}>
              {subscriptionKey?.title}
            </p>

            <p className={paymentStyles.AHASubText}>
              Advanced Health Academy (AHA)
            </p>
          </div>
        </div>
        <div className={paymentStyles.durationContainer}>
          <div className={paymentStyles.eachStep}>
            <div className={paymentStyles.blueDotContainer}>
              <span className={paymentStyles.blueDot}></span>
            </div>
            <div className={paymentStyles.durationTextsWrapper}>
              <p className={paymentStyles.leftText}>Rate</p>
              <p className={paymentStyles.rightText}>
                {subscriptionKey?.rate}/month
              </p>
            </div>
          </div>
          <div className={paymentStyles.eachStep}>
            <div className={paymentStyles.blueDotContainer}>
              <span className={paymentStyles.lastblueDot}></span>
            </div>
            <div className={paymentStyles.durationTextsWrapper}>
              <p className={paymentStyles.leftText}>Expires on</p>
              <p className={paymentStyles.rightText}>On Cancellation</p>
            </div>
          </div>
        </div>
        <div className={paymentStyles.NotesContainer}>
          <div className={paymentStyles.includedTaxText}>
            See included Tax
            <span>
              <BsInfoCircle />
            </span>
          </div>
          <ul className={paymentStyles.notesPoints}>
            <li>
              The recurring subscription payment will be deducted automatically
              every month.
            </li>
            <li>
              Once the subscription is activated, you can change or cancel the
              plan at any time. The payment amount will be auto adjusted.
            </li>
            <li>
              Payment can be made by debit or credit card by entering card
              details and tapping the Subscribe button. Your subscription will
              then start.
            </li>
          </ul>
        </div>
        <div className={paymentStyles.paymentMethodLabel}>
          {subscriptionKey.status == "" && (
            <>
            <input
              type="text"
              placeholder={"Enter Customer Code"}
              value={promoCode}
              onChange={handlePromoChange}
              className={notificationStyles.inputField}
            />
              <label>Credit / Debit card: </label>
              <CardElement />
            </>
          )}

          {/* <CommonButton
            loading={false}
            disabled={false}
            title={"Subscribe"}
            className={paymentStyles.paymentButton}
          /> */}
          <button
            className={paymentStyles.paymentButton}
            onClick={handleSubscribe}
          >
            Subscribe
          </button>
        </div>
      </Dialog>
    </div>
  );
}
