import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import { ReactComponent as NotFoundImage } from "../../assets/svg/404.svg";

const StyledButton = styled(Button)({
  width: 120,
  color: "#fff",
  margin: "auto",
  display: "flex",
  flexWrap: "wrap",
  borderRadius: 30,
  boxShadow: "none",
  position: "relative",
  alignItems: "center",
  background: "#35baf8",
  borderColor: "#35baf8",
  justifyContent: "center",
});

const NotFound = () => {
  const navigate = useNavigate();
  const handleButtonClick = () => navigate("/");

  return (
    <div
      id="not-found-container"
      style={{ background: "#fff" }}
      className="not-found-container"
    >
      <div
        style={{
          height: "88vh",
          margin: "auto",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <NotFoundImage />
        <div>
          <Typography
            id="page-not-found-title"
            sx={{
              color: "#707070",
              fontSize: "4.5vh",
              fontWeight: "400",
              textAlign: "center",
              fontFamily: "Poppins",
            }}
          >
            Oops, This Page Could Not Be Found.
          </Typography>
        </div>
        <Box
          id="page-not-found-desc"
          sx={{
            mb: 4,
            color: "#707070",
            fontWeight: "450",
            fontSize: "2.15vh",
            textAlign: "center",
          }}
        >
          The page you are looking for might have been removed had its <br />
          <span
            sx={{
              color: "#707070",
              fontWeight: "450",
              fontSize: "2.15vh",
              textAlign: "center",
            }}
          >
            name changed or is temporarily unavailable
          </span>{" "}
        </Box>
        <StyledButton
          variant="contained"
          onClick={handleButtonClick}
          id="page-not-found-redirection-btn"
        >
          Back
        </StyledButton>
      </div>
    </div>
  );
};

export default NotFound;
