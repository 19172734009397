import { useEffect, useState } from "react";
import FirstStep from "./FirstStep/FirstStep";
import FourthStep from "./FourthStep/FourthStep";
import SecondStep from "./SecondStep/SecondStep";
import ThirdStep from "./ThirdStep/ThirdStep";
import { useSelector } from "react-redux";
import FifthStep from "./FifthStep/FifthStep";
import SixthStep from "./SixthStep.js/SixthStep";
import { useLocation } from "react-router-dom";

const GetBloodTestInterpreted = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const location = useLocation();

  useEffect(() => {
    if (location?.state?.bloodTestId && location?.state?.resultId && location?.state?.profileId) {
      setCurrentStep(5)
    }
  }, [location?.state])
  const { userInfo } = useSelector((state) => state.dashboard)

  return (
    <>
      {currentStep === 1 && !location.state && <FirstStep setCurrentStep={setCurrentStep} />}
      {currentStep === 2 && !location.state && <SecondStep setCurrentStep={setCurrentStep} />}
      {currentStep === 3 && !location.state && <ThirdStep setCurrentStep={setCurrentStep} />}
      {currentStep === 4 && !location.state && <FourthStep setCurrentStep={setCurrentStep} />}
      {currentStep === 5 && <FifthStep profileId={location?.state?.profileId}
        bloodTestId={location?.state?.bloodTestId}
        resultId={location?.state?.resultId} setCurrentStep={setCurrentStep} />}
      {currentStep === 6 && <SixthStep setCurrentStep={setCurrentStep} profileId={location?.state?.profileId}
        bloodTestId={location?.state?.bloodTestId}
        resultId={location?.state?.resultId} />}
    </>
  );
};

export default GetBloodTestInterpreted;
