import React from "react";
import { useLocation, Navigate } from "react-router";
import FifthStep from "../review-blood-test/FifthStep/FifthStep";

function ReportConsumer() {
  const location = useLocation();

  return (
    <div>
      {location.state === null ? (
        <Navigate to={"/home"} />
      ) : (
        <FifthStep
          profileId={location.state.profileId}
          bloodTestId={location.state.bloodTestId}
          resultId={location.state.resultId}
        />
      )}
    </div>
  );
}

export default ReportConsumer;
