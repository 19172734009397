import axios from "axios";
import { store } from "../redux/store";

// base URL
const api = axios.create({
   baseURL: "https://api.ahealthacademy.com/",
   //baseURL: "http://localhost:1337/",
});
const axiosInstance = async (
  url,
  method = "GET",
  data = null,
  token = null,
  headers = {}
) => {
  const stateToken = store.getState().accessToken.token;
  const accessToken = token && token !== null ? token : stateToken;
  try {
    headers = {};
    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }
 
    const response = await api({
      url,
      method,
      data,
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export default axiosInstance;
