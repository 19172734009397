import React from "react";
import loaderCss from "./loader.module.scss";

function SectionLoader({ loading, bloodTest, deleteProfile, find , findUpload}) {
  return (
    <div>
      {loading ? (
        <div
          className={loaderCss.loading2}
          style={
            bloodTest
              ? { padding: 0 }
              : deleteProfile
                ? {
                  padding: 0,
                  marginBottom: 0,
                  position: "absolute",
                }
                : { padding: 0 }
          }
        >
          <div
            className={find ? loaderCss.uil_ring_css2find : findUpload ? loaderCss.uil_ring_css2findUpload : loaderCss.uil_ring_css2}
            style={{ transform: "scale(0.79)" }}
          >
            <div></div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default SectionLoader;
