import React, { useState, useMemo, useEffect, useCallback } from "react";
import reportDataStyles from "./reportData.module.scss";
import { ReactComponent as ExpandedIcon } from "../../../../assets/svg/trendsReport/expanded.svg";
import Graph from "../../organ-chart";
import { biomarkerPlugin } from "../../utils";
import BiomarkerChart from "../../biomarker-chart";
const ReportData = (props) => {
  const {
    description,
    Biomarker_Name,
    xLabels,
    unit,
    convertedPoints,
    oldPoints,
    yLabels,
    setOpenAccordion,
    openAccordion,
    selected,
    steps,
  } = props;
  const lastOldPointIndex = oldPoints?.length - 1;

  const graphData = useMemo(() => {
    if (!xLabels || !convertedPoints)
      return { convertedPoints: [], xLabels: [] };
    const x = [...xLabels]?.map((dateString) => {
      const [day, month, y] = dateString.split("/");
      const year = y?.substring(2);
      return `${day}/${month}/${year}`;
    });

    return {
      xLabels: [...x]?.reverse(),
      convertedPoints: [...convertedPoints]?.reverse(),
    };
  }, [xLabels, convertedPoints]);

  const [utils, setUtils] = useState({
    hidden: true,
    text: `${description?.substr(0, 100).trim()}...`,
  });
  const toggleAccordion = useCallback(() => {
    const newAccordionState = openAccordion.includes(Biomarker_Name)
      ? openAccordion.filter((name) => name !== Biomarker_Name)
      : [...openAccordion, Biomarker_Name];
    setOpenAccordion(newAccordionState);
  }, [openAccordion, Biomarker_Name, setOpenAccordion]);

  const handleMoreCLick = useCallback(() => {
    setUtils((prev) => {
      return {
        ...prev,
        hidden: !prev.hidden,
        text:
          prev.hidden === true
            ? description
            : `${description?.substr(0, 100).trim()}...`,
      };
    });
  }, [description]);

  useEffect(() => {
    if (selected === "Optimized") {
      if (oldPoints && oldPoints[lastOldPointIndex]?.isBorder) {
        setOpenAccordion((prev) => [...prev, Biomarker_Name]);
      }
    }
  }, [selected]);

  const border = {
    fontSize: "12px",
    padding: "4px 4px",
    borderRadius: "9px",
    border: "1px solid #fd4e51",
  };

  const verticalLineStyles = {
    backgroundColor:
      oldPoints[lastOldPointIndex]?.color || "rgb(155, 172, 186)",

    width: oldPoints[lastOldPointIndex]?.isBorder ? "5px" : "3px",
  };

  return (
    <div className={reportDataStyles.accordionWrapper}>
      <div className={reportDataStyles.accordion} onClick={toggleAccordion}>
        <div
          className={reportDataStyles.verticalLine}
          style={{ ...verticalLineStyles }}
        ></div>
        <div className={reportDataStyles.accordionHeadings}>
          {Biomarker_Name}
        </div>
        <div className={reportDataStyles.reportCounts}>
          <span></span>
          {oldPoints?.map((e, i) => {
            const numberString = e?.unConvertedPoint?.toString()?.split("");
            let count;
            if (numberString?.includes(".")) {
              count = Number(numberString?.join(""))?.toFixed(1);
            } else {
              count = Number(`${numberString?.join("")}.0`)?.toFixed(1);
            }

            return (e?.unConvertedPoint === 0 && !e.points) ||
              e?.unConvertedPoint == null ||
              e.unConvertedPoint === undefined ? (
              <span key={i} style={{ minWidth: "42px", textAlign: "center" }}>
                -
              </span>
            ) : (
              <span
                key={i}
                className={reportDataStyles.count}
                style={
                  e?.isBorder || Number(e?.unConvertedPoint) === 0
                    ? {
                        ...border,
                        color:
                          Number(e?.unConvertedPoint) === 0
                            ? "#FD4E51"
                            : e?.color,
                        minWidth: "42px",
                        textAlign: "center",
                      }
                    : { color: e?.color, minWidth: "42px", textAlign: "center" }
                }
              >
                {numberString}
              </span>
            );
          })}
          <span></span>
        </div>
        <button
          className={`${reportDataStyles.expandedIcon} ${
            openAccordion.includes(Biomarker_Name)
              ? reportDataStyles.expanded
              : reportDataStyles.closed
          }`}
        >
          <ExpandedIcon />
        </button>
      </div>
      <div
        className={`${reportDataStyles.AccordionDetails} ${
          openAccordion.includes(Biomarker_Name)
            ? reportDataStyles.openAccordion
            : reportDataStyles.closeAccordion
        }`}
      >
        <div className={reportDataStyles.biomarkerGraphWrapper}>
          <div className={reportDataStyles.blankDiv}>
            <span className={reportDataStyles.units}>{unit}</span>
          </div>
          <div className={reportDataStyles.graphDiv}>
            {openAccordion && openAccordion?.includes(Biomarker_Name) && (
              <>
                <div className={reportDataStyles.canvas}>
                  <BiomarkerChart
                    key={Biomarker_Name}
                    chartId={Biomarker_Name}
                    steps={steps}
                    points={convertedPoints && graphData?.convertedPoints}
                    yLabels={yLabels}
                    xLabels={xLabels && graphData?.xLabels}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className={reportDataStyles.organInformation}>
          <p>{utils?.text}</p>
          {
            <button onClick={handleMoreCLick}>
              {utils.hidden && utils.text.length > 100
                ? "Read More"
                : "Read less"}
            </button>
          }
        </div>
      </div>
    </div>
  );
};

export default ReportData;
