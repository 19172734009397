import { Card } from "@mui/material";
import React from "react";
import summaryCss from "./summary.module.scss";
import bodyImage from "../../assets/svg/Body.svg";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  imageFix: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "19px",
  },
}));
function ReportSummary({
  summary1,
  summary2,
  summary3,
  summary4,
  summary5,
  summary6,
  summary7,
  summary8,
  SummaryExplanation,
  SummaryLast,
  SummaryOpener,
  newDate,
  userRole,
  firstBold = true
}) {
  const classes = useStyles();
  return (
    <div className={userRole ? summaryCss.summaryParaRole :summaryCss.summaryPara}>
      <Card className={summaryCss.summary_main}>
        {(summary1 !== "" || undefined) ||
          (summary2 !== "" || undefined) ||
          (summary3 !== "" || undefined) ||
          (summary4 !== "" || undefined) ||
          (summary5 !== "" || undefined) ||
          (summary6 !== "" || undefined) ||
          (summary7 !== "" || undefined) ||
          (summary8 !== "" || undefined) ? (
          newDate ? (
            <>
              <p className={summaryCss.summary_details}>{summary1}</p>
              <p className={firstBold ? !summary1 && summaryCss.summary_details : summaryCss.summary_newDetails}>
                {summary2}
              </p>
              <p
                className={firstBold ? !summary1 && !summary2 && summaryCss.summary_details : ""}
              >
                {summary3}
              </p>
              <p
                className={
                  !summary1 &&
                  !summary2 &&
                  !summary3 &&
                  summaryCss.summary_details
                }
              >
                {summary4}
              </p>
              <p
                className={
                  !summary1 &&
                  !summary2 &&
                  !summary3 &&
                  !summary4 &&
                  summaryCss.summary_details
                }
              >
                {summary5}
              </p>
              <p
                className={
                  firstBold ?
                  !summary1 &&
                  !summary2 &&
                  !summary3 &&
                  !summary4 &&
                  !summary5 &&
                  summaryCss.summary_details
                  : ""
                }
              >
                {summary6}
              </p>
              <p
                className={
                  firstBold ?
                  !summary1 &&
                  !summary2 &&
                  !summary3 &&
                  !summary4 &&
                  !summary5 &&
                  !summary6 &&
                  summaryCss.summary_details
                  : ""
                }
              >
                {summary7}
              </p>
              <p
                className={
                  firstBold ?
                  !summary1 &&
                  !summary2 &&
                  !summary3 &&
                  !summary4 &&
                  !summary5 &&
                  !summary6 &&
                  !summary7 &&
                  summaryCss.summary_details
                  : ""
                }
              >
                {summary8}
              </p>
            </>
          ) : (
            <>
              <p>{SummaryExplanation}</p>
              <p>{SummaryLast}</p>
              <p>{SummaryOpener}</p>
            </>
          )
        ) : (
          <p className="commonSummaryError">No Summary Found</p>
        )}
      </Card>
      {userRole && <div className={classes.imageFix}>
        <img src={bodyImage} alt="bodyImage" width={"70%"} />
      </div>}
    </div>
  );
}

export default ReportSummary;
