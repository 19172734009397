import { Box, Checkbox, Dialog, DialogContent } from "@mui/material";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import signInCss from "./SignIn.module.scss";
import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CommonButton from "../../button";
import { ReactComponent as EmailIcon } from "../../../assets/svg/email.svg";
import { ReactComponent as LockIcon } from "../../../assets/svg/lock.svg";
import { ReactComponent as AppleIcon } from "../../../assets/svg/apple.svg";
import { ReactComponent as FacebookIcon } from "../../../assets/svg/facebook.svg";
import { ReactComponent as GoogleIcon } from "../../../assets/svg/google.svg";
import IconTextField from "../../common-textfield/IconTextField";
import { styled } from "@mui/material/styles";
import MuiButton from "@mui/material/Button";
import { useSelector } from "react-redux";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

// button style
export const ButtonStyle = styled(MuiButton)((props) => ({
  borderRadius: 33,
  padding: "14px 12px",
  width: "100%",
  border: "1px solid #707070",
  fontWeight: "bold",
  color: "black",
  textTransform: "capitalize",
  marginBottom: "10px",
  justifyContent: "space-around",
  paddingRight: "50px",

  "&:hover": {
    background: "white",
  },
}));

// checkbox style
const CustomCheckbox = withStyles((theme) => ({
  root: {
    "&$checked": {
      color: "#122D5B !important",
    },
  },
  checked: {},
}))(Checkbox);

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    "&.MuiDialogContent-root": {
      padding: 0,
      maxWidth: "414px",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  },
  inputError: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "red !important",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px !important",
    },
  },
  signInInputContainer: {
    "& .MuiFormControl-root .MuiInputBase-root": {
      width: "414px",
      height: "65px",
      paddingLeft: "21px",
      "@media (max-height: 695px)": {
        height: "50px",
      },
      "@media (min-height: 696px) and (max-height: 764px)": {
        height: "50px",
      },
      "@media (min-height: 765px) and (max-height: 833px)": {
        height: "55px",
      },
    },
  },
  socialMediaButtonsWrapper: {
    display: "flex",
    flexFlow: "column",
    gap: "10px",
    "& .MuiButton-startIcon": {
      position: "absolute",
      left: 24,
      "@media (max-height: 695px)": {
        left: 21,
      },
    },
  },
}));

// signin schema
const SignInSchema = Yup.object().shape({
  email: Yup.string()
    .email("The email address you entered is invalid.")
    .required("Email is required")
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      "The email address you entered is invalid."
    )
    .trim(),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Pasword must be 6 or more characters")
    .trim(),
});

const SignInModal = (props) => {
  const classes = useStyles();
  const checkIsDisabled = (values, errors) => {
    return values.email && values.password && !Object.keys(errors)?.length
      ? false
      : true;
  };
  const [passwordVisible, setPasswordVisible] = useState(false);
  const handlePasswordVisibility = () => setPasswordVisible(!passwordVisible);
  const getPasswordIcons = () => {
    return passwordVisible ? (
      <RemoveRedEyeOutlinedIcon
        onClick={handlePasswordVisibility}
        className={signInCss.passwordShow}
      />
    ) : (
      <VisibilityOffOutlinedIcon
        className={signInCss.passwordShow}
        onClick={handlePasswordVisibility}
      />
    );
  };
  return (
    <div>
      <Dialog
        backdrop="static"
        open={props.open}
        // onClose={props.onClose}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "20px",
            padding: "0px 75px",
            "@media (max-height: 695px)": {
              padding: "0px 47px",
            },
            "@media (min-height: 696px) and (max-height: 764px)": {
              padding: "0px 50px",
            },
            "@media (min-height: 765px) and (max-height: 833px)": {
              padding: "0px 65px",
            },
            margin: 0,
          },
        }}
      >
        <h1 className={signInCss.signInTitle}>Sign in to Your Account</h1>
        {/* <Box className={signInCss.closebtn}>
          <CloseIcon size={"17px"} onClick={props.onClick} />
        </Box> */}
        <DialogContent className={classes.dialogContent}>
          <Formik
            initialValues={props.initialData}
            validationSchema={SignInSchema}
            onSubmit={(values) => {
              props.onSubmit(values);
            }}
          >
            {({
              values,
              handleBlur,
              handleChange,
              touched,
              errors,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Box
                  className="App"
                  sx={{
                    display: "grid",
                    gridGap: 15,
                    position: "relative",
                    top: "1px",
                    "@media (max-height: 695px)": {
                      gridGap: 12,
                    },
                    "@media (min-height: 696px) and (max-height: 765px)": {
                      gridGap: 13,
                    },
                    "@media (min-height: 765px) and (max-height: 833px)": {
                      gridGap: 14,
                    },
                  }}
                >
                  <div className={classes.signInInputContainer}>
                    <IconTextField
                      iconStart={<EmailIcon />}
                      placeholder="Enter email address"
                      onChange={(e) => {
                        handleChange(e);
                        props.setSignInError("");
                      }}
                      onBlur={handleBlur}
                      autocomplete="off"
                      name="email"
                      type="email"
                      value={values.email}
                      className={
                        touched.email && errors.email
                          ? classes.inputError
                          : props.signInError.includes("Email")
                          ? classes.inputError
                          : null
                      }
                    />
                    {touched.email && errors.email ? (
                      <div className={signInCss.errorMessage}>
                        {errors.email}
                      </div>
                    ) : null}
                    {props.signInError.includes("Email") && (
                      <div className={signInCss.errorMessage}>
                        {props.signInError}
                      </div>
                    )}
                  </div>
                  <div className={classes.signInInputContainer}>
                    <IconTextField
                      iconStart={<LockIcon />}
                      iconEnd={getPasswordIcons()}
                      placeholder="Enter password"
                      onChange={(e) => {
                        handleChange(e);
                        props.setSignInError("");
                      }}
                      onBlur={handleBlur}
                      autocomplete="off"
                      name="password"
                      type={passwordVisible ? "text" : "password"}
                      value={values.password}
                      className={
                        touched.password && errors.password
                          ? classes.inputError
                          : props.signInError.includes("password")
                          ? classes.inputError
                          : null
                      }
                    />
                    {touched.password && errors.password ? (
                      <div className={signInCss.errorMessage}>
                        {errors.password}
                      </div>
                    ) : null}
                    {props.signInError.includes("password") && (
                      <div className={signInCss.errorMessage}>
                        {props.signInError}
                      </div>
                    )}
                  </div>
                </Box>
                <Box className={signInCss.staySign}>
                  <Box className={signInCss.forgotText}>
                    <CustomCheckbox />
                    <p sx={{ color: "black" }}>Stay signed in</p>
                  </Box>
                  <p
                    className={signInCss.forgotPass}
                    onClick={props.onPassClick}
                  >
                    Forgot password ?
                  </p>
                </Box>
                <Box
                  className={signInCss.SignButtonBox}
                  style={{
                    cursor:
                      props.titleName !== "Add Other" &&
                      checkIsDisabled(values, errors)
                        ? "not-allowed"
                        : "pointer",
                  }}
                >
                  <CommonButton
                    loading={props.signinLoading}
                    disabled={checkIsDisabled(values, errors)}
                    title={props.signinLoading ? "Please wait..." : "Sign in"}
                    className={signInCss.signInBtn}
                  />
                </Box>
                <p className={signInCss.options}>OR</p>
                <div className={classes.socialMediaButtonsWrapper}>
                  {/* <BiLogoFacebookCircle size="27px" color="#1976d2" /> */}
                  <ButtonStyle
                    className={signInCss.facebookBtn}
                    sx={{
                      "& svg": {
                        height: "24px",
                        width: "25px",
                      },
                    }}
                    variant="text"
                    onClick={props.handleGoogleLogin}
                    startIcon={<GoogleIcon />}
                  >
                    Continue with Google
                  </ButtonStyle>
                  <ButtonStyle
                    className={signInCss.facebookBtn}
                    sx={{
                      "& svg": {
                        height: "25px",
                        width: "25px",
                      },
                    }}
                    variant="text"
                    startIcon={<FacebookIcon />}
                    onClick={props.onFacebookLogin}
                  >
                    Continue with Facebook
                  </ButtonStyle>
               
                  <ButtonStyle
                    className={signInCss.facebookBtn}
                    sx={{
                      "& svg": {
                        height: "27px",
                      },
                    }}
                    variant="text"
                    startIcon={<AppleIcon />}
                    onClick={props.onAppleLogin}
                  >
                    Continue with Apple
                  </ButtonStyle>
                </div>
                <Box className={signInCss.signUpText}>
                  <p>
                    Don’t have an account ?{" "}
                    <span onClick={props.onSignUpClick}>Sign Up</span>
                  </p>
                </Box>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SignInModal;
