import secondStepStyles from "../../pages/review-blood-test/SecondStep/secondStep.module.scss";

export const GenericInputField = ({
  label,
  fields,
  handleChange,
  addInputField,
}) => {
  return (
    <div className={secondStepStyles.diseases_section}>
      <label>{label}</label>
      <div className={secondStepStyles.top_section}>
        {fields.map((data, index) => (
          <div key={index}>
            {label === "Allergies" && (
              <div className={secondStepStyles.second_label}>
                <input
                  type="text"
                  onChange={(evnt) =>
                    handleChange(index, "allergies", evnt.target.value)
                  }
                  value={data.allergies}
                  className={secondStepStyles.allergies_input}
                  placeholder="Allergy"
                />
              </div>
            )}

            {label === "Diseases & illnesses" && (
              <div className={secondStepStyles.second_label_next}>
                <div className={secondStepStyles.label_align}>
                  {!index && <label>Disease or illness</label>}
                  <input
                    type="text"
                    onChange={(evnt) =>
                      handleChange(index, "disease", evnt.target.value)
                    }
                    value={data.disease}
                    name="DiseaseOne"
                    placeholder="Disease or illness"
                    className={secondStepStyles.allergies_input}
                  />
                </div>
                <div className={secondStepStyles.label_align}>
                  {!index && <label>When</label>}
                  <input
                    type="text"
                    onChange={(evnt) =>
                      handleChange(index, "when", evnt.target.value)
                    }
                    value={data.when}
                    name="DiseaseTwo"
                    placeholder="Timeframe"
                    className={secondStepStyles.when_input}
                  />
                </div>
                <div className={secondStepStyles.label_align}>
                  {!index && <label>Comments</label>}
                  <input
                    type="text"
                    onChange={(evnt) =>
                      handleChange(index, "comments", evnt.target.value)
                    }
                    value={data.comments}
                    name="DiseaseThree"
                    placeholder="Comment"
                    className={secondStepStyles.comment_input}
                  />
                </div>
              </div>
            )}

            {label === "Medications" && (
              <div className={secondStepStyles.second_label_next}>
                <div className={secondStepStyles.label_align}>
                  {!index && <label>Medication</label>}
                  <input
                    type="text"
                    onChange={(evnt) =>
                      handleChange(index, "medication", evnt.target.value)
                    }
                    name="medication"
                    value={data.medication}
                    className={secondStepStyles.allergies_input}
                    placeholder="Medication"
                  />
                </div>
                <div className={secondStepStyles.label_align}>
                  {!index && <label>Dosage</label>}
                  <input
                    type="text"
                    onChange={(evnt) =>
                      handleChange(index, "dosage", evnt.target.value)
                    }
                    name="dosage"
                    value={data.dosage}
                    placeholder="Dosage"
                    className={secondStepStyles.when_input}
                  />
                </div>
                <div className={secondStepStyles.label_align}>
                  {!index && <label>Frequency</label>}
                  <input
                    type="text"
                    onChange={(evnt) =>
                      handleChange(index, "frequency", evnt.target.value)
                    }
                    name="frequency"
                    value={data.frequency}
                    placeholder="Frequency"
                    className={secondStepStyles.comment_input}
                  />
                </div>
              </div>
            )}
            {label === "Supplements" && (
              <div className={secondStepStyles.second_label_next}>
                <div className={secondStepStyles.label_align}>
                  {!index && <label>Supplement</label>}
                  <input
                    type="text"
                    onChange={(evnt) =>
                      handleChange(index, "supplement", evnt.target.value)
                    }
                    value={data.supplement}
                    name="supplement"
                    className={secondStepStyles.allergies_input}
                    placeholder="Supplement"
                  />
                </div>
                <div className={secondStepStyles.label_align}>
                  {!index && <label>Dosage</label>}
                  <input
                    type="text"
                    onChange={(evnt) =>
                      handleChange(index, "dosage", evnt.target.value)
                    }
                    value={data.dosage}
                    name="dosage"
                    placeholder="Dosage"
                    className={secondStepStyles.when_input}
                  />
                </div>
                <div className={secondStepStyles.label_align}>
                  {!index && <label>Frequency</label>}
                  <input
                    type="text"
                    onChange={(evnt) =>
                      handleChange(index, "frequency", evnt.target.value)
                    }
                    value={data.frequency}
                    name="frequency"
                    placeholder="Frequency"
                    className={secondStepStyles.comment_input}
                  />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      <button className={secondStepStyles.addNewBtn} onClick={addInputField}>
        + Add More
      </button>
    </div>
  );
};
