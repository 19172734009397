import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBiLwWV1TVqP4zI6bf3XPab2pn7ArUtXTA",
  authDomain: "ahalogin-db226.firebaseapp.com",
  projectId: "ahalogin-db226",
  storageBucket: "ahalogin-db226.appspot.com",
  messagingSenderId: "70405414157",
  appId: "1:70405414157:web:cd3da800fecef5783ba444",
  measurementId: "G-ESECQ8EP0Q",
};

firebase.initializeApp(firebaseConfig);

export default firebase;
