import React from "react";
import breadcrumbsStyles from "./breadcrumbs.module.scss";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

export const BackButton = styled(Button)(({ theme }) => ({
  height: "42px !important",
  padding: "11px 75px !important",
  border: "1px solid #122d5b !important",
  color: "#122d5b !important",
  background: "transparent !important",
  fontWeight: "400 !important",
  fontFamily: "Poppins !important",
  fontSize: "16px !important",
  borderRadius: "33px !important",
  textTransform: "none !important",
  "&:hover": {
    backgroundColor: "transparent",
  },
  "&.Mui-disabled": {
    opacity: 0.5,
    pointerEvents: "all",
    cursor: "not-allowed",
    color: "#122d5b",
  },
}));

export const NextButton = styled(Button)(({ theme }) => ({
  height: "42px !important",
  padding: "11px 75px !important",
  border: "1px solid #122d5b !important",
  color: "white !important",
  background: "#122d5b !important",
  fontWeight: "400 !important",
  fontFamily: "Poppins !important",
  fontSize: "16px !important",
  borderRadius: "33px !important",
  textTransform: "none !important",
  "&:hover": {
    backgroundColor: "#122d5b !important",
  },
  "&.Mui-disabled": {
    opacity: 0.5,
    pointerEvents: "all",
    cursor: "not-allowed",
    color: "white",
  },
}));

const BreadCrumbs = ({
  breadCrumbsArr,
  isBackBtnVisible = false,
  isNextBtnVisible = false,
  isBackBtnDisabled = false,
  isNextBtnDisabled = false,
  handleBack,
  handleNext,
  backButtonText,
  noResultId
}) => {
  return (
    <div className={noResultId ? breadcrumbsStyles.mainContainerClass: breadcrumbsStyles.mainContainer}>
      <div>
        {breadCrumbsArr?.map((eachBReadCrumb, index) => {
          if (breadCrumbsArr.length - 1 === index) {
            return (
              <>
                <span className={breadcrumbsStyles.LastBreadCrumb}>
                  {eachBReadCrumb.title}
                </span>
              </>
            );
          } else {
            return (
              <>
                <Link
                  to={eachBReadCrumb.link}
                  className={breadcrumbsStyles.LinkTo}
                >
                  <span
                    className={`${breadcrumbsStyles.LinkBreadCrumb} ${
                      index && breadcrumbsStyles.FirstLinkBreadCrumb
                    }`}
                  >
                    {eachBReadCrumb.title}
                  </span>
                </Link>
                <span className={breadcrumbsStyles.BreadCrumbArrow}>{">"}</span>
              </>
            );
          }
        })}
      </div>
      <div className={breadcrumbsStyles.buttonsContainer}>
        {isBackBtnVisible && (
          <BackButton
            disabled={isBackBtnDisabled}
            onClick={handleBack}
            style={{ padding: backButtonText ? "11px 35px" : "11px 75px" }}
          >
            {backButtonText || "Back"}
          </BackButton>
        )}
        {isNextBtnVisible && (
          <NextButton disabled={isNextBtnDisabled} onClick={handleNext}>
            Next
          </NextButton>
        )}
      </div>
    </div>
  );
};

export default BreadCrumbs;
