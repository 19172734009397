import React from "react";
import ThirdStepStyles from "./thirdStep.module.scss";
import CommonDropdown from "../../../components/CustomDropdown/CommonDropdown";
import Grid from "@mui/material/Grid";
import { getNumberVal } from "../../../helper";

function CustomDropdown({
  name,
  value,
  dropdown,
  notes,
  error,
  convUnit,
  siUnit,
  valueShow,
  handleChangeBiomarker,
  setUnits,
  units,
  blankValue,
  altUnit,
  lowerLimit,
  upperLimit
}) {
  const handleChangeUnits = (unit, name, state, select) => {
    setUnits({ ...units, [name]: unit });
    state(false);
    select(unit);
  };
  return (
    <>
      <Grid
        container
        spacing={2}
        className={
          value && !(getNumberVal(value) > lowerLimit && getNumberVal(value) < upperLimit) || valueShow === "VERIFY" || valueShow === "PLEASE_INPUT"
            ? ThirdStepStyles.grid_container_error
            : ThirdStepStyles.grid_container
        }
      >
        <Grid item xs={5} className={ThirdStepStyles.grid_item_space_first}>
          <p className={ThirdStepStyles.allNames}>{name}</p>
          {value && !(getNumberVal(value) > lowerLimit && getNumberVal(value) < upperLimit) ? (
            <div className={ThirdStepStyles.checkValue}>
              Please doublecheck the value and units, as this value will not be accepted.
            </div>
          ) :
            valueShow === "VERIFY" ? (
              <div className={ThirdStepStyles.checkValue}>
                Please check the value
              </div>
            ) : valueShow === "PLEASE_INPUT" ? (
              <div className={ThirdStepStyles.checkValue}>
                Oops! Something went wrong. Please fill in the value
              </div>
            ) : (
              ""
            )}
        </Grid>
        <Grid item xs={4} className={ThirdStepStyles.grid_item_space}>
          <div className={ThirdStepStyles.valueBox}>
            <input
              name={name}
              className={
                valueShow === "VERIFY" || valueShow === "PLEASE_INPUT"
                  ? ThirdStepStyles.errorInput
                  : value
                    ? ThirdStepStyles.bloodTest_values_input
                    : ThirdStepStyles.bloodTest_values
              }
              value={
                valueShow === "BLANK" || valueShow === "PLEASE_INPUT"
                  ? blankValue
                  : value
              }
              onChange={handleChangeBiomarker}
              placeholder="00"
            />
          </div>
        </Grid>
        <Grid item xs={3} className={ThirdStepStyles.grid_item}>
          <CommonDropdown
            dropDownValuesUnits={[dropdown, convUnit, siUnit, altUnit]}
            handleChangeUnits={handleChangeUnits}
            name={name}
            units={units}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default CustomDropdown;
