export const TrendReport = [
  {
    name: "Tony Stark",
    data: [
      {
        title: "Trends Report",
        date: "23 Apr 2023",
      },
      {
        title: "Trends Report",
        date: "23 Apr 2023",
      },
      {
        title: "Trends Report",
        date: "23 Apr 2023",
      },
      {
        title: "Trends Report",
        date: "23 Apr 2023",
      },
      {
        title: "Trends Report",
        date: "23 Apr 2023",
      },
    ],
  },
  {
    name: "Pepper Potts",
    data: [
      {
        title: "Trends Report",
        date: "23 Apr 2023",
      },
      {
        title: "Trends Report",
        date: "23 Apr 2023",
      },
    ],
  },
  {
    name: "James Rhodes",
    data: [
      {
        title: "Trends Report",
        date: "23 Apr 2023",
      },
      {
        title: "Trends Report",
        date: "23 Apr 2023",
      },
      {
        title: "Trends Report",
        date: "23 Apr 2023",
      },
    ],
  },
];

export const organOverviewMock = [
  {
    fieldName: "Brain (hypothalamus/pituitary gland",
    name: "Brain",
    value: 85,
    content:
      "The brain controls memory, thought, emotion, analysis, motor skills, vision, touch, taste, breathing, temperature, and processes that regulate the body. The brain processes information that it receives from the central and peripheral nervous systems and endocrine organs, and sends messages to the body coordinating actions and reactions.",
  },
  {
    fieldName: "Thyroid",
    name: "Thyroid",
    value: 20,
    content:
      "The thyroid gland produces hormones that regulate the body’s metabolism, heart rate, muscle contrcation, food digestion, brain development, bond integrity, white blood cell development, and many other essential functions.",
  },
  {
    fieldName: "Heart",
    name: "Heart",
    value: 40,
    content:
      "As the primary organ of the circulatory system, the heart pumps blood throughout the body and delivers oxygen and nutrients to the cells. In exchange, the blood carries away unwanted carbon dioxide and waste products to be excreted out of the body via exhaling, sweating, urinating, and defecating. The heart also acts as an endocrine organ producing the hormones ANF (atrial natriuretic factor) and BNP (beta-natriuretic peptide) which are necessary for modulating blood volume and regulating blood pressure. The brain, liver, kidneys, nervous system and endocrine system (e.g., adrenal glands) play a direct role in the heart’s function.",
  },
  {
    fieldName: "Stomach",
    name: "Stomach",
    value: 50,
    content:
      "The stomach is a muscular organ in the gastrointestinal (GI) tract responsible for digesting fifty percent of food after the mouth has finished digesting the first fifty percent. When the stomach receives food, it produces enzymes and acids critical for breaking food down and absorbing essential nutrients and vitamins. Thereafter, digested food goes to the small intestine.",
  },
  {
    fieldName: "Small Intestine",
    name: "Small\nIntestine",
    value: 2,
    content:
      "The small intestine is the longest segment of the gastrointestinal tract, measuring about 22 feet and is divided into three sections: duodenum, jejunum, and ileum. The small intestine holds the highest concentration of digestive juices and enzymes necessary for breaking down food and is assisted by the liver, gallbladder and pancreas. Intestinal walls are only one cell width thick, necessary for absorbing nutrients and water to be delivered via blood to all the other cells in the body. Waste byproducts derived from food are sent from the small intestines to the colon.",
  },
  {
    fieldName: "Kidney & Adrenal Glands",
    name: "Kidney &\nAdrenal Glands",
    value: 89,
    content:
      "The kidneys make red blood cells, filter blood, balance the body’s electrolytes, acidity, alkalinity, and fluid levels, and remove toxins and other waste products, such as nitrogen (urea) and creatinine, through urine. The adrenal glands are located directly above the kidneys and produce hormones, including cortisol, adrenaline and aldosterone. These hormones respond to external and internal stressors, control cellular metabolism, reduce inflammation, and regulate blood pressure, blood sugar levels, mood and sleep patterns.",
  },
  {
    fieldName: "Liver",
    name: "Liver",
    value: 96,
    content:
      "The body’s largest solid organ, the liver not only has metabolic and detoxifying functions but also serves as a unique lymphoid organ. It performs more than 500 vital functions, including filtering blood, removing toxic chemicals, dead cells, and antigens, producing bile to break down food, metabolizing proteins, carbohydrates, and fats, and regulating hormones.The liver is also a gland, responsible for producing essential proteins and hormones and controlling the amount of blood delivered to the heart.",
  },
  {
    fieldName: "Lungs",
    name: "Lungs",
    value: 46,
    content:
      "The lungs extract oxygen from the air we breathe in and pass it into the blood when the heart relaxes or expands. From the lungs, the bloodstream delivers oxygen to all of the body’s cells, organs and tissues, necessary for them to function. The bloodstream also carries carbon dioxide and other waste products out of the body in the form of “dead” (deoxygenated) air when we exhale.",
  },
  {
    fieldName: "Gallbladder",
    name: "Gall-\nBladder",
    value: 28,
    content:
      "The gallbladder serves as the liver’s assistant and the storehouse of bile. Bile is a fluid produced by the liver consisting mostly of cholesterol, bilirubin and bile salts. Bile is released by the gallbladder whenever the small intestine needs help to break down fats in digested food.",
  },
  {
    fieldName: "Colon",
    name: "Colon",
    value: 67,
    content:
      "The colon, also known as the large intestine or bowel, is the second longest segment of the gastrointestinal tract, measuring about five feet long. It houses around forty trillion microbes which break down undigested food’s remaining carbohydrates, extract essential vitamins such as B and K and electrolytes, neutralize harmful by-products, and prevent disease-causing bacteria from sticking to the intestinal walls. The colon solidifies food waste into stool (poop) and pushes it out via the rectum.",
  },
  {
    fieldName: "Spleen",
    name: "Spleen",
    value: 70,
    content:
      "The spleen is part of the lymphatic system, which plays a major role in the immune system. The spleen maintains fluid levels in the body, makes white blood cells and antibodies that protect us from infections, keeps inflammation in check, and filters blood by removing cellular waste, damaged and aged blood cells.",
  },
  {
    fieldName: "Pancreas",
    name: "Pancreas",
    value: 16,
    content:
      "The pancreas produces enzymes that aid digestion of fats, carbohydrates, and proteins in the digestive tract. The pancreas also produces and releases hormones, such as insulin and glucagon, into the bloodstream to regulate blood sugar levels, which is critical for brain, liver, and kidney function.",
  },
];

export const bloodTestCBC = [
  {
    name: "White Blood Count",
    value: "5.5",
    dropdown: "1",
  },
  {
    name: "Red Blood Count",
    value: "5.4",
    dropdown: "2",
    error: "🔴 Please check the value",
  },
  {
    name: "Hemoglobin",
    value: "15.8",
    dropdown: "3",
  },
  {
    name: "Hematocrit",
    value: "15.8",
    dropdown: "3",
    error: "🔴 Oops! Something went wrong. Please fill in the value",
  },
  {
    name: "MCV",
    value: "86",
    dropdown: "3",
  },
  {
    name: "MCH",
    value: "33.4",
    dropdown: "3",
  },
  {
    name: "MCMH",
    value: "00",
    dropdown: "3",
  },
  {
    name: "RDW",
    value: "00",
    dropdown: "3",
  },
  {
    name: "Platelets",
    value: "226",
    dropdown: "3",
  },
  {
    name: "MPV",
    value: "00",
    dropdown: "3",
  },
  {
    name: "Neutrophil Absolute Count",
    value: "00",
    dropdown: "3",
  },
  {
    name: "Lymphocyte Absolute Count",
    value: "00",
    dropdown: "3",
  },
  {
    name: "Monocyte Absolute Count",
    value: "00",
    dropdown: "3",
  },
  {
    name: "Eosinophil Absolute Count",
    value: "00",
    dropdown: "3",
  },
  {
    name: "Basophil Absolute Count",
    value: "00",
    dropdown: "3",
  },
  {
    name: "% Neutrophil",
    value: "00",
    dropdown: "3",
  },
  {
    name: "% Lymphocytes",
    value: "00",
    dropdown: "3",
  },
  {
    name: "% Monocyte",
    value: "00",
    dropdown: "3",
  },
  {
    name: "% Eosinophil",
    value: "00",
    dropdown: "3",
  },
  {
    name: "% Basophil",
    value: "00",
    dropdown: "3",
  },
];

export const bloodTestCMP = [
  {
    name: "Glucose",
    value: "95",
    dropdown: "1",
  },
  {
    name: "Blood Urea Nitrogen",
    value: "00",
    dropdown: "2",
    error: "🔴 Please check the value",
  },
  {
    name: "Creatinine",
    value: "1.0",
    dropdown: "3",
  },
  {
    name: "BUN/Creatinine Ration",
    value: "00",
    dropdown: "3",
    error: "🔴 Oops! Something went wrong. Please fill in the value",
  },
  {
    name: "eGFR",
    value: "31",
    dropdown: "3",
    notes: "(If non-African American/Black)",
  },
  {
    name: "eGFR African",
    value: "33",
    dropdown: "3",
    notes: "(If non-African American/Black)",
  },
  {
    name: "Albumin / Creatinine ration (ACR)",
    value: "00",
    dropdown: "3",
  },
  {
    name: "Sodium",
    value: "00",
    dropdown: "3",
  },
  {
    name: "Potassium",
    value: "00",
    dropdown: "3",
  },
  {
    name: "Chloride",
    value: "00",
    dropdown: "3",
  },
  {
    name: "Carbon Dioxide(CO2)",
    value: "24",
    dropdown: "3",
  },
  {
    name: "Calcium",
    value: "00",
    dropdown: "3",
    notes: "(serum)",
  },
  {
    name: "Protein",
    value: "00",
    dropdown: "3",
    notes: "(Total Protein)",
  },
  {
    name: "Albumin",
    value: "00",
    dropdown: "3",
    notes: "(Serum plasma)",
  },
  {
    name: "Globulin",
    value: "00",
    dropdown: "3",
  },
  {
    name: "Albumin/Globulin Ration (A/G)",
    value: "00",
    dropdown: "3",
  },
  {
    name: "Bilirubin",
    value: "00",
    dropdown: "3",
  },
  {
    name: "Alkaline Phosphatase",
    value: "00",
    dropdown: "3",
  },
  {
    name: "AST",
    value: "00",
    dropdown: "3",
  },
  {
    name: "ALT",
    value: "00",
    dropdown: "3",
  },
  {
    name: "GGT",
    value: "00",
    dropdown: "3",
  },
  {
    name: "Hemoglobin A1c (HbA1c)",
    value: "00",
    dropdown: "3",
  },
  {
    name: "Estimated Average Glucose (eAG)",
    value: "00",
    dropdown: "3",
  },
  {
    name: "Phosphorus",
    value: "00",
    dropdown: "3",
  },
  {
    name: "Magnesium",
    value: "00",
    dropdown: "3",
  },
  {
    name: "Anion Gap",
    value: "00",
    dropdown: "3",
  },
];

export const bloodTestLipid = [
  {
    name: "Cholesterol, Total",
    value: "00",
    dropdown: "1",
  },
  {
    name: "HDL Cholesterol",
    value: "00",
    dropdown: "1",
  },
  {
    name: "Triglycerides, Serum",
    value: "00",
    dropdown: "1",
  },
  {
    name: "LDL Cholesterol",
    value: "00",
    dropdown: "1",
  },
  {
    name: "VLDL Cholesterol",
    value: "00",
    dropdown: "1",
  },
  {
    name: "Total Cholesterol /HDL(ratio)",
    value: "00",
    dropdown: "1",
  },
];

export const bloodTestCommon = [
  {
    name: "Arsenic (Blood, Serum)",
    value: "00",
    dropdown: "1",
  },
  {
    name: "Mercury (Blood, Serum)",
    value: "00",
    dropdown: "1",
  },
  {
    name: "Cadmium (Blood, Serum)",
    value: "00",
    dropdown: "1",
  },
  {
    name: "Lead (Blood, Serum)",
    value: "00",
    dropdown: "1",
  },
  {
    name: "Ceruloplasmin",
    value: "00",
    dropdown: "1",
  },
  {
    name: "Cardiac C-reactive protein (hs-CRP)",
    value: "00",
    dropdown: "1",
  },
  {
    name: "Apolipoprotein A (ApoA)",
    value: "00",
    dropdown: "1",
  },
  {
    name: "Apolipoprotein B (ApoB)",
    value: "00",
    dropdown: "1",
  },
  {
    name: "LDP-P (Low Density Lipoprotein Particles)",
    value: "00",
    dropdown: "1",
  },
  {
    name: "HLD-P (High Density Lipoprotein Particles)",
    value: "00",
    dropdown: "1",
  },
  {
    name: "Small LDL-P",
    value: "00",
    dropdown: "1",
  },
  {
    name: "Thyroid - Stimulation Hormone (TSH)",
    value: "00",
    dropdown: "1",
  },
  {
    name: "Free T4(Thyroxine)",
    value: "00",
    dropdown: "1",
  },
  {
    name: "Total T4 (Thyroxine)",
    value: "00",
    dropdown: "1",
  },
  {
    name: "Free T3 ( Triiodothyronine)",
    value: "00",
    dropdown: "1",
  },
  {
    name: "Reverse T3",
    value: "00",
    dropdown: "1",
  },
  {
    name: "T3 Uptake",
    value: "00",
    dropdown: "1",
  },
  {
    name: "Total T3 (Triiodothyronine)",
    value: "00",
    dropdown: "1",
  },
  {
    name: "Thyroglobulin Antibody",
    value: "00",
    dropdown: "1",
  },
  {
    name: "Thyroid Peroxidase Antibody",
    value: "00",
    dropdown: "1",
  },
  {
    name: "Vitamin D, Total 25-Hydroxy",
    value: "00",
    dropdown: "1",
  },
  {
    name: "Ferritin (Serum)",
    value: "00",
    dropdown: "1",
  },
  {
    name: "",
    value: "00",
    dropdown: "1",
    notes: "(Total iron binding capacity)",
  },
  {
    name: "",
    value: "00",
    dropdown: "1",
    notes: "(un. iron binding capacity)",
  },
  {
    name: "Vitamin B12",
    value: "00",
    dropdown: "1",
  },
  {
    name: "Folate",
    value: "00",
    dropdown: "1",
    notes: "(Folic Acid, Vitamin B9)",
  },
  {
    name: "Vitamin B6",
    value: "00",
    dropdown: "1",
    notes: "(Pyridoxine)",
  },
];
