import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import DialogTitle from "@mui/material/DialogTitle"
import settingStyle from "./settings.module.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmationModal({
  openModal,
  handleClose,
  content,
  subcontent,
  handleYes,
  buttonNo,
  headerClassName,
  subHeaderClassName,
  status,
}) {
  return (
    <div>
      <Dialog
        open={openModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            minHeight: "180px",
            borderRadius: "20px",
            padding: subHeaderClassName ? "50px 35px" : "25px 35px",
          },
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        {status == 'delete' && <DialogTitle sx={{ fontWeight: 'bold', padding: '0px', color: '#122d5b', fontSize: '24px' }}>{"Are you sure you want to delete the report?"}</DialogTitle>}
        <DialogContent
          sx={{
            padding: 0,
          }}
        >
          <p
            id="alert-dialog-slide-description"
            className={
              headerClassName ? headerClassName : settingStyle.confirm_text
            }
          >
            {content}
          </p>
          <p
            id="alert-dialog-slide-description"
            className={
              subHeaderClassName
                ? subHeaderClassName
                : settingStyle.confirm_text
            }
          >
            {subcontent}
          </p>
        </DialogContent>
        <div className={settingStyle.modal_footer}>
          {buttonNo ? (
            <button
              className={settingStyle.confirm_btn}
              style={{ marginTop: "50px" }}
              onClick={handleClose}
            >
              Ok
            </button>
          ) : (
            <>
              <button
                className={settingStyle.confirm_btn}
                onClick={handleClose}
                style={{ fontFamily: "var(--poppins-regular)" }}
              >
                {status == "cancel" ? "No, I want to keep using AHA" : "No"}
              </button>
              <button
                className={settingStyle.confirm_btn}
                onClick={handleYes}
                style={{ fontFamily: "var(--poppins-regular)" }}
              >
                {status == "cancel" ? "Yes, please cancel" : "Yes"}
              </button>
            </>
          )}
        </div>
      </Dialog>
    </div>
  );
}
