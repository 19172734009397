import React, { useEffect, useState } from "react";
import { Card } from "@mui/material";
import biomarker from "./biomarker.module.scss";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { StyledEngineProvider } from "@mui/material/styles";
import CustomizedSlider from "./SliderRange";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useDispatch } from "react-redux";
import { getNumber } from "../../helper";
import expandIcon from '../../assets/svg/expand.svg'
import collapseIcon from '../../assets/svg/collapse.svg'

const useStyles = makeStyles((theme) => ({
  arraowIcon: {
    fill: "#fff !important",
    fontSize: "22px !important",
    backgroundColor: "#122d5b",
    borderRadius: "50%",
  },
  accordion_summary: {
    borderRadius: "15px",
    // border: "1px solid #D1D1D1",
    height: "70px",
    padding: "5px 10px 0px 15px",
    boxShadow: "0 0 8px 0 #b8b0b05c",
  },
  grid_set: {
    justifyContent: "center",
    alignItems: "center",
  },
  accordion_details: {
    borderRadius: "0px 0px 18.92px 18.92px",
    background: "rgb(255, 255, 255)",
    borderWidth: "0px 1px 1px",
    borderStyle: "solid",
    borderColor: "lightgray",
    borderImage: "initial",
    paddingTop: "16px",
    borderTop: "0px !important",
    color: "#385A76",
    boxShadow: "0 6px 8px 0 #b8b0b05c",
  },
}));

function BiomarkerSection({ biomarkerInfo, getData, gender, userRole }) {
  const classes = useStyles();
  // const [expanded, setExpanded] = React.useState(false);
  const [expandedPanels, setExpandedPanels] = useState({});
  const [marker, setMarker] = useState("");
  const [fontColor, setFontColor] = useState("#8A8A8A");
  const [isCollapsed, setCollapsed] = useState(false);
  const [isCollapsedOne, setCollapsedOne] = useState(false);
  const [isCollapsedTwo, setCollapsedTwo] = useState(false);
  const [isCollapsedThree, setCollapsedThree] = useState(false);

  const toggleCollapse = () => {
    setCollapsed(!isCollapsed);
  };
  const toggleCollapseOne = () => {
    setCollapsedOne(!isCollapsedOne);
  };
  const toggleCollapseTwo = () => {
    setCollapsedTwo(!isCollapsedTwo);
  };
  const toggleCollapseThree = () => {
    setCollapsedThree(!isCollapsedThree);
  };

  // const handleChange = (item) => (event, isExpanded) => {
  //   // Update the expanded state for the specific item that was clicked
  //   setExpanded({
  //     ...expanded,
  //     [item]: isExpanded,
  //   });
  // };

  const getActualSlider = (mainValue, Rmin, Omin, Gmin, Gmax, Omax, Rmax) => {
    if (
      Number(mainValue) >= getNumber(Gmin) &&
      Number(mainValue) <= getNumber(Gmax)
    ) {
      return "#4C942A";
    } else if (
      Number(mainValue) < getNumber(Gmin) &&
      Number(mainValue) >= getNumber(Omin)
    ) {
      return "#E68413";
    } else if (
      Number(mainValue) < getNumber(Omin)
      // &&
      // Number(mainValue) <= getNumber(Rmin)
    ) {
      return "#FF0000";
    } else if (
      Number(mainValue) > getNumber(Gmax) &&
      Number(mainValue) <= getNumber(Omax)
    ) {
      return "#E68413";
    } else if (Number(mainValue) > getNumber(Omax)) {
      return "#FF0000";
    } else {
      return "#9bacba";
    }
  };

  useEffect(() => {
    const initialExpandedPanels = {};
    const categories = ['CBC', 'CMP', 'Lipid', 'Other'];
    categories.forEach(category => {
      if (biomarkerInfo && biomarkerInfo?.biomarker?.[category]?.length) {
        biomarkerInfo?.biomarker[category].forEach(item => {
          let colorCode = getActualSlider(
            getData(item.Biomarker_Name.S),
            item["Absolute Lower Limit"]?.S,
            item[`${gender ? gender : "Woman"} Abnormal Lower Limit`]?.S,
            item[`${gender ? gender : "Woman"} Optimal Lower Limit`]?.S,
            item[`${gender ? gender : "Woman"} Optimal Upper Limit`]?.S,
            item[`${gender ? gender : "Woman"} Abnormal Upper Limit`]?.S,
            item["Absolute Upper Limit"]?.S
          );
          if (colorCode === "#FF0000") {
            initialExpandedPanels[item.Biomarker_Name.S] = true;
          }
        });
      }
    });
    setExpandedPanels(initialExpandedPanels);
  }, [biomarkerInfo, gender]);

  const handleChangeNew = (panel) => (event, isExpanded) => {
    setExpandedPanels((prev) => ({
      ...prev,
      [panel]: isExpanded
    }));
  };
  return (
    <>
      <div className={!userRole && biomarker.itemA}>
        <Card className={biomarker.biomarkerMain}>
          <div className={biomarker.biomarker_collapse}>
            <div className={biomarker.cbcTitle}>Complete Blood Count (CBC)</div>
            {userRole && <div><button onClick={toggleCollapse} className={biomarker.toggle_biomarker_expand}>
              {isCollapsed ? <ExpandMoreIcon className={classes.arraowIcon} /> : <ExpandLessIcon className={classes.arraowIcon} />}
            </button></div>}
          </div>
          {userRole && <p className={biomarker.biomarker_desc}>The results of a CBC can help identify a wide range of medical conditions, such as anemia, infections, immune system disorders, bleeding disorders, and certain types of cancers. The test is typically ordered as part of a routine health checkup, to diagnose specific health conditions, or to monitor the effects of certain treatments.</p>}
          {!isCollapsed && <Grid className={userRole ? biomarker.main_biomarker_box_role : biomarker.main_biomarker_box}>

            {biomarkerInfo &&
              biomarkerInfo.biomarker?.CBC.length &&
              biomarkerInfo.biomarker?.CBC.map((item) => {
                let colorCode = getActualSlider(
                              getData(item.Biomarker_Name.S),
                              item["Absolute Lower Limit"]?.S,
                              item[
                                `${gender ? gender : "Woman"
                                } Abnormal Lower Limit`
                              ]?.S,
                              item[
                                `${gender ? gender : "Woman"
                                } Optimal Lower Limit`
                              ]?.S,
                              item[
                                `${gender ? gender : "Woman"
                                } Optimal Upper Limit`
                              ]?.S,
                              item[
                                `${gender ? gender : "Woman"
                                } Abnormal Upper Limit`
                              ]?.S,
                              item["Absolute Upper Limit"]?.S
                            )
                return (
                  <div className="organOverview">
                    <Accordion
                      // expanded={expanded[item.Biomarker_Name.S]}
                      // defaultExpanded={colorCode === "#FF0000" ? true : expanded[item.Biomarker_Name.S]}
                      key={item.Biomarker_Name.S}
                      expanded={expandedPanels[item.Biomarker_Name.S] || false}
                      onChange={handleChangeNew(item.Biomarker_Name.S)}
                    >
                      <div className="accordian">
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon className={classes.arraowIcon} />
                          }
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                          className={classes.accordion_summary}
                          style={{
                            border: `${colorCode === "#FF0000" ||
                            colorCode === "#E68413"
                              ? `1px solid ${colorCode}`
                              : `1px solid #D1D1D1`
                              } `,
                          }}
                        >
                          <div
                            className={biomarker.vertical}
                            style={{
                              borderLeft: `${colorCode === "#FF0000" ||
                              colorCode === "#E68413"
                                ? `5px solid ${colorCode}`
                                : `3px solid ${colorCode}`
                                }`,
                              filter: `drop-shadow(0px 0px 2px ${colorCode}`,
                            }}
                          ></div>
                          <Box sx={{ flexGrow: 2 }}>
                            <Grid container className={classes.grid_set}>
                              <Grid
                                item
                                xs={6}
                                className={biomarker.main_grid_set}
                              >
                                <div className={biomarker.innerData}>
                                  <p
                                    className={biomarker.nameTitle}
                                    title={item.Biomarker_Name.S}
                                  >
                                    {item.Biomarker_Name.S}
                                  </p>
                                  <p
                                    className={biomarker.units}
                                    title={item["Conventional Units"]?.S}
                                  >
                                    {item["Conventional Units"]?.S}
                                  </p>
                                </div>
                              </Grid>
                              <div
                                className={biomarker.bio_value}
                                style={{
                                  color: colorCode,
                                }}
                              >
                                {getData(item.Biomarker_Name.S)}
                              </div>
                              <Grid item xs={6} className={biomarker.slider_grid}>
                                <StyledEngineProvider injectFirst>
                                  <CustomizedSlider
                                    userRole={userRole}
                                    // color={color}
                                    Rmin={item["Absolute Lower Limit"]?.S}
                                    Omin={
                                      item[
                                        `${gender ? gender : "Woman"
                                        } Abnormal Lower Limit`
                                      ]?.S
                                    }
                                    Gmin={
                                      item[
                                        `${gender ? gender : "Woman"
                                        } Optimal Lower Limit`
                                      ]?.S
                                    }
                                    Gmax={
                                      item[
                                        `${gender ? gender : "Woman"
                                        } Optimal Upper Limit`
                                      ]?.S
                                    }
                                    Omax={
                                      item[
                                        `${gender ? gender : "Woman"
                                        } Abnormal Upper Limit`
                                      ]?.S
                                    }
                                    Rmax={item["Absolute Upper Limit"]?.S}
                                    mainValue={getData(item.Biomarker_Name.S)}
                                    setFontColor={setFontColor}
                                  />
                                </StyledEngineProvider>
                              </Grid>
                            </Grid>
                          </Box>
                        </AccordionSummary>
                      </div>
                      <AccordionDetails
                        className={classes.accordion_details}
                        style={{
                          border: `${colorCode === "#FF0000" ||
                          colorCode === "#E68413"
                            ? `1px solid ${colorCode}`
                            : `1px solid #D1D1D1`
                            } `,
                          borderTop: "0px !important",
                        }}
                      >
                        <p className={biomarker.biomarker_details}>
                          {item.Biomarker_Explanation.S}
                        </p>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                );
              })}
          </Grid>}
        </Card>
      </div>
      <div className={userRole ? biomarker.itemBrole : biomarker.itemB}>
        <Card className={biomarker.biomarkerMain}>
          <div className={biomarker.biomarker_collapse}>
            <div className={biomarker.cbcTitle}> Comprehensive Metabolic Panel (CMP)</div>
            {userRole && <div><button onClick={toggleCollapseOne} className={biomarker.toggle_biomarker_expand}>
              {isCollapsedOne ? <ExpandMoreIcon className={classes.arraowIcon} /> : <ExpandLessIcon className={classes.arraowIcon} />}
            </button></div>}
          </div>
          {userRole && <p className={biomarker.biomarker_desc}>The comprehensive metabolic panel (CMP) is a blood test that provides information about a person's kidney and liver function, electrolyte levels, and blood sugar levels, aiding in the assessment of overall metabolic health.</p>}
          {!isCollapsedOne && <Grid className={userRole ? biomarker.main_biomarker_box_role : biomarker.main_biomarker_box}>
            {biomarkerInfo &&
              biomarkerInfo.biomarker?.CMP.length &&
              biomarkerInfo.biomarker?.CMP.map((item) => {
                let colorCode = getActualSlider(
                              getData(item.Biomarker_Name.S),
                              item["Absolute Lower Limit"]?.S,
                              item[
                                `${gender ? gender : "Woman"
                                } Abnormal Lower Limit`
                              ]?.S,
                              item[
                                `${gender ? gender : "Woman"
                                } Optimal Lower Limit`
                              ]?.S,
                              item[
                                `${gender ? gender : "Woman"
                                } Optimal Upper Limit`
                              ]?.S,
                              item[
                                `${gender ? gender : "Woman"
                                } Abnormal Upper Limit`
                              ]?.S,
                              item["Absolute Upper Limit"]?.S
                            )
                return (
                  <div className="organOverview">
                    <Accordion
                      //  expanded={expanded[item.Biomarker_Name.S] || colorCode == "#FF0000"}
                      // defaultExpanded={colorCode === "#FF0000"}
                      key={item.Biomarker_Name.S}
                      expanded={expandedPanels[item.Biomarker_Name.S] || false}
                      onChange={handleChangeNew(item.Biomarker_Name.S)}
                    >
                      <div className="accordian">
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon className={classes.arraowIcon} />
                          }
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                          className={classes.accordion_summary}
                          style={{
                            border: `${colorCode === "#FF0000" ||
                            colorCode === "#E68413"
                              ? `1px solid ${colorCode}`
                              : `1px solid #D1D1D1`
                              } `,
                          }}
                        >
                          <div
                            className={biomarker.vertical}
                            style={{
                              borderLeft: `${colorCode === "#FF0000" ||
                              colorCode === "#E68413"
                                ? `5px solid ${colorCode}`
                                : `3px solid ${colorCode}`
                                }`,
                              filter: `drop-shadow(0px 0px 2px ${colorCode}`,
                            }}
                          ></div>
                          <Box sx={{ flexGrow: 2 }}>
                            <Grid container className={classes.grid_set}>
                              <Grid
                                item
                                xs={6}
                                className={biomarker.main_grid_set}
                              >
                                <div className={biomarker.innerData}>
                                  <p
                                    className={biomarker.nameTitle}
                                    title={item.Biomarker_Name.S}
                                  >
                                    {item.Biomarker_Name.S}
                                  </p>
                                  <p
                                    className={biomarker.units}
                                    title={item["Conventional Units"]?.S}
                                  >
                                    {item["Conventional Units"]?.S}
                                  </p>
                                </div>
                              </Grid>
                              <div
                                className={biomarker.bio_value}
                                style={{
                                  color: colorCode,
                                }}
                              >
                                {getData(item.Biomarker_Name.S)}
                              </div>
                              <Grid item xs={6} className={biomarker.slider_grid}>
                                <StyledEngineProvider injectFirst>
                                  <CustomizedSlider
                                    userRole={userRole}
                                    getFontColor={(data) => {
                                      setMarker(data);
                                    }}
                                    Rmin={item["Absolute Lower Limit"]?.S}
                                    Omin={
                                      item[
                                        `${gender ? gender : "Woman"
                                        } Abnormal Lower Limit`
                                      ]?.S
                                    }
                                    Gmin={
                                      item[
                                        `${gender ? gender : "Woman"
                                        } Optimal Lower Limit`
                                      ]?.S
                                    }
                                    Gmax={
                                      item[
                                        `${gender ? gender : "Woman"
                                        } Optimal Upper Limit`
                                      ]?.S
                                    }
                                    Omax={
                                      item[
                                        `${gender ? gender : "Woman"
                                        } Abnormal Upper Limit`
                                      ]?.S
                                    }
                                    Rmax={item["Absolute Upper Limit"]?.S}
                                    mainValue={getData(item.Biomarker_Name.S)}
                                    setFontColor={setFontColor}
                                  />
                                </StyledEngineProvider>
                              </Grid>
                            </Grid>
                          </Box>
                        </AccordionSummary>
                      </div>
                      <AccordionDetails
                        className={classes.accordion_details}
                        style={{
                          border: `${colorCode === "#FF0000" ||
                          colorCode === "#E68413"
                            ? `1px solid ${colorCode}`
                            : `1px solid #D1D1D1`
                            } `,
                          borderTop: "0px !important",
                        }}
                      >
                        <p className={biomarker.biomarker_details}>
                          {item.Biomarker_Explanation.S}
                        </p>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                );
              })}
          </Grid>}
        </Card>
      </div>
      <div className={userRole ? biomarker.role_panel : biomarker.section_panel}>
        <div className={biomarker.itemC}>
          <Card className={biomarker.biomarkerMain}>
            <div className={biomarker.biomarker_collapse}>
              <div className={biomarker.cbcTitle}>Lipid Panel</div>
              {userRole && <div><button onClick={toggleCollapseTwo} className={biomarker.toggle_biomarker_expand}>
                {isCollapsedTwo ? <ExpandMoreIcon className={classes.arraowIcon} /> : <ExpandLessIcon className={classes.arraowIcon} />}
              </button></div>}
            </div>
            {userRole && <p className={biomarker.biomarker_desc}>A lipid panel is a blood test that measures levels of cholesterol and triglycerides, offering insights into an individual's cardiovascular health and risk of heart disease.</p>}
            {!isCollapsedTwo && <Grid className={userRole ? biomarker.main_biomarker_box_role : biomarker.main_biomarker_box}>
              {biomarkerInfo &&
                biomarkerInfo.biomarker?.Lipid.length &&
                biomarkerInfo.biomarker?.Lipid.map((item) => {
                  let colorCode = getActualSlider(
                              getData(item.Biomarker_Name.S),
                              item["Absolute Lower Limit"]?.S,
                              item[
                                `${gender ? gender : "Woman"
                                } Abnormal Lower Limit`
                              ]?.S,
                              item[
                                `${gender ? gender : "Woman"
                                } Optimal Lower Limit`
                              ]?.S,
                              item[
                                `${gender ? gender : "Woman"
                                } Optimal Upper Limit`
                              ]?.S,
                              item[
                                `${gender ? gender : "Woman"
                                } Abnormal Upper Limit`
                              ]?.S,
                              item["Absolute Upper Limit"]?.S
                            )
                  return (
                    <div className="organOverview">
                      <Accordion
                        // expanded={expanded[item.Biomarker_Name.S] || colorCode == "#FF0000"}
                        // defaultExpanded={!!colorCode == "#FF0000"}
                        key={item.Biomarker_Name.S}
                        expanded={expandedPanels[item.Biomarker_Name.S] || false}
                        onChange={handleChangeNew(item.Biomarker_Name.S)}
                      >
                        <div className="accordian">
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon className={classes.arraowIcon} />
                            }
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className={classes.accordion_summary}
                            style={{
                              border: `${colorCode === "#FF0000" ||
                              colorCode === "#E68413"
                                ? `1px solid ${colorCode}`
                                : `1px solid #D1D1D1`
                                } `,
                            }}
                          >
                            <div
                              className={biomarker.vertical}
                              style={{
                                borderLeft: `${colorCode === "#FF0000" ||
                                colorCode === "#E68413"
                                  ? `5px solid ${colorCode}`
                                  : `3px solid ${colorCode}`
                                  }`,
                                filter: `drop-shadow(0px 0px 2px ${colorCode}`,
                              }}
                            ></div>
                            <Box sx={{ flexGrow: 2 }}>
                              <Grid container className={classes.grid_set}>
                                <Grid
                                  item
                                  xs={6}
                                  className={biomarker.main_grid_set}
                                >
                                  <div className={biomarker.innerData}>
                                    <p
                                      className={biomarker.nameTitle}
                                      title={item.Biomarker_Name.S}
                                    >
                                      {item.Biomarker_Name.S}
                                    </p>
                                    <p
                                      className={biomarker.units}
                                      title={item["Conventional Units"]?.S}
                                    >
                                      {item["Conventional Units"]?.S}
                                    </p>
                                  </div>
                                </Grid>
                                <div
                                  className={biomarker.bio_value}
                                  style={{
                                    color: colorCode,
                                  }}
                                >
                                  {getData(item.Biomarker_Name.S)}
                                </div>
                                <Grid
                                  item
                                  xs={6}
                                  className={biomarker.slider_grid}
                                >
                                  <StyledEngineProvider injectFirst>
                                    <CustomizedSlider
                                      userRole={userRole}
                                      getFontColor={(data) => {
                                        setMarker(data);
                                      }}
                                      Rmin={item["Absolute Lower Limit"]?.S}
                                      Omin={
                                        item[
                                          `${gender ? gender : "Woman"
                                          } Abnormal Lower Limit`
                                        ]?.S
                                      }
                                      Gmin={
                                        item[
                                          `${gender ? gender : "Woman"
                                          } Optimal Lower Limit`
                                        ]?.S
                                      }
                                      Gmax={
                                        item[
                                          `${gender ? gender : "Woman"
                                          } Optimal Upper Limit`
                                        ]?.S
                                      }
                                      Omax={
                                        item[
                                          `${gender ? gender : "Woman"
                                          } Abnormal Upper Limit`
                                        ]?.S
                                      }
                                      Rmax={item["Absolute Upper Limit"]?.S}
                                      mainValue={getData(item.Biomarker_Name.S)}
                                      setFontColor={setFontColor}
                                    />
                                  </StyledEngineProvider>
                                </Grid>
                              </Grid>
                            </Box>
                          </AccordionSummary>
                        </div>
                        <AccordionDetails
                          className={classes.accordion_details}
                          style={{
                            border: `${colorCode === "#FF0000" ||
                            colorCode === "#E68413"
                              ? `1px solid ${colorCode}`
                              : `1px solid #D1D1D1`
                              } `,
                            borderTop: "0px !important",
                          }}
                        >
                          <p className={biomarker.biomarker_details}>
                            {item.Biomarker_Explanation.S}
                          </p>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  );
                })}
            </Grid>}
          </Card>
        </div>
        <div className={userRole ? biomarker.itemDrole : biomarker.itemD}>
          <Card className={biomarker.biomarkerMain}>
            <div className={biomarker.biomarker_collapse}>
              <div className={biomarker.cbcTitle}>Common Other Blood Tests</div>
              {userRole && <div><button onClick={toggleCollapseThree} className={biomarker.toggle_biomarker_expand}>
                {isCollapsedThree ? <ExpandMoreIcon className={classes.arraowIcon} /> : <ExpandLessIcon className={classes.arraowIcon} />}
              </button></div>}
            </div>
            {userRole && <p className={biomarker.biomarker_desc}>The comprehensive metabolic panel (CMP) is a blood test that provides information about a person's kidney and liver function, electrolyte levels, and blood sugar levels, aiding in the assessment of overall metabolic health.</p>}
            {!isCollapsedThree && <Grid className={userRole ? biomarker.main_biomarker_box_role : biomarker.main_biomarker_box}>
              {biomarkerInfo &&
                biomarkerInfo.biomarker?.Other.length &&
                biomarkerInfo.biomarker?.Other.map((item) => {
                  let colorCode = getActualSlider(
                              getData(item.Biomarker_Name.S),
                              item["Absolute Lower Limit"]?.S,
                              item[
                                `${gender ? gender : "Woman"
                                } Abnormal Lower Limit`
                              ]?.S,
                              item[
                                `${gender ? gender : "Woman"
                                } Optimal Lower Limit`
                              ]?.S,
                              item[
                                `${gender ? gender : "Woman"
                                } Optimal Upper Limit`
                              ]?.S,
                              item[
                                `${gender ? gender : "Woman"
                                } Abnormal Upper Limit`
                              ]?.S,
                              item["Absolute Upper Limit"]?.S
                            )
                  return (
                    <div className="organOverview">
                      <Accordion
                        //  expanded={expanded[item.Biomarker_Name.S] && (!expanded[item.Biomarker_Name.S] || colorCode == "#FF0000")}
                        // defaultExpanded={colorCode === "#FF0000"}
                        key={item.Biomarker_Name.S}
                        expanded={expandedPanels[item.Biomarker_Name.S] || false}
                        onChange={handleChangeNew(item.Biomarker_Name.S)}
                      >
                        <div className="accordian">
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon className={classes.arraowIcon} />
                            }
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className={classes.accordion_summary}
                            style={{
                              border: `${colorCode === "#FF0000" ||
                              colorCode === "#E68413"
                                ? `1px solid ${colorCode}`
                                : `1px solid #D1D1D1`
                                } `,
                            }}
                          >
                            <div
                              className={biomarker.vertical}
                              style={{
                                borderLeft: `${colorCode === "#FF0000" ||
                                colorCode === "#E68413"
                                  ? `5px solid ${colorCode}`
                                  : `3px solid ${colorCode}`
                                  }`,
                                filter: `drop-shadow(0px 0px 2px ${colorCode}`,
                              }}
                            ></div>
                            <Box sx={{ flexGrow: 2 }}>
                              <Grid container className={classes.grid_set}>
                                <Grid
                                  item
                                  xs={6}
                                  className={biomarker.main_grid_set}
                                >
                                  <div className={biomarker.innerData}>
                                    <p
                                      className={biomarker.nameTitle}
                                      title={item.Biomarker_Name.S}
                                    >
                                      {item.Biomarker_Name.S}
                                    </p>
                                    <p
                                      className={biomarker.units}
                                      title={item["Conventional Units"]?.S}
                                    >
                                      {item["Conventional Units"]?.S}
                                    </p>
                                  </div>
                                </Grid>
                                <div
                                  className={biomarker.bio_value}
                                  style={{
                                    color: colorCode,
                                  }}
                                >
                                  {getData(item.Biomarker_Name.S)}
                                </div>
                                <Grid
                                  item
                                  xs={6}
                                  className={biomarker.slider_grid}
                                >
                                  <StyledEngineProvider injectFirst>
                                    <CustomizedSlider
                                      userRole={userRole}
                                      getFontColor={(data) => {
                                        setMarker(data);
                                      }}
                                      Rmin={item["Absolute Lower Limit"]?.S}
                                      Omin={
                                        item[
                                          `${gender ? gender : "Woman"
                                          } Abnormal Lower Limit`
                                        ]?.S
                                      }
                                      Gmin={
                                        item[
                                          `${gender ? gender : "Woman"
                                          } Optimal Lower Limit`
                                        ]?.S
                                      }
                                      Gmax={
                                        item[
                                          `${gender ? gender : "Woman"
                                          } Optimal Upper Limit`
                                        ]?.S
                                      }
                                      Omax={
                                        item[
                                          `${gender ? gender : "Woman"
                                          } Abnormal Upper Limit`
                                        ]?.S
                                      }
                                      Rmax={item["Absolute Upper Limit"]?.S}
                                      mainValue={getData(item.Biomarker_Name.S)}
                                      setFontColor={setFontColor}
                                    />
                                  </StyledEngineProvider>
                                </Grid>
                              </Grid>
                            </Box>
                          </AccordionSummary>
                        </div>
                        <AccordionDetails
                          className={classes.accordion_details}
                          style={{
                            border: `${colorCode === "#FF0000" ||
                            colorCode === "#E68413"
                              ? `1px solid ${colorCode}`
                              : `1px solid #D1D1D1`
                              } `,
                            borderTop: "0px",
                          }}
                        >
                          <p className={biomarker.biomarker_details}>
                            {item.Biomarker_Explanation.S}
                          </p>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  );
                })}
            </Grid>}
          </Card>
        </div>
      </div>
    </>
  );
}

export default BiomarkerSection;
