import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import deleteUserListModelStyle from "./deleteUserListModel.module.scss";
import { Box } from "@material-ui/core";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteProfile } from "../../redux/actions/SettingsAction";
import { ReactComponent as CloseWhite } from "../../assets/svg/CloseWhite.svg";
import axiosInstance from "../../axios/axiosInstance";
import SectionLoader from "../../components/loader/SectionLoader";
import { getBloodTests } from "../../redux/actions/firstStepAction";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteUserListModel({
  openModal,
  userListModelData,
  setOpenUserListModel,
  activePlan,
}) {
  const dispatch = useDispatch();
  const { bloodTests } = useSelector((state) => state.firstStep);
  const [userList, setUserList] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    dispatch(getBloodTests());
  }, [activePlan]);

  const confirmDelete = async (profileId) => {
    setLoader(true);
    const submittedReportsList = bloodTests?.filter((e) => e?.reports > 0);

    if (
      activePlan < submittedReportsList?.length ||
      (!activePlan && submittedReportsList?.length > 0)
    ) {
      const payload = {
        profileId: profileId,
      };
      dispatch(deleteProfile(payload));
      setUserList([...submittedReportsList]);
    } else {
      setOpenUserListModel(false);
      setLoader(false);
    }
  };
  const deleteUser = async (profileId) => {
    confirmDelete(profileId);
  };

  useEffect(() => {
    if (userListModelData) {
      const newArr = [...userListModelData]?.sort((a, b) => {
        if (a.isDefault === "true" && b.isDefault === "false") {
          return 1;
        } else if (a.isDefault === "false" && b.isDefault === "true") {
          return -1;
        } else {
          return 0;
        }
      });
      setUserList(newArr);
      setLoader(false);
    }
  }, [userListModelData]);

  return (
    <div>
      <Dialog
        open={openModal}
        TransitionComponent={Transition}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "20px",
            padding: "25px 35px",
            width: "80%",
          },
        }}
        className={deleteUserListModelStyle.modelWrapper}
      >
        <h1
          className={deleteUserListModelStyle.share_title}
          style={{
            opacity: loader ? 0.4 : 1,
            pointerEvents: loader ? "none" : "auto",
          }}
        >
          Delete User Profiles
        </h1>
        <p
          className={deleteUserListModelStyle.share_info}
          style={{
            opacity: loader ? 0.4 : 1,
            pointerEvents: loader ? "none" : "auto",
          }}
        >
          {`Downgrading your plan requires deleting at least ${Math.abs(
            userList?.length - activePlan
          )} user profile.`}
        </p>
        <DialogContent
          sx={{
            padding: "20px 0 0 0",
            position: "relative",
            "& .MuiDialogContent-root": {
              padding: "20px 0",
            },
          }}
        >
          <Box
            className="App"
            sx={{
              display: "grid",
              gridGap: 15,
              opacity: loader ? 0.4 : 1,
              pointerEvents: loader ? "none" : "auto",
            }}
          >
            {userList?.length &&
              userList?.map((e, i) => {
                return (
                  <div
                    key={i}
                    className={deleteUserListModelStyle.review_profile_section}
                  >
                    <div className={deleteUserListModelStyle.innerSection}>
                      <div className={deleteUserListModelStyle.innerSectionX}>
                        <p>{e?.name}</p>
                        <div className={deleteUserListModelStyle.icconSet}>
                          <CloseWhite
                            onClick={() =>
                              deleteUser(e?.profileId, e?.isDefault)
                            }
                          />
                        </div>
                      </div>
                      {e?.isDefault == "true" && (
                        <span
                          style={{
                            opacity: loader ? 0.4 : 1,
                            pointerEvents: loader ? "none" : "auto",
                            alignSelf: "baseline",
                          }}
                        >
                          Your profile cannot be restored after it is deleted.
                        </span>
                      )}
                    </div>
                  </div>
                );
              })}
          </Box>
        </DialogContent>
      <SectionLoader loading={loader} deleteProfile={loader} />

      </Dialog>
    </div>
  );
}
