import React, { useState } from "react";
import search from "./search.module.scss";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import SignUpModal from "../modal/sign-up/SignUpModal";
import { useDispatch } from "react-redux";
import { addProfileData } from "../../redux/actions/GetProfileAction";
import { useNavigate } from "react-router-dom";

const Search = ({
  searchText,
  onChange,
  suggestions,
  handleClick,
  reviewLoaderSearch,
  reviewLoaderReport,
  labReport,
  setCreatePatientModel,
  createPatientModel
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const loadingText = reviewLoaderSearch || reviewLoaderReport;

  const createNewPatient = (formData) => {
    const firstName =
      formData.name.charAt(0).toUpperCase() + formData.name.slice(1);
    const lastName =
      formData.lName.charAt(0).toUpperCase() + formData.lName.slice(1);
    const payload = {
      ...formData,
      name: `${firstName} ${lastName}`,
    };
    dispatch(addProfileData(payload));
    setCreatePatientModel(false);
    navigate("/get-blood-test-interpreted");
  };
  
  return (
    <>
      <input
        type="text"
        placeholder={
          labReport ? "Find Patient Lab Report ..." : "Find Patient ..."
        }
        value={searchText}
        className={search.inputField}
        onChange={onChange}
      />
      <div className={search.mainDiv}>
        {searchText && (
          <ul
            className={search.listItem}
            style={{
              opacity: loadingText ? 0.4 : 1,
              pointerEvents: loadingText ? "none" : "all",
            }}
          >
            {suggestions?.suggestions
              ?.filter((val) => {
                const isNameAvailable =
                  val?.FullName.S &&
                  val?.FullName.S?.toLowerCase().includes(
                    searchText.toLowerCase()
                  );
                return isNameAvailable ? val : "";
              })
              .map((data, index) => (
                <>
                  {labReport ? (
                    <Box sx={{ display: "flex", flexGrow: 1 }}>
                      <Grid container sx={{ marginBottom: '10px' }}>
                        <Grid item xs={4} className={search.grid_content}>
                          <div
                            className={search.menuItem}
                            onClick={() => handleClick(data?.ProfileID?.S, data?.FullName?.S, data?.BloodTestID?.S)}
                          >
                            {data?.FullName.S && data?.FullName.S}
                          </div>
                        </Grid>
                        <Grid item xs={8} className={search.grid_content}>
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                              marginLeft: '15px'
                            }}
                          >
                            <div className={search.birthDateMenu}>
                              DOB:{" "}
                              {data?.DateOfBirth?.S && data?.DateOfBirth?.S},
                            </div>
                            <div className={search.birthDateMenu}>
                              Last Lab Test:{" "}
                              {data?.BloodTestDate?.S && data?.BloodTestDate?.S}
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                      {/* <div
                        className={search.menuItem}
                        onClick={() => handleClick(data?.ProfileID?.S, data?.FullName?.S)}
                      >
                        {data?.FullName.S && data?.FullName.S}
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center',gap:'10px' }}>
                        <div className={search.birthDateMenu}>
                          DOB:{" "}
                          {data?.DateOfBirth?.S && data?.DateOfBirth?.S},
                        </div>


                        <div className={search.birthDateMenu}>
                          Last Lab Test:{" "}
                          {data?.BloodTestDate?.S && data?.BloodTestDate?.S}
                        </div>
                      </div> */}
                    </Box>
                  ) : (
                    <Box sx={{ display: "flex", flexGrow: 1 }}>
                      <Grid container sx={{ marginBottom: '10px' }}>
                        <Grid item xs={4} className={search.grid_content}>
                          <div
                            className={search.menuItem}
                            onClick={() => handleClick(data?.ProfileID?.S, data?.FullName?.S, data?.BloodTestID?.S)}
                          >
                            {data?.FullName.S && data?.FullName.S}
                          </div>
                        </Grid>
                        <Grid item xs={8} className={search.grid_content}>
                          <div style={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                            marginLeft: '15px'
                          }}>
                            <div className={search.birthDateMenu}>
                              DOB: {data?.DateOfBirth?.S && data?.DateOfBirth?.S},
                            </div>
                            <div className={search.birthDateMenu}>
                              Last Lab Test:{" "}
                              {data?.BloodTestDate?.S && data?.BloodTestDate?.S}
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </>
              ))}
            {!labReport && <p style={{ cursor: 'pointer' }} className={search.paraLink} onClick={() => setCreatePatientModel(true)}>Create a New Patient Report with this name</p>}
          </ul>
        )}
      </div>
      <SignUpModal
        open={createPatientModel}
        onClick={() => {
          setCreatePatientModel(false);
        }}
        initialData={{
          name: "",
          email: "",
          mobileNumber: "",
          lName: "",
          countryCode: "",
          dateOfBirth: "",
          sex: "",
          gender: "",
          ethnicity: "",
          allergies: [],
          illnesses: [],
          medications: [],
          supplements: [],
          height: "",
          weight: "",
          userAccessList: [],
          isDefault: "false",
        }}
        onSubmit={createNewPatient}
        disabled={false}
        titleName={"Add Other"}
        signUpName={"Submit"}
      />
    </>
  );
};

export default Search;
