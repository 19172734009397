import { GET_TOKEN_SUCCESS } from "../actionTypes/AuthActionTypes";

const INITIAL_STATE = {
  token: "",
  loading: false,
};

const AuthReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_TOKEN_SUCCESS:
      return { loading: false, token: action.payload, error: null };
    default:
      return state;
  }
};

export default AuthReducer;
