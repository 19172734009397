import {
  GET_SIGNIN_FAILURE,
  GET_SIGNIN_REQUEST,
  GET_SIGNIN_SUCCESS,
  GET_SOCIAL_LOGIN,
  SET_SOCIAL_LOGIN,
} from "../actionTypes/SignInActionTypes";

const INITIAL_STATE = {
  signIn: null,
  loading: false,
  flag: null,
  social: null,
};

const SignInReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SIGNIN_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_SIGNIN_SUCCESS:
      return { ...state, loading: false, signIn: action.payload, error: null };
    case GET_SOCIAL_LOGIN:
      return { ...state, loading: false, flag: action.payload, error: null };
    case SET_SOCIAL_LOGIN:
      return { ...state, loading: false, social: action.payload, error: null };
    case GET_SIGNIN_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default SignInReducer;
