import { ADD_PROFILE_SUCCESS } from "../actionTypes/AddProfileActionTypes";
import { GET_REVIEW_BLOOD_TEST_REPORT } from "../actionTypes/DashboardActionTypes";
import {
  GET_BLOOD_TEST_FIRST_PID_SUCCESS,
  GET_BLOOD_TEST_FIRST_FAILURE,
  GET_BLOOD_TEST_FIRST_REQUEST,
  GET_BLOOD_TEST_FIRST_START_TEST,
  GET_BLOOD_TEST_FIRST_UPDATE_METADATA,
  GET_BLOOD_TEST_FIRST_UPLOAD_IMAGE,
  GET_BLOOD_TEST_SECOND_SUCCESS,
  GET_BLOOD_TEST_VALUES,
  SET_UPLOADED_FILES,
  GET_PROFILE_INFO_SUCCESS,
  GET_BLOOD_TEST_SCAN_STATUS,
  UPDATE_TEST_VALUES,
  SUBMIT_BLOOD_TEST,
  GET_BLOOD_TEST_FIRST_TEST_SCAN,
  GET_BLOOD_TEST_INSIGHTS_SUCCESS,
  GET_BLOOD_TEST_ORGAN_SUCCESS,
  GET_BLOOD_TEST_FIRST_PID_SUCCESS_PROVIDER,
  GET_SEARCH_TEXT,
} from "../actionTypes/GetBloodTestFirstActionTypes";
import { GET_PROFILE_SUCCESS } from "../actionTypes/GetProfileActionTypes";
import { GET_INSIGHTS_SUCCESS } from "../actionTypes/InsightsActionTypes";
import { GET_ORGAN_SUCCESS } from "../actionTypes/OrganSectoinActionTypes";

const INITIAL_STATE = {
  pId: "",
  name: "",
  loading: false,
  firstPage: {
    startBloodTestData: {},
    updateMetadata: {},
    imageDetails: [],
    files: [],
    reviewAllData: [],
    getActiveProfile: {},
  },
  secondPage: {
    updateProfile: {},
  },
  fourthPage: {
    profileInfo: {},
    organData: {},
    insightsData: {},
  },
  bloodTestValues: {},
  scanStatus: {},
  updateValue: {},
  submitValue: {},
  getProfileData: {},
};

const GetBloodTestFirstReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_BLOOD_TEST_FIRST_REQUEST:
      return {
        ...state,
        loading: action.payload ? action.payload : true,
        error: null,
      };
    case GET_BLOOD_TEST_FIRST_PID_SUCCESS:
      return { ...state, loading: false, pId: action.payload, error: null };
      case GET_BLOOD_TEST_FIRST_PID_SUCCESS_PROVIDER:
        return { ...state, loading: false, name: action.payload, error: null };
    case GET_REVIEW_BLOOD_TEST_REPORT:
      return {
        ...state,
        loading: false,
        firstPage: { ...state.firstPage, reviewAllData: action.payload },
        error: null,
      };
    case GET_BLOOD_TEST_FIRST_START_TEST:
      return {
        ...state,
        loading: false,
        firstPage: { ...state.firstPage, startBloodTestData: action.payload },
        error: null,
      };
    case GET_BLOOD_TEST_FIRST_UPDATE_METADATA:
      return {
        ...state,
        loading: false,
        firstPage: { ...state.firstPage, updateMetadata: action.payload },
        error: null,
      };
    case GET_BLOOD_TEST_FIRST_UPLOAD_IMAGE:
      return {
        ...state,
        loading: false,
        firstPage: { ...state.firstPage, imageDetails: { ...action.payload } },
        error: null,
      };
    case GET_BLOOD_TEST_SECOND_SUCCESS:
      return {
        ...state,
        loading: false,
        secondPage: { ...state.secondPage, updateProfile: action.payload },
        error: null,
      };
    case GET_BLOOD_TEST_VALUES:
      return {
        ...state,
        loading: false,
        bloodTestValues: action.payload,
        error: null,
      };
    case GET_PROFILE_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        fourthPage: { ...state.fourthPage, profileInfo: action.payload },
        error: null,
      };
    case GET_BLOOD_TEST_SCAN_STATUS:
      return {
        ...state,
        loading: false,
        scanStatus: action.payload,
        error: null,
      };
    case UPDATE_TEST_VALUES:
      return {
        ...state,
        loading: false,
        updateValue: action.payload,
        error: null,
      };
    case SUBMIT_BLOOD_TEST:
      return {
        ...state,
        loading: false,
        submitValue: action.payload,
        error: null,
      };
    case GET_BLOOD_TEST_ORGAN_SUCCESS:
      return {
        ...state,
        loading: false,
        fourthPage: { ...state.fourthPage, organData: action.payload },
        error: null,
      };
    case GET_BLOOD_TEST_INSIGHTS_SUCCESS:
      return {
        ...state,
        loading: false,
        fourthPage: { ...state.fourthPage, insightsData: action.payload },
        error: null,
      };
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        getProfileData: action.payload,
        loading: false,
        error: null,
      };
    case ADD_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case GET_BLOOD_TEST_FIRST_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case SET_UPLOADED_FILES:
      return {
        ...state,
        firstPage: { ...state.firstPage, files: action.payload },
      };
    default:
      return state;
  }
};

export default GetBloodTestFirstReducer;
