import React, { useEffect, useState, useRef } from "react";
import Header from "../../components/header/Header";
import { CircularProgress } from "@mui/material";
import Loader from "../../components/loader/Loader";
import settingsStyles from "./settings.module.scss";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import { ReactComponent as ShareWhite } from "../../assets/svg/ShareWhite.svg";
import { ReactComponent as CloseWhite } from "../../assets/svg/CloseWhite.svg";
import { ReactComponent as CloseBlack } from "../../assets/svg/CloseBlack.svg";
import { ReactComponent as SubscriptionIcon } from "../../assets/svg/Subscription.svg";
import Radio from "@mui/material/Radio";
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/material/styles";
import firebase from "../../config/FirebaseConfig";
import { toast } from "react-toastify";
import ConfirmationModal from "./ConfirmationModal";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import axiosInstance from "../../axios/axiosInstance";
import Notification from "../../components/notification/notification";

import {
  closeAccount,
  deleteProfile,
  contactUsFeedback,
  listRestoreProfile,
  removeProfileAccess,
  restoreProfile,
  shareProfile,
  getPaymentData,
  getSubscription,
  cancelSubscriptionAction,
  getPaymentDataForProvider,
} from "../../redux/actions/SettingsAction";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeLetter, toastStyle } from "../../helper";
import ShareModal from "./ShareEmail";
import moment from "moment";
import { GET_TOKEN_SUCCESS } from "../../redux/actionTypes/AuthActionTypes";
import PaymentModal from "./PaymentModal";
import DeleteUserListModel from "./deleteUserListModel";
import { getBloodTests } from "../../redux/actions/firstStepAction";
const Settings = () => {
  const { listProfile, loading, paymentData, subsciptionPlan, error } =
    useSelector((state) => state.settings);
  const { userInfo } = useSelector((state) => state.dashboard);
  const { signIn } = useSelector((state) => state.signIn);
  const { signUp } = useSelector((state) => state.signUp);

  const { getProfileData } = useSelector((state) => state.dashboard);
  const stripePromise = loadStripe(
    //"pk_test_51NevP2EjLVuRDJw6GTidAJ4ZaHdzWYQK2aV0NOw5zBe36FOoispL06B0KYMV2kXbKowqo1NO5wxhez5Stbl8YtrN00hatUcVuo",
    "pk_live_51NevP2EjLVuRDJw6NokIljm81JWhzqmpzFYXrn82hOf1yiQIc0aznP7HvjWFpSdUbqrbhkPpXCpxjGxIdkvICP8z000xWueXKc"
  ); // starts with pk_
  const dispatch = useDispatch();
  const timer = useRef();
  const [checkedRadio, setCheckedRadio] = useState({
    plan: null,
    region: "",
    rate: "",
    title: "",
  });
  const [allCheckedRadio, setAllCheckedRadio] = useState([]);
  const [openUserListModel, setOpenUserListModel] = useState(false);
  const [userListModelData, setUserListModelData] = useState([]);
  const [activePlan, setActivePlan] = useState(null);

  const [subscriptionKey, setSubscriptionKey] = useState({
    region: "",
    status: "",
    rate: "",
    title: "",
  });
  const [openModal, setOpenModal] = useState(false);
  const [cancelSubscriptionConfirmModel, setCancelSubscriptionConfirmModel] =
    useState(false);
  const [openModalFeedback, setOpenModalFeedback] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [profileId, setProfileId] = useState();
  const [emails, setEmails] = useState();
  const [islLoading, setIsLoading] = useState(false);
  const [contactUs, setContactUs] = useState({
    subject: "",
    body: "",
  });
  const [disableRestoreButton, setDisableRestoreButton] = useState(false);
  const [notificationData, setNotificationData] = useState({
    message: "",
    openModel: false,
    upgradePlan: false,
    status: "",
    isFirstStep: false,
    paymentFailed: false,
  });
  const [feedBackError, setFeedBackError] = useState({
    isErrorSubject: false,
    isErrorBody: false,
  });
  const [feedBackErrorMessage, setFeedBackErrorMessage] = useState({
    errorMsgSubject: "",
    errorMsgBody: "",
  });
  const [isEnable, setIsEnable] = useState(false);
  const [open, setOpen] = React.useState(false);
  const radioButtonGroupRef = useRef();
  const handleOpen = () => setOpen(true);
  const handleClosePayment = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!subsciptionPlan) return;
    (async () => {
      let userPlan = null;
      if (
        subsciptionPlan?.subscriptionPlan &&
        subsciptionPlan?.subscriptionPlan?.userPlan
      ) {
        userPlan = Number(subsciptionPlan?.subscriptionPlan?.userPlan);
      } else if (
        subsciptionPlan?.subscriptionPlan &&
        subsciptionPlan?.subscriptionPlan?.productId
      ) {
        let key = subsciptionPlan?.subscriptionPlan?.productId;
        if (key.includes("one")) {
          userPlan = 1;
        } else if (key.includes("two")) {
          userPlan = 2;
        } else if (key.includes("three")) {
          userPlan = 3;
        } else if (key.includes("four")) {
          userPlan = 4;
        } else if (key.includes("five")) {
          userPlan = 5;
        } else if (key.includes("six")) {
          userPlan = 6;
        } else if (key.includes("seven")) {
          userPlan = 7;
        } else if (key.includes("eight")) {
          userPlan = 8;
        } else if (key.includes("nine")) {
          userPlan = 9;
        } else if (key.includes("ten")) {
          userPlan = 10;
        }
      } else {
        userPlan = null;
      }
      setActivePlan(userPlan);
      const userData =
        getProfileData &&
        getProfileData?.profiles &&
        getProfileData?.profiles?.Items?.map((item) => ({
          name: item?.FullName?.S,
          ProfileID: item?.ProfileID?.S,
          isDefault: item?.isDefault?.S,
        }));
      if (userData) {
        const finalBloodTest = [];
        for (let item of userData) {
          if (!item?.ProfileID?.includes("DELETED")) {
            const response = await axiosInstance(`v1/getBloodTests`, "post", {
              profileId: item?.ProfileID,
            });
            const bloodTests = response?.data?.data?.bloodTests?.Items;
            let newValue = [];
            if (bloodTests && bloodTests?.length) {
              for (let value of bloodTests) {
                if (
                  value?.BloodTestStatus?.S === "Scan Complete" &&
                  value?.Results?.length > 0
                ) {
                  newValue.push({
                    ...value,
                    resultLength: value?.Results?.length,
                  });
                }
              }
            }
            finalBloodTest?.push({
              reports: newValue?.length,
              name: item?.name,
              profileId: item?.ProfileID,
              isDefault: item?.isDefault,
            });
          }
        }
        const submittedReportsList = finalBloodTest?.filter(
          (e) => e?.reports > 0
        );
        if (userInfo && userInfo?.user?.role?.S === 'Provider') {
          setOpenUserListModel(false)
        } else if (submittedReportsList?.length > userPlan) {
          setUserListModelData([...submittedReportsList]);
          setOpenUserListModel(true);
        } else {
          setOpenUserListModel(false);
        }
        setDisableRestoreButton(false);
      }
    })();
  }, [subsciptionPlan, getProfileData]);

  useEffect(() => {
    if (error) setCheckedRadio({ plan: null, region: "", rate: "", title: "" });

    setSubscriptionKey({
      region: "",
      status: "",
      rate: "",
      title: "",
    });
  }, [error]);
  useEffect(() => {
    allCheckedRadio.length == 0 || !checkedRadio?.plan
      ? setSubscriptionKey({
        region: checkedRadio?.region,
        status: "",
        rate: checkedRadio?.rate,
        title: checkedRadio?.title,
      })
      : allCheckedRadio?.every((e) => e > Number(checkedRadio?.plan))
        ? setSubscriptionKey({
          region: checkedRadio?.region,
          status: "downgrade",
          rate: checkedRadio?.rate,
          title: checkedRadio?.title,
        })
        : setSubscriptionKey({
          region: checkedRadio?.region,
          status: "upgrade",
          rate: checkedRadio?.rate,
          title: checkedRadio?.title,
        });
  }, [checkedRadio?.plan]);
  useEffect(() => {
    activePlan ? setAllCheckedRadio([`${activePlan}`]) : setAllCheckedRadio([]);
    setCheckedRadio({ plan: null, region: "", rate: "", title: "" });
    if (activePlan && radioButtonGroupRef?.current) {
      const gapBetweenRadio = 20;
      const radioButtonHeight = 82;
      const activeCardOffset =
        (Number(activePlan) - 2) * (radioButtonHeight + gapBetweenRadio);
      setTimeout(() => {
        radioButtonGroupRef.current?.scrollTo({
          top: activeCardOffset,
          behavior: "smooth",
        });
      }, 1000);
    }
  }, [activePlan]);

  const inputRef = useRef(null);
  const fieldRef = useRef(null);
  const breadCrumbsArr = [
    {
      title: "Home",
      link: "/home",
    },
    {
      title: "Settings",
      link: null,
    },
  ];

  const StyledFormControlLabel = styled((props) => (
    <FormControlLabel {...props} />
  ))(({ theme, checked }) => ({
    ".MuiFormControlLabel-label": checked && {
      color: "#122D5B !important",
      fontSize: "17px",
      fontFamily: "Poppins",
      fontWeight: "600",
      border: "#707070",
    },
  }));
  const handleChange = (e, region, rate, title) => {
    setCheckedRadio({ plan: e.target.value, region, rate, title });
  };
  function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();

    let checked = false;
    if (radioGroup) {
      checked = allCheckedRadio?.find((e) => e === props?.value);
    }
    return (
      <StyledFormControlLabel
        checked={checked}
        {...props}
        onChange={handleChange}
      />
    );
  }
  useEffect(() => {
    dispatch(listRestoreProfile());
    if (userInfo?.user?.role?.S == 'Provider') {
      dispatch(getPaymentDataForProvider())
    }else{
      dispatch(getPaymentData());
    }
    dispatch(getSubscription());
  }, [dispatch]);

  const SettingsSection = ({
    heading,
    subHeading,
    children,
    sectionContainerClass,
    headingClass,
    style,
  }) => {
    return (
      <div
        className={`${settingsStyles.sectionContainer} ${sectionContainerClass || ""
          }`}
        style={{ ...style }}
      >
        <p className={`${settingsStyles.sectionHeading} ${headingClass || ""}`}>
          {heading}
        </p>
        <p
          className={settingsStyles.sectionSubHeading}
          dangerouslySetInnerHTML={{ __html: subHeading || "" }}
        />
        {children}
      </div>
    );
  };

  const restoreProfileData = (closeDate) => {
    let current = moment(closeDate).add(30, "days");
    let expiryDate = current.diff(new Date(), "days");

    return (
      <div className={settingsStyles.expiryDate}>
        Expires in {expiryDate} days
      </div>
    );
  };
  // modal functions
  const handleClickOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleCloseFeedback = () => {
    setOpenModalFeedback(false);
  };
  // share modal functions
  const handleShareClick = (pid) => {
    setOpenShareModal(true);
    setEmails();
    setProfileId(pid);
  };

  const handleShareClose = () => {
    setOpenShareModal(false);
    setEmails();
  };

  // handle close account button
  const handleCloseAccount = () => {
    dispatch(closeAccount(setOpenModal));
  };
  const subscriptionCancel = () => {
    cancelSubscription("cancel");
    setCancelSubscriptionConfirmModel(false);
  };
  const subscriptionContinue = () => {
    setCancelSubscriptionConfirmModel(false);
  };

  // handle reset button
  const handleResetPassword = async () => {
    try {
      const email = userInfo?.user?.Email?.S;
      const response = await firebase.auth().sendPasswordResetEmail(email);
      // Code to handle success (if needed)
      toast.success("Email has been sent successfully!", toastStyle);
      if (!islLoading) {
        setIsLoading(true);
        timer.current = window.setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
      setEmailError(false);
    } catch (error) {
      // toast.error(error.message, toastStyle);
    }
  };

  const handleRestore = async (pid) => {
    setDisableRestoreButton(true);
    const userData =
      getProfileData &&
      getProfileData?.profiles?.Items?.map((item) => ({
        name: item?.FullName?.S,
        ProfileID: item?.ProfileID?.S,
        isDefault: item?.isDefault?.S,
      }));
    if (userData) {
      const finalBloodTest = [];
      for (let item of userData) {
        if (!item?.ProfileID?.includes("DELETED")) {
          const response = await axiosInstance(`v1/getBloodTests`, "post", {
            profileId: item?.ProfileID,
          });
          const bloodTests = response?.data?.data?.bloodTests?.Items;
          let newValue = [];
          if (bloodTests && bloodTests?.length) {
            for (let value of bloodTests) {
              if (
                value?.BloodTestStatus?.S === "Scan Complete" &&
                value?.Results?.length > 0
              ) {
                newValue.push({
                  ...value,
                  resultLength: value?.Results?.length,
                });
              }
            }
          }
          finalBloodTest?.push({
            reports: newValue?.length,
            name: item?.name,
            profileId: item?.ProfileID,
            isDefault: item?.isDefault,
          });
        }
      }
      const submittedReportsList = finalBloodTest?.filter(
        (e) => e?.reports > 0
      );
      if (activePlan > submittedReportsList?.length ) {
        setOpenUserListModel(false);
        const payload = {
          profileId: pid,
        };
        dispatch(restoreProfile(payload, setNotificationData));
      }
      else {
        setNotificationData({
          openModel: true,
          message: "Please upgrade your subscription plan to restore profiles.",
          status: "upgrade",
          rate: activePlan + 1,
          upgradePlan: true,
          paymentFailed: false,
          isFirstStep:
            subsciptionPlan &&
            subsciptionPlan?.subscriptionPlan &&
            subsciptionPlan?.subscriptionPlan?.device &&
            (subsciptionPlan?.subscriptionPlan?.device === "ios" ||
              subsciptionPlan?.subscriptionPlan?.device === "android"),
        });
        setTimeout(() => {
          setDisableRestoreButton(false);
        }, 1500);
      }
    }
  };

  const handleDeleteProfile = (id) => {
    const payload = {
      profileId: id,
    };
    dispatch(deleteProfile(payload));
  };

  const handleRemoveAccess = (id, email) => {
    let emailShare = [];
    emailShare.push(email);
    emailShare.map((e) => {
      dispatch(
        removeProfileAccess({
          emails: e,
          profileId: id,
        })
      );
    });
  };
  const handleChangeFeedBack = (e) => {
    const { name, value } = e.target;
    setContactUs({ ...contactUs, [name]: value });
    setFeedBackErrorMessage({
      errorMsgBody: "",
      errorMsgSubject: "",
    });
  };
  const handleSubmit = () => {
    if (!contactUs.subject && !contactUs.body) {
      setFeedBackError({
        isErrorBody: true,
        isErrorSubject: true,
      });
      setFeedBackErrorMessage({
        errorMsgBody: "body is required",
        errorMsgSubject: "subject is required",
      });
    } else if (!contactUs.subject && contactUs.body) {
      setFeedBackError({
        isErrorBody: false,
        isErrorSubject: true,
      });
      setFeedBackErrorMessage({
        errorMsgBody: "",
        errorMsgSubject: "subject is required",
      });
    } else if (contactUs.subject && !contactUs.body) {
      setFeedBackError({
        isErrorBody: true,
        isErrorSubject: false,
      });
      setFeedBackErrorMessage({
        errorMsgBody: "body is required",
        errorMsgSubject: "",
      });
    } else {
      const payload = {
        ...contactUs,
        email: userInfo?.user?.Email?.S,
      };
      dispatch(contactUsFeedback(payload));
      setContactUs({
        subject: "",
        body: "",
      });
      setOpenModalFeedback(true);
    }
  };
  const handleChangeEmail = (e) => {
    const { name, value } = e.target;
    setEmails({ ...emails, [name]: value });
  };
  const handleShareEmail = () => {
    const emailValues = Object.values(emails);
    const cleanedEmailValues = emailValues.map((email) =>
      email.replace(/"/g, "")
    );
    const formattedEmails = cleanedEmailValues.join(", ");

    dispatch(
      shareProfile({
        emails: formattedEmails,
        profileId: profileId,
      })
    );
    setOpenShareModal(false);
  };
  function SubmitButton() {
    if (contactUs?.subject && contactUs?.body) {
      setIsEnable(true);
    } else if (contactUs?.subject && !contactUs?.body) {
      setIsEnable(false);
    } else if (!contactUs?.subject && contactUs?.body) {
      setIsEnable(false);
    }

    return (
      <button
        className={
          isEnable ? settingsStyles.feedback_button : settingsStyles.disable_btn
        }
        onClick={handleSubmit}
        disabled={!isEnable}
      >
        Submit
      </button>
    );
  }
  const viewReport = () => {
    if (getProfileData && getProfileData?.profiles?.Items?.length > 0) {
      return true;
    } else {
      return false;
    }
  };
  const cancelSubscription = (status) => {
    dispatch(cancelSubscriptionAction({ status }, setNotificationData));
  };

  return (
    <div>
      <Header showNavigations={false} />
      <div className={settingsStyles.contaninerBg}>
        <Loader loading={loading || disableRestoreButton} />
        <div
          style={{
            opacity: loading || disableRestoreButton ? 0.4 : 1,
            pointerEvents: loading || disableRestoreButton ? "none" : "auto",
          }}
          className={settingsStyles.mainContainer}
        >
          <BreadCrumbs breadCrumbsArr={breadCrumbsArr} />
          <div className={settingsStyles.pageTitle}>Settings</div>
          <div className={settingsStyles.allSectionWrapper}>
            {viewReport() && (
              <SettingsSection
                heading="Review Connections"
                subHeading="Manage who has access to blood test results:"
              >
                <div className={settingsStyles.mainSection}>
                  {getProfileData &&
                    [...getProfileData.profiles?.Items]
                      .sort((a, b) => {
                        if (
                          a?.isDefault?.S === "true" &&
                          b?.isDefault?.S === "false"
                        )
                          return -1;
                        if (
                          a?.isDefault?.S === "false" &&
                          b?.isDefault?.S === "true"
                        )
                          return 1;
                        return a?.profile?.isDefault?.S.localeCompare(
                          b?.profile?.isDefault?.S
                        );
                      })
                      .map(
                        (item) =>
                          !item?.ProfileID?.S.includes("DELETED") && (
                            <div
                              className={settingsStyles.review_main}
                              key={item?.ProfileID?.S}
                            >
                              <div
                                className={
                                  settingsStyles.review_profile_section
                                }
                              >
                                <div className={settingsStyles.innerSection}>
                                  <p>
                                    {item.FullName.S
                                      ? capitalizeLetter(item.FullName.S)
                                      : ""}
                                  </p>
                                  <div className={settingsStyles.icconSet}>
                                    <ShareWhite
                                      onClick={() =>
                                        handleShareClick(item?.ProfileID?.S)
                                      }
                                    />
                                    <CloseWhite
                                      onClick={() =>
                                        handleDeleteProfile(item?.ProfileID?.S)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{ overflowY: "scroll", height: "100px" }}
                              >
                                {item?.UserAccessList &&
                                  item?.UserAccessList.L.map((val) => {
                                    return (
                                      <div
                                        key={val?.S}
                                        className={
                                          settingsStyles.review_profileList
                                        }
                                      >
                                        <p>{val?.S}</p>
                                        <CloseBlack
                                          onClick={() =>
                                            handleRemoveAccess(
                                              item?.ProfileID?.S,
                                              val?.S
                                            )
                                          }
                                        />
                                      </div>
                                    );
                                  })}
                              </div>
                              <button
                                className={settingsStyles.addBtnLink}
                                onClick={() =>
                                  handleShareClick(item.ProfileID?.S)
                                }
                              >
                                + Add Another
                              </button>
                            </div>
                          )
                      )}
                </div>
              </SettingsSection>
            )}
            {listProfile?.restorableProfiles?.Items.length > 0 && (
              <SettingsSection
                heading="Restore Profile"
                subHeading="Click to restore deleted profile"
              >
                <div className={settingsStyles.restore_main}>
                  {listProfile?.restorableProfiles?.Items.map((data) => {
                    return (
                      <div className={settingsStyles.inner_main}>
                        <p className={settingsStyles.restore_profileName}>
                          {data.FullName.S
                            ? capitalizeLetter(data.FullName.S)
                            : ""}
                          {restoreProfileData(data.ProfileCloseDate.S)}
                        </p>
                        <button
                          disabled={disableRestoreButton}
                          style={
                            disableRestoreButton
                              ? {
                                opacity: "0.4",
                                pointerEvents: "none",
                              }
                              : {}
                          }
                          className={settingsStyles.restore_button}
                          onClick={() => handleRestore(data.ProfileID.S)}
                        >
                          Restore
                        </button>
                      </div>
                    );
                  })}
                </div>
              </SettingsSection>
            )}
            {subsciptionPlan &&
              subsciptionPlan?.subscriptionPlan &&
              subsciptionPlan?.subscriptionPlan?.device &&
              (subsciptionPlan?.subscriptionPlan?.device === "ios" ||
                subsciptionPlan?.subscriptionPlan?.device === "android") ? (
              <SettingsSection
                heading="Manage Subscription"
                subHeading={`You initiated the AHA <b>${activePlan || "0"
                  } User Plan</b>. from your mobile device.  Please upgrade your plan there.`}
                style={{ padding: "43px" }}
              >
                <a
                  href={
                    subsciptionPlan?.subscriptionPlan?.device === "ios"
                      ? "https://apps.apple.com/in/app/advanced-health-academy/id6444776238"
                      : subsciptionPlan?.subscriptionPlan?.device === "android"
                        ? "https://play.google.com/store/search?q=advanced+health+academy&c=apps"
                        : ""
                  }
                  target="_blank"
                >
                  <button className={settingsStyles.getAppButton}>
                    Upgrade in AHA App
                  </button>
                </a>
              </SettingsSection>
            ) : (
              <SettingsSection
                heading="Manage Subscription"
                subHeading="Subscription levels are based on the number of users allowed to get blood test interpretations."
              >
                <div className={settingsStyles.subscription_section}>
                  <div>
                    <div className={settingsStyles.set_section}>
                      <div>
                        <SubscriptionIcon width={16} height={21} />
                      </div>
                      <p>Interpretation of unlimited number of blood tests</p>
                    </div>

                    <div className={settingsStyles.set_section}>
                      <div>
                        <SubscriptionIcon width={16} height={21} />
                      </div>
                      <p>
                        Summary and root cause analysis of significant issue(s)
                        in blood tests
                      </p>
                    </div>
                    <div className={settingsStyles.set_section}>
                      <div>
                        <SubscriptionIcon width={16} height={21} />
                      </div>
                      <p>
                        Comprehensive assessment of organ function and health
                      </p>
                    </div>
                    <div className={settingsStyles.set_section}>
                      <div>
                        <SubscriptionIcon width={16} height={21} />
                      </div>
                      <p>
                        Prioritized insights and explanations by U.S.
                        board-certified physicians
                      </p>
                    </div>
                  </div>
                  <div>
                    <FormControl
                      sx={{
                        gap: "20px",
                        overflowY: "auto",
                        maxHeight: "514px",
                      }}
                      ref={radioButtonGroupRef}
                    >
                      {paymentData?.payment?.map((item, index) => {
                        return (
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={`${activePlan}`}
                            name="radio-buttons-group"
                            onChange={(e) =>
                              handleChange(
                                e,
                                item?.webPriceIdArr?.region,
                                item?.webPriceIdArr?.rate,
                                item?.title
                              )
                            }
                            value={checkedRadio?.plan}
                            key={index}
                          >
                            <div className={settingsStyles.user_plan}>
                              <div
                                className={`${settingsStyles.lable_form} ${allCheckedRadio?.includes(item?.userPlan) &&
                                  settingsStyles.selectedPlans
                                  } ${checkedRadio?.plan === item?.userPlan &&
                                  settingsStyles.currentlySelectedPlan
                                  }`}
                              >
                                <MyFormControlLabel
                                  value={item?.userPlan}
                                  label={item.title}
                                  control={<Radio />}
                                />
                                <p
                                  className={
                                    allCheckedRadio?.includes(item?.userPlan)
                                      ? settingsStyles.font_weight
                                      : settingsStyles.font_normal
                                  }
                                >
                                  {item?.webPriceIdArr?.rate}/month
                                </p>
                              </div>
                            </div>
                          </RadioGroup>
                        );
                      })}
                    </FormControl>
                    <p className={settingsStyles.note_info}>
                      For an unlimited number of each person’s blood test
                      interpretations.
                    </p>
                    <div className={settingsStyles.btn_link}>
                      <button
                        className={
                          checkedRadio?.plan
                            ? settingsStyles.purchase_btn
                            : settingsStyles.purchase_disabledBtn
                        }
                        onClick={handleOpen}
                        disabled={!checkedRadio?.plan}
                      >
                        {"Continue"}
                      </button>
                      <button
                        style={
                          !activePlan
                            ? {
                              opacity: 0.6,
                              pointerEvents: "none",
                              userSelect: "none",
                            }
                            : {}
                        }
                        disabled={!activePlan}
                        className={settingsStyles.purchase_btn}
                        onClick={() => setCancelSubscriptionConfirmModel(true)}
                      >
                        {`Cancel`}
                      </button>
                    </div>
                  </div>
                </div>
              </SettingsSection>
            )}

            <div className={settingsStyles.feedback_main_box}>
              <p className={settingsStyles.feedback_heading}>Feedback</p>
              <p className={settingsStyles.feedback_subheading}>
                Please reach out if you have any questions. We also truly
                appreciate your suggestions and insights - it’s how we continue
                improving.
              </p>
              <p className={settingsStyles.feedback_subheading2}>
                If you would like to send a suggestion about AHA, please contact
                us.
              </p>
              <div className={settingsStyles.feedback_main}>
                <div>
                  <input
                    type="text"
                    // key="subject"
                    className={
                      feedBackError.isErrorSubject
                        ? settingsStyles.feedbackSubjectError
                        : settingsStyles.feedbackSubject
                    }
                    placeholder="Subject"
                    name="subject"
                    onChange={(e) => handleChangeFeedBack(e)}
                    value={contactUs?.subject}
                    ref={inputRef}
                    autoFocus={inputRef.current === document.activeElement}
                    error={contactUs.subject}
                  // autoFocus="autoFocus"
                  />
                  {feedBackError.isErrorSubject && (
                    <span className={settingsStyles.errorMsg}>
                      {feedBackErrorMessage.errorMsgSubject}
                    </span>
                  )}
                </div>
                <div>
                  <input
                    // key="body"
                    type="text"
                    className={
                      feedBackError.isErrorBody
                        ? settingsStyles.feedbackMessageError
                        : settingsStyles.feedbackMessage
                    }
                    placeholder="Message"
                    name="body"
                    onChange={(e) => handleChangeFeedBack(e)}
                    value={contactUs?.body}
                    ref={fieldRef}
                    autoFocus={fieldRef.current === document.activeElement}
                    error={contactUs.body}
                  // autoFocus="autoFocus"
                  />
                  {feedBackError.isErrorBody && (
                    <span className={settingsStyles.errorMsg}>
                      {feedBackErrorMessage.errorMsgBody}
                    </span>
                  )}
                </div>
              </div>
              <div className={settingsStyles.feedback_button_main}>
                <SubmitButton />
              </div>
            </div>
            {/* <SettingsSection
              heading="Close Account"
              subHeading="Click below to close your AHA account. You will no longer have
                  access to our reports, site, or services. After account
                  closure, you will not be able to access AHA Without creating a
                  new account, and you will need to upload aa of your blood test
                  results again. You will lose access to shared reports. Peoples
                  you have Shared reports with will also lose access to those
                  reports."
              sectionContainerClass={settingsStyles.closeAccount_boxStyle}
            >
              <div className={settingsStyles.closeButton_main}>
                <button onClick={handleClickOpen}>Close Account</button>
              </div>
            </SettingsSection> */}
            <div
              className={settingsStyles.sectionContainer_acc}
              style={{
                marginBottom:
                  signIn?.providerId === "Sign In Button" ||
                    signUp?.providerId === "Sign In Button"
                    ? ""
                    : "30px",
              }}
            >
              <p className={settingsStyles.sectionHeading}>Close Account</p>
              <div className={settingsStyles.acc_section}>
                <div>
                  <p className={settingsStyles.sectionSubHeading_acc}>
                    {/* Click to close your AHA account. You will no longer have
                    access to our reports, site, or services. After account
                    closure, you will not be able to access AHA Without creating
                    a new account, and you will need to upload aa of your blood
                    test results again. You will lose access to shared reports.
                    Peoples you have Shared reports with will also lose access
                    to those reports. */}
                    Click to close your AHA account. You will no longer have
                    access to our reports, site, or services. After account
                    closure, you will not be able to access AHA without creating
                    a new account, and you will need to upload all of your blood
                    test results again. You will lose access to shared reports.
                    People you have shared reports with will also lose access to
                    those reports.
                  </p>
                </div>
                <div className={settingsStyles.closeButton_main}>
                  <button onClick={handleClickOpen}>Close Account</button>
                </div>
              </div>
            </div>
            {(signIn?.providerId === "Sign In Button" ||
              signUp?.providerId === "Sign In Button") && (
                <>
                  <div className={settingsStyles.resetButton_boxStyle}>
                    <div className={settingsStyles.reset_box}>
                      <div>
                        <p className={settingsStyles.resetButton_mainTitle}>
                          Reset Password
                        </p>
                        <p className={settingsStyles.resetButton_description}>
                          Click to receive email with instruction to reset your
                          password
                        </p>
                      </div>
                      <div className={settingsStyles.resetButton_main}>
                        <button
                          onClick={handleResetPassword}
                          className={settingsStyles.reset_btn}
                        >
                          Reset Password
                          {islLoading && (
                            <CircularProgress size={20} sx={{ color: "white" }} />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
          </div>
        </div>
      </div>
      <ConfirmationModal
        openModal={openModal}
        handleClose={handleClose}
        content="Are you sure you want to close your AHA account ?"
        handleYes={handleCloseAccount}
        button={true}
        status={"close"}
      />
      <ConfirmationModal
        openModal={cancelSubscriptionConfirmModel}
        handleClose={subscriptionContinue}
        content="Are you sure you want to cancel your subscription? You will not be able to access AHA after the end of this billing period."
        handleYes={subscriptionCancel}
        button={true}
        status={"cancel"}
      />
      <ConfirmationModal
        openModal={openModalFeedback}
        handleClose={handleCloseFeedback}
        content="Thank you for contacting AHA!"
        subcontent="We have received your message."
        buttonNo={true}
        headerClassName={settingsStyles.confirmation_header}
        subHeaderClassName={settingsStyles.confirmation_subHeader}
      />
      <ShareModal
        openModal={openShareModal}
        handleClose={handleShareClose}
        handleSubmit={handleShareEmail}
        handleChangeEmail={handleChangeEmail}
        emails={emails}
        openShareModal={openShareModal}
      />
      <Elements stripe={stripePromise}>
        <PaymentModal
          subscriptionKey={subscriptionKey}
          open={open}
          setOpen={setOpen}
          handleClose={handleClosePayment}
          userPlan={checkedRadio?.plan}
          notificationData={notificationData}
          setNotificationData={setNotificationData}
          setDisableRestoreButton={setDisableRestoreButton}
        />
      </Elements>
      <DeleteUserListModel
        openModal={openUserListModel}
        userListModelData={userListModelData}
        setOpenUserListModel={setOpenUserListModel}
        activePlan={activePlan}
      />
      <Notification
        setNotificationData={setNotificationData}
        notificationData={notificationData}
        setDisableRestoreButton={setDisableRestoreButton}
      />
    </div>
  );
};

export default Settings;
