import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Dashboard from "../pages/dashboard/Dashboard";
import OtpScreen from "../pages/otp-screen/OtpScreen";
import { useSelector } from "react-redux";
import TrendsReport from "../pages/trends-report/TrendsReport";
import Settings from "../pages/settings/Settings";
import GetBloodTestInterpreted from "../pages/review-blood-test/GetBloodTestInterpreted";
import NotFound from "../components/NotFound/NotFound";
import ErrorBoundary from "../components/ErrorBoundary/ErrorBoundary";
import SignInPage from "../pages/create-account/SignInPage";
import SignUpPage from "../pages/create-account/SignUpPage";
import Forgotpage from "../pages/create-account/Forgotpage";
import ReportResult from "../pages/dashboard/ReportResult";
import ReportConsumer from "../pages/dashboard/ReportConsumer";

export const RoutesPage = () => {
  const { token } = useSelector((state) => state.accessToken);

  const PublicRoute = ({ children }) => {
    if (token) {
      return <Navigate to="/home" />;
    }
    return children;
  };

  const PrivateRoute = ({ children }) => {
    if (!token) {
      return <Navigate to="/sign-in" />;
    }
    return children;
  };

  const CheckNavigation = () => {
    if (!token) {
      return <Navigate replace to="sign-in" />;
    } else {
      return <Navigate replace to="home" />;
    }
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={CheckNavigation()} />
        <Route
          path="/sign-in"
          element={
            <PublicRoute>
              <ErrorBoundary>
                <SignInPage />
              </ErrorBoundary>
            </PublicRoute>
          }
        ></Route>
        <Route
          path="/forgot-password"
          element={
            <PublicRoute>
              <ErrorBoundary>
                <Forgotpage />
              </ErrorBoundary>
            </PublicRoute>
          }
        ></Route>
        <Route
          path="/sign-up"
          element={
            <PublicRoute>
              <ErrorBoundary>
                <SignUpPage />
              </ErrorBoundary>
            </PublicRoute>
          }
        ></Route>
        <Route
          path="/otp"
          element={
            <PublicRoute>
              <ErrorBoundary>
                <OtpScreen />
              </ErrorBoundary>
            </PublicRoute>
          }
        ></Route>
        <Route
          path="/home"
          element={
            <PrivateRoute>
              <ErrorBoundary>
                <Dashboard />
              </ErrorBoundary>
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/trends"
          element={
            <PrivateRoute>
              <ErrorBoundary>
                <TrendsReport />
              </ErrorBoundary>
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/settings"
          element={
            <PrivateRoute>
              <ErrorBoundary>
                <Settings />
              </ErrorBoundary>
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/get-blood-test-interpreted"
          element={
            <PrivateRoute>
              <ErrorBoundary>
                <GetBloodTestInterpreted />
              </ErrorBoundary>
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/result"
          element={
            <PrivateRoute>
              <ErrorBoundary>
                <ReportResult />
              </ErrorBoundary>
            </PrivateRoute>
          }
        ></Route>
          <Route
          path="/result-screen"
          element={
            <PrivateRoute>
              <ErrorBoundary>
                <ReportConsumer />
              </ErrorBoundary>
            </PrivateRoute>
          }
        ></Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};
