import { CircularProgress } from "@mui/material";
import React from "react";
import "./button.scss";
import { styled } from "@mui/material/styles";
import MuiButton from "@mui/material/Button";
export const ButtonStyle = styled(MuiButton)((props) => ({
  borderRadius: 33,
  padding: "14px 12px",
  width: "100%",
  border: "1px solid #707070",
  fontWeight: "bold",
  color: "black",
  textTransform: "capitalize",
  marginBottom: "10px",
  justifyContent: "space-around",
  paddingRight: "50px",
  "&.Mui-disabled": {
    backgroundColor: "#122d5ba3 !important",
  },
  "&:hover": {
    background: "#122D5B",
  },
  "& .MuiCircularProgress-root": {
    marginLeft: 15,
  },
}));
function CommonButton({ title, className, children, loading, ...rest }) {
  return (
    <div>
      <ButtonStyle
        {...rest}
        type="submit"
        className={className ? className : "common-btn"}
      >
        {title}
        {loading && <CircularProgress size={20} sx={{ color: "white" }} />}
        {children}
      </ButtonStyle>
    </div>
  );
}

export default CommonButton;
