import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import dashboardCss from "./dashboard.module.scss";
import ShareIcon from "@mui/icons-material/Share";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { organOverviewMock } from "../../json/mockData";
import PatientInfo from "../../components/patient-info/PatientInfo";
import Header from "../../components/header/Header";
import ReportSummary from "../../components/report-summary/ReportSummary";
import OrganOverview from "../../components/organ-overview/OrganOverview";
import Insights from "../../components/insighṭs/Insights";
import BiomarkerSection from "../../components/biomarker-section/BiomarkerSection";
import {
  addProfileData,
  addSearchText,
  fetchProfileData,
} from "../../redux/actions/GetProfileAction";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserData,
  getSuggestions,
} from "../../redux/actions/GetUserAction";
import axiosInstance from "../../axios/axiosInstance";
import moment from "moment";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Loader from "../../components/loader/Loader";
import { ReactComponent as TrensReportIcon } from "../../assets/svg/trends-report.svg";
import Container from "@mui/material/Container";
import SignUpModal from "../../components/modal/sign-up/SignUpModal";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import {
  fetchInsightsData,
  fetchOrganData,
  fetchSampleData,
} from "../../redux/actions/SampleReportAction";
import SectionLoader from "../../components/loader/SectionLoader";
import { useNavigate } from "react-router-dom";
import { capitalizeLetter } from "../../helper";
import {
  GET_BLOOD_TEST_FIRST_PID_SUCCESS,
  GET_BLOOD_TEST_FIRST_PID_SUCCESS_PROVIDER,
  GET_BLOOD_TEST_FIRST_START_TEST,
  GET_BLOOD_TEST_FIRST_TEST_SCAN,
  GET_BLOOD_TEST_FIRST_UPDATE_METADATA,
  GET_BLOOD_TEST_FIRST_UPLOAD_IMAGE,
  GET_BLOOD_TEST_INSIGHTS_SUCCESS,
  GET_BLOOD_TEST_ORGAN_SUCCESS,
  GET_BLOOD_TEST_SCAN_STATUS,
  GET_BLOOD_TEST_SECOND_SUCCESS,
  GET_BLOOD_TEST_VALUES,
  GET_PROFILE_INFO_SUCCESS,
  SET_UPLOADED_FILES,
  SUBMIT_BLOOD_TEST,
  UPDATE_TEST_VALUES,
} from "../../redux/actionTypes/GetBloodTestFirstActionTypes";
import { fetchBiomarkerData } from "../../redux/actions/GetBiomarkerAction";
import ShareModal from "../settings/ShareEmail";
import {
  getSubscription,
  shareProfile,
} from "../../redux/actions/SettingsAction";
import DeleteUserListModel from "../settings/deleteUserListModel";
import {
  GET_BIOMARKER_REPORT_SUCCESS,
  GET_BIOMARKER_SUCCESS,
  GET_ORGAN_REPORT_ERROR,
  GET_ORGAN_REPORT_SUCCESS,
  PAGE_CHANGE,
} from "../../redux/actionTypes/trendsReportActionTypes";
import { GET_BLOOD_TEST_SUCCESS } from "../../redux/actionTypes/firstStepActionTypes";
import Search from "../../components/search/Search";
import useDebounce from "../../hooks/useDebounce";
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationModal from "../settings/ConfirmationModal";
import { deleteBloodTest } from "../../redux/actions/GetBloodTestAction";

const useStyles = makeStyles((theme) => ({
  arraowIcon: {
    fill: "#fff",
    fontSize: "22px",
    // marginBottom: "30px",
    backgroundColor: "#405973",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
  },
  accordion_summary: {
    marginTop: "10px",
    borderRadius: "15px",
    border: "1px solid #D1D1D1",
    height: "61px",
    padding: "5px 10px 0px 15px",
  },
  grid_set: {
    justifyContent: "center",
    alignItems: "center",
  },
  accordion_details: {
    borderRadius: "15px",
    background: "transparent",
    // borderWidth: "0px 1px 1px",
    // borderStyle: "solid",
    // borderColor: "lightgray",
    // borderImage: "initial",
    // marginTop: "-27px",
    paddingTop: "29px",
    // color: "#385A76",
    // fontFamily: "Poppins",
    // fontWeight: "500",
  },
  accor_summary: {
    marginBottom: "19px !important",
    marginTop: "48px !important",
  },
}));
function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const {
    userInfo,
    sampleReport,
    organData,
    insightsData,
    getProfileData,
    loading,
    biomarkerInfo,
    profileGrantedAccess,
    searchInfo,
    reviewBloodTestData,
    searchText
  } = useSelector((state) => state.dashboard);
  const userRole = userInfo?.user?.role?.S;
  const userType = "Provider";
  const isProvider = userRole === userType;
  const { subsciptionPlan } = useSelector((state) => state?.settings);
  const [bloodTest, setBloodTest] = useState([]);
  const [open, setOpen] = useState(false);
  const [reviewLoader, setReviewLoader] = useState(false);
  const [reviewLoaderFind, setReviewLoaderFind] = useState(false);
  const [expanded, setExpanded] = useState("panel1");
  const [isCompare, setIsCompare] = useState(false);
  const [addOtherLoading, setAddOtherLoading] = useState(false);
  const [array, setArray] = useState([]);
  const createAccount = useSelector((state) => state.signUp);
  const createAccountData = useSelector((state) => state);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [emails, setEmails] = useState();
  const [profileId, setProfileId] = useState();
  const [openSeach, setOpenSearch] = useState(false);
  // const [searchText, setSearchText] = useState("");
  const [searchTextReport, setSearchTextReport] = useState("");
  const [openModal, setOpenModal] = useState(false)
  const [clickReport, setClickReport] = useState(false)
  const [state, setState] = useState([])
  // const [openShowRecord,setOpenShowRecord] = useState(false)
  const [addOther, setAddOther] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    lName: "",
    countryCode: "",
    dateOfBirth: "",
    sex: "",
    gender: "",
    ethnicity: "",
    allergies: [],
    illnesses: [],
    medications: [],
    supplements: [],
    height: "",
    weight: "",
    userAccessList: [],
    isDefault: "false",
  });
  const [openUserListModel, setOpenUserListModel] = useState(false);
  const [userListModelData, setUserListModelData] = useState([]);
  const [activePlan, setActivePlan] = useState(null);
  const [profileIdSet, setProfileIdSet] = useState()
  const [bloodTestIdSet, setBloodTestIdSet] = useState()
  const [resultIdSet, setResultIdSet] = useState()
  const [createPatientModel, setCreatePatientModel] = useState(false);
  const { loader: reviewLoaderSearch } = useDebounce(searchText);
  const { loader: reviewLoaderReport } = useDebounce(searchTextReport);


  const handleClose = () => {
    setOpen(false);
    setAddOtherLoading(false);
  };
  useEffect(() => {
    if (!open) {
      setAddOtherLoading(false);
    }
  }, [open]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    dispatch(addSearchText(""))
  }, []);

  const handleOpen = () => setOpen(true);
  const handleSubmit = (values) => {
    const firstName =
      values.name.charAt(0).toUpperCase() + values.name.slice(1);
    const lastName =
      values.lName.charAt(0).toUpperCase() + values.lName.slice(1);
    const payload = {
      ...values,
      name: `${firstName} ${lastName}`,
      allergies: "[,]",
      illnesses: "{}",
      medications: "{}",
      supplements: "{}",
    };
    dispatch(addProfileData(payload));
    handleClose();
    setAddOtherLoading(true);
  };
  const getReportDetail = async (pId, name) => {
    setReviewLoaderFind(true)
    const response = await axiosInstance(`v1/getBloodTests`, "post", {
      profileId: pId,
    });
    const bloodTests = response?.data?.data?.bloodTests?.Items;
    let newValue = [];
    const finalBloodTest = [];
    if (bloodTests && bloodTests.length) {
      for (const value of bloodTests) {
        if (
          value.BloodTestStatus.S === "Scan Complete" &&
          value?.Results?.length > 0
        ) {
          newValue.push({
            ...value,
          });
        }
      }
    }
    finalBloodTest.push({
      newValue,
      name: name,
      profileId: pId,
    });


    finalBloodTest.forEach((profile) => {
      profile?.newValue?.sort((testA, testB) => {
        const dateA = moment(testA.BloodTestDate?.S).unix();
        const dateB = moment(testB.BloodTestDate?.S).unix();
        return dateB - dateA; // Compare in descending order
      });
    });

    // Sort the profiles based on the latest blood test date
    finalBloodTest.sort((profileA, profileB) => {
      const latestDateA = moment(
        profileA?.newValue[0]?.BloodTestDate?.S
      ).unix();
      const latestDateB = moment(
        profileB?.newValue[0]?.BloodTestDate?.S
      ).unix();
      return latestDateB - latestDateA; // Compare in descending order
    });

    setBloodTest([...finalBloodTest]);
    setReviewLoaderFind(false)
  }
  // review blood test function
  const getDetails = async () => {
    setReviewLoader(true);
    const getAllNames = [];
    const getDefaultProfile = getProfileData?.profiles?.Items?.filter(
      (item) => item.isDefault?.S === "true"
    );
    const userData =
      reviewBloodTestData &&
      reviewBloodTestData.length > 0 &&
      reviewBloodTestData?.map((item) => {
        return getAllNames.push({
          name: item?.profile?.FullName?.S,
          ProfileID: item?.profile?.ProfileID?.S,
          bloodTestCount: item?.BloodTestCount ? item?.BloodTestCount : 0,
          parentProfile:
            item?.isShared === true
              ? getDefaultProfile && getDefaultProfile[0]?.ProfileID?.S
              : "",
          isShared: item?.isShared,
          isDefault: item?.profile?.isDefault?.S,
        });
      });
    const finalBloodTest = [];
    for (const item of getAllNames) {
      if (!item?.ProfileID?.includes("DELETED")) {
        const response = await axiosInstance(`v1/getBloodTests`, "post", {
          profileId: item?.ProfileID,
          parentProfileId: item?.isShared === true ? item?.parentProfile : "",
        });
        const bloodTests = response?.data?.data?.bloodTests?.Items;

        let newValue = [];
        if (bloodTests && bloodTests.length) {
          for (const value of bloodTests) {
            if (
              value?.BloodTestStatus?.S === "Scan Complete" &&
              value?.Results?.length > 0
            ) {
              newValue.push({
                ...value,
                isShared: item.isShared ? item.isShared : false,
                parentProfileId: item?.parentProfileId ?? "",
                isRestricted: item.isRestricted ? item.isRestricted : false,
              });
            }
          }
        }
        setState(getAllNames)
        finalBloodTest.push({
          newValue,
          name: item?.name ? item?.name : "",
          profileId: item.ProfileID,
          isShared: item?.isShared,
          reports: newValue?.length,
          isDefault: item?.isDefault,
          parentProfileId: item?.parentProfileId ?? ""
        });
        // let newData = [bloodTest, newValue];
      }
    }

    finalBloodTest.forEach((profile) => {
      profile?.newValue?.sort((testA, testB) => {
        const dateA = moment(testA.BloodTestDate?.S).unix();
        const dateB = moment(testB.BloodTestDate?.S).unix();
        return dateB - dateA; // Compare in descending order
      });
    });
    // Sort the profiles based on the latest blood test date
    finalBloodTest.sort((profileA, profileB) => {
      const latestDateA = moment(
        profileA?.newValue[0]?.BloodTestDate?.S
      ).unix();
      const latestDateB = moment(
        profileB?.newValue[0]?.BloodTestDate?.S
      ).unix();
      return latestDateB - latestDateA; // Compare in descending order
    });
    setBloodTest([...finalBloodTest]);
    setReviewLoader(false);
  };
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  useEffect(() => {
    dispatch(fetchProfileData());
    dispatch(fetchUserData());
    dispatch(fetchBiomarkerData());
    dispatch(fetchSampleData());
    dispatch(getSubscription());
    compareDate();

    // get blood test empty all states
    dispatch({
      type: GET_BLOOD_TEST_FIRST_PID_SUCCESS,
      payload: null,
    });
    dispatch({
      type: GET_BLOOD_TEST_FIRST_START_TEST,
      payload: null,
    });
    dispatch({
      type: GET_BLOOD_TEST_FIRST_UPDATE_METADATA,
      payload: null,
    });
    dispatch({
      type: GET_BLOOD_TEST_FIRST_UPLOAD_IMAGE,
      payload: null,
    });
    dispatch({
      type: GET_BLOOD_TEST_FIRST_TEST_SCAN,
      payload: null,
    });
    dispatch({
      type: GET_BLOOD_TEST_SECOND_SUCCESS,
      payload: null,
    });
    dispatch({
      type: GET_BLOOD_TEST_VALUES,
      payload: null,
    });
    dispatch({
      type: SET_UPLOADED_FILES,
      payload: null,
    });
    dispatch({
      type: GET_PROFILE_INFO_SUCCESS,
      payload: null,
    });
    dispatch({
      type: GET_BLOOD_TEST_SCAN_STATUS,
      payload: null,
    });
    dispatch({
      type: UPDATE_TEST_VALUES,
      payload: null,
    });
    dispatch({
      type: SUBMIT_BLOOD_TEST,
      payload: null,
    });
    dispatch({
      type: GET_BLOOD_TEST_INSIGHTS_SUCCESS,
      payload: null,
    });
    dispatch({
      type: GET_BLOOD_TEST_ORGAN_SUCCESS,
      payload: null,
    });
    dispatch({
      type: GET_ORGAN_REPORT_SUCCESS,
      payload: null,
    });
    dispatch({
      type: GET_BIOMARKER_REPORT_SUCCESS,
      payload: null,
    });
    dispatch({
      type: GET_BLOOD_TEST_SUCCESS,
      payload: null,
    });
    dispatch({
      type: PAGE_CHANGE,
      payload: 1,
    });

    dispatch({
      type: GET_ORGAN_REPORT_SUCCESS,
      payload: null,
    });
    dispatch({
      type: GET_BIOMARKER_REPORT_SUCCESS,
      payload: null,
    });
    dispatch({
      type: GET_BIOMARKER_SUCCESS,
      payload: null,
    });
    dispatch({
      type: GET_PROFILE_INFO_SUCCESS,
      payload: null,
    });
    dispatch({
      type: GET_ORGAN_REPORT_ERROR,
      payload: null,
    });
  }, []);
  useEffect(() => {
    if (
      createAccount &&
      createAccount?.signUp &&
      Object.keys(createAccount?.signUp).length
    ) {
      if (
        // (!getProfileData?.profiles && getProfileData === null) ||
        getProfileData &&
        getProfileData?.profiles &&
        getProfileData?.profiles?.Items &&
        getProfileData?.profiles?.Items.length === 0
      ) {
        const profilePayload = {
          name: createAccount?.signUp?.user?.FullName.S,
          email: createAccount?.signUp?.user?.Email.S,
          mobileNumber: createAccount?.signUp?.user?.MobileNumber.S,
          lName: "",
          countryCode: createAccount?.signUp?.user?.LocationPointer.S,
          dateOfBirth: "",
          sex: "",
          gender: "",
          ethnicity: "",
          allergies: [],
          illnesses: [],
          medications: [],
          supplements: [],
          height: "",
          weight: "",
          userAccessList: [],
          isDefault: "true",
        };
        const token = createAccount?.signUp?.token;
        dispatch(addProfileData(profilePayload, token));
      }
    }
  }, [getProfileData?.profiles?.Items]);

  useEffect(() => {
    // getDetails();
    if (!isProvider) {
      getDetails();
    }
  }, [getProfileData?.profiles?.Items, reviewBloodTestData, userInfo]);
  useEffect(() => {
    const submittedReportsList = bloodTest?.filter(
      (e) => e?.reports > 0 && e?.isShared == false
    );
    setUserListModelData([...submittedReportsList]);
  }, [bloodTest]);

  useEffect(() => {
    if (!userListModelData) return;
    let userPlan = null;
    if (
      subsciptionPlan?.subscriptionPlan &&
      subsciptionPlan?.subscriptionPlan?.userPlan
    ) {
      userPlan = Number(subsciptionPlan?.subscriptionPlan?.userPlan);
    } else if (
      subsciptionPlan?.subscriptionPlan &&
      subsciptionPlan?.subscriptionPlan?.productId
    ) {
      let key = subsciptionPlan?.subscriptionPlan?.productId;
      if (key.includes("one")) {
        userPlan = 1;
      } else if (key.includes("two")) {
        userPlan = 2;
      } else if (key.includes("three")) {
        userPlan = 3;
      } else if (key.includes("four")) {
        userPlan = 4;
      } else if (key.includes("five")) {
        userPlan = 5;
      } else if (key.includes("six")) {
        userPlan = 6;
      } else if (key.includes("seven")) {
        userPlan = 7;
      } else if (key.includes("eight")) {
        userPlan = 8;
      } else if (key.includes("nine")) {
        userPlan = 9;
      } else if (key.includes("ten")) {
        userPlan = 10;
      }
    } else {
      userPlan = null;
    }
    setActivePlan(userPlan);
    const submittedReportsList = bloodTest?.filter(
      (e) => e?.reports > 0 && !e.isShared
    );
    if (userInfo && userInfo?.user?.role?.S === 'Provider') {
      setOpenUserListModel(false)
    } else if (submittedReportsList?.length > userPlan) {
      setOpenUserListModel(true);
    } else {
      setOpenUserListModel(false);
    }
  }, [userListModelData]);

  // compare date function
  const compareDate = () => {
    const convertDate = 1683797069;
    const date2 = moment(
      sampleReport?.sampleReport?.BloodTestStartDate?.S
    )?.unix();
    if (date2 > convertDate) {
      setIsCompare(true);
    } else {
      setIsCompare(false);
    }
  };

  useEffect(() => {
    let finalArray = [];
    const organOverview = organData?.res
      ? organData?.res?.OrganData?.S && JSON.parse(organData?.res?.OrganData?.S)
      : "";
    const organ = organOverview && Object.keys(organOverview);
    if (organ) {
      for (const key of organ) {
        const content = organOverviewMock.filter(
          (val) => val.fieldName === key
        );
        if (key !== "Other") {
          finalArray.push({
            name: key,
            count: organOverview[key].count,
            finalScore: organOverview[key].finalScore,
            data: content[0]?.content ? content[0]?.content : "",
            organImpact: organOverview[key].maxScores,
          });
          setArray(finalArray);
        }
      }
    }
  }, [organData]);

  useEffect(() => {
    if (sampleReport?.sampleReport) {
      const payload = {
        profileId: sampleReport?.sampleReport?.ProfileID?.S,
        bloodTestId: sampleReport?.sampleReport?.BloodTestID?.S,
        resultId: sampleReport?.sampleReport?.resultId,
      };
      dispatch(fetchOrganData(payload));
      dispatch(fetchInsightsData(payload));
    }
  }, [sampleReport?.sampleReport]);
  const checkDisabled = (bloodTest) => {
    if (bloodTest?.length) {
      if (
        bloodTest?.filter((eachTest) => {
          return eachTest.newValue.length;
        })?.length
      ) {
        return true;
      }
    } else {
      return false;
    }
  };

  const getData = (bloodName) => {
    if (sampleReport?.sampleReport) {
      const sampleReportValue = JSON.parse(
        sampleReport?.sampleReport?.BloodTestValues?.S
      );
      const allKeys = Object.keys(sampleReportValue);
      const getBlood = allKeys.find((item) => item === bloodName);
      if (
        sampleReportValue[getBlood] &&
        sampleReportValue[getBlood] !== null &&
        sampleReportValue[getBlood] !== undefined
      ) {
        return sampleReportValue[getBlood];
      } else {
        return "?";
      }
    }
  };

  const handleShowBloodReport = (pId, bId, rId, isShared = false) => {
    if (isShared) return;
    if (userInfo?.user?.role?.S == 'Provider') {
      navigate("/result", {
        state: { profileId: pId, bloodTestId: bId, resultId: rId },
      });
    } else {
      navigate("/get-blood-test-interpreted", {
        state: { profileId: pId, bloodTestId: bId, resultId: rId },
      });
    }
  };

  // share functionality
  const handleChangeEmail = (e) => {
    const { name, value } = e.target;
    setEmails({ ...emails, [name]: value });
  };

  const handleChangeSearch = (e) => {
    // setSearchText(e.target.value);
    dispatch(addSearchText(e.target.value))
  };
  const handleChangeSearchReport = (e) => {
    // setClickReport(false)
    setSearchTextReport(e.target.value);
  };
  const handleShareEmail = () => {
    const emailValues = Object.values(emails);
    const cleanedEmailValues = emailValues.map((email) =>
      email.replace(/"/g, "")
    );
    const formattedEmails = cleanedEmailValues.join(", ");

    dispatch(
      shareProfile({
        emails: formattedEmails,
        profileId: profileId,
      })
    );
    setOpenShareModal(false);
  };
  const handleShareClick = (pid) => {
    setOpenShareModal(true);
    setProfileId(pid);
    setEmails();
  };
  const handleClickSerach = async (pId, name) => {
    let response;
    for (const key of state) {
      if (key.parentProfile !== "") {
        const payload = {
          parentProfileId: key.parentProfile,
          profileId: pId
        }
        response = await axiosInstance(
          "/v1/getProfile",
          "POST",
          payload
        );
      }
    }

    if (response?.data?.success == false) {
      setCreatePatientModel(true)
    } else {
      dispatch({
        type: GET_BLOOD_TEST_FIRST_PID_SUCCESS_PROVIDER,
        payload: name,
      });
      dispatch({
        type: GET_BLOOD_TEST_FIRST_PID_SUCCESS,
        payload: pId,
      });
      navigate("/get-blood-test-interpreted");
    }
    // dispatch(
    //   type: GET_BLOOD_TEST_FIRST_PID_SUCCESS,
    //   payload: { pId, name },
    // });

  };
  const handleShareClose = () => {
    setOpenShareModal(false);
    setEmails();
  };
  const handleClick = (pId, shared, bloodTestDate) => {
    navigate("/trends", {
      state: { profileId: pId, isShared: shared, date: bloodTestDate },
    });
  };
  const handleCloseDeleteModal = () => {
    setOpenModal(false)
  }
  const handleCloseDelete = async (pId, bId, rId) => {
    const payload = {
      profileId: pId,
      bloodTestId: bId,
      resultId: rId
    }
    dispatch(deleteBloodTest(payload, setReviewLoader))
    setOpenModal(false)
  }
  const handleOpenDelete = (pId, bId, rId) => {
    setOpenModal(true)
    setProfileIdSet(pId)
    setBloodTestIdSet(bId)
    setResultIdSet(rId)
  }
  const handleClickReport = (pId, name, bId) => {
    setClickReport(true)
    getReportDetail(pId, name)
    setSearchTextReport('')
    // const data = {
    //   profileId: pId,
    //   bloodTestId: bId
    // };
    // dispatch(getBloodTestValues(data));
    // setReviewLoaderFind(false)
  }

  return (
    <div>
      <Header bloodTest={bloodTest} />
      <div className={dashboardCss.contaninerBg}>
        <Container maxWidth="xl">
          {!isProvider && <Loader loading={loading} />}
          <div
            style={{
              opacity: !isProvider && loading ? 0.4 : 1,
              pointerEvents: !isProvider && loading ? "none" : "all",
            }}
            className={dashboardCss.mainBg}
          >
            <div className={dashboardCss.container}>
              <h1>
                Hello,{" "}
                {userInfo?.user?.FullName?.S
                  ? capitalizeLetter(userInfo?.user?.FullName?.S)
                  : ""}
              </h1>
              {/* first section */}

              {userInfo && isProvider ? (
                <div className={dashboardCss.findCard}>
                  <p>Get Blood Tests Interpreted </p>
                  <SectionLoader
                    loading={reviewLoaderSearch}
                    bloodTest={searchInfo}
                    find={"patient"}
                  />

                  <Search
                    searchText={searchText}
                    // setSearchText={setSearchText}
                    onChange={handleChangeSearch}
                    suggestions={searchInfo}
                    handleClick={handleClickSerach}
                    reviewLoaderSearch={reviewLoaderSearch}
                    setCreatePatientModel={setCreatePatientModel}
                    createPatientModel={createPatientModel}
                  />
                </div>
              ) : (
                <Card className={dashboardCss.profileCard}>
                  <p>Get Blood Tests Interpreted </p>
                  <div className={dashboardCss.mainProfile}>
                    {reviewBloodTestData && reviewBloodTestData?.length > 0 ? (
                      <div className={dashboardCss.profileDesign}>
                        {reviewBloodTestData &&
                          reviewBloodTestData.length > 0 &&
                          [...reviewBloodTestData]
                            .sort((a, b) => {
                              if (
                                a?.profile?.isDefault?.S === "true" &&
                                b?.profile?.isDefault?.S === "false"
                              )
                                return -1;
                              if (
                                a?.profile?.isDefault?.S === "false" &&
                                b?.profile?.isDefault?.S === "true"
                              )
                                return 1;
                              return a?.profile?.isDefault?.S.localeCompare(
                                b?.profile?.isDefault?.S
                              );
                            })
                            ?.map((item) =>
                              !item?.profile?.ProfileID?.S?.includes(
                                "DELETED"
                              ) ? (
                                <div
                                  className={dashboardCss.showProfile}
                                  onClick={() => {
                                    dispatch({
                                      type: GET_BLOOD_TEST_FIRST_PID_SUCCESS,
                                      payload: item?.profile?.ProfileID?.S,
                                    });
                                    navigate("/get-blood-test-interpreted");
                                  }}
                                >
                                  {item?.profile?.FullName?.S
                                    ? capitalizeLetter(
                                      item?.profile?.FullName?.S
                                    )
                                    : ""}
                                </div>
                              ) : (
                                ""
                              )
                            )}
                      </div>
                    ) : (
                      <p className={dashboardCss.no_profileAvailable}>
                        There are no profiles currently accessible or visible.
                      </p>
                    )}
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <button
                        className={`${dashboardCss.addButton} ${getProfileData &&
                          getProfileData?.profiles &&
                          getProfileData?.profiles?.Items?.length > 3 &&
                          dashboardCss.MakebuttonCenter
                          }`}
                        onClick={handleOpen}
                      >
                        +Add Other
                      </button>
                    </div>
                  </div>
                </Card>
              )}

              {/* second section */}
              <Card className={dashboardCss.reviewReport}>
                <p id="reviewBloodTest" className={dashboardCss.review_title}>
                  Review Blood Test Reports
                </p>
                {!isProvider ? (
                  <SectionLoader loading={reviewLoader} bloodTest={bloodTest} />
                ) : (
                  <SectionLoader
                    loading={reviewLoaderReport}
                    bloodTest={searchInfo}
                    find={"patient"}
                  />
                )}
                {clickReport && <SectionLoader loading={reviewLoaderFind} bloodTest={bloodTest} find={"patient"} />}
                <>
                  {clickReport || isProvider ? <> <div>
                    <Search
                      searchText={searchTextReport}
                      setSearchText={setSearchTextReport}
                      onChange={handleChangeSearchReport}
                      suggestions={searchInfo}
                      labReport={"labReport"}
                      reviewLoaderReport={reviewLoaderReport}
                      handleClick={handleClickReport}
                    />
                  </div>
                    {/* {clickReport && bloodTest.map((e) => {
                      if (e.newValue.length == 0) {
                        return (
                          <p className={dashboardCss.noReport}>No Report Found</p>
                        )
                      }
                    })} */}
                  </>
                    : ''}
                  <div
                    className={clickReport ? dashboardCss.bloodTest_mainReport : dashboardCss.bloodTest_main}
                    style={{
                      opacity: reviewLoader && !isProvider ? 0.4 : 1,
                      pointerEvents: reviewLoader && !isProvider ? "none" : "all",
                    }}
                  >
                    {clickReport || (!isProvider && checkDisabled(bloodTest)) ? (
                      bloodTest.map((item, index) => {
                        if (item.newValue.length > 0) {
                          return (
                            <Card className={dashboardCss.trendReport} key={index}>
                              <div style={{ height: 360, overflow: "auto" }}>
                                <p className={dashboardCss.report_title}>
                                  {item.name ? capitalizeLetter(item.name) : ""}
                                </p>
                                <div className={dashboardCss.tr_reportDetails}>
                                  {item.newValue.length > 1 && (
                                    <div
                                      className={dashboardCss.tr_trendReport}
                                      onClick={() =>
                                        handleClick(
                                          item.profileId,
                                          item?.isShared,
                                          item?.newValue?.sort(
                                            (a, b) =>
                                              moment(b?.BloodTestDate?.S)?.valueOf() -
                                              moment(a?.BloodTestDate?.S)?.valueOf()
                                          )
                                        )
                                      }
                                    >
                                      <p className={dashboardCss.tr_title_details}>
                                        Trends Report
                                      </p>
                                      <div className={dashboardCss.tr_details}>
                                        <div className={dashboardCss.tr_shareInfo}>
                                          <p className={dashboardCss.tr_date}>
                                            <TrensReportIcon />
                                          </p>
                                          <div className={dashboardCss.arrowIcon}>
                                            <NavigateNextIcon
                                              sx={{
                                                color: "white",
                                                height: "20px !important",
                                                width: "20px !important",
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {item.newValue
                                    .sort(
                                      (a, b) =>
                                        moment(b.BloodTestDate?.S).valueOf() -
                                        moment(a.BloodTestDate?.S).valueOf()
                                    )
                                    .map((value, innerIndex) => (
                                      <div
                                        key={innerIndex}
                                        className={dashboardCss.tr_Text}
                                        onClick={() =>
                                          handleShowBloodReport(
                                            value?.ProfileID?.S,
                                            value.BloodTestID?.S,
                                            value.Results[0]?.resultId,
                                            !value?.isShared
                                          )
                                        }
                                      >
                                        <p className={dashboardCss.tr_report_name} onClick={() =>
                                          handleShowBloodReport(
                                            value?.ProfileID?.S,
                                            value.BloodTestID?.S,
                                            value.Results[0]?.resultId
                                          )
                                        }>
                                          {item.name}
                                        </p>
                                        <div className={dashboardCss.tr_details}>
                                          <div className={dashboardCss.tr_shareInfo}>
                                            <p className={dashboardCss.tr_bloodTestDate} onClick={() =>
                                              handleShowBloodReport(
                                                value?.ProfileID?.S,
                                                value.BloodTestID?.S,
                                                value.Results[0]?.resultId
                                              )
                                            }>
                                              {moment(value?.BloodTestDate?.S).format("DD MMM YYYY")}
                                            </p>
                                            {!value?.isShared && (
                                              <ShareIcon
                                                size="18px"
                                                sx={{
                                                  color: "#405973",
                                                  margin: "17px 16px 15px auto",
                                                  fontSize: "18px",
                                                }}
                                                onClick={() =>
                                                  handleShareClick(value?.ProfileID?.S)
                                                }
                                              />
                                            )}
                                            <div style={{ display: 'flex', paddingRight: '15px' }}>
                                              <DeleteIcon
                                                style={{ color: '#405973' }}
                                                onClick={() =>
                                                  handleOpenDelete(
                                                    value?.ProfileID?.S,
                                                    value.BloodTestID?.S,
                                                    value.Results[0]?.resultId
                                                  )
                                                }
                                              />
                                            </div>
                                            <div
                                              className={dashboardCss.tr_arrowIcon}
                                            >
                                              <NavigateNextIcon
                                                sx={{
                                                  color: "white",
                                                  height: "20px !important",
                                                  width: "20px !important",
                                                }}
                                                onClick={() =>
                                                  handleShowBloodReport(
                                                    value?.ProfileID?.S,
                                                    value.BloodTestID?.S,
                                                    value.Results[0]?.resultId
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  <ConfirmationModal
                                    openModal={openModal}
                                    handleClose={handleCloseDeleteModal}
                                    content="Deleting the report cannot be reversed."
                                    handleYes={() => handleCloseDelete(profileIdSet, bloodTestIdSet, resultIdSet)}
                                    button={true}
                                    pId={profileIdSet}
                                    bId={bloodTestIdSet}
                                    rId={resultIdSet}
                                    status={"delete"}
                                  />
                                </div>
                              </div>
                            </Card>
                          );
                        } else {
                          return userInfo?.user?.role?.S == 'Provider' && <div key={index} className={dashboardCss.noReport}>{userInfo?.user?.role?.S == 'Provider' ? 'No Report Found' : ''}</div>;
                        }
                      })
                    ) : (
                      <div className={!isProvider && dashboardCss.bloodTest_nodata}>
                        {!reviewLoader && !isProvider
                          ? "Currently, there are no blood test reports available for any profiles in this user's account. If you have added reports recently, please allow some time for processing."
                          : ""}
                      </div>
                    )}



                    {/* {clickReport || !isProvider && checkDisabled(bloodTest) ? (
                      bloodTest.map(
                        (item, index) =>
                          item.newValue.length > 0 && (
                            <Card className={dashboardCss.trendReport}>
                              <div style={{ height: 360, overflow: "auto" }}>
                                <p className={dashboardCss.report_title}>
                                  {item.name ? capitalizeLetter(item.name) : ""}
                                </p>
                                <div className={dashboardCss.tr_reportDetails}>
                                  {item.newValue.length > 1 && (
                                    <div
                                      className={dashboardCss.tr_trendReport}
                                      onClick={() =>
                                        handleClick(
                                          item.profileId,
                                          item?.isShared,
                                          item?.newValue?.sort(
                                            (a, b) =>
                                              moment(
                                                b?.BloodTestDate?.S
                                              )?.valueOf() -
                                              moment(
                                                a?.BloodTestDate?.S
                                              )?.valueOf()
                                          )
                                        )
                                      }
                                    >
                                      <p
                                        className={
                                          dashboardCss.tr_title_details
                                        }
                                      >
                                        Trends Report
                                      </p>
                                      <div className={dashboardCss.tr_details}>
                                        <div
                                          className={dashboardCss.tr_shareInfo}
                                        >
                                          <p className={dashboardCss.tr_date}>
                                            <TrensReportIcon />
                                          </p>
                                          <div
                                            className={dashboardCss.arrowIcon}
                                          >
                                            <NavigateNextIcon
                                              sx={{
                                                color: "white",
                                                height: "20px !important",
                                                width: "20px !important",
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {item.newValue
                                    .sort(
                                      (a, b) =>
                                        moment(b.BloodTestDate?.S).valueOf() -
                                        moment(a.BloodTestDate?.S).valueOf()
                                    )
                                    .map((value, innerIndex) => (
                                      <>
                                        <div
                                          key={innerIndex}
                                          className={dashboardCss.tr_Text}
                                          onClick={() =>
                                            handleShowBloodReport(
                                              value?.ProfileID?.S,
                                              value.BloodTestID?.S,
                                              value.Results[0]?.resultId,
                                              !value?.isShared
                                            )
                                          }
                                        >
                                          <p
                                            className={
                                              dashboardCss.tr_report_name
                                            }
                                          >
                                            {item.name}
                                          </p>
                                          <div
                                            className={dashboardCss.tr_details}
                                          >
                                            <div
                                              className={
                                                dashboardCss.tr_shareInfo
                                              }
                                            >
                                              <p
                                                className={
                                                  dashboardCss.tr_bloodTestDate
                                                }
                                              >
                                                {moment(
                                                  value?.BloodTestDate?.S
                                                ).format("DD MMM YYYY")}
                                              </p>
                                              {!value?.isShared && (
                                                <ShareIcon
                                                  size="18px"
                                                  sx={{
                                                    color: "#405973",
                                                    margin: "17px 16px 15px auto",
                                                    fontSize: "18px",
                                                  }}
                                                  onClick={() =>
                                                    handleShareClick(
                                                      value?.ProfileID?.S
                                                    )
                                                  }
                                                />
                                              )}
                                              <div style={{ display: 'flex', paddingRight: '15px' }}><DeleteIcon style={{ color: '#405973' }} onClick={() => handleOpenDelete(value?.ProfileID?.S,
                                                value.BloodTestID?.S,
                                                value.Results[0]?.resultId)} /></div>
                                              <div
                                                className={
                                                  dashboardCss.tr_arrowIcon
                                                }
                                              >
                                                <NavigateNextIcon
                                                  sx={{
                                                    color: "white",
                                                    height: "20px !important",
                                                    width: "20px !important",
                                                  }}
                                                  onClick={() =>
                                                    handleShowBloodReport(
                                                      value?.ProfileID?.S,
                                                      value.BloodTestID?.S,
                                                      value.Results[0]?.resultId
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                      </>
                                    ))}
                                  <ConfirmationModal
                                    openModal={openModal}
                                    handleClose={handleCloseDeleteModal}
                                    content="Are you sure you want to Delete Report ?"
                                    handleYes={() => handleCloseDelete(profileIdSet, bloodTestIdSet, resultIdSet)}
                                    button={true}
                                    pId={profileIdSet}
                                    bId={bloodTestIdSet}
                                    rId={resultIdSet}
                                    status={"delete"}
                                  />
                                </div>
                              </div>
                            </Card>
                          )
                      )
                    ) : isProvider ? (
                      <div>
                        <Search
                          searchText={searchTextReport}
                          setSearchText={setSearchTextReport}
                          onChange={handleChangeSearchReport}
                          suggestions={searchInfo}
                          labReport={"labReport"}
                          reviewLoaderReport={reviewLoaderReport}
                          // handleClick={handleClick}
                        />
                      </div>
                    ) : (
                      <div className={dashboardCss.bloodTest_nodata}>
                        {!reviewLoader && !isProvider
                          ? "Currently, there are no blood test reports available for any profiles in this user's account. If you have added reports recently, please allow some time for processing."
                          : ""}
                      </div>
                    )} */}
                  </div>
                </>
              </Card>
              <div className="section-accordian">
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon className={classes.arraowIcon} />
                    }
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    className={classes.accor_summary}
                    sx={{
                      "& .MuiAccordionSummary-content.Mui-expanded": {
                        minHeight: "0px",
                        margin: "0px",
                      },
                    }}
                  >
                    <p className={dashboardCss.viewSample}>
                      View Sample Report
                    </p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <PatientInfo
                      name={sampleReport?.sampleReport?.FullName?.S}
                      dob={sampleReport?.sampleReport?.DateOfBirth?.S}
                      gen={sampleReport?.sampleReport?.Gender?.S}
                      testDate={sampleReport?.sampleReport?.BloodTestDate?.S}
                      // inter={`Results are based on inputted values and interpreted for functionally optimal levels on ${moment(
                      //   sampleReport?.sampleReport?.BloodTestStartDate?.S
                      // ).format("ll")} by Advanced Health Academy`}
                      inter={`Results are based on inputted values and interpreted on ${moment(
                        sampleReport?.sampleReport?.BloodTestStartDate?.S
                      ).format("ll")}`}
                    />

                    <Grid className={dashboardCss.section}>
                      <div className={dashboardCss.sectionWrapper}>
                        <p
                          style={{ flex: " 0 0 32px" }}
                          className={dashboardCss.report_summary_title}
                        >
                          Summary
                        </p>
                        <div
                          style={{ flex: "1 1 auto" }}
                          className={dashboardCss.section_para}
                        >
                          <ReportSummary
                            summary1={organData?.res?.Summary1?.S}
                            summary2={organData?.res?.Summary2?.S}
                            summary3={organData?.res?.Summary3?.S}
                            summary4={organData?.res?.Summary4?.S}
                            summary5={organData?.res?.Summary5?.S}
                            summary6={organData?.res?.Summary6?.S}
                            summary7={organData?.res?.Summary7?.S}
                            summary8={organData?.res?.Summary8?.S}
                            SummaryExplanation={
                              organData?.res?.SummaryExplanation?.S
                            }
                            SummaryLast={organData?.res?.SummaryLast?.S}
                            SummaryOpener={organData?.res?.SummaryOpener?.S}
                            newDate={isCompare}
                          />
                        </div>
                      </div>
                      <div className={dashboardCss.sectionWrapper}>
                        <p
                          style={{ flex: " 0 0 32px" }}
                          className={dashboardCss.organ_title}
                        >
                          Organ Overview
                        </p>
                        <div
                          style={{ flex: "1 1 auto" }}
                          className={dashboardCss.section_para}
                        >
                          <OrganOverview
                            organData={array}
                            gender={sampleReport?.sampleReport?.Gender?.S}
                          />
                        </div>
                      </div>
                      <div className={dashboardCss.sectionWrapper}>
                        <p
                          style={{ flex: " 0 0 32px" }}
                          className={dashboardCss.insights_title}
                        >
                          Insights
                        </p>
                        <div
                          style={{ flex: "1 1 auto" }}
                          className={dashboardCss.section_para}
                        >
                          <Insights insightsData={insightsData} />
                        </div>
                      </div>
                    </Grid>
                    <p className={dashboardCss.summaryTitle}>
                      Summary of Individual Biomarkers
                    </p>

                    <section className={dashboardCss.biomarkerSection}>
                      <BiomarkerSection
                        biomarkerInfo={biomarkerInfo}
                        getData={getData}
                        gender={sampleReport?.sampleReport?.Gender?.S}
                      />
                    </section>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <SignUpModal
        setSignupLoading={setAddOtherLoading}
        signupLoading={addOtherLoading}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        onSubmit={handleSubmit}
        loading={loading}
        disabled={loading}
        initialData={addOther}
        titleName={"Add Other"}
        signUpName={"Submit"}
      />

      <ShareModal
        openModal={openShareModal}
        handleClose={handleShareClose}
        handleSubmit={handleShareEmail}
        handleChangeEmail={handleChangeEmail}
        emails={emails}
        openShareModal={openShareModal}
      />
      <DeleteUserListModel
        openModal={openUserListModel}
        userListModelData={userListModelData}
        setOpenUserListModel={setOpenUserListModel}
        activePlan={activePlan}
      />
    </div>

  );
}

export default Dashboard;
