import { Card, Grid } from "@mui/material";
import { Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import BreadCrumbs, {
  BackButton, NextButton,
} from "../../../components/breadcrumbs/BreadCrumbs";
import Header from "../../../components/header/Header";
import Insights from "../../../components/insighṭs/Insights";
import Loader from "../../../components/loader/Loader";
import {
  fetchGetBloodOrganData,
  getBloodTestValues,
  getProfileInfo,
} from "../../../redux/actions/GetBloodTestFirstAction";
import PatientInfo from "../../../components/patient-info/PatientInfo";
import ReportSummary from "../../../components/report-summary/ReportSummary";
import fourthStepStyles from "./fourthStep.module.scss";
import dashboardCss from "../../dashboard/dashboard.module.scss";
import { useDispatch, useSelector } from "react-redux";
import BiomarkerSection from "../../../components/biomarker-section/BiomarkerSection";
import OrganOverview from "../../../components/organ-overview/OrganOverview";
import { organOverviewMock } from "../../../json/mockData";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { deleteBloodTest } from "../../../redux/actions/GetBloodTestAction";
import ConfirmationModal from "../../settings/ConfirmationModal";
import { getNumber } from "../../../helper";
import CodePage from "../../../components/icd-codes/CodePage";
function FourthStep({ setCurrentStep, profileId, bloodTestId, resultId }) {
  const breadCrumbsArr = [
    {
      title: "Home",
      link: "/home",
    },
    {
      title: "Get Blood test Interpreted",
      link: null,
    },
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { fourthPage, bloodTestValues, firstPage, loading, submitValue } = useSelector(
    (state) => state.getBloodTestFirst
  );
  const { biomarkerInfo, reviewBloodTestData, parentProfileId, userInfo } = useSelector(
    (state) => state.dashboard
  );
  const [array, setArray] = useState([]);
  const [openModal, setOpenModal] = useState(false)
  const [isCompare, setIsCompare] = useState(false);
  const [highCode, sethighCode] = useState([])
  const [lowCode, setLowCode] = useState([])
  const [highDesc, setHighDesc] = useState([])
  const [lowDesc, setLowDesc] = useState([])
  const [loadingTimer, setLoadingTimer] = useState(false);
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  useEffect(() => {
    const sampleReportValue = bloodTestValues && JSON.parse(
      bloodTestValues?.bloodTestData?.BloodTestValues?.S
    );
    const allKeys = sampleReportValue && Object.keys(sampleReportValue);
    let highKey = []
    let highDesc = []
    let lowKey = []
    let lowDesc = []
    biomarkerInfo && biomarkerInfo.biomarker?.CBC.length && biomarkerInfo.biomarker.CBC.map((e) => {
      allKeys && allKeys.map((item) => {
        if (item == e.Biomarker_Name.S && sampleReportValue[item] !== null) {
          if (sampleReportValue[item] && getNumber(sampleReportValue[item]) > getNumber(e["Man Optimal Upper Limit"]?.S) && fourthPage?.profileInfo?.profile?.Gender?.S == "Man") {
            highKey.push(e["ICD10-High"]?.S)
            highDesc.push(e["ICD10-High-Description"]?.S)
          }
          if (sampleReportValue[item] && getNumber(sampleReportValue[item]) < getNumber(e["Man Optimal Lower Limit"]?.S) && fourthPage?.profileInfo?.profile?.Gender?.S == "Man") {

            lowKey.push(e["ICD10-Low"]?.S)
            lowDesc.push(e["ICD10-Low-Description"]?.S)
          }
          if (sampleReportValue[item] && getNumber(sampleReportValue[item]) > getNumber(e["Woman Optimal Upper Limit"]?.S) && fourthPage?.profileInfo?.profile?.Gender?.S == "Woman") {

            highKey.push(e["ICD10-High"]?.S)
            highDesc.push(e["ICD10-High-Description"]?.S)
          }
          if (sampleReportValue[item] && getNumber(sampleReportValue[item]) < getNumber(e["Woman Optimal Lower Limit"]?.S) && fourthPage?.profileInfo?.profile?.Gender?.S == "Woman") {

            lowKey.push(e["ICD10-Low"]?.S)
            lowDesc.push(e["ICD10-Low-Description"]?.S)
          }
        }
      })
    })
    biomarkerInfo && biomarkerInfo.biomarker?.CMP.length && biomarkerInfo.biomarker.CMP.map((e) => {
      allKeys && allKeys.map((item) => {
        if (item == e.Biomarker_Name.S && sampleReportValue[item] !== null) {
          if (sampleReportValue[item] && getNumber(sampleReportValue[item]) > getNumber(e["Man Optimal Upper Limit"]?.S) && fourthPage?.profileInfo?.profile?.Gender?.S == "Man") {
            highKey.push(e["ICD10-High"]?.S)
            highDesc.push(e["ICD10-High-Description"]?.S)
          }
          if (sampleReportValue[item] && getNumber(sampleReportValue[item]) < getNumber(e["Man Optimal Lower Limit"]?.S) && fourthPage?.profileInfo?.profile?.Gender?.S == "Man") {
            lowKey.push(e["ICD10-Low"]?.S)
            lowDesc.push(e["ICD10-Low-Description"]?.S)
          }
          if (sampleReportValue[item] && getNumber(sampleReportValue[item]) > getNumber(e["Woman Optimal Upper Limit"]?.S) && fourthPage?.profileInfo?.profile?.Gender?.S == "Woman") {
            highKey.push(e["ICD10-High"]?.S)
            highDesc.push(e["ICD10-High-Description"]?.S)
          }
          if (sampleReportValue[item] && getNumber(sampleReportValue[item]) < getNumber(e["Woman Optimal Lower Limit"]?.S) && fourthPage?.profileInfo?.profile?.Gender?.S == "Woman") {
            lowKey.push(e["ICD10-Low"]?.S)
            lowDesc.push(e["ICD10-Low-Description"]?.S)
          }
        }
      })
    })
    biomarkerInfo && biomarkerInfo.biomarker?.Lipid.length && biomarkerInfo.biomarker.Lipid.map((e) => {
      allKeys && allKeys.map((item) => {
        if (item == e.Biomarker_Name.S && sampleReportValue[item] !== null) {
          if (sampleReportValue[item] && getNumber(sampleReportValue[item]) > getNumber(e["Man Optimal Upper Limit"]?.S) && fourthPage?.profileInfo?.profile?.Gender?.S == "Man") {
            highKey.push(e["ICD10-High"]?.S)
            highDesc.push(e["ICD10-High-Description"]?.S)
          }
          if (sampleReportValue[item] && getNumber(sampleReportValue[item]) < getNumber(e["Man Optimal Lower Limit"]?.S) && fourthPage?.profileInfo?.profile?.Gender?.S == "Man") {
            lowKey.push(e["ICD10-Low"]?.S)
            lowDesc.push(e["ICD10-Low-Description"]?.S)
          }
          if (sampleReportValue[item] && getNumber(sampleReportValue[item]) > getNumber(e["Woman Optimal Upper Limit"]?.S) && fourthPage?.profileInfo?.profile?.Gender?.S == "Woman") {
            highKey.push(e["ICD10-High"]?.S)
            highDesc.push(e["ICD10-High-Description"]?.S)
          }
          if (sampleReportValue[item] && getNumber(sampleReportValue[item]) < getNumber(e["Woman Optimal Lower Limit"]?.S) && fourthPage?.profileInfo?.profile?.Gender?.S == "Woman") {
            lowKey.push(e["ICD10-Low"]?.S)
            lowDesc.push(e["ICD10-Low-Description"]?.S)
          }
        }
      })
    })
    biomarkerInfo && biomarkerInfo.biomarker?.Other.length && biomarkerInfo.biomarker.Other.map((e) => {
      allKeys && allKeys.map((item) => {
        if (item == e.Biomarker_Name.S && sampleReportValue[item] !== null) {
          if (sampleReportValue[item] && getNumber(sampleReportValue[item]) > getNumber(e["Man Optimal Upper Limit"]?.S) && fourthPage?.profileInfo?.profile?.Gender?.S == "Man") {
            highKey.push(e["ICD10-High"]?.S)
            highDesc.push(e["ICD10-High-Description"]?.S)
          }
          if (sampleReportValue[item] && getNumber(sampleReportValue[item]) < getNumber(e["Man Optimal Lower Limit"]?.S) && fourthPage?.profileInfo?.profile?.Gender?.S == "Man") {
            lowKey.push(e["ICD10-Low"]?.S)
            lowDesc.push(e["ICD10-Low-Description"]?.S)
          }
          if (sampleReportValue[item] && getNumber(sampleReportValue[item]) > getNumber(e["Woman Optimal Upper Limit"]?.S) && fourthPage?.profileInfo?.profile?.Gender?.S == "Woman") {
            highKey.push(e["ICD10-High"]?.S)
            highDesc.push(e["ICD10-High-Description"]?.S)
          }
          if (sampleReportValue[item] && getNumber(sampleReportValue[item]) < getNumber(e["Woman Optimal Lower Limit"]?.S) && fourthPage?.profileInfo?.profile?.Gender?.S == "Woman") {
            lowKey.push(e["ICD10-Low"]?.S)
            lowDesc.push(e["ICD10-Low-Description"]?.S)
          }
        }
      })
    })
    sethighCode(highKey)
    setHighDesc(highDesc)
    setLowCode(lowKey)
    setLowDesc(lowDesc)
  }, [biomarkerInfo])
  // compare date function
  const compareDate = () => {
    const convertDate = 1683797069;
    const date2 = moment(
      bloodTestValues?.bloodTestData?.BloodTestStartDate?.S
    ).unix();
    if (date2 > convertDate) {
      setIsCompare(true);
    } else {
      setIsCompare(false);
    }
  };
  const handleCloseDeleteModal = () => {
    setOpenModal(false)
  }
  const handleCloseDelete = async (pId, bId, rId) => {
    // console.log("pId", pId, bId, rId)
    // const payload = {
    //   profileId: pId,
    //   bloodTestId: bId,
    //   resultId: rId
    // }
    // console.log("payload", payload)
    const data = {
      profileId: profileId
        ? profileId
        : firstPage?.startBloodTestData?.profileId,
      bloodTestId: bloodTestId
        ? bloodTestId
        : firstPage?.startBloodTestData?.bloodTestId,
        resultId: submitValue && submitValue.rulesRes.resultId,
    };
    dispatch(deleteBloodTest(data))
    setOpenModal(false)
    navigate('/home')
  }

  useEffect(() => {
    let parentProfileIdData;
    if (reviewBloodTestData.length > 0) {
      for (const iterator of reviewBloodTestData) {
        if (
          iterator?.profile?.ProfileID?.S ===
          (profileId ? profileId : firstPage?.startBloodTestData?.profileId) &&
          iterator.isShared === true
        ) {
          parentProfileIdData = parentProfileId;
        }
      }
    }
    const profilePayload = {
      profileId: profileId
        ? profileId
        : firstPage?.startBloodTestData?.profileId,
      parentProfileId: parentProfileIdData ? parentProfileIdData : "",
    };
    const data = {
      profileId: profileId
        ? profileId
        : firstPage?.startBloodTestData?.profileId,
      bloodTestId: bloodTestId
        ? bloodTestId
        : firstPage?.startBloodTestData?.bloodTestId,
      parentProfileId: parentProfileIdData ? parentProfileIdData : "",
    };
    compareDate();
    dispatch(getBloodTestValues(data));
    dispatch(getProfileInfo(profilePayload));
    if (resultId) {
      const payload = {
        profileId: profileId,
        bloodTestId: bloodTestId,
        resultId: resultId,
        parentProfileId: parentProfileIdData ? parentProfileIdData : "",
      };
      dispatch(fetchGetBloodOrganData(payload));
    }
    dispatch(getBloodTestValues(data));
    dispatch(getBloodTestValues(data));
    dispatch(getBloodTestValues(data));
    dispatch(getBloodTestValues(data));
    const timer = setTimeout(() => {
      setLoadingTimer(false);
    }, 8000); 

    return () => clearTimeout(timer);
  }, []);

  // biomarker function
  const getData = (bloodName) => {
    if (bloodTestValues?.bloodTestData) {
      const sampleReportValue = bloodTestValues?.bloodTestData?.BloodTestValues && JSON.parse(
        bloodTestValues?.bloodTestData?.BloodTestValues?.S
      );
      const allKeys = sampleReportValue && Object.keys(sampleReportValue);
      const getBlood = allKeys.find((item) => item === bloodName);
      if (
        sampleReportValue[getBlood] &&
        sampleReportValue[getBlood] !== null &&
        sampleReportValue[getBlood] !== undefined
      ) {
        return sampleReportValue[getBlood];
      } else {
        return "?";
      }
    }
  };

  useEffect(() => {
    let finalArray = [];
    const organOverview = fourthPage?.organData?.res
      ? fourthPage?.organData?.res?.OrganData?.S &&
      JSON.parse(fourthPage?.organData?.res?.OrganData?.S)
      : "";
    const organ = organOverview && Object.keys(organOverview);
    if (organ) {
      for (const key of organ) {
        const content = organOverviewMock.filter(
          (val) => val.fieldName === key
        );
        if (key !== "Other") {
          finalArray.push({
            name: key,
            count: organOverview[key].count,
            finalScore: organOverview[key].finalScore,
            data: content[0]?.content ? content[0]?.content : "",
            organImpact: organOverview[key].maxScores,
          });
          setArray(finalArray);
        }
      }
    }
  }, [fourthPage?.organData]);
  return (
    <div>
      <Header showNavigations={false} />
      <div className={fourthStepStyles.contaninerBg}>
        <Loader loading={loading || loadingTimer} />
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {!resultId && (
            <BreadCrumbs
              breadCrumbsArr={breadCrumbsArr}
              isBackBtnVisible={false}
              isNextBtnVisible={false}
              handleBack={() => navigate("/home")}
              noResultId={"noResultId"}
            // backButtonText={"Back to homepage"}
            />

          )}
          {!resultId && (
            <div style={{ display: 'flex', gap: '10px', paddingTop: '47px' }}>
              <div>
                <BackButton
                  onClick={() => setOpenModal(true)}
                  style={{ padding: "11px 35px" }}
                >
                  Delete Report
                </BackButton>
              </div>
              <div>
                <NextButton
                  onClick={() => navigate("/home")}
                  style={{ padding: "11px 35px" }}
                >
                  Back to homepage
                </NextButton>
              </div>

            </div>

          )}
        </div>


        <Container
          style={{
            maxWidth: "1440px",
            paddingInline: "30px",
            opacity: loading || loadingTimer ? 0.4 : 1,
            paddingTop: resultId ? "1px" : "",
            pointerEvents: loading || loadingTimer ? "none" : "all",
          }}
        >
          <div>
            {resultId ? (
              <div className={fourthStepStyles.back_button}>
                <p className={fourthStepStyles.patientInfo_Title}>
                  Patient Information
                </p>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <div>
                    <BackButton
                      onClick={() => setOpenModal(true)}
                      style={{ padding: "11px 35px" }}
                    >
                      Delete Report
                    </BackButton>
                  </div>
                  <div>
                    <NextButton
                      onClick={() => navigate("/home")}
                      style={{ padding: "11px 35px" }}
                    >
                      Back to homepage
                    </NextButton>
                  </div>

                </div>
              </div>
            ) : (
              <p className={fourthStepStyles.patientInfo_Title}>
                Patient Information
              </p>
            )}
            <PatientInfo
              name={fourthPage?.profileInfo?.profile?.FullName?.S}
              dob={fourthPage?.profileInfo?.profile?.DateOfBirth?.S}
              gen={fourthPage?.profileInfo?.profile?.Sex?.S}
              testDate={bloodTestValues?.bloodTestData?.BloodTestDate?.S}
              // inter={`Results are based on inputted values and interpreted for functionally optimal levels on ${moment(
              //   bloodTestValues?.bloodTestData?.BloodTestStartDate?.S
              // ).format("ll")} by Advanced Health Academy`}
              inter={`Results are based on inputted values and interpreted on ${moment(
                bloodTestValues?.bloodTestData?.BloodTestStartDate?.S
              ).format("ll")}`}
            />
          </div>
          {userInfo && userInfo?.user?.role?.S == 'Provider' &&
                <><p className={fourthStepStyles.title}>Comprehensive findings</p><Card className={fourthStepStyles.comprehensive_card}>
              <div className={fourthStepStyles.comp_main}>
                <div
                  style={{ flex: "1 1 auto" }}
                  className={dashboardCss.section_para}
                >
                  <p className={fourthStepStyles.comp_content1}>{fourthPage?.organData?.res?.Summary3?.S}</p>
                  <p className={fourthStepStyles.comp_content2}>{fourthPage?.organData?.res?.Summary6?.S}</p>

                </div>
              </div>
            </Card></>}

          
          {userInfo && userInfo?.user?.role?.S == 'Provider' ? 
          <Grid className={dashboardCss.section}>
          <div className={dashboardCss.sectionWrapper}>
              <p
                style={{ flex: " 0 0 32px" }}
                className={dashboardCss.organ_title}
              >
                ORGAN HEALTH
              </p>
              <div
                style={{ flex: "1 1 auto" }}
                className={dashboardCss.section_para}
              >
                <OrganOverview
                  organData={array}
                  gender={fourthPage?.profileInfo?.profile?.Gender?.S}
                />
              </div>
            </div>

            <div className={dashboardCss.sectionWrapper}>
              <p
                style={{ flex: " 0 0 32px" }}
                className={dashboardCss.report_summary_title}
              >
                KEY INSIGHTS
              </p>
              <div
                style={{ flex: "1 1 auto" }}
                className={dashboardCss.section_para}
              >
                {fourthPage && fourthPage?.organData && <ReportSummary
                  // summary1={fourthPage?.organData?.res?.Summary1?.S}
                  summary2={fourthPage?.organData?.res?.Summary2?.S}
                  summary3={fourthPage?.organData?.res?.Summary3?.S}
                  // summary4={fourthPage?.organData?.res?.Summary4?.S}
                  // summary5={fourthPage?.organData?.res?.Summary5?.S}
                  summary6={fourthPage?.organData?.res?.Summary6?.S}
                  summary7={fourthPage?.organData?.res?.Summary7?.S}
                  summary8={fourthPage?.organData?.res?.Summary8?.S}
                  SummaryExplanation={
                    fourthPage?.organData?.res?.SummaryExplanation?.S
                  }
                  SummaryLast={fourthPage?.organData?.res?.SummaryLast?.S}
                  SummaryOpener={fourthPage?.organData?.res?.SummaryOpener?.S}
                  newDate={isCompare}
                  firstBold = {false}
                />}

              </div>

            </div>           
            
            <div className={dashboardCss.sectionWrapper}>
            <p
                style={{ flex: " 0 0 32px" }}
                className={dashboardCss.report_summary_title}
              >
               ASSOCIATED ICD-10 CODES
              </p>
                <div className={dashboardCss.sectionSet}>
                  <CodePage highCode={highCode} highDesc={highDesc} lowCode={lowCode} lowDesc={lowDesc} />
                </div>
                </div>
          </Grid> : 
          <Grid className={dashboardCss.section}>
            <div className={dashboardCss.sectionWrapper}>
              <p
                style={{ flex: " 0 0 32px" }}
                className={dashboardCss.report_summary_title}
              >
                Summary
              </p>
              <div
                style={{ flex: "1 1 auto" }}
                className={dashboardCss.section_para}
              >
                {fourthPage && fourthPage?.organData && <ReportSummary
                  summary1={fourthPage?.organData?.res?.Summary1?.S}
                  summary2={fourthPage?.organData?.res?.Summary2?.S}
                  summary3={fourthPage?.organData?.res?.Summary3?.S}
                  summary4={fourthPage?.organData?.res?.Summary4?.S}
                  summary5={fourthPage?.organData?.res?.Summary5?.S}
                  summary6={fourthPage?.organData?.res?.Summary6?.S}
                  summary7={fourthPage?.organData?.res?.Summary7?.S}
                  summary8={fourthPage?.organData?.res?.Summary8?.S}
                  SummaryExplanation={
                    fourthPage?.organData?.res?.SummaryExplanation?.S
                  }
                  SummaryLast={fourthPage?.organData?.res?.SummaryLast?.S}
                  SummaryOpener={fourthPage?.organData?.res?.SummaryOpener?.S}
                  newDate={isCompare}
                />}

              </div>

            </div>

            <div className={dashboardCss.sectionWrapper}>
              <p
                style={{ flex: " 0 0 32px" }}
                className={dashboardCss.organ_title}
              >
                Organ Overview
              </p>
              <div
                style={{ flex: "1 1 auto" }}
                className={dashboardCss.section_para}
              >
                <OrganOverview
                  organData={array}
                  gender={fourthPage?.profileInfo?.profile?.Gender?.S}
                />
              </div>
            </div>
            <div className={dashboardCss.sectionWrapper}>
              <p
                style={{ flex: " 0 0 32px" }}
                className={dashboardCss.insights_title}
              >
                Insights
              </p>
              <div
                style={{ flex: "1 1 auto" }}
                className={dashboardCss.section_para}
              >
                <Insights insightsData={fourthPage?.insightsData} />
              </div>
            </div>
          </Grid> 
          }

          {userInfo && userInfo?.user?.role?.S == 'Provider' ? <p className={dashboardCss.summaryTitle} style={{textAlign: 'center'}}>
            BIOMARKER VALUES
          </p> : 
          <p className={dashboardCss.summaryTitle}>
            Summary of Individual Biomarkers
          </p> }

          <section className={dashboardCss.biomarkerSection}>
            <BiomarkerSection
              biomarkerInfo={biomarkerInfo}
              getData={getData}
              gender={fourthPage?.profileInfo?.profile?.Gender?.S}
            />
          </section>
          {/* {!resultId && (
            <div className={fourthStepStyles.BottomNextBtn}>
              <BackButton
                onClick={() => navigate("/home")}
                style={{ padding: "11px 35px" }}
              >
                Back to homepage
              </BackButton>
            </div>
          )} */}

          {userInfo && userInfo?.user?.role?.S == 'Provider' && 
          <div className={dashboardCss.sectionWrapper} style={{marginBottom: '58px'}}>
              <p
                style={{ flex: " 0 0 32px", textAlign: 'center', marginBottom: '26px', marginTop: 0 }}
                className={dashboardCss.insights_title}
              >
                LIFESTYLE INSIGHTS
              </p>
              <div
                style={{ flex: "1 1 auto" }}
                className={dashboardCss.section_para}
              >
                <Insights insightsData={fourthPage?.insightsData} />
              </div>
            </div>}
          <ConfirmationModal
            openModal={openModal}
            handleClose={handleCloseDeleteModal}
            content="Deleting the report cannot be reversed."
            handleYes={() => handleCloseDelete(profileId, bloodTestId, resultId)}
            button={true}
            pId={profileId}
            bId={bloodTestId}
            rId={resultId}
            status={"delete"}
          />
        </Container>
        <div className={fourthStepStyles.bottomButton}>
          <div>
            <BackButton
              onClick={() => setOpenModal(true)}
              style={{ padding: "11px 35px" }}
            >
              Delete Report
            </BackButton>
          </div>
          <div>
            <NextButton
              onClick={() => navigate("/home")}
              style={{ padding: "11px 35px" }}
            >
              Back to homepage
            </NextButton>
          </div>

        </div>
      </div>

    </div>
  );
}

export default FourthStep;
