import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Card } from "@mui/material";
import organ from "./organ.module.scss";
import bodyImage from "../../assets/svg/Body.svg";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { ReactComponent as BrainMale } from "../../assets/svg/BodyPartImages/BrainImage.svg";
import { ReactComponent as ThyroidMale } from "../../assets/svg/BodyPartImages/Thyroid_Male.svg";
import { ReactComponent as ThyroidFemale } from "../../assets/svg/BodyPartImages/Thyroid_Female.svg";
import { ReactComponent as HeartMale } from "../../assets/svg/BodyPartImages/Heart_Male.svg";
import { ReactComponent as HeartFemale } from "../../assets/svg/BodyPartImages/Heart_Female.svg";
import { ReactComponent as StomachMale } from "../../assets/svg/BodyPartImages/Stomach_Male.svg";
import { ReactComponent as StomachFemale } from "../../assets/svg/BodyPartImages/Stomach_Female.svg";
import { ReactComponent as SmallIntestineMale } from "../../assets/svg/BodyPartImages/SmallIntestine_Male.svg";
import { ReactComponent as SmallIntestineFemale } from "../../assets/svg/BodyPartImages/Small-Intestin_Female.svg";
import { ReactComponent as KidneysMale } from "../../assets/svg/BodyPartImages/Kidneys_Male.svg";
import { ReactComponent as KidneysFemale } from "../../assets/svg/BodyPartImages/Kidneys_Female.svg";
import { ReactComponent as LiverMale } from "../../assets/svg/BodyPartImages/Liver_Male.svg";
import { ReactComponent as LiverFemale } from "../../assets/svg/BodyPartImages/Liver_Female.svg";
import { ReactComponent as LungsMale } from "../../assets/svg/BodyPartImages/Lungs_Male.svg";
import { ReactComponent as LungsFemale } from "../../assets/svg/BodyPartImages/Lungs_Female.svg";
import { ReactComponent as GallBladderMale } from "../../assets/svg/BodyPartImages/GallBladder_Male.svg";
import { ReactComponent as GallBladderFemale } from "../../assets/svg/BodyPartImages/Gallbladder_Female.svg";
import { ReactComponent as ColonMale } from "../../assets/svg/BodyPartImages/Colon_Male.svg";
import { ReactComponent as ColonFemale } from "../../assets/svg/BodyPartImages/Colon_Female.svg";
import { ReactComponent as SpleenMale } from "../../assets/svg/BodyPartImages/Spleen_Male.svg";
import { ReactComponent as SpleenFemale } from "../../assets/svg/BodyPartImages/Spleen_Female.svg";
import { ReactComponent as PancreasMale } from "../../assets/svg/BodyPartImages/Pancreas_Male.svg";
import { ReactComponent as PancreasFemale } from "../../assets/svg/BodyPartImages/Pancreas_Female.svg";
import BrainImage from "../../assets/svg/BodyParts/Brain.svg";
import ColonImage from "../../assets/svg/BodyParts/Colon.svg";
import GallbladderImage from "../../assets/svg/BodyParts/Gall-Bladder.svg";
import HeartImage from "../../assets/svg/BodyParts/Heart.svg";
import KidneyImage from "../../assets/svg/BodyParts/Kidney.svg";
import LiverImage from "../../assets/svg/BodyParts/Liver.svg";
import LungsImage from "../../assets/svg/BodyParts/Lungs.svg";
import PancreasImage from "../../assets/svg/BodyParts/Pancreas.svg";
import SmallIntestineImage from "../../assets/svg/BodyParts/Small-Intestine.svg";
import SpleenImage from "../../assets/svg/BodyParts/Spleen.svg";
import StomachImage from "../../assets/svg/BodyParts/Stomach.svg";
import ThyroidImage from "../../assets/svg/BodyParts/Thyroid.svg";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  heading: {
    textAlign: "right",
    color: "black",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "500",
    maxWidth: "107px",
    // whiteSpace: "nowrap",
    overflow: "hidden",
    overflowWrap: "break-word",
    // textOverflow: "ellipsis",
    "@media (min-width: 768px) and (max-width: 1024px)": {
      maxWidth: "initial",
    },
  },
  secondaryHeading: {
    fontSize: "16px",
    fontWeight: "500",
    fontFamily: "Poppins",
  },
  organ_title: {
    fontSize: "22px",
    color: "#122D5B",
    fontFamily: "Poppins",
    fontWeight: "500",
    marginTop: "35px",
  },
  overview_main: {
    backgroundColor: "white",
    borderRadius: "15px",
    padding: "25px",
    boxShadow: "none",
    width: "445px",
  },
  imageFix: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "19px",
  },
  arraowIcon: {
    fill: "#fff",
    fontSize: "22px",
    backgroundColor: "#122d5b",
    borderRadius: "50%",
  },
  organ_info: {
    display: "flex",
    width: "100%",
  },
  text_fix: {
    display: "flex",
    alignItems: "center",
  },
  // grid_set: {
  //     justifyContent: 'center',
  //     alignItems: 'center',
  //     // marginLeft:'-22px'
  // },
  accordion_summary: {
    marginTop: "28px",
    maxWidth: "440px",
    borderRadius: "15px",
    border: "1px solid #D1D1D1",
    padding: "0px 10px",
    height: "76px",
    "@media (min-width: 768px) and (max-width: 1024px)": {
      maxWidth: "inherit",
    },
    "& .MuiAccordionSummary-content": {
      margin: 0,
    },
  },
  accordion_details: {
    borderRadius: "0px 0px 18.92px 18.92px",
    background: "rgb(255, 255, 255)",
    borderWidth: "0px 1px 1px",
    borderStyle: "solid",
    borderColor: "lightgray",
    borderImage: "initial",
    color: "#385A76",
    fontFamily: "Poppins",
    fontWeight: "500",
    paddingTop: "16px",
    display: "block",
  },
  organ_data_set: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // margin: 'auto',
    // width: '50%'
  },
}));

export default function OrganOverview({ organData, gender, userRole }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [finalOrgan, setFinalOrgan] = useState();
 
  const handleChange = (item) => (event, isExpanded) => {
    setExpanded({
      ...expanded,
      [item]: isExpanded,
    });
  };
  const {userInfo} = useSelector((state) => state.dashboard);


  // Define a function to determine the color based on secondaryHeading
  const getSecondaryHeadingColor = (secondaryHeading) => {
    switch (secondaryHeading) {
      case "Problematic":
        return "#FF0000"; // You can set the desired color for problematic headings
      case "Dysfunctional":
        return "#FF0000";
      case "Stressed":
        return "#F59F3C"; // You can set the desired color for stressed headings
      case "Good":
        return "#4C942A"; // You can set the desired color for good headings
      case "unknown":
        return "#9bacba";
      default:
        return "black"; // Default color if no matching value is found
    }
  };

  useEffect(() => {
    let array = [];
    let status = {
      Dysfunctional: 0,
      Problematic: 1,
      Stressed: 2,
      Good: 3,
      unknown: 4,
    };
    organData.forEach((element) => {
      let val = element.finalScore;
      let count = element.count;
      let organName = element.name;
      let status;
      let imageSrc;
      let height;
      let organStyles = null;
      if (val <= 2) {
        status = "Good";
      }
      if (val === 0 && count === 0) {
        status = "unknown";
      }
      if (val >= 3 && val < 5) {
        status = "Good";
      }
      if (val >= 5 && val < 7) {
        status = "Stressed";
      }
      if (val >= 7 && val < 9) {
        status = "Problematic";
      }
      if (val >= 9) {
        status = "Dysfunctional";
      }
      if (organName === "Brain (hypothalamus/pituitary gland") {
        imageSrc = BrainImage;
        height = 53;
        organStyles = {
          position: "relative",
          left: "5px",
          top: "2px",
        };
      }
      if (organName === "Thyroid") {
        imageSrc = ThyroidImage;
        height = 43;
      }
      if (organName === "Heart") {
        imageSrc = HeartImage;
        height = 54;
        organStyles = {
          position: "relative",
          left: "13px",
        };
      }
      if (organName === "Stomach") {
        imageSrc = StomachImage;
        height = 60;
        organStyles = {
          position: "relative",
          top: "3px",
        };
      }
      if (organName === "Small Intestine") {
        imageSrc = SmallIntestineImage;
        height = 52;
        organStyles = {
          position: "relative",
          top: "5px",
          left: "4px",
        };
      }
      if (organName === "Kidney & Adrenal Glands") {
        imageSrc = KidneyImage;
        height = 50;
        organStyles = {
          position: "relative",
          top: "3px",
        };
      }
      if (organName === "Liver") {
        imageSrc = LiverImage;
        height = 52;
        organStyles = {
          position: "relative",
          top: "1px",
          left: "3px",
        };
      }
      if (organName === "Lungs") {
        imageSrc = LungsImage;
        height = 53;
        organStyles = {
          position: "relative",
          top: "5px",
          left: "5px",
        };
      }
      if (organName === "Gallbladder") {
        imageSrc = GallbladderImage;
        height = 50;
        organStyles = {
          position: "relative",
          top: "3px",
          left: "7px",
        };
      }
      if (organName === "Colon") {
        imageSrc = ColonImage;
        height = 51;
        organStyles = {
          position: "relative",
          top: "4px",
          left: "13px",
        };
      }
      if (organName === "Spleen") {
        imageSrc = SpleenImage;
        height = 55;
        organStyles = {
          position: "relative",
          top: "2px",
          left: "11px",
        };
      }
      if (organName === "Pancreas") {
        imageSrc = PancreasImage;
        height = 35;
        organStyles = {
          position: "relative",
          left: "6px",
        };
      }

      array.push({
        ...element,
        status: status,
        image: imageSrc,
        height,
        organStyles,
      });
    });
    array.sort((a, b) => status[a.status] - status[b.status]);
    setFinalOrgan(array);
  }, [organData]);
  const contentImage = (item) => {
    // eslint-disable-next-line default-case
    switch (item) {
      case "Brain (hypothalamus/pituitary gland":
        return gender === "Male" || gender === "Man" ? (
          <BrainMale height={126} width={317} />
        ) : (
          <BrainMale height={126} width={317} />
        );
      case "Thyroid":
        return gender === "Male" || gender === "Man" ? (
          <ThyroidMale height={126} width={317} />
        ) : (
          <ThyroidFemale height={126} width={317} />
        );
      case "Heart":
        return gender === "Male" || gender === "Man" ? (
          <HeartMale height={126} width={317} />
        ) : (
          <HeartFemale height={126} width={317} />
        );
      case "Stomach":
        return gender === "Male" || gender === "Man" ? (
          <StomachMale height={126} width={317} />
        ) : (
          <StomachFemale height={126} width={317} />
        );
      case "Small Intestine":
        return gender === "Male" || gender === "Man" ? (
          <SmallIntestineMale height={126} width={317} />
        ) : (
          <SmallIntestineFemale height={126} width={317} />
        );
      case "Kidney & Adrenal Glands":
        return gender === "Male" || gender === "Man" ? (
          <KidneysMale height={126} width={317} />
        ) : (
          <KidneysFemale height={126} width={317} />
        );
      case "Liver":
        return gender === "Male" || gender === "Man" ? (
          <LiverMale height={126} width={317} />
        ) : (
          <LiverFemale height={126} width={317} />
        );
      case "Lungs":
        return gender === "Male" || gender === "Man" ? (
          <LungsMale height={126} width={317} />
        ) : (
          <LungsFemale height={126} width={317} />
        );
      case "Gallbladder":
        return gender === "Male" || gender === "Man" ? (
          <GallBladderMale height={126} width={317} />
        ) : (
          <GallBladderFemale height={126} width={317} />
        );
      case "Colon":
        return gender === "Male" || gender === "Man" ? (
          <ColonMale height={126} width={317} />
        ) : (
          <ColonFemale height={126} width={317} />
        );
      case "Spleen":
        return gender === "Male" || gender === "Man" ? (
          <SpleenMale height={126} width={317} />
        ) : (
          <SpleenFemale height={126} width={317} />
        );
      case "Pancreas":
        return gender === "Male" || gender === "Man" ? (
          <PancreasMale height={126} width={317} />
        ) : (
          <PancreasFemale height={126} width={317} />
        );
    }
  };
  return (

    <div className={userRole ? organ.organMainRole : organ.organMain}>
      <Card className={organ.overview_main}>

        <div className={userRole && organ.set_display_organ}>
          {!userRole && <div className={classes.imageFix}>
            <img src={bodyImage} alt="bodyImage" width={"162px"} />
          </div>}
          {finalOrgan?.length > 0 ? (
            finalOrgan &&
            finalOrgan.map((accordion) => {
              const {
                name,
                data,
                status,
                image,
                height,
                organStyles,
                organImpact,
              } = accordion;
              return (
                <div className="organOverview">
                  <Accordion
                    expanded={expanded[name]}
                    defaultExpanded={status === 'Dysfunctional' || status === 'Problematic' ? true : false}
                    key={name}
                    onChange={handleChange(name)}
                    sx={{
                      "& .MuiIconButton-label": {
                        backgroundColor: "#122d5b !important",
                      },
                    }}
                  >
                    <div className="accordian_detail">
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon className={classes.arraowIcon} />
                        }
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        className={userRole ? organ.accordion_summary_organ : classes.accordion_summary}
                      >
                        <div className={classes.organ_info}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "center",
                              minWidth: "57px",
                            }}
                          >
                            <img
                              style={{ ...organStyles }}
                              src={image}
                              alt="kidneyImage"
                              width={"auto"}
                              height={height}
                            />

                          </div>

                          <Box sx={{ display: "flex", flexGrow: 1 }}>
                            <Grid container className={organ.grid_set}>
                              <Grid item xs={4} className={userRole ? organ.grid_title_role : organ.grid_title}>
                                <div className={classes.heading} title={name}>
                                  {name.replace(/\(.*/, "")}
                                </div>
                              </Grid>
                              <Grid item>
                                <div
                                  className={organ.vertical_line}
                                  style={{
                                    backgroundColor:
                                      getSecondaryHeadingColor(status),
                                  }}
                                ></div>
                              </Grid>
                              <Grid item xs={4}>
                                <div
                                  style={{
                                    color: getSecondaryHeadingColor(status),
                                  }}
                                  className={classes.secondaryHeading}
                                >
                                  {status}
                                </div>
                              </Grid>
                            </Grid>
                          </Box>
                        </div>
                      </AccordionSummary>
                    </div>
                    <AccordionDetails className={classes.accordion_details}>
                    {userInfo && userInfo?.user?.role?.S != 'Provider' && <p className={organ.organ_data}>{data}</p>}
                      {contentImage(name) ? (
                        <>
                          {organImpact?.length > 0 &&
                            organImpact[0].organScore >= 4 && (
                              <>
                                <p className={organ.organImpactstyle}>
                                  {`Indication of ${name.replace(
                                    /\(.*/,
                                    ""
                                  )} issues include, but are not limited to, the following:`}
                                </p>
                                <ul>
                                  {organImpact?.map((e) => {
                                    return (
                                      <>
                                        {e.organImpact.S ? (
                                          <>
                                            {e.organImpact.S &&
                                              e.organScore >= 4 ? (
                                              <li className={organ.organ_li}>
                                                {e.organImpact.S}
                                              </li>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    );
                                  })}
                                </ul>
                              </>
                            )}
                          <p className={organ.body_image}>{contentImage(name)}</p>
                        </>
                      ) : (
                        ""
                      )}
                    </AccordionDetails>
                  </Accordion>
                </div>
              );
            })
          ) : (
            <p className="commonSummaryError">No Organs Found.</p>
          )}
        </div>
      </Card>
    </div>

  );
}
