import axiosInstance from "../../axios/axiosInstance";
import {
  GET_BLOOD_TEST_ERROR,
  GET_BLOOD_TEST_SUCCESS,
} from "../actionTypes/firstStepActionTypes";
export const getBloodTests = () => async (dispatch, getState) => {
  try {
    const states = getState()?.getBloodTestFirst?.getProfileData;
    const userData =
      states &&
      states?.profiles?.Items?.map((item) => ({
        name: item?.FullName?.S,
        ProfileID: item?.ProfileID?.S,
        isDefault: item?.isDefault?.S,
      }));
    const finalBloodTest = [];
    if (userData) {
      for (let item of userData) {
        if (!item?.ProfileID?.includes("DELETED")) {
          const response = await axiosInstance(`v1/getBloodTests`, "post", {
            profileId: item?.ProfileID,
          });
          const bloodTests = response?.data?.data?.bloodTests?.Items;
          let newValue = [];
          if (bloodTests && bloodTests?.length) {
            for (let value of bloodTests) {
              if (
                value?.BloodTestStatus?.S === "Scan Complete" &&
                value?.Results?.length > 0
              ) {
                newValue.push({
                  ...value,
                  resultLength: value?.Results?.length,
                });
              }
            }
          }

          finalBloodTest?.push({
            reports: newValue?.length,
            name: item?.name,
            profileId: item?.ProfileID,
            isDefault: item?.isDefault,
          });
        }
      }

      dispatch({
        type: GET_BLOOD_TEST_SUCCESS,
        payload: finalBloodTest,
      });
    }
  } catch (error) {
    dispatch({ type: GET_BLOOD_TEST_ERROR, payload: error.message });
  }
};
