export const calculateGraphArea = (
  stepDifference,
  barStepDifference,
  addSteps,
  minValue,
  color,
  isBorder
) => ({
  pointStepDifference: stepDifference,
  barStepDifference: barStepDifference,
  addSteps: addSteps,
  minValue: minValue,
  color: color,
  isBorder: isBorder,
});

export const replacePercentage = (yLabels, index) => {
  const num = yLabels[index];
  if (num) return parseFloat(num);
  return 0;
};

export const getPoints = (
  {
    barStepDifference,
    addSteps,
    pointStepDifference,
    minValue,
    color,
    isBorder,
  },
  value,
  outOfRangeValue = false
) => {
  // const calculate =
  //   ((value - minValue) * barStepDifference) / pointStepDifference + addSteps;
  return {
    points: outOfRangeValue ? null : value,
    color: color,
    isBorder: isBorder,
    unConvertedPoint: value,
  };
};

export const biomarkerPlugin = [
  {
    bgColor: "rgb(255,4,4,28%)", //red
    start: 12,
    end: 10,
  },
  {
    bgColor: "rgb(193,175,10,28%)", //yellow
    start: 10,
    end: 8,
  },
  {
    bgColor: "rgb(43, 147, 6 , 28%)", //green
    start: 8,
    end: 4,
  },
  {
    bgColor: "rgb(193,175,10,28%)", //yellow
    start: 4,
    end: 2,
  },
  {
    bgColor: "rgb(255,4,4,28%)", //red
    start: 2,
    end: 0,
  },
];

export const organPlugin = [
  {
    bgColor: "rgb(255,4,4,28%)",
    start: 11.5,
    end: 8.5,
  },
  {
    bgColor: "rgb(255,140,93,28%)",
    start: 8.5,
    end: 6.5,
  },
  {
    bgColor: "rgb(193,175,10,28%)",
    start: 6.5,
    end: 4.5,
  },
  {
    bgColor: "rgb(43,147,6,28%)",
    start: 4.5,
    end: -0.5,
  },
];
export const stepSize = {
  "Complete Blood Count (CBC)": [
    0.1, 0.1, 0.1, 0.1, 1, 1, 1, 0.1, 1, 0.1, 100, 50, 10, 1, 1, 1, 1, 0.1, 0.1,
    0.1,
  ],
  "Common Other Blood Tests": [
    0.01, 5, 1, 10, 1, 1, 0.1, 0.1, 0.01, 0.1, 10, 0.1, 1, 1, 0.1, 10, 1, 1, 1,
    1, 10, 1, 0.1, 1, 0.1, 0.1, 1, 1, 1, 0.1, 0.1, 0.1, 0.1, 0.1, 1,
  ],
  "Lipid Panel": [1, 5, 1, 1, 1, 0.1],
  "Comprehensive Metabolic Panel (CMP)": [
    5, 1, 0.01, 0.1, 1, 10, 1, 1, 0.1, 1, 1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.01, 1,
    0.1, 1, 1, 0.1, 10, 0.1, 0.1, 1,
  ],
};
