export const GET_BLOOD_TEST_FIRST_REQUEST = "GET_BLOOD_TEST_FIRST_REQUEST";
export const GET_BLOOD_TEST_FIRST_SUCCESS = "GET_BLOOD_TEST_FIRST_SUCCESS";
export const GET_BLOOD_TEST_FIRST_FAILURE = "GET_BLOOD_TEST_FIRST_FAILURE";

export const GET_BLOOD_TEST_FIRST_PID_SUCCESS =
  "GET_BLOOD_TEST_FIRST_PID_SUCCESS";

  export const GET_SEARCH_TEXT =
  "GET_SEARCH_TEXT";

export const GET_BLOOD_TEST_FIRST_START_TEST =
  "GET_BLOOD_TEST_FIRST_START_TEST";

export const GET_BLOOD_TEST_FIRST_UPDATE_METADATA =
  "GET_BLOOD_TEST_FIRST_UPDATE_METADATA";

export const GET_BLOOD_TEST_FIRST_UPLOAD_IMAGE =
  "GET_BLOOD_TEST_FIRST_UPLOAD_IMAGE";

export const GET_BLOOD_TEST_FIRST_TEST_SCAN = "GET_BLOOD_TEST_FIRST_TEST_SCAN";
export const GET_BLOOD_TEST_SECOND_SUCCESS = "GET_BLOOD_TEST_SECOND_SUCCESS";

export const GET_BLOOD_TEST_VALUES = "GET_BLOOD_TEST_VALUES";
export const SET_UPLOADED_FILES = "SET_UPLOADED_FILES";

export const GET_PROFILE_INFO_SUCCESS = "GET_PROFILE_INFO_SUCCESS";

export const GET_BLOOD_TEST_SCAN_STATUS = "GET_BLOOD_TEST_SCAN_STATUS";

export const UPDATE_TEST_VALUES = "UPDATE_TEST_VALUES";

export const SUBMIT_BLOOD_TEST = "SUBMIT_BLOOD_TEST";

export const GET_BLOOD_TEST_INSIGHTS_SUCCESS =
  "GET_BLOOD_TEST_INSIGHTS_SUCCESS";

export const GET_BLOOD_TEST_ORGAN_SUCCESS = "GET_BLOOD_TEST_ORGAN_SUCCESS";

export const GET_BLOOD_TEST_FIRST_PID_SUCCESS_PROVIDER = "GET_BLOOD_TEST_FIRST_PID_SUCCESS_PROVIDER"