import React, { useEffect, useRef } from "react";
import dropdownStyles from "./dropdown.module.scss";
import { ReactComponent as DownArrowGrayIcon } from "../../assets/svg/DownArrowGray.svg";

const Dropdown = ({
  label,
  value,
  open,
  setOpen,
  dropDownValues,
  placeholder,
  dropdownContainerClass,
  handleDropdowns,
  dKey,
}) => {
  const getLIstyles = (eachListValue) => {
    if (eachListValue.name === value[dKey]) {
      return `${dropdownStyles.LIStyles} ${dropdownStyles.isListValueSelected}`;
    } else {
      return dropdownStyles.LIStyles;
    }
  };

  const handleToggleDropdown = () => {
    setOpen((prev) => !prev);
  };
  const dropdownRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  return (
    <div
      className={`${dropdownStyles.dropdownWrapper} ${dropdownContainerClass}`}
      ref={dropdownRef}
    >
      <p className={dropdownStyles.dropdownHeading}>{label}</p>
      <div
        className={dropdownStyles.dropdownBtn}
        onClick={handleToggleDropdown}
      >
        {value[dKey] || placeholder}
        <span
          className={`${dropdownStyles.dropdownArrow} ${
            open && dropdownStyles.dropdownArrowOepn
          }`}
        >
          <DownArrowGrayIcon />
        </span>
        <div
          className={`${dropdownStyles.dropdownContent} ${
            open && dropdownStyles.dropdownContentOpen
          }`}
        >
          <ul>
            {dropDownValues?.length > 0 &&
              dropDownValues.map((eachDropDownValue, index) => {
                return (
                  <>
                    <li
                      key={index}
                      className={getLIstyles(eachDropDownValue)}
                      onClick={() =>
                        handleDropdowns(dKey, eachDropDownValue.name)
                      }
                    >
                      {eachDropDownValue.name}
                    </li>
                  </>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
