import { ADD_PROFILE_SUCCESS } from "../actionTypes/AddProfileActionTypes";
import { GET_BIOMARKER_SUCCESS } from "../actionTypes/BiomarkerActionTypes";
import {
  GET_DASHBOARD_FAILURE,
  GET_DASHBOARD_REQUEST,
  GET_PARENT_PROFILE_ID,
  GET_PROFILE_GRANTED_ACCESS_SUCCESS,
  GET_REVIEW_BLOOD_TEST_REPORT,
} from "../actionTypes/DashboardActionTypes";
import { GET_SEARCH_TEXT } from "../actionTypes/GetBloodTestFirstActionTypes";
import { GET_PROFILE_SUCCESS } from "../actionTypes/GetProfileActionTypes";
import { GET_SUGGESTIONS_SUCCESS, GET_USER_SUCCESS } from "../actionTypes/GetUserActionTypes";
import { GET_INSIGHTS_SUCCESS } from "../actionTypes/InsightsActionTypes";
import { GET_ORGAN_SUCCESS } from "../actionTypes/OrganSectoinActionTypes";
import { SAMPLE_REPORT_SUCCESS } from "../actionTypes/SampleReportActionTypes";

const INITIAL_STATE = {
  userInfo: {},
  searchInfo: {},
  getProfileData: {},
  bloodTestData: {},
  sampleReport: {},
  organData: {},
  insightsData: {},
  biomarkerInfo: {},
  reviewBloodTestData: [],
  parentProfileId: "",
  loading: false,
  profileGrantedAccess: {},
  searchText: ""
};

const DashboardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_DASHBOARD_REQUEST:
      return {
        ...state,
        loading: action.payload ? action.payload : true,
        error: null,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        userInfo: action.payload,
        loading: false,
        error: null,
      };
      case GET_SUGGESTIONS_SUCCESS:
        return {
          ...state,
          searchInfo: action.payload,
          loading: false,
          error: null,
        };
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        getProfileData: action.payload,
        loading: false,
        error: null,
      };
    case ADD_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case GET_PROFILE_GRANTED_ACCESS_SUCCESS:
      return {
        ...state,
        loading: false,
        profileGrantedAccess: action.payload,
        error: null,
      };
    case GET_REVIEW_BLOOD_TEST_REPORT:
      return {
        ...state,
        loading: false,
        reviewBloodTestData: action.payload,
        error: null,
      };
    case SAMPLE_REPORT_SUCCESS:
      return {
        ...state,
        sampleReport: action.payload,
        loading: false,
        error: null,
      };
    case GET_ORGAN_SUCCESS:
      return {
        ...state,
        organData: action.payload,
        loading: false,
        error: null,
      };
    case GET_INSIGHTS_SUCCESS:
      return {
        ...state,
        insightsData: action.payload,
        loading: false,
        error: null,
      };
    case GET_BIOMARKER_SUCCESS:
      return {
        ...state,
        biomarkerInfo: action.payload,
        loading: false,
        error: null,
      };
    case GET_PARENT_PROFILE_ID:
      return {
        ...state,
        parentProfileId: action.payload,
        loading: false,
        error: null,
      };
    case GET_DASHBOARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      case GET_SEARCH_TEXT:
        return {
          ...state,
          loading: false,
          searchText: action.payload,
          error: null,
        };
    default:
      return state;
  }
};

export default DashboardReducer;
