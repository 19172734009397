import { GET_BIOMARKER_SUCCESS } from "../actionTypes/BiomarkerActionTypes";
import axiosInstance from "../../axios/axiosInstance";
import {
  GET_DASHBOARD_FAILURE,
  GET_DASHBOARD_REQUEST,
} from "../actionTypes/DashboardActionTypes";

export const fetchBiomarkerData = () => async (dispatch) => {
  // dispatch({ type: GET_DASHBOARD_REQUEST });
  try {
    const response = await axiosInstance(`v1/getBiomarker`, "post");
    dispatch({
      type: GET_BIOMARKER_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({ type: GET_DASHBOARD_FAILURE, payload: error.message });
  }
};
