import Chart from "chart.js/auto";
import React, { useEffect, useState, memo } from "react";
import { colors } from "../utils/constants";

const BiomarkerGraph = ({
  chartId,
  steps,
  yLabels,
  points = [],
  xLabels = [],
}) => {
  const [chartData, setChartData] = useState({
    yLabels: [],
    xLabel: [],
    points: [],
  });

  const FixedDecimalPoints = 2;
  const maxYLabel = financial(Math.max(...yLabels)); //40
  const minYLabel = financial(Math.min(...yLabels)); //0.01

  // const maxPoint = financial(Math.max(...points)); //10.4
  const filteredPoints = points.filter(value => value !== null);
  let minPoint
  let maxPoint
  if (filteredPoints.length > 0) {
    minPoint = Math.min(...filteredPoints);
    maxPoint = financial(Math.max(...filteredPoints)); //10.4
  }
  // const minPoint = financial(Math.min(...points)); //3.2

  const increasePercentage = financial(maxPoint + (maxPoint * 10) / 100); //increase 10% to actual max label value
  const decreasePercentage = financial(minPoint - (minPoint * 20) / 100); ///decrease 20% to actual min label value
  let finalMaxLabel =
    maxYLabel > increasePercentage ? increasePercentage : maxYLabel; //reset range of max label

  let finalMinLabel =
    minYLabel < decreasePercentage ? decreasePercentage : minYLabel; //reset range of min label

  const filteredLabels = yLabels
    .map((val, i, array) => {
      const setValue =
        finalMinLabel > val && array[i + 1] > finalMinLabel
          ? finalMinLabel
          : val;
      return {
        color: colors[i],
        value: setValue,
        nextElement: array[i + 1],
      };
    })
    .filter(
      ({ value }) => increasePercentage >= value && value >= decreasePercentage
    )
    .map((prev, i, arr) => {
      const lastIndex = arr.length - 1;
      return {
        ...prev,
        value:
          i === 0 && prev.value !== finalMinLabel ? finalMinLabel : prev.value,
        nextElement:
          i === lastIndex && prev.nextElement !== finalMaxLabel
            ? finalMaxLabel
            : prev.nextElement,
      };
    });

  function financial(x) {
    return Number(parseFloat(x).toFixed(FixedDecimalPoints));
  }

  useEffect(() => {
    const x = [...xLabels];
    const p = [...points];
    const commonData = {
      yLabels: [...yLabels],
      xLabel: [null, ...x, null],
      points: [null, ...p, null],
    };

    setChartData(commonData);
  }, [xLabels, points, yLabels]);

  useEffect(() => {
    const chartInstance = Chart.getChart(chartId);
    if (chartInstance) {
      chartInstance.destroy();
    }
    new Chart(chartId, {
      type: "line",
      data: {
        labels: chartData?.xLabel,
        datasets: [
          {
            data: chartData?.points,
            borderWidth: 2,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        scales: {
          y: {
            max: finalMaxLabel,
            min: finalMinLabel,
            beginAtZero: true,
            grid: {
              display: false,
            },
            ticks: {
              beginAtZero: false,
              stepSize: steps,
              display: true,
              color: "#000000",
              font: {
                size: 10,
              },
              callback: function (value, index, values) {
                const floatedNumber = Number(
                  parseFloat(value).toFixed(FixedDecimalPoints)
                );
                if (
                  floatedNumber === finalMaxLabel ||
                  floatedNumber === finalMinLabel
                )
                  return floatedNumber;
                for (let label of filteredLabels) {
                  if (floatedNumber === label.value) return floatedNumber;
                }
              },
            },
          },
          x: {
            beginAtZero: false,
            grid: {
              display: false,
            },
            border: {
              display: false,
            },
            ticks: {
              display: true,
              color: "#000000",
              font: {
                size: 9,
              },
            },
          },
        },
        elements: {
          point: {
            radius: 4,
            backgroundColor: "#ffffff",
            borderWidth: 4,
            borderColor: "#182d5b",
            hoverRadius: 4,
            hoverBorderWidth: 4,
            hoverBorderColor: "#182d5b",
          },
          line: {
            borderColor: "rgba(112, 112, 112, 1)",
          },
        },
        animation: {
          duration: 0,
        },
      },
      plugins: filteredLabels.map((label, i, array) => ({
        id: chartId,
        beforeDraw: (chart, args, pluginOptions) => {
          const { ctx, chartArea, scales } = chart;
          ctx.save();
          ctx.fillStyle = label.color;
          ctx.fillRect(
            chartArea.left,
            scales.y.getPixelForValue(label.nextElement),
            chartArea.right - chartArea.left,
            scales.y.getPixelForValue(label.value) -
            scales.y.getPixelForValue(label.nextElement)
          );
        },
      })),
    });

    return () => {
      if (chartInstance) {
        chartInstance?.destroy();
      }
    };
  }, [
    chartId,
    chartData?.points,
    chartData?.xLabel,
    chartData.yLabels,
    finalMaxLabel,
    finalMinLabel,
    filteredLabels,
    decreasePercentage,
    increasePercentage,
    steps,
  ]);

  return <canvas id={chartId}></canvas>;
};

export default memo(BiomarkerGraph);
