import { SAMPLE_REPORT_SUCCESS } from "../actionTypes/SampleReportActionTypes";
import { GET_ORGAN_SUCCESS } from "../actionTypes/OrganSectoinActionTypes";
import { GET_INSIGHTS_SUCCESS } from "../actionTypes/InsightsActionTypes";
import axiosInstance from "../../axios/axiosInstance";
import {
  GET_DASHBOARD_FAILURE,
  GET_DASHBOARD_REQUEST,
} from "../actionTypes/DashboardActionTypes";

export const fetchSampleData = () => async (dispatch) => {
  dispatch({ type: GET_DASHBOARD_REQUEST });
  try {
    const response = await axiosInstance("/v1/getSampleReport", "POST", null);
    dispatch({
      type: SAMPLE_REPORT_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({ type: GET_DASHBOARD_FAILURE, payload: error.message });
  }
};

export const fetchOrganData = (data) => async (dispatch) => {
  dispatch({ type: GET_DASHBOARD_REQUEST });
  try {
    const response = await axiosInstance("/v1/getAnalysis", "POST", data);
    dispatch({
      type: GET_ORGAN_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({ type: GET_DASHBOARD_FAILURE, payload: error.message });
  }
};

export const fetchInsightsData = (data) => async (dispatch) => {
  dispatch({ type: GET_DASHBOARD_REQUEST });
  try {
    const response = await axiosInstance("/v1/getInsights", "POST", data);
    dispatch({
      type: GET_INSIGHTS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({ type: GET_DASHBOARD_FAILURE, payload: error.message });
  }
};
