import { configureStore } from "@reduxjs/toolkit";
import { applyMiddleware, combineReducers, compose } from "redux";
import thunk from "redux-thunk";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import SignInReducer from "./reducers/SignInReducer";
import SignUpReducer from "./reducers/SignUpReducer";
import DashboardReducer from "./reducers/DashboardReducer";
import SettingReducer from "./reducers/SettingsReducer";
import TrendsReportReducer from "./reducers/trendsReportReducer";
import AuthReducer from "./reducers/AuthReducer";
import GetBloodTestFirstReducer from "./reducers/GetBloodTestFirstReducer";
import firstStepReducer from "./reducers/firstStepReducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  signUp: SignUpReducer,
  signIn: SignInReducer,
  dashboard: DashboardReducer,
  settings: SettingReducer,
  trendsReport: TrendsReportReducer,
  accessToken: AuthReducer,
  getBloodTestFirst: GetBloodTestFirstReducer,
  firstStep: firstStepReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore(
  {
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  },
  composeEnhancers(applyMiddleware(thunk))
);

export const persistor = persistStore(store);
