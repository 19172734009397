import { GET_PROFILE_SUCCESS } from "../actionTypes/GetProfileActionTypes";
import axiosInstance from "../../axios/axiosInstance";
import { ADD_PROFILE_SUCCESS } from "../actionTypes/AddProfileActionTypes";
import {
  GET_DASHBOARD_FAILURE,
  GET_DASHBOARD_REQUEST,
  GET_PARENT_PROFILE_ID,
  GET_PROFILE_GRANTED_ACCESS_SUCCESS,
  GET_REVIEW_BLOOD_TEST_REPORT,
} from "../actionTypes/DashboardActionTypes";
import { getProfileInfo } from "./GetBloodTestFirstAction";
import { GET_BLOOD_TEST_FIRST_PID_SUCCESS, GET_BLOOD_TEST_FIRST_PID_SUCCESS_PROVIDER, GET_SEARCH_TEXT } from "../actionTypes/GetBloodTestFirstActionTypes";

export const fetchProfileGrantedData = (pId) => async (dispatch) => {
  dispatch({ type: GET_DASHBOARD_REQUEST });
  try {
    const response = await axiosInstance(
      "/v1/getProfilesGrantedAccessTo",
      "POST",
      pId
    );
    dispatch({
      type: GET_PROFILE_GRANTED_ACCESS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({ type: GET_DASHBOARD_FAILURE, payload: error.message });
  }
};

export const fetchProfileData = () => async (dispatch) => {
  dispatch({ type: GET_DASHBOARD_REQUEST });
  try {
    const response = await axiosInstance("/v1/getProfiles", "POST", null);
    const getDefaultProfile = response.data?.data?.profiles?.Items?.filter(
      (item) => item.isDefault?.S === "true"
    );

    const payload = {
      profileId: getDefaultProfile[0]?.ProfileID?.S,
    };
    const response2 = await axiosInstance(
      "/v1/getProfilesGrantedAccessTo",
      "POST",
      payload
    );

    let getName = [];
    let sharedName = [];
    dispatch({
      type: GET_DASHBOARD_REQUEST,
      payload: true,
    });
    console.log("getDefaultProfile[0]?.ProfileID?.S",getDefaultProfile[0]?.ProfileID?.S)
    if (response2?.data?.data) {
      for (const value of response2?.data?.data?.profileList) {
        const payloadData = {
          profileId: value?.profileId,
          parentProfileId: getDefaultProfile[0]?.ProfileID?.S,
        };
        const response = await axiosInstance(
          "/v1/getProfile",
          "POST",
          payloadData
        );
        sharedName.push(response?.data?.data);
      }
    }
    for (const iterator of response?.data?.data?.profiles?.Items) {
      const payloadData = {
        profileId: iterator?.ProfileID?.S,
      };
      const getProfileData = await axiosInstance(
        "/v1/getProfile",
        "POST",
        payloadData
      );
      const getBloodCount = {
        ...getProfileData.data.data,
        BloodTestCount: iterator.BloodTestCount,
      };
      getName.push(getBloodCount);
    }

    dispatch({
      type: GET_PROFILE_SUCCESS,
      payload: response.data.data,
    });
    dispatch({
      type: GET_PARENT_PROFILE_ID,
      payload: getDefaultProfile[0]?.ProfileID?.S,
    });
    dispatch({
      type: GET_PROFILE_GRANTED_ACCESS_SUCCESS,
      payload: response2.data.data,
    });
    dispatch({
      type: GET_REVIEW_BLOOD_TEST_REPORT,
      payload: [...getName, ...sharedName],
    });
  } catch (error) {
    dispatch({ type: GET_DASHBOARD_FAILURE, payload: error.message });
  }
};

// export const addProfileData = (data, token) => async (dispatch) => {
//   dispatch({ type: GET_DASHBOARD_REQUEST });
//   try {
//     const response = await axiosInstance(`v1/addProfile`, "post", data, token);
//     dispatch({
//       type: ADD_PROFILE_SUCCESS,
//       payload: response.data.data,
//     });
//     console.log("dataaaa", data,response)
//     let pId = response?.data?.data?.profileId
//   //   const firstName =
//   //   data?.name?.charAt(0).toUpperCase() + data?.name?.slice(1);
//   // const lastName =
//   //   data?.lName?.charAt(0).toUpperCase() + data?.lName?.slice(1);
//   //   let name = `${firstName} ${lastName}`
//   let name = data?.name
//     dispatch({
//       type: GET_BLOOD_TEST_FIRST_PID_SUCCESS,
//       payload: { pId, name },
//     });
//     dispatch(fetchProfileData());
//   } catch (error) {
//     dispatch({ type: GET_DASHBOARD_FAILURE, payload: error.message });
//   }
// };

export const addProfileData = (data, token) => async (dispatch) => {
  dispatch({ type: GET_DASHBOARD_REQUEST });
  try {
    const response = await axiosInstance(`v1/addProfile`, "post", data, token);
    dispatch({
      type: ADD_PROFILE_SUCCESS,
      payload: response.data.data,
    });
    let pId = response?.data?.data?.profileId
    let name = data?.name
    dispatch({
      type: GET_BLOOD_TEST_FIRST_PID_SUCCESS,
      payload: pId,
    });
    dispatch({
      type: GET_BLOOD_TEST_FIRST_PID_SUCCESS,
      payload: pId,
    });
    dispatch({
      type: GET_BLOOD_TEST_FIRST_PID_SUCCESS,
      payload: pId,
    });
    dispatch({
      type: GET_BLOOD_TEST_FIRST_PID_SUCCESS_PROVIDER,
      payload: name,
    });
    dispatch(fetchProfileData());
  } catch (error) {
    dispatch({ type: GET_DASHBOARD_FAILURE, payload: error.message });
  }
};

export const addSearchText = (data) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SEARCH_TEXT,
      payload: data,
    });
  } catch (error) {
    console.log(error.message)
  }
}