import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import settingStyle from "./settings.module.scss";
import { Box, Checkbox, withStyles } from "@material-ui/core";
import IconTextField from "../../components/common-textfield/IconTextField";
import { GrFormClose } from "react-icons/gr";
import { TfiEmail } from "react-icons/tfi";
import { useState } from "react";
import { useEffect } from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const checkBoxStyles = (theme) => ({
  root: {
    "&$checked": {
      color: "#122D5B !important",
    },
    "&.MuiIconButton-label": {
      background: "transparent !important",
    },
  },
  checked: {},
});

const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);
export default function ShareModal({
  openModal,
  handleClose,
  handleSubmit,
  handleChangeEmail,
  emails,
  openShareModal,
  title,
}) {
  function DisableButton() {
    if (
      (emails?.emailOne || emails?.emailTwo || emails?.emailThree) &&
      checked
    ) {
      return (
        <button onClick={handleSubmit} className={settingStyle.share_submitBtn}>
          Submit
        </button>
      );
    } else {
      return (
        <button
          onClick={handleSubmit}
          className={settingStyle.share_submitBtn_disable}
          disabled
        >
          Submit
        </button>
      );
    }
  }
  function DisableInviteButton() {
    if (emails?.emailOne || emails?.emailTwo || emails?.emailThree) {
      return (
        <button onClick={handleSubmit} className={settingStyle.share_submitBtn}>
          Invite
        </button>
      );
    } else {
      return (
        <button
          onClick={handleSubmit}
          className={settingStyle.share_submitBtn_disable}
          disabled
        >
          Invite
        </button>
      );
    }
  }
  useEffect(() => {
    if (!openShareModal) {
      setChecked(false);
    }
  }, [openShareModal]);
  const [checked, setChecked] = useState(false);
  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };
  return (
    <div>
      <Dialog
        open={openModal}
        TransitionComponent={Transition}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "20px",
            padding: "25px 35px",
          },
        }}
      >
        <Box className={settingStyle.share_closeBtn}>
          <GrFormClose size={"30px"} onClick={handleClose} />
        </Box>
        <h1 className={settingStyle.share_title}>
          {title ? "Invite Others" : "Share Result"}
        </h1>
        <p className={settingStyle.share_desc}>
          {title
            ? "Invite others who may benefit from detailed blood test interpretations with their contact info or email address."
            : "Share this person's blood test results with others by adding their email addresses:"}
        </p>
        <DialogContent
          sx={{
            padding: "20px 0 0 0",
            "& .MuiDialogContent-root": {
              padding: "20px 0 0 0",
            },
          }}
        >
          <Box
            className="App"
            sx={{
              display: "grid",
              gridGap: 15,
            }}
          >
            <IconTextField
              iconStart={<TfiEmail sx={{ color: "#8A8A8A" }} />}
              placeholder="Enter email address"
              type="email"
              name="emailOne"
              onChange={handleChangeEmail}
              // onBlur={handleBlur}
              // value={values.email}
              // className={
              //   touched.email && errors.email ? classes.inputError : ""
              // }
            />
            <IconTextField
              iconStart={<TfiEmail sx={{ color: "#8A8A8A" }} />}
              placeholder="Enter email address"
              type="email"
              name="emailTwo"
              onChange={handleChangeEmail}
              // onBlur={handleBlur}
              // value={values.email}
              // className={
              //   touched.email && errors.email ? classes.inputError : ""
              // }
            />
            <IconTextField
              iconStart={<TfiEmail sx={{ color: "#8A8A8A" }} />}
              placeholder="Enter email address"
              type="email"
              name="emailThree"
              onChange={handleChangeEmail}
              // onBlur={handleBlur}
              // value={values.email}
              // className={
              //   touched.email && errors.email ? classes.inputError : ""
              // }
            />
          </Box>
          <div className={settingStyle.checkbox_content}>
            {!title && (
              <>
                <CustomCheckbox
                  sx={{
                    marginLeft: "6px",
                    background: "transparent",
                    "MuiIconButton-label": {
                      background: "transparent",
                    },
                  }}
                  checked={checked}
                  onChange={handleCheckboxChange}
                />
                <p>
                  I consent to AHA sharing my Blood Test Summary Reports with
                  the individuals and/or entities I have listed as indicated,
                  and for no other puposes, pursuant to applicable HIPAA
                  regulations.
                </p>
              </>
            )}
          </div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
            //   style={{
            //     cursor: checkIsDisabled(values, errors)
            //       ? "not-allowed"
            //       : "pointer",
            //   }}
          >
            {/* <button
              onClick={handleSubmit}
              className={settingStyle.share_submitBtn}
            >
              Submit
            </button> */}
            {title ? <DisableInviteButton /> : <DisableButton />}

            {/* <CommonButton
              color="primary"
              // loading={props.loading}
              // disabled={checkIsDisabled(values, errors) || props.loading}
              title={"Submit"}
              onClick={handleSubmit}
              className={settingStyle.share_submitBtn}
            // className={`${password.submitBtn}`}
            /> */}
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
