import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import BreadCrumbs, {
    BackButton, NextButton,
} from "../../../components/breadcrumbs/BreadCrumbs";
import Header from "../../../components/header/Header";
import Insights from "../../../components/insighṭs/Insights";
import Loader from "../../../components/loader/Loader";
import {
    fetchGetBloodOrganData,
    getBloodTestValues,
    getProfileInfo,
} from "../../../redux/actions/GetBloodTestFirstAction";
import fourthStepStyles from "../FourthStep/fourthStep.module.scss";
import dashboardCss from "../../dashboard/dashboard.module.scss";
import { useDispatch, useSelector } from "react-redux";
import BiomarkerSection from "../../../components/biomarker-section/BiomarkerSection";
import { organOverviewMock } from "../../../json/mockData";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { deleteBloodTest } from "../../../redux/actions/GetBloodTestAction";
import ConfirmationModal from "../../settings/ConfirmationModal";
import fifthStepStyles from '../FifthStep/fifthstep.module.scss'
import backArrow from '../../../assets/svg/backArrow.svg'
import nextArrow from '../../../assets/svg/nextArrow.svg'
import PatientInfo from "../../../components/patient-info/PatientInfo";

function SixthStep({ setCurrentStep, profileId, bloodTestId, resultId }) {
    const breadCrumbsArr = [
        {
            title: "Home",
            link: "/home",
        },
        {
            title: "Review Blood Test ",
            link: null,
        },
    ];
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { fourthPage, bloodTestValues, firstPage, loading } = useSelector(
        (state) => state.getBloodTestFirst
    );
    const { biomarkerInfo, reviewBloodTestData, parentProfileId, userInfo } = useSelector(
        (state) => state.dashboard
    );
    const [openModal, setOpenModal] = useState(false)
    const [isCompare, setIsCompare] = useState(false);

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    // compare date function
    const compareDate = () => {
        const convertDate = 1683797069;
        const date2 = moment(
            bloodTestValues?.bloodTestData?.BloodTestStartDate?.S
        ).unix();
        if (date2 > convertDate) {
            setIsCompare(true);
        } else {
            setIsCompare(false);
        }
    };
    const handleCloseDeleteModal = () => {
        setOpenModal(false)
    }
    const handleCloseDelete = async (pId, bId, rId) => {
        const payload = {
            profileId: pId,
            bloodTestId: bId,
            resultId: rId
        }
        dispatch(deleteBloodTest(payload))
        setOpenModal(false)
        navigate('/home')
    }

    useEffect(() => {
        let parentProfileIdData;
        if (reviewBloodTestData.length > 0) {
            for (const iterator of reviewBloodTestData) {
                if (
                    iterator?.profile?.ProfileID?.S ===
                    (profileId ? profileId : firstPage?.startBloodTestData?.profileId) &&
                    iterator.isShared === true
                ) {
                    parentProfileIdData = parentProfileId;
                }
            }
        }
        const profilePayload = {
            profileId: profileId
                ? profileId
                : firstPage?.startBloodTestData?.profileId,
            parentProfileId: parentProfileIdData ? parentProfileIdData : "",
        };
        const data = {
            profileId: profileId
                ? profileId
                : firstPage?.startBloodTestData?.profileId,
            bloodTestId: bloodTestId
                ? bloodTestId
                : firstPage?.startBloodTestData?.bloodTestId,
            parentProfileId: parentProfileIdData ? parentProfileIdData : "",
        };
        compareDate();
        dispatch(getBloodTestValues(data));
        dispatch(getProfileInfo(profilePayload));
        if (resultId) {
            const payload = {
                profileId: profileId,
                bloodTestId: bloodTestId,
                resultId: resultId,
                parentProfileId: parentProfileIdData ? parentProfileIdData : "",
            };
            dispatch(fetchGetBloodOrganData(payload));
        }
        dispatch(getBloodTestValues(data));
        dispatch(getBloodTestValues(data));
        dispatch(getBloodTestValues(data));
        dispatch(getBloodTestValues(data));
    }, []);

    // biomarker function
    const getData = (bloodName) => {
        if (bloodTestValues?.bloodTestData) {
            const sampleReportValue = bloodTestValues?.bloodTestData?.BloodTestValues && JSON.parse(
                bloodTestValues?.bloodTestData?.BloodTestValues?.S
            );
            const allKeys = sampleReportValue && Object.keys(sampleReportValue);
            const getBlood = allKeys?.find((item) => item === bloodName);
            if (
                sampleReportValue[getBlood] &&
                sampleReportValue[getBlood] !== null &&
                sampleReportValue[getBlood] !== undefined
            ) {
                return sampleReportValue[getBlood];
            } else {
                return "?";
            }
        }
    };


    return (
        <div>
            <Header showNavigations={false} />
            <div className={fourthStepStyles.contaninerBg}>
                <Loader loading={loading} />
                <div className={fifthStepStyles.breadCrumb}>
                    <div>
                        <BreadCrumbs
                            breadCrumbsArr={breadCrumbsArr}
                            // isBackBtnVisible={true}
                            isNextBtnVisible={false}
                            handleBack={() => navigate("/home")}
                        // backButtonText={"Back to homepage"}
                        />
                    </div>
                    <div>
                        <div className={fifthStepStyles.btns}>
                            <div className={fifthStepStyles.btnsArrow} onClick={() => setCurrentStep(5)} >
                                <div>
                                    <img src={backArrow} alt="backarrow" />
                                </div>
                                <div>
                                    Back to Summary
                                </div>
                            </div>
                            <div className={fifthStepStyles.btnsArrow} onClick={() => navigate('/home')}>
                                <div>
                                    Return to Homepage
                                </div>
                                <div>
                                    <img src={nextArrow} alt="backarrow" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Container
                    style={{
                        maxWidth: "1440px",
                        paddingInline: "30px",
                        opacity: loading ? 0.4 : 1,
                        paddingTop: resultId ? "1px" : "",
                        pointerEvents: loading ? "none" : "all",
                    }}
                >   <div style={{ marginBottom: '31px' }}>
                        {resultId ? (
                            <div className={fourthStepStyles.back_button}>
                                <p className={fifthStepStyles.report_Title}>
                                    Blood Test Biomarker Overview
                                </p>
                            </div>
                        ) : (
                            <p className={fourthStepStyles.patientInfo_Title}>
                                Patient Information
                            </p>
                        )}
                        <PatientInfo
                            name={fourthPage?.profileInfo?.profile?.FullName?.S}
                            dob={fourthPage?.profileInfo?.profile?.DateOfBirth?.S}
                            gen={fourthPage?.profileInfo?.profile?.Sex?.S}
                            testDate={bloodTestValues?.bloodTestData?.BloodTestDate?.S}
                            // inter={`Results are based on inputted values and interpreted for functionally optimal levels on ${moment(
                            //     bloodTestValues?.bloodTestData?.BloodTestStartDate?.S
                            // ).format("ll")} by Advanced Health Academy`}
                            inter={`Results are based on inputted values and interpreted on ${moment(
                                bloodTestValues?.bloodTestData?.BloodTestStartDate?.S
                            ).format("ll")}`}
                        />
                    </div>

                    <section className={dashboardCss.biomarkerSection_role}>
                        <BiomarkerSection
                            biomarkerInfo={biomarkerInfo}
                            getData={getData}
                            userRole={'Provider'}
                            gender={fourthPage?.profileInfo?.profile?.Gender?.S}
                        />
                    </section>
                    {!resultId && (
                        <div className={fourthStepStyles.BottomNextBtn}>
                            <BackButton
                                onClick={() => navigate("/home")}
                                style={{ padding: "11px 35px" }}
                            >
                                Back to homepage
                            </BackButton>

                            {/* <NextButton onClick={() => setCurrentStep(3)}>Next</NextButton> */}
                        </div>
                    )}
                    <ConfirmationModal
                        openModal={openModal}
                        handleClose={handleCloseDeleteModal}
                        content="Deleting the report cannot be reversed."
                        handleYes={() => handleCloseDelete(profileId, bloodTestId, resultId)}
                        button={true}
                        pId={profileId}
                        bId={bloodTestId}
                        rId={resultId}
                        status={"delete"}
                    />
                </Container>
                <div className={fourthStepStyles.bottomButton}>
                    <BackButton style={{ padding: "11px 35px" }} onClick={() => setCurrentStep(5)} >
                        Back to Summary
                    </BackButton>
                    <div>
                        <BackButton
                            onClick={() => setOpenModal(true)}
                            style={{ padding: "11px 35px" }}
                        >
                            Delete Report
                        </BackButton>
                    </div>
                    <div>
                        <NextButton
                            onClick={() => navigate("/home")}
                            style={{ padding: "11px 35px" }}
                        >
                            Return to homepage
                        </NextButton>
                    </div>

                </div>
            </div>

        </div>
    );
}

export default SixthStep;
