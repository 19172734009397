import * as React from "react";
import PropTypes from "prop-types";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import biomarkerCss from "./biomarker.module.scss";
import { useState } from "react";
import { useEffect } from "react";
import { getNumber } from "../../helper";

function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
};

const PrettoSlider = styled(Slider)({
  color: "#4C942A",
  height: "3px !important",
  position: "relative",
  padding: "13px 0 !important",
  "@media (min-width: 768px) and (max-width: 1024px)": {
    padding: "20px 0 !important",
  },
  "& .MuiSlider-track": {
    background: "none",
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 0,
    width: 0,
    backgroundColor: "#fff",
    border: "none",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-markLabel": {
    top: "20px",
    color: "#1D1D1D",
    fontFamily: "Poppins-Regular",
    fontWeight: "normal",
    "@media (min-width: 768px) and (max-width: 1024px)": {
      top: "25px",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 6,
    top: "-8.1px",
    background: "unset",
    fontFamily: "Poppins-Regular",
    fontWeight: "normal",
    padding: 0,
    width: 15,
    height: 15,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#4C942A",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": {
      content: '""',
      display: "block",
      width: "1px",
      height: "7px",
      background: "#4C942A",
      left: "0px",
      top: "52%",
      position: "absolute",
    },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
  "& .MuiSlider-rail": {
    background: "#4C942A",
    opacity: 1,
    border: "none",
    transform: "none",
    position: "unset",
  },
  "& .MuiSlider-mark": {
    width: 0,
    height: 0,
  },
});

export default function CustomizedSlider({
  Rmin,
  Omin,
  Gmin,
  Gmax,
  Omax,
  Rmax,
  mainValue,
  setFontColor,
  userRole
  // color,
}) {
  const marks = [
    {
      value: getNumber(Gmin),
      label: `${getNumber(Gmin)}`,
    },
    {
      value: getNumber(Gmax),
      label: `${getNumber(Gmax)}`,
    },
  ];
  const [value, setValue] = useState(mainValue);
  const [green, setGreen] = useState();
  const [red1, setRed1] = useState();
  const [orange1, setOrange1] = useState();
  const [orange2, setOrange2] = useState();
  const [red2, setRed2] = useState();
  const handleChange = (e) => {
    console.log("eeeeeee", e);
  };
  // if (getFontColor) {
  //   getFontColor(fontColor);
  // }
  const getActualSlider = () => {
    if (
      Number(mainValue) >= getNumber(Gmin) &&
      Number(mainValue) <= getNumber(Gmax)
    ) {
      setValue(mainValue);
      setGreen(Number(mainValue));
      setFontColor("#4C942A");
      setOrange1("");
      setOrange2("");
      setRed1("");
      setRed2("");
    } else if (
      Number(mainValue) < getNumber(Gmin) &&
      Number(mainValue) >= getNumber(Omin)
    ) {
      setOrange1(Number(mainValue));
      setFontColor("#E68413");
      setOrange2("");
      setRed1("");
      setRed2("");
      setValue("")
    } else if (
      Number(mainValue) < getNumber(Omin)
      //  &&
      // Number(mainValue) <= getNumber(Rmin)
    ) {
      setRed1(Number(mainValue));
      setFontColor("#FF0000");
      setOrange1("");
      setOrange2("");
      setRed2("");
      setValue("")
    } else if (
      Number(mainValue) > getNumber(Gmax) &&
      Number(mainValue) <= getNumber(Omax)
    ) {
      setOrange2(Number(mainValue));
      setFontColor("#E68413");
      setRed1("");
      setOrange1("");
      setRed2("");
      setValue("")
    } else if (Number(mainValue) > getNumber(Omax)) {
      setRed2(Number(mainValue));
      setFontColor("#FF0000");
    } else {
      setOrange1("");
      setRed1("");
      setOrange2("");
      setRed2("");
      setValue("")
      return "";
    }
  };
  useEffect(() => {
    getActualSlider();
    // color(fontColor);
  }, [mainValue, Gmin, Gmax, Omin, Omax, Rmin, Rmax]);
  return (
    <div className={biomarkerCss.slider_main}>
      <Box>
        <div className={biomarkerCss.slider_inner}>
          <div className={biomarkerCss.slider_first}>
            <span
              className={userRole == 'Provider' ?  biomarkerCss.symbol_role : biomarkerCss.symbol}
              style={
                (red1 && red1 !== undefined) || red1 === 0
                  ? { display: "" }
                  : { display: "none" }
              }
            >
              <span className={biomarkerCss.symbolText}>
                <span className={biomarkerCss.innersymbolText}>{red1}</span>
              </span>
            </span>
          </div>
        </div>
      </Box>
      <div className={biomarkerCss.slider_inner}>
        <div className={biomarkerCss.slider_second}>
          <span
            className={userRole == 'Provider' ? biomarkerCss.symbol_o1_role : biomarkerCss.symbol_o1}
            style={
              (orange1 && orange1 !== undefined) || orange1 === 0
                ? { display: "" }
                : { display: "none" }
            }
          >
            <span className={biomarkerCss.symbolText_o1}>
              <span className={biomarkerCss.innersymbolText_o1}>{orange1}</span>
            </span>
          </span>
        </div>
      </div>
      <Box sx={{ width: 72.5, marginRight: "3px" }}>
        <div style={{ display: "" }}>
          <PrettoSlider
            value={Number(value)}
            onChange={handleChange}
            min={getNumber(Gmin)}
            max={getNumber(Gmax)}
            disabled
            focusVisible={false}
            disableSwap
            valueLabelDisplay="on"
            marks={marks}
            sx={{
              "& .MuiSlider-valueLabel": {
                display:
                  Number(mainValue) >= getNumber(Gmin) &&
                  Number(mainValue) <= getNumber(Gmax)
                    ? ""
                    : "none",
              },
            }}
          />
        </div>
      </Box>
      <div className={biomarkerCss.slider_inner}>
        <div className={biomarkerCss.slider_second}>
          <span
            className={userRole == 'Provider'? biomarkerCss.symbol_o2_role : biomarkerCss.symbol_o2}
            style={
              (orange2 && orange2 !== undefined) || orange2 === 0
                ? { display: "" }
                : { display: "none" }
            }
          >
            <span className={biomarkerCss.symbolText_o2}>
              <span className={biomarkerCss.innersymbolText_o2}>{orange2}</span>
            </span>
          </span>
        </div>
      </div>
      <div className={biomarkerCss.slider_inner}>
        <div className={biomarkerCss.slider_first}>
          <span
            className={userRole == 'Provider' ? biomarkerCss.symbol_r2_role : biomarkerCss.symbol_r2}
            style={
              (red2 && red2 !== undefined) || red2 === 0
                ? { display: "" }
                : { display: "none" }
            }
          >
            <span className={biomarkerCss.symbolText_r2}>
              <span className={biomarkerCss.innersymbolText_r2}>{red2}</span>
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}
