import { Typography } from "@material-ui/core";
import React, { useState, memo, useEffect, useMemo } from "react";
import organTrendsStyle from "./organTrendsReport.module.scss";
import { ReactComponent as ExpandedIcon } from "../../../../assets/svg/trendsReport/expanded.svg";

import BrainImage from "../../../../assets/svg/BodyParts/Brain.svg";
import ColonImage from "../../../../assets/svg/BodyParts/Colon.svg";
import GallbladderImage from "../../../../assets/svg/BodyParts/Gall-Bladder.svg";
import HeartImage from "../../../../assets/svg/BodyParts/Heart.svg";
import KidneyImage from "../../../../assets/svg/BodyParts/Kidney.svg";
import LiverImage from "../../../../assets/svg/BodyParts/Liver.svg";
import LungsImage from "../../../../assets/svg/BodyParts/Lungs.svg";
import PancreasImage from "../../../../assets/svg/BodyParts/Pancreas.svg";
import SmallIntestineImage from "../../../../assets/svg/BodyParts/Small-Intestine.svg";
import SpleenImage from "../../../../assets/svg/BodyParts/Spleen.svg";
import StomachImage from "../../../../assets/svg/BodyParts/Stomach.svg";
import ThyroidImage from "../../../../assets/svg/BodyParts/Thyroid.svg";
import Graph from "../../organ-chart";
import { organPlugin } from "../../utils";

const Accordion = (props) => {
  const {
    organName,
    reportLevel,
    description,
    heading,
    reportLevelColor,
    xLabel,
    points,
    handlePagination,
    selected,
    openAccordion,
    setOpenAccordion,
  } = props;
  const [utils, setUtils] = useState({
    rotate: 0,
    length: 0,
    hidden: true,
    text: `${description?.substr(0, 100).trim()}...`,
  });
  const icons = useMemo(() => {
    return {
      Liver: LiverImage,
      "Kidney & Adrenal Glands": KidneyImage,
      Pancreas: PancreasImage,
      "Small Intestine": SmallIntestineImage,
      Heart: HeartImage,
      Brain: BrainImage,
      "Gall-Bladder": GallbladderImage,
      Lungs: LungsImage,
      Spleen: SpleenImage,
      Colon: ColonImage,
      Stomach: StomachImage,
      Thyroid: ThyroidImage,
    };
  }, []);
  const toggleAccordion = () => {
    if (openAccordion?.includes(organName)) {
      const arr = [...openAccordion];
      const indexToRemove = openAccordion.indexOf(organName);
      arr.splice(indexToRemove, 1);
      setOpenAccordion(arr);
    } else {
      setOpenAccordion((prev) => [...prev, organName]);
    }
  };
  const handleMoreCLick = () => {
    setUtils((prev) => {
      return {
        ...prev,
        hidden: !prev.hidden,
        text:
          prev.hidden === true
            ? description
            : `${description?.substr(0, 100).trim()}...`,
      };
    });
  };
  useEffect(() => {
    if (selected === "Optimized") {
      if (reportLevel === "Dysfunctional") {
        setOpenAccordion((prev) => [...prev, organName]);
      }
    }
  }, [selected]);
  return (
    <div className={organTrendsStyle.accordionWrapper}>
      <div
        className={`${organTrendsStyle.accordion} ${
          openAccordion?.includes(organName)
            ? organTrendsStyle.roundBorder
            : organTrendsStyle.angleBorder
        }`}
        onClick={toggleAccordion}
      >
        <div className={organTrendsStyle.organIcon}>
          <img src={icons[organName]} alt="" />
        </div>
        <div className={organTrendsStyle.accordionHeadings}>
          <Typography className={organTrendsStyle.organName}>
            {organName}
          </Typography>
          <div
            className={organTrendsStyle.verticalLine}
            style={{ backgroundColor: reportLevelColor }}
          ></div>
          <Typography
            className={organTrendsStyle.reportLevel}
            style={{ color: reportLevelColor }}
          >
            {reportLevel}
          </Typography>
        </div>
        <button
          className={`${organTrendsStyle.expandedIcon} 
          ${
            openAccordion?.includes(organName)
              ? organTrendsStyle.expanded
              : organTrendsStyle.closed
          }`}
        >
          <ExpandedIcon />
        </button>
      </div>
      <div
        className={`${organTrendsStyle.AccordionDetails} 
        ${
          openAccordion?.includes(organName)
            ? organTrendsStyle.openAccordion
            : organTrendsStyle.closeAccordion
        }`}
      >
        <div className={organTrendsStyle.organGraphWrapper}>
          <div className={organTrendsStyle.yAxisLabels}>
            <span className={organTrendsStyle.DyFunctional}>Dysfunctional</span>
            <span className={organTrendsStyle.Problematic}>Problematic</span>
            <span className={organTrendsStyle.Stressed}>Stressed</span>
            <span className={organTrendsStyle.Good}>Good</span>
          </div>
          <div className={organTrendsStyle.paginationButtons}>
            <div>
              <button
                className={organTrendsStyle.backward}
                onClick={() => handlePagination("organ", "previous")}
              >
                {"<<"}
              </button>
              <button
                className={organTrendsStyle.forward}
                onClick={() => handlePagination("organ", "next")}
              >
                {">>"}
              </button>
            </div>
          </div>
          {openAccordion && openAccordion?.includes(organName) && (
            <Graph
              chartId={organName}
              yTicksDisplay={false}
              min={-0.5}
              max={11.5}
              xAxisFontSize={14}
              pointWidth={5}
              plugins={organPlugin}
              points={points}
              xLabels={xLabel}
              yLabels={{}}
            />
          )}
        </div>
        <div className={organTrendsStyle.organInformation}>
          <h4>{heading}</h4>
          <p>{utils?.text}</p>
          <button onClick={handleMoreCLick}>
            {utils.hidden && utils.text.length > 100
              ? "Read More"
              : "Read less"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default memo(Accordion);
