import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SignUpModal from "../../components/modal/sign-up/SignUpModal";
import firebase from "../../config/FirebaseConfig";
import { getErrorMessage, toastStyle } from "../../helper";
import OtpScreen from "../otp-screen/OtpScreen";
import { fetchSignInData } from "../../redux/actions/SignInAction";
import { useDispatch, useSelector } from "react-redux";
import SocialLogin from "../../components/modal/social-login/SocialLogin";

function SignUpPage({ numberOfDigits = 6 }) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [signUp] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    password: "",
    userToken: "",
    lName: "",
    deviceId: "12345",
    locationPointer: "",
    notificationId: "",
    deviceSpecifications: "",
    provider: "",
  });
  const [loading, setLoading] = useState(false);
  const [phoneNumberForOTP, setPhoneNumberForOTP] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [phoneErrorMessage, setPhoneErrorMessage] = useState("");
  const [emailExist, setEmailExist] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [openOtp, setOpenOtp] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [otp, setOtp] = useState(Array(numberOfDigits).fill(""));
  const otpInputRefs = useRef([]);
  const [verificationId, setVerificationId] = useState("");
  const [signUpApi, setSignUpApi] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [otpErrors, setOtpErrors] = useState("");
  const [signupLoading, setSignupLoading] = useState(false);
  const [socialModal, setSocialModal] = useState(false);
  const {flag} = useSelector((state) => state.signIn)
  const [payloadDataSet,setPayloadDataSet] = useState()
  const [providerIdSet,setProviderIdSet] = useState()
  const [socialData, setSocialData] = useState({
    mobileNumber: "",
  })
  const dispatch = useDispatch()
  const handleClose = () => {
    setOpen(false);
    setSignupLoading(false);
  };
  const handleCloseOtp = () => setOpenOtp(false);

  // handle sign up button
  const handleSubmit = async (values) => {
    setPhoneNumberForOTP(`+${values.mobileNumber}`);
    setSignUpApi(values);
    const valid = await firebase
      .auth()
      .fetchSignInMethodsForEmail(values.email);
    if (valid[0] === "password" || !values.mobileNumber) {
      setEmailExist(
        "Email already exists. Please use a different email or log in."
      );
      setPhoneError(true);
      setPhoneErrorMessage("mobile number is required");
    } else {
      setOpenOtp(true);
      setEmailExist("");
      handleSendOTP(`+${values.mobileNumber}`);
      setSignupLoading(true);
      handleClose();
    }
  };

  const handleSubmitSocial = async (values) => {
  
    try {
      const payload = {
        locationPointer: values.locationPointer,
        MobNumber: values.phoneNumberWithoutCountryCode
      }
      // console.log("valuess", values)
      // dispatch(setSocialDataLogin(payload))
    
      dispatch(fetchSignInData(payloadDataSet, providerIdSet,values,payload,flag));
      setSocialModal(false)
    } catch (error) {
      console.log(error)
    }
  };
  const handleSendOTP = (number) => {
    const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      { size: "invisible" }
    );
    const phoneProvider = new firebase.auth.PhoneAuthProvider();

    phoneProvider
      .verifyPhoneNumber(number, recaptchaVerifier)
      .then((verificationId) => {
        setVerificationId(verificationId);
        // setOpenOtp(true);
        toast.success("Verification code sent!", toastStyle);
      })
      .catch((error) => {
        setOtpErrors(getErrorMessage(error.message));
      });
  };

  const handleOpenModal = () => {
    navigate("/sign-in", { replace: true });
  };

  useEffect(() => {
    setOpen(true);
  }, []);
  const checkIsDisabled = () => {
    return otp.every(Boolean) ? false : true;
  };
  const handleChangeOtp = (index, value) => {
    setOtp((prevOtp) => {
      const newOtp = [...prevOtp];
      newOtp[index] = value;
      return newOtp;
    });

    // Move focus to the next input field
    if (value !== "") {
      if (index < numberOfDigits - 1) {
        otpInputRefs.current[index + 1].focus();
      }
      // else {
      //   // If last digit, trigger the submit callback
      //   const otpCode = otp.join('');
      //   // onOtpSubmit(otpCode);
      // }
    }
  };
  useEffect(() => {
    setVerificationCode(otp.join(""));
  }, [handleChangeOtp]);

  const handleKeyDown = (e, index) => {
    // Handle backspace to navigate to the previous input field
    if (e.key === "Backspace" && index > 0 && otp[index] === "") {
      otpInputRefs.current[index - 1].focus();
    }
  };
   // handle google login
   const handleGoogleLogin = async () => {
    try {
      var provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope("profile");
      provider.addScope("email");
      const response = await firebase.auth().signInWithPopup(provider);
      const payload = {
        email: response.user?.multiFactor?.user?.email
          ? response.user?.multiFactor?.user?.email
          : response.additionalUserInfo?.profile?.email,
        mobileNumber: response?.user.multiFactor?.user?.phoneNumber
          ? response.user.multiFactor?.user?.phoneNumber
          : "",
        locationPointer: "",
        notificationId: "",
        deviceSpecifications: "",
        provider: response.user.multiFactor?.user?.providerId
          ? response.user.multiFactor?.user?.providerId
          : response.additionalUserInfo?.providerId,
        userToken: response.user?.multiFactor?.user?.accessToken,
        deviceId: "12345",
        name: response.user.multiFactor?.user?.displayName
          ? response.user.multiFactor?.user?.displayName
          : response.additionalUserInfo?.profile?.name,
      };
      const providerId = response?.additionalUserInfo?.providerId;
      setPayloadDataSet(payload)
      setProviderIdSet(providerId)
      dispatch(fetchSignInData(payload, providerId));
      
   } catch ({ code, email }) {
      if (code === "auth/account-exists-with-different-credential") {
        firebase
          .auth()
          .fetchSignInMethodsForEmail(email)

          .then((providers) => {
            if (providers.includes("google.com")) {
              handleGoogleLogin();
            }
          });
      }
    }
  };
  // handle facebook login
  const handleFacebookLogin = async () => {
    try {
      const provider = new firebase.auth.FacebookAuthProvider();
      provider.setCustomParameters({
        display: "popup",
      });
      provider.addScope("email");
      const result = await firebase.auth().signInWithPopup(provider);
      const payload = {
        email: result.user?.multiFactor?.user?.providerData[0]?.email,
        mobileNumber: result?.user.multiFactor?.user?.phoneNumber
          ? result.user.multiFactor?.user?.phoneNumber
          : "",
        locationPointer: "",
        notificationId: "",
        deviceSpecifications: "",
        provider: result.user.multiFactor?.user?.providerId,
        userToken: result.user?.multiFactor?.user?.accessToken,
        deviceId: "12345",
        name: result.user.multiFactor?.user?.displayName,
      };
      const providerId = result?.additionalUserInfo?.providerId;
      setPayloadDataSet(payload)
      setProviderIdSet(providerId)
      dispatch(fetchSignInData(payload, providerId));
    } catch (error) {
      console.log("facebook", error);
      // toast.error(error.message, toastStyle);
    }
  };

  // handle apple login
  const handleAppleLogin = async () => {
    try {
      const provider = new firebase.auth.OAuthProvider("apple.com");
      provider.addScope("email");
      provider.addScope("name");
      const result = await firebase.auth().signInWithPopup(provider);
      const payload = {
        email: result?.additionalUserInfo.profile?.email,
        mobileNumber: result?.user.multiFactor?.user?.phoneNumber
          ? result.user.multiFactor?.user?.phoneNumber
          : "",
        locationPointer: "",
        notificationId: "",
        deviceSpecifications: "",
        provider: result.additionalUserInfo?.providerId,
        userToken: result.user?.multiFactor?.user?.accessToken,
        deviceId: "12345",
        name: result.user.multiFactor?.user?.displayName,
      };
      const providerId = result?.additionalUserInfo?.providerId;
      setPayloadDataSet(payload)
      setProviderIdSet(providerId)
      dispatch(fetchSignInData(payload, providerId));
    } catch (error) {
      // toast.error(error.message, toastStyle);
      console.log("apple", error);
    }
  };
  return (
    <div id="login-page-BgImage">
      <div id="recaptcha-container"></div>
      <SignUpModal
        open={open}
        setSignupLoading={setSignupLoading}
        signupLoading={signupLoading}
        onClose={handleClose}
        onClick={handleClose}
        onSubmit={handleSubmit}
        loading={loading}
        disabled={loading}
        onSignInClick={handleOpenModal}
        initialData={signUp}
        titleName={"Create Your Account"}
        signUpName={"Sign Up"}
        phoneError={phoneError}
        phoneErrorMessage={phoneErrorMessage}
        setPhoneError={setPhoneError}
        setPhoneErrorMessage={setPhoneErrorMessage}
        emailExist={emailExist}
        setEmailExist={setEmailExist}
        handleGoogleLogin={handleGoogleLogin}
        onFacebookLogin={handleFacebookLogin}
        onAppleLogin={handleAppleLogin}
      />
      <OtpScreen
        otpLoading={otpLoading}
        setOtpLoading={setOtpLoading}
        setSignupLoading={setSignupLoading}
        checkIsDisabled={checkIsDisabled}
        handleOpenOtp={openOtp}
        handleCloseOtp={handleCloseOtp}
        setOpenOtp={setOpenOtp}
        handleChangeOtp={handleChangeOtp}
        handleKeyDown={handleKeyDown}
        otp={otp}
        otpInputRefs={otpInputRefs}
        verificationCode={verificationCode}
        verificationId={verificationId}
        setOpenModal={setOpenModal}
        signUpApi={signUpApi}
        phoneNumberForOTP={phoneNumberForOTP}
        otpErrors={otpErrors}
        setOtpErrors={setOtpErrors}
        handleSendOTP={handleSendOTP}
      />
         <SocialLogin
        open={socialModal}
        onSubmit={handleSubmitSocial}
        initialData={socialData}
      />
    </div>
  );
}

export default SignUpPage;
