import {
  GET_BLOOD_TEST_ERROR,
  GET_BLOOD_TEST_SUCCESS,
} from "../actionTypes/firstStepActionTypes";

const INITIAL_STATE = {
  error: null,
  bloodTests: [],
  bloodTestLoading: true,
};

const firstStepReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_BLOOD_TEST_SUCCESS:
      return {
        error: null,
        bloodTestLoading: false,
        bloodTests: action.payload,
      };
    case GET_BLOOD_TEST_ERROR:
      return {
        ...state,
        bloodTestLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default firstStepReducer;
