import React, { useEffect, useState } from "react";
import { Card, Container } from "@mui/material";
import Header from "../../../components/header/Header";
import Loader from "../../../components/loader/Loader";
import BreadCrumbs, {
  NextButton,
  BackButton,
} from "../../../components/breadcrumbs/BreadCrumbs";
import CommonDropdown from "../../../components/CustomDropdown/CommonDropdown";
import secondStepStyles from "./secondStep.module.scss";
import { GenericInputField } from "../../../components/common-textfield/GenericInputField";
import { useDispatch, useSelector } from "react-redux";
import {
  getBloodTestScan,
  getProfileInfo,
  updateProfile,
} from "../../../redux/actions/GetBloodTestFirstAction";
import { GET_BLOOD_TEST_FIRST_REQUEST } from "../../../redux/actionTypes/GetBloodTestFirstActionTypes";

const SecondStep = ({ setCurrentStep }) => {
  const breadCrumbsArr = [
    {
      title: "Home",
      link: "/home",
    },
    {
      title: "Get Blood test Interpreted",
      link: null,
    },
  ];

  const [isActive, setIsActive] = useState(false);
  const [selected, setIsSelected] = useState("feet");
  const [isActiveLbs, setIsActiveLbs] = useState(false);
  const [selectedLbs, setIsSelectedLbs] = useState("lbs");
  const [loadingTimer, setLoadingTimer] = useState(true);
  const dispatch = useDispatch();
  const [inputFields, setInputFields] = useState({
    allergies: [{ allergies: "" }],
    diseases: [{ disease: "", when: "", comments: "" }],
    medications: [{ medication: "", dosage: "", frequency: "" }],
    supplements: [{ supplement: "", dosage: "", frequency: "" }],
  });

  const { pId } = useSelector((state) => state.getBloodTestFirst);
  const { firstPage, secondPage, fourthPage, loading } = useSelector(
    (state) => state.getBloodTestFirst
  );
  const { parentProfileId, reviewBloodTestData } = useSelector(
    (state) => state.dashboard
  );
  const [valueSet, setValueSet] = useState({
    height:
      secondPage?.updateProfile?.updatedAttributes?.Height?.split("'")[0] || "",
    weight: secondPage?.updateProfile?.updatedAttributes?.Weight || "",
    inches:
      secondPage?.updateProfile?.updatedAttributes?.Height?.split("'")[1] || "",
  });
  const handleChange = (section, index, field, value) => {
    setInputFields((prevFields) => ({
      ...prevFields,
      [section]: prevFields[section].map((item, i) =>
        i === index ? { ...item, [field]: value } : item
      ),
    }));
  };

  const addInputField = (section) => {
    setInputFields((prevFields) => ({
      ...prevFields,
      [section]: [...prevFields[section], {}],
    }));
  };

  const handleDropdownChange = (name) => {
    setIsSelected(name);
    setIsActive(false);
  };
  const handleDropdownChangeLbs = (name) => {
    setIsSelectedLbs(name);
    setIsActiveLbs(false);
  };
  const handleChangeValues = (e) => {
    const { name, value } = e.target;
    setValueSet({ ...valueSet, [name]: value });
  };

  useEffect(() => {
    const getData =
      pId &&
      firstPage?.reviewAllData &&
      firstPage?.reviewAllData?.map((iterator) => {
        if (
          iterator?.profile?.ProfileID?.S === pId &&
          iterator.isShared === true
        ) {
          return {
            profileId: pId,
            parentProfileId: parentProfileId,
          };
        } else {
          return {
            profileId: pId,
            parentProfileId: "",
          };
        }
      });
    const sharedProfile = getData.find((e) => e?.parentProfileId);
    const unSharedProfile = getData.find((e) => !e?.parentProfileId);
    if (sharedProfile) {
      dispatch(getProfileInfo(sharedProfile));
    } else {
      dispatch(getProfileInfo(unSharedProfile));
    }
    const illnessReduxData =
      secondPage?.updateProfile &&
      secondPage?.updateProfile?.updatedAttributes?.Illnesses?.L[0]?.S &&
      JSON.parse(
        secondPage?.updateProfile?.updatedAttributes?.Illnesses?.L[0]?.S
      );
    const medicationsReduxData =
      secondPage?.updateProfile &&
      secondPage?.updateProfile?.updatedAttributes?.Medications?.L[0]?.S &&
      JSON.parse(
        secondPage?.updateProfile?.updatedAttributes?.Medications?.L[0]?.S
      );
    const supplementsReduxData =
      secondPage?.updateProfile &&
      secondPage?.updateProfile?.updatedAttributes?.Supplements?.L[0]?.S &&
      JSON.parse(
        secondPage?.updateProfile?.updatedAttributes?.Supplements?.L[0]?.S
      );
    const allergiesReduxData =
      secondPage?.updateProfile &&
      secondPage?.updateProfile?.updatedAttributes?.Allergies?.L?.map(
        (item, index) => {
          return { allergies: index >= 1 ? item?.S : "" };
        }
      );
    setInputFields({
      ...inputFields,
      diseases: [
        {
          disease: illnessReduxData?.disease,
          when: illnessReduxData?.when,
          comments: illnessReduxData?.comments,
        },
      ],
      medications: [
        {
          medication: medicationsReduxData?.medication,
          dosage: medicationsReduxData?.dosage,
          frequency: medicationsReduxData?.frequency,
        },
      ],
      supplements: [
        {
          supplement: supplementsReduxData?.supplement,
          dosage: supplementsReduxData?.dosage,
          frequency: supplementsReduxData?.frequency,
        },
      ],
    });
    if (allergiesReduxData) {
      setInputFields({
        ...inputFields,
        allergies: allergiesReduxData
          ? allergiesReduxData
          : [{ allergies: "" }],
      });
    }
    const timer = setTimeout(() => {
      setLoadingTimer(false);
    }, 8000); 

    return () => clearTimeout(timer);
  }, []);
  const onNextClick = () => {
    let parentProfileIdData;
    for (const iterator of reviewBloodTestData) {
      if (
        iterator?.profile?.ProfileID?.S === pId &&
        iterator.isShared === true
      ) {
        parentProfileIdData = parentProfileId;
      }
    }
    let allergiesData = inputFields.allergies.map(function (item) {
      return item["allergies"];
    });
    let formattedallergies = allergiesData.join(",");
    let stringAllergy = inputFields.allergies.map((obj) =>
      JSON.stringify(obj.allergies)
    );

    let convertedDisease = inputFields.diseases
      .map((obj) => JSON.stringify(obj))
      .join(",");
    let convertedMedication = inputFields.medications
      .map((obj) => JSON.stringify(obj))
      .join(",");
    let convertedSupplement = inputFields.supplements
      .map((obj) => JSON.stringify(obj))
      .join(",");
    const payload = {
      profileId: pId,
      name: fourthPage?.profileInfo?.profile?.FullName?.S,
      email: fourthPage?.profileInfo?.profile?.Email?.S,
      mobileNumber: fourthPage?.profileInfo?.profile?.MobileNumber?.S,
      allergies: formattedallergies,
      illnesses: convertedDisease,
      medications: convertedMedication,
      supplements: convertedSupplement,
      height: `${valueSet?.height}'${valueSet?.inches || 0}`,
      weight: valueSet?.weight,
      parentProfileId: parentProfileIdData,
    };
    dispatch(updateProfile(payload));
    const scanPayload = {
      profileId: firstPage?.startBloodTestData?.profileId,
      bloodTestId: firstPage?.startBloodTestData?.bloodTestId,
      parentProfileId: parentProfileIdData,
    };
    dispatch(getBloodTestScan(scanPayload));
    setCurrentStep(3);
  };

  return (
    <div>
      <Header showNavigations={false} />
      <div className={secondStepStyles.contaninerBg}>
        <Loader loading={loadingTimer} />
        <Container
          style={{
            maxWidth: "1440px",
            paddingInline: "30px",
            opacity: loadingTimer ? 0.4 : 1,
            pointerEvents: loadingTimer ? "none" : "all",
          }}
        >
        <BreadCrumbs
          breadCrumbsArr={breadCrumbsArr}
          isNextBtnVisible={true}
          isBackBtnVisible={true}
          handleNext={onNextClick}
          handleBack={() => setCurrentStep(1)}
        />
          <Card className={secondStepStyles.mainScreen}>
            <h4>Share your medical history</h4>
            <p>
              Providing medical history will help us give a more comprehensive
              assessment of the blood test results.
            </p>
            {/* ... Height and Weight sections ... */}
            <div className={secondStepStyles.main_element_section}>
              <div className={secondStepStyles.inputLabel}>
                <label>Height</label>
                <div className={secondStepStyles.manage_dropdown}>
                  <div className={secondStepStyles.manage_section}>
                    <div>
                      <input
                        type="text"
                        className={secondStepStyles.inputText}
                        // defaultValue={"00"}
                        placeholder="00"
                        onChange={handleChangeValues}
                        name="height"
                        autoComplete="on"
                        value={valueSet.height}
                      />
                    </div>
                    <div>
                      <CommonDropdown
                        isActiveFeet={isActive}
                        setIsActiveFeet={setIsActive}
                        selectedFeet={selected}
                        setIsSelectedFeet={setIsSelected}
                        handleDropdownChange={handleDropdownChange}
                        dropDownValues={[
                          { value: "cm", name: "cm" },
                          { value: "feet", name: "feet" },
                          // { value: "option_3", name: "Option 3" },
                        ]}
                      />
                    </div>
                  </div>

                  {selected === "feet" && (
                    <div className={secondStepStyles.manage_section}>
                      <div>
                        <input
                          type="text"
                          className={secondStepStyles.inputText}
                          // defaultValue={"00"}
                          placeholder="00"
                          onChange={handleChangeValues}
                          name="inches"
                          autoComplete="on"
                          value={valueSet.inches}
                        />
                      </div>
                      <div>
                        <div className={secondStepStyles.inchesText}>
                          Inches
                        </div>

                        {/* <CommonDropdown
                        isActive={isActiveInches}
                        setIsActive={setIsActiveInches}
                        selected={selectedInches}
                        setIsSelected={setIsSelectedInches}
                        dropDownValues={[
                          // { value: "option_1", name: "Option 1" },
                          // { value: "option_2", name: "Option 2" },
                          // { value: "option_3", name: "Option 3" },
                        ]} /> */}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className={secondStepStyles.inputLabel}>
                <label>Weight</label>

                <div className={secondStepStyles.manage_section}>
                  <div>
                    <input
                      type="text"
                      className={secondStepStyles.inputText}
                      // defaultValue={"00"}
                      placeholder="00"
                      onChange={handleChangeValues}
                      name="weight"
                      autocomplete="on"
                      value={valueSet.weight}
                    />
                  </div>
                  <div>
                    <CommonDropdown
                      isActiveFeet={isActiveLbs}
                      setIsActiveFeet={setIsActiveLbs}
                      selectedFeet={selectedLbs}
                      setIsSelectedFeet={setIsSelectedLbs}
                      handleDropdownChange={handleDropdownChangeLbs}
                      dropDownValues={[
                        { value: "kg", name: "kg" },
                        { value: "lbs", name: "lbs" },
                        // { value: "option_3", name: "Option 3" },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr className={secondStepStyles.lineStyle} />

            {/* Reusable GenericInputField components */}
            <GenericInputField
              label="Allergies"
              fields={inputFields.allergies}
              handleChange={(index, field, value) =>
                handleChange("allergies", index, field, value)
              }
              addInputField={() => addInputField("allergies")}
            />
            <hr className={secondStepStyles.lineStyleNext} />
            {/* ... Other GenericInputField components ... */}
            <GenericInputField
              label="Diseases & illnesses"
              fields={inputFields.diseases}
              handleChange={(index, field, value) =>
                handleChange("diseases", index, field, value)
              }
              addInputField={() => addInputField("diseases")}
            />
            <hr className={secondStepStyles.lineStyleNext} />

            <GenericInputField
              label="Medications"
              fields={inputFields.medications}
              handleChange={(index, field, value) =>
                handleChange("medications", index, field, value)
              }
              addInputField={() => addInputField("medications")}
            />
            <hr className={secondStepStyles.lineStyleNext} />

            <GenericInputField
              label="Supplements"
              fields={inputFields.supplements}
              handleChange={(index, field, value) =>
                handleChange("supplements", index, field, value)
              }
              addInputField={() => addInputField("supplements")}
            />
          </Card>
        <div className={secondStepStyles.BottomNextBtn}>
          <BackButton onClick={() => setCurrentStep(1)}>Back</BackButton>
          <NextButton onClick={onNextClick}>Next</NextButton>
        </div>
        </Container>
      </div>
    </div>
  );
};

export default SecondStep;
