export const GET_DASHBOARD_REQUEST = "GET_DASHBOARD_REQUEST";
export const GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS";
export const GET_DASHBOARD_FAILURE = "GET_DASHBOARD_FAILURE";

export const GET_PROFILE_GRANTED_ACCESS_SUCCESS =
  "GET_PROFILE_GRANTED_ACCESS_SUCCESS";

export const GET_REVIEW_BLOOD_TEST_REPORT = "GET_REVIEW_BLOOD_TEST_REPORT";

export const GET_PARENT_PROFILE_ID = "GET_PARENT_PROFILE_ID";

export const DELETE_BLOOD_TEST_REPORT = "DELETE_BLOOD_TEST_REPORT"