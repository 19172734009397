import React, { useEffect, useState } from "react";

import { ReactComponent as CloseIcon } from "../../../assets/svg/Close.svg";
import signupCSS from "../sign-up/SignUp.module.scss";
import { makeStyles } from "@material-ui/core/styles";
import libphonenumber from "google-libphonenumber";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import { Dialog, DialogContent } from "@mui/material";
import Box from "@mui/material/Box";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import axios from "axios";
import CommonButton from "../../button";

const SocialLogin = (props) => {
    const useStyles = makeStyles((theme) => ({
        // dialogContent: {
        //     "&.MuiDialogContent-root": {
        //         padding: 0,
        //         scrollbarWidth: "none",
        //         overflow: props.titleName !== "Add Other" ? "auto" : "visible",
        //         "&::-webkit-scrollbar": {
        //             display: "none",
        //         },
        //     },
        // },
        inputError: {
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "red !important",
            },
            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px !important",
            },
        },
        // signupInputContainer: {
        //     "& .MuiFormControl-root .MuiInputBase-root": {
        //         width: "414px",
        //         height: "65px",
        //         "@media (max-height: 695px)": {
        //             height: "50px",
        //         },
        //         "@media (min-height: 696px) and (max-height: 764px)": {
        //             height: "50px",
        //         },
        //         "@media (min-height: 765px) and (max-height: 833px)": {
        //             height: "55px",
        //         },
        //     },
        // },
        errorMessage: {
            fontSize: "12px",
            color: "red",
            marginLeft: "19px",
            marginTop: "-5px",
        },
    }));


    // checkbox validation
    const [checked, setChecked] = useState(false);





    const [phoneNumber, setPhoneNumber] = useState(null);
    const [phoneNumberWithoutCountryCode, setPhoneNumberWithoutCountryCode] =
        useState("");
    const [countryCode, setCountryCode] = useState("");
    const [intialCountry, setIntialCountry] = useState("us");
    const [phoneNumberError, setphoneNumberError] = useState({
        isTouch: false,
        isError: false,
        errorMsg: "",
    });



    const validatePhoneNumber = (phonenum, code) => {
        const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
        try {
            const parsedNumber = phoneUtil.parse(phonenum, code);
            if (phoneUtil.isValidNumber(parsedNumber)) {
                setphoneNumberError((prev) => {
                    return {
                        ...prev,
                        isError: false,
                        errorMsg: "",
                    };
                });
            } else {
                setphoneNumberError((prev) => {
                    return {
                        ...prev,
                        isTouch: true,
                        isError: true,
                        errorMsg: "Invalid phone number.",
                    };
                });
            }
        } catch (error) {
            setphoneNumberError((prev) => {
                return {
                    ...prev,
                    isTouch: true,
                    isError: true,
                    errorMsg: "Invalid phone number.",
                };
            });
        }
    };

    const handleNumberChange = (value, data, event, formattedValue) => {
        setPhoneNumberWithoutCountryCode(value.slice(data.dialCode.length));
        setCountryCode(data.dialCode);
        setPhoneNumber(value);
        if (value.slice(data.dialCode.length)) {
            validatePhoneNumber(
                value.slice(data.dialCode.length),
                data.countryCode?.toUpperCase()
            );
        } else {
            setphoneNumberError((prev) => {
                return {
                    ...prev,
                    isError: true,
                    errorMsg: "Phone number is required.",
                };
            });
        }
        // if(props.setPhoneError){
        // props.setPhoneError(false)
        // }
    };

    const handleBlurPhone = () => {
        if (!phoneNumberWithoutCountryCode) {
            setphoneNumberError((prev) => {
                return {
                    ...prev,
                    isTouch: true,
                    isError: true,
                    errorMsg: "Phone number is required.",
                };
            });
        }
    };


    useEffect(() => {
        setPhoneNumber("");
        setphoneNumberError((prev) => {
            return {
                isTouch: false,
                isError: false,
                errorMsg: "",
            };
        });

    }, [props?.onClose]);

    const classes = useStyles();




    useEffect(() => {
        if (!props?.open) {
            setChecked(false);
        }
    }, [props?.open]);

    const getGeoInfo = async () => {
        axios
            .get("https://ipapi.co/json/")
            .then((response) => {
                let data = response.data;
                setIntialCountry(data.country_code);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        getGeoInfo();
    }, []);

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.onClose}
                sx={{
                    "& .MuiDialog-paper": {
                        borderRadius: "20px",
                    },
                }}
            >

                {/* <Box className={signupCSS.closebtn}>
                    <CloseIcon size={"17px"} onClick={props.onClick} />
                </Box> */}

                {/* <h1 className={signupCSS.signupTitle}>{props.titleName}</h1> */}
                <DialogContent className={classes.dialogContent}>
                    <Formik
                        initialValues={props.initialData}
                        // validationSchema={SignUpSchema}
                        onSubmit={(values) => {
                            const data = {
                                phoneNumberWithoutCountryCode,
                                locationPointer: countryCode,
                            };
                            props.onSubmit(data);
                        }}
                    >
                        {({
                            values,
                            handleBlur,
                            handleChange,
                            touched,
                            errors,
                            handleSubmit,
                        }) => (
                            <Form onSubmit={handleSubmit}>
                            <div className={signupCSS.textMsg}>Thank you for signing up with AHA. Please provide your telephone number for blood test related communications.</div>
                                <Box
                                    className="App"
                                    sx={{
                                        display: "grid",
                                        gridGap: 15,
                                        position: "relative",
                                        top: "1px",
                                        "@media (max-height: 695px)": {
                                            gridGap: 12,
                                        },
                                        "@media (min-height: 696px) and (max-height: 765px)": {
                                            gridGap: 13,
                                        },
                                        "@media (min-height: 765px) and (max-height: 833px)": {
                                            gridGap: 14,
                                        },
                                    }}
                                >



                                    <div>
                                        <PhoneInput
                                            country={
                                                intialCountry
                                                    ? intialCountry?.toLowerCase()
                                                    : intialCountry
                                            }
                                            enableSearch={true}
                                            name="mobileNumber"
                                            placeholder="Enter mobile number"
                                            onChange={handleNumberChange}
                                            onBlur={handleBlurPhone}
                                            value={phoneNumber}
                                            // error={props.phoneError}
                                            countryCodeEditable={false}
                                            autocomplete="off"
                                            searchNotFound="No matching countries found."
                                            className={
                                                phoneNumberError.isError && phoneNumberError.isTouch
                                                    ? signupCSS.phoneInputErrorSocial
                                                    : signupCSS.phoneInputSocial
                                            }
                                        />
                                        {phoneNumberError.isError && phoneNumberError.isTouch ? (
                                            <div className={signupCSS.errorMessage}>
                                                {phoneNumberError.errorMsg}
                                            </div>
                                        ) : null}
                                    </div>

                                </Box>
                                {/* <CommonButton
                    loading={props.signupLoading}
                    // disabled={
                    //   props.titleName !== "Add Other"
                    //     ? checkIsDisabled(values, errors) ||
                    //     props?.signupLoading
                    //     : checkIsDisabledForAddOther(values, errors)
                    // }
                    // title={
                    //   props?.signupLoading ? "Please wait..." : props.signUpName
                    // }
                    title={'Submit'}
                    className={
                    `${signupCSS.signupBtn}`
                       
                    }
                  /> */}
                    <button
                    className={
                    `${signupCSS.signupBtn}`
                       
                    }
                  >Submit</button>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default SocialLogin;
