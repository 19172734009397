import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import MuiButton from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import firebase from "../../config/FirebaseConfig";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import headerCss from "./header.module.scss";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/images/Ahalogo.svg";
import arrowUp from "../../assets/svg/ArrowUpTrans_blue.svg";
import DrawerComponent from "../drawer/DrawerComponent";
import arrowImg from "../../assets/svg/ArrowDownTrans_blue.svg";
import { fetchUserData } from "../../redux/actions/GetUserAction";
import {
  capitalizeLetter,
  generateAbbreviation,
  removePersistData,
} from "../../helper";
import ShareModal from "../../pages/settings/ShareEmail";
import { inviteOthers } from "../../redux/actions/SettingsAction";
import { addSearchText } from "../../redux/actions/GetProfileAction";
import { GET_BLOOD_TEST_FIRST_PID_SUCCESS_PROVIDER } from "../../redux/actionTypes/GetBloodTestFirstActionTypes";
const useStyles = makeStyles((theme) => ({
  navlinks: {
    // marginLeft: "99px",
    display: "flex",
    alignItems: "center",
    gap: "53px",
    "@media (min-width: 1024px) and (max-width: 1100px)": {
      gap: "24px",
    },
    "@media (min-width: 1101px) and (max-width: 1228px)": {
      gap: "30px",
    },
    "@media (min-width: 1229px) and (max-width: 1329px)": {
      gap: "35px",
    },
    "@media (min-width: 1330px) and (max-width: 1476px)": {
      gap: "34px",
    },
  },
  logo: {
    cursor: "pointer",
    color: "#000",
  },
  link: {
    textDecoration: "none",
    color: "#000000",
    fontSize: "18px",
    fontWeight: "600",
    fontFamily: "Poppins",
    gap: "53px",
    "@media (min-width: 1024px) and (max-width: 1100px)": {
      fontSize: "12px",
    },
    "@media (min-width: 1101px) and (max-width: 1228px)": {
      fontSize: "12px",
    },
    "@media (min-width: 1229px) and (max-width: 1329px)": {
      fontSize: "14px",
    },
    "@media (min-width: 1330px) and (max-width: 1476px)": {
      fontSize: "16px",
    },
  },
  navHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  profile: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  profileData: {
    display: "block",
    marginLeft: "12px",
    paddingRight: "15px",
  },
  profileName: {
    color: "#122D5B",
    fontSize: "18px",
    fontWeight: "500",
    fontFamily: "Poppins",
    "@media (min-width: 1024px) and (max-width: 1329px)": {
      fontSize: "14px",
    },
  },
  readProfile: {
    color: "#8A8A8A",
    fontFamily: "Poppins",
    fontSize: "14px",
    textAlign: "initial",
    "@media (min-width: 1024px) and (max-width: 1329px)": {
      fontSize: "12px",
    },
  },
  toolbarCss: {
    display: "flex",
    justifyContent: "space-between",
    //  paddingInline: '258px'
    // maxWidth: '1920px',
    paddingTop: "13px",
    paddingInline: "30px !important",
    "@media (min-width: 1470px)": {
      paddingInline: "0 !important",
    },
    paddingBottom: "12px",
    maxWidth: "1440px",
    margin: "auto",
    width: "100%",
    // "@media (min-width: 1024px) and (max-width: 1100px)": {
    //   gap: "75px",
    // },
    // "@media (min-width: 1101px) and (max-width: 1228px)": {
    //   gap: "100px",
    // },
    // "@media (min-width: 1229px) and (max-width: 1329px)": {
    //   gap: "136px",
    // },
    // "@media (min-width: 1330px) and (max-width: 1476px)": {
    //   gap: "160px",
    // },
  },
  toolbarCssIfNoNav: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "1440px",
    margin: "auto",
    width: "100%",
  },
  appbarCssIfNoNav: {
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 3px 6px #00000029",
    zIndex: 3,
    position: "relative",
    paddingTop: "13px",
    paddingBottom: "12px",
    paddingInline: "30px",
    "@media (min-width: 1024px) and (max-width: 1329px)": {
      paddingInline: "5px",
    },
    "@media (min-width: 1330px) and (max-width: 1476px)": {
      paddingInline: "6px",
    },
  },
  appbarCss: { zIndex: 3, boxShadow: "0px 3px 6px #00000029" },
  content_fix: {
    display: "flex",
  },
  menubar: {
    display: "flex",
  },
}));

export const ButtonStyle = styled(MuiButton)((props) => ({
  borderRadius: 33,
  padding: "10px 17px 9px 17px",
  fontWeight: "600",
  color: "#fff",
  textTransform: "capitalize",
  justifyContent: "space-around",
  backgroundColor: "#122D5B",
  marginLeft: "74px",
  fontFamily: "'Poppins', sans-serif",
  "&:hover": {
    background: "#122D5B",
  },
}));

function Header({ showNavigations = true, bloodTest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isTablet = useMediaQuery("(max-width:1023px)");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [emails, setEmails] = useState();
  const handleShareClose = () => {
    setOpenShareModal(false);
  };
  const handleChangeEmail = (e) => {
    const { name, value } = e.target;
    setEmails({ ...emails, [name]: value });
  };
  const handleShareEmail = () => {
    const emailValues = Object.values(emails);
    const cleanedEmailValues = emailValues.map((email) =>
      email.replace(/"/g, "")
    );
    const formattedEmails = cleanedEmailValues.join(", ");

    dispatch(
      inviteOthers({
        emails: formattedEmails,
      })
    );
    setOpenShareModal(false);
  };
  const handleShareClick = (pid) => {
    setOpenShareModal(true);
  };
  const { userInfo } = useSelector((state) => state.dashboard);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    dispatch(fetchUserData());
  }, []);

  const handleLogout = async () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        // window.location.replace("https://www.ahealthacademy.com/");
        removePersistData(dispatch);
      })
      .catch(() => {
        console.log("error in logging out firebase");
      });
  };

  const handleNavigate = () => {
    navigate("/settings");
  };

  const checkDisabled = (bloodTest) => {
    if (bloodTest?.length) {
      if (
        bloodTest?.filter((eachTest) => {
          return eachTest.newValue.length;
        })?.length
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const handleNewBloodTest = () => {
    navigate("/get-blood-test-interpreted");
    dispatch(addSearchText(""))
    dispatch({
      type: GET_BLOOD_TEST_FIRST_PID_SUCCESS_PROVIDER,
      payload: '',
    });
  }

  return (
    <div className="header-main">
      <AppBar
        position="relative"
        className={
          !showNavigations ? classes.appbarCssIfNoNav : classes.appbarCss
        }
      >
        <CssBaseline />
        <Toolbar
          className={
            !showNavigations || isTablet
              ? classes.toolbarCssIfNoNav
              : classes.toolbarCss
          }
        >
          <img src={logo} alt="logo" width="162px" height="91px" />
          {isTablet ? (
            <DrawerComponent
              bloodTest={bloodTest}
              showNavigations={showNavigations}
            />
          ) : (
            <div className={classes.menubar}>
              {showNavigations && (
                <div className={classes.navlinks}>
                  <Link to="/home" className={classes.link}>
                    Home
                  </Link>
                  <Link to="/settings" className={classes.link}>
                    Settings
                  </Link>
                  <a className={classes.link} href="#reviewBloodTest">
                    Review Blood Test
                  </a>
                </div>
              )}
              <div className={classes.content_fix}>
                {showNavigations && (
                  <div className={classes.navHeader}>
                    {/* <button className={headerCss.bloodTestButton}>+ New Blood Test</button> */}
                    <button
                      onClick={() => handleNewBloodTest()}
                      variant="contained"
                      className={headerCss.bloodTestButton}
                    >
                      + New Blood Test
                    </button>
                  </div>
                )}
                <div>
                  <Tooltip>
                    <IconButton
                      onClick={handleClick}
                      size="small"
                      // sx={{ ml: 2 }}
                      aria-controls={open ? "account-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      sx={{
                        ml: 2,
                      }}
                      className={headerCss.header_dropdown}
                    >
                      <div className={classes.profile}>
                        <Avatar
                          sx={{
                            width: 39,
                            height: 39,
                            color: "#FF9900",
                            background: "#122D5B",
                            fontSize: "19px",
                            fontFamily: "Poppins",
                            fontWeight: "500",
                          }}
                        >
                          {generateAbbreviation(userInfo?.user?.FullName?.S)}
                          {/* {userInfo?.user?.FullName?.S.slice(0,1)}  */}
                          {/* {capitalizeLetter(userInfo?.user?.FullName?.S)} */}
                        </Avatar>
                        <div className={classes.profileData}>
                          <div className={classes.profileName}>
                            {/* {userInfo?.user?.FullName?.S} */}
                            {userInfo?.user?.FullName?.S
                              ? capitalizeLetter(userInfo?.user?.FullName?.S)
                              : ""}
                          </div>
                          <div className={classes.readProfile}>See Profile</div>
                        </div>
                        <div>
                          <img
                            src={open ? arrowUp : arrowImg}
                            alt="arrow"
                            width={22}
                            height={22}
                          />
                        </div>
                      </div>
                    </IconButton>
                  </Tooltip>
                  <div className="menuItem">
                    <Menu
                      anchorEl={anchorEl}
                      id="account-menu"
                      open={open}
                      onClose={handleClose}
                      onClick={handleClose}
                      className={headerCss.menu_content}
                      sx={{
                        "& .MuiPaper-rounded": {
                          borderRadius: "15px",
                        },
                        "& .MuiPaper-root-MuiPopover-paper-MuiMenu-paper": {
                          borderRadius: "15px",
                        },
                        "& .MuiList-root-MuiMenu-list": {
                          padding: "14px",
                          paddingTop: "12px",
                          paddingBottom: "12px",
                        },
                      }}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 3px 6px #00000029)",
                          mt: "9px",
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          "& .MuiList-root-MuiMenu-list": {
                            padding: "14px",
                            paddingTop: "12px",
                            paddingBottom: "12px",
                          },
                        },
                      }}
                      transformOrigin={{
                        horizontal: "center",
                        vertical: "top",
                      }}
                      anchorOrigin={{
                        horizontal: "center",
                        vertical: "bottom",
                      }}
                    >
                      {/* <CustomMenuItem>Settings</CustomMenuItem> */}
                      <MenuItem
                        onClick={handleNavigate}
                        sx={{
                          color: "#000000",
                          fontSize: "18px",
                          fontWeight: "600",
                          fontFamily: "Poppins",
                          padding: "6px 16px !important",
                          // "& .MuiButtonBase-root-MuiMenuItem-root": {
                          //   paddingTop: "6px",
                          //   paddingBottom: "6px",
                          //   paddingLeft: "16px",
                          //   paddingRight: "16px",
                          //   lineHeight: "1.5",
                          //   letterSpacing: "0.00938em",
                          // },
                        }}
                        className={headerCss.menuItem}
                      >
                        Settings
                      </MenuItem>
                      <a
                        style={{ textDecoration: "none", color: "#000000" }}
                        href="https://www.ahealthacademy.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        <MenuItem
                          // onClick={handleClose}
                          sx={{
                            color: "#000000",
                            fontSize: "18px",
                            fontWeight: "600",
                            fontFamily: "Poppins",
                          }}
                          className={headerCss.menuItem}
                        >
                          AHA
                        </MenuItem>
                      </a>
                      <MenuItem
                        onClick={() => handleShareClick()}
                        sx={{
                          color: "#000000",
                          fontSize: "18px",
                          fontWeight: "600",
                          fontFamily: "Poppins",
                        }}
                        className={headerCss.menuItem}
                        disabled={bloodTest ? checkDisabled(bloodTest) : true}
                      >
                        Invite Others
                      </MenuItem>
                      <MenuItem
                        // onClick={handleClose}
                        sx={{
                          color: "#000000",
                          fontSize: "18px",
                          fontWeight: "600",
                          fontFamily: "Poppins",
                        }}
                        className={headerCss.menuItem}
                        onClick={handleLogout}
                      >
                        Logout
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <ShareModal
        title={"invite"}
        openModal={openShareModal}
        handleClose={handleShareClose}
        handleSubmit={handleShareEmail}
        handleChangeEmail={handleChangeEmail}
        emails={emails}
        openShareModal={openShareModal}
      />
    </div>
  );
}
export default Header;
