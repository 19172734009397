import axios from "axios";
import axiosInstance from "../../axios/axiosInstance";
import { ADD_PROFILE_SUCCESS } from "../actionTypes/AddProfileActionTypes";
import { GET_BIOMARKER_SUCCESS } from "../actionTypes/BiomarkerActionTypes";
import { GET_REVIEW_BLOOD_TEST_REPORT } from "../actionTypes/DashboardActionTypes";
import {
  GET_BLOOD_TEST_FIRST_FAILURE,
  GET_BLOOD_TEST_FIRST_REQUEST,
  GET_BLOOD_TEST_FIRST_START_TEST,
  GET_BLOOD_TEST_FIRST_TEST_SCAN,
  GET_BLOOD_TEST_FIRST_UPDATE_METADATA,
  GET_BLOOD_TEST_FIRST_UPLOAD_IMAGE,
  GET_BLOOD_TEST_VALUES,
  GET_PROFILE_INFO_SUCCESS,
  GET_BLOOD_TEST_SCAN_STATUS,
  GET_BLOOD_TEST_SECOND_SUCCESS,
  SUBMIT_BLOOD_TEST,
  UPDATE_TEST_VALUES,
  GET_BLOOD_TEST_ORGAN_SUCCESS,
  GET_BLOOD_TEST_INSIGHTS_SUCCESS,
} from "../actionTypes/GetBloodTestFirstActionTypes";
import { GET_PROFILE_SUCCESS } from "../actionTypes/GetProfileActionTypes";

export const startBloodTest = (payload) => async (dispatch) => {
  dispatch({ type: GET_BLOOD_TEST_FIRST_REQUEST });
  try {
    let response;
    if(payload.profileId.name || payload.profileId.pId){
      const data = {
        profileId : payload.profileId.pId
      }
    response = await axiosInstance("/v1/startBloodTest", "POST", data);
    }
    else{
    response = await axiosInstance("/v1/startBloodTest", "POST", payload);
    }
    dispatch({
      type: GET_BLOOD_TEST_FIRST_START_TEST,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({ type: GET_BLOOD_TEST_FIRST_FAILURE, payload: error.message });
  }
};

export const updateTestMetadata = (data) => async (dispatch) => {
  dispatch({ type: GET_BLOOD_TEST_FIRST_REQUEST });
  try {
    const response = await axiosInstance(
      "/v1/updateTestMetadata",
      "POST",
      data
    );
    dispatch({
      type: GET_BLOOD_TEST_FIRST_UPDATE_METADATA,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({ type: GET_BLOOD_TEST_FIRST_FAILURE, payload: error.message });
  }
};

export const updateProfile = (payload) => async (dispatch) => {
  dispatch({ type: GET_BLOOD_TEST_FIRST_REQUEST });
  try {
    const response = await axiosInstance("/v1/updateProfile", "PUT", payload);
    dispatch({
      type: GET_BLOOD_TEST_SECOND_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({ type: GET_BLOOD_TEST_FIRST_FAILURE, payload: error.message });
  }
};

export const bloodTestScan = (data) => async (dispatch) => {
  try {
    const response = await axiosInstance(
      "/v1/startBloodTestScan",
      "POST",
      data
    );
    dispatch({
      type: GET_BLOOD_TEST_FIRST_TEST_SCAN,
      payload: response.data.data,
    });
  } catch (error) {
    console.log("Scan Start", error.message);
  }
};
export const getBloodTestValues = (payload) => async (dispatch) => {
  try {
    const response = await axiosInstance("/v1/getBloodTest", "POST", payload);
    dispatch({
      type: GET_BLOOD_TEST_VALUES,
      payload: response.data.data,
    });
  } catch (error) {
    // dispatch({ type: GET_BLOOD_TEST_FIRST_FAILURE, payload: error.message });
    console.log("Get Blood Test Value", error.message);
  }
};
export const getBloodTestScan = (payload) => async (dispatch) => {
  dispatch({ type: GET_BLOOD_TEST_FIRST_REQUEST });
  try {
    const response = await axiosInstance(
      "/v1/getBloodTestScanStatus",
      "POST",
      payload
    );
    if (response.data.data?.res?.BloodTestStatus?.S === "Scan In Progress") {
      setTimeout(() => {
        dispatch(getBloodTestScan(payload));
      }, 1000);
    } else if (
      response.data.data?.res?.BloodTestStatus?.S === "Uploaded Blood Test(s)"
    ) {
      setTimeout(() => {
        dispatch(getBloodTestScan(payload));
      }, 1000);
    } else if (
      response.data.data?.res?.BloodTestStatus?.S === "Scan Complete"
    ) {
      dispatch({
        type: GET_BLOOD_TEST_SCAN_STATUS,
        payload: response.data.data,
      });
      dispatch(getBloodTestValues(payload));
    }
  } catch (error) {
    dispatch({ type: GET_BLOOD_TEST_FIRST_FAILURE, payload: error.message });
  }
};
export const updateTestValues = (payload) => async (dispatch) => {
  try {
    const response = await axiosInstance(
      "/v1/updateTestValues",
      "POST",
      payload
    );
    dispatch({
      type: UPDATE_TEST_VALUES,
      payload: response.data.data,
    });
  } catch (error) {
    // dispatch({ type: GET_BLOOD_TEST_FIRST_FAILURE, payload: error.message });
    console.log("Update Test Values", error.message);
  }
};
export const submitBloodTest = (payload) => async (dispatch) => {
  try {
    const response = await axiosInstance(
      "/v1/submitBloodTest",
      "POST",
      payload
    );
    dispatch({
      type: SUBMIT_BLOOD_TEST,
      payload: response.data.data,
    });
    dispatch(
      fetchGetBloodOrganData({
        profileId: payload.profileId,
        bloodTestId: payload.bloodTestId,
        resultId: response.data.data.rulesRes?.resultId,
      })
    );
  } catch (error) {
    // dispatch({ type: GET_BLOOD_TEST_FIRST_FAILURE, payload: error.message });
    console.log("Submit Report", error.message);
  }
};

export const uploadImage = (data) => async (dispatch) => {
  dispatch({ type: GET_BLOOD_TEST_FIRST_REQUEST });
  const obj = {};
  data.forEach((value, key) => {
        // Check if the key already exists
        if (obj[key]) {
            // If it's not an array, convert it to an array
            if (!Array.isArray(obj[key])) {
                obj[key] = [obj[key]];
            }
            // Add the new value to the array
            obj[key].push(value);
        } else {
            obj[key] = value;
        }
    });
  try {
    const response = await axiosInstance(
      "v1/uploadCapturedReport",
      "POST",
      data,
      null,
      {
        "Content-Type": "multipart/form-data",
      }
    );
    dispatch({
      type: GET_BLOOD_TEST_FIRST_UPLOAD_IMAGE,
      payload: response.data.data,
    });
    dispatch(
      bloodTestScan({
        profileId: obj.profileId,
        bloodTestId: obj.bloodTestId,
      })
    );
  } catch (error) {
    dispatch({ type: GET_BLOOD_TEST_FIRST_FAILURE, payload: error.message });
  }
};

export const getProfileInfo = (payload) => async (dispatch) => {
  try {
    let response;
    if(payload.profileId.name || payload.profileId.pId){
      const data = {
        profileId : payload.profileId.pId
      }
     response = await axiosInstance("/v1/getProfile", "POST", data);
    }
    else{
      response = await axiosInstance("/v1/getProfile", "POST", payload);
    }
   
    dispatch({
      type: GET_PROFILE_INFO_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    // dispatch({ type: GET_BLOOD_TEST_FIRST_FAILURE, payload: error.message });
    console.log("Profile Information", error.message);
  }
};

export const fetchGetBloodBiomarkerData = () => async (dispatch) => {
  dispatch({ type: GET_BLOOD_TEST_FIRST_REQUEST });
  try {
    const response = await axiosInstance(`v1/getBiomarker`, "post");
    dispatch({
      type: GET_BIOMARKER_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({ type: GET_BLOOD_TEST_FIRST_FAILURE, payload: error.message });
    console.log("Get Biomarker", error.message);
  }
};
export const fetchGetBloodInsightsData = (data) => async (dispatch) => {
  try {
    const response = await axiosInstance("/v1/getInsights", "POST", data);
    dispatch({
      type: GET_BLOOD_TEST_INSIGHTS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    console.log("Insights Data", error.message);
  }
};
export const fetchGetBloodOrganData = (data) => async (dispatch) => {
  try {
    dispatch({ type: GET_BLOOD_TEST_FIRST_REQUEST });
    const response = await axiosInstance("/v1/getAnalysis", "POST", data);

    if (response.data.data?.res?.ResultStatus?.S === "In Progress") {
      setTimeout(() => {
        dispatch(fetchGetBloodOrganData(data));
      }, 1000);
    } else if (response.data.data?.res?.ResultStatus?.S === "Completed") {
      dispatch({
        type: GET_BLOOD_TEST_ORGAN_SUCCESS,
        payload: response.data.data,
      });
      dispatch(fetchGetBloodInsightsData(data));
      dispatch(fetchGetBloodBiomarkerData());
    }
  } catch (error) {
    dispatch({ type: GET_BLOOD_TEST_FIRST_FAILURE, payload: error.message });
  }
};

export const fetchGetBloodProfileData = () => async (dispatch) => {
  dispatch({ type: GET_BLOOD_TEST_FIRST_REQUEST });
  try {
    const response = await axiosInstance("/v1/getProfiles", "POST", null);
    const getDefaultProfile = response.data?.data?.profiles?.Items?.filter(
      (item) => item.isDefault?.S === "true"
    );

    const payload = {
      profileId: getDefaultProfile[0]?.ProfileID?.S,
    };
    const response2 = await axiosInstance(
      "/v1/getProfilesGrantedAccessTo",
      "POST",
      payload
    );

    let getName = [];
    let sharedName = [];
    dispatch({
      type: GET_BLOOD_TEST_FIRST_REQUEST,
      payload: true,
    });
    for (const value of response2?.data?.data?.profileList) {
      const payloadData = {
        profileId: value?.profileId,
        parentProfileId: getDefaultProfile[0]?.ProfileID?.S,
      };
      const response = await axiosInstance(
        "/v1/getProfile",
        "POST",
        payloadData
      );
      sharedName.push(response?.data?.data);
    }
    for (const iterator of response?.data?.data?.profiles?.Items) {
      const payloadData = {
        profileId: iterator?.ProfileID?.S,
      };
      const getProfileData = await axiosInstance(
        "/v1/getProfile",
        "POST",
        payloadData
      );
      const getBloodCount = {
        ...getProfileData.data.data,
        BloodTestCount: iterator.BloodTestCount,
      };
      getName.push(getBloodCount);
    }

    dispatch({
      type: GET_PROFILE_SUCCESS,
      payload: response.data.data,
    });
    dispatch({
      type: GET_REVIEW_BLOOD_TEST_REPORT,
      payload: [...getName, ...sharedName],
    });
  } catch (error) {
    dispatch({ type: GET_BLOOD_TEST_FIRST_FAILURE, payload: error.message });
  }
};

export const fetchGetBloodaddProfileData = (data) => async (dispatch) => {
  try {
    const response = await axiosInstance(`v1/addProfile`, "post", data);
    dispatch({
      type: ADD_PROFILE_SUCCESS,
    });
    dispatch(fetchGetBloodProfileData());
  } catch (error) {
    // dispatch({ type: GET_BLOOD_TEST_FIRST_FAILURE, payload: error.message });
  }
};
