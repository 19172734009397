import "./App.css";
import { RoutesPage } from "./routes/RoutesPage";
const App = () => {
  return (
    <div className="App">
      <RoutesPage />
    </div>
  );
};

export default App;
