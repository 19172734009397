import { GET_PROFILE_INFO_SUCCESS } from "../actionTypes/GetBloodTestFirstActionTypes";
import {
  GET_ORGAN_REPORT_SUCCESS,
  GET_ORGAN_REPORT_ERROR,
  GET_BIOMARKER_REPORT_SUCCESS,
  GET_BIOMARKER_REPORT_ERROR,
  GET_BIOMARKER_ERROR,
  GET_BIOMARKER_SUCCESS,
  ORGAN_REQUEST,
  PAGE_CHANGE,
} from "../actionTypes/trendsReportActionTypes";

const INITIAL_STATE = {
  organReport: {},
  biomarkerReport: {},
  biomarker: {},
  pageNumber: 1,
  profileInfo: {},
  pageSize: 6,
  loading: true,
  error: null,
};

const trendsReportReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ORGAN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ORGAN_REPORT_SUCCESS:
      return { ...state, loading: false, organReport: action.payload };
    case GET_BIOMARKER_REPORT_SUCCESS:
      return { ...state, loading: false, biomarkerReport: action.payload };
    case GET_BIOMARKER_SUCCESS:
      return { ...state, loading: false, biomarker: action.payload };
    case PAGE_CHANGE:
      return { ...state, loading: false, pageNumber: action.payload };
    case GET_PROFILE_INFO_SUCCESS:
      return { ...state, loading: false, profileInfo: action.payload };
    case GET_ORGAN_REPORT_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default trendsReportReducer;
