import React, { useEffect, useRef, useState } from "react";
import firstStepStyles from "./firstStep.module.scss";
import Header from "../../../components/header/Header";
import Loader from "../../../components/loader/Loader";
import CheckIcon from "@mui/icons-material/Check";
import BreadCrumbs, {
  NextButton,
} from "../../../components/breadcrumbs/BreadCrumbs";
import { Button, TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import styled from "@emotion/styled";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CustomDropdown from "../../../components/CustomDropdown/Dropdown";
import CloseBlack from "../../../assets/svg/CloseBlack.svg";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeLetter } from "../../../helper";
import {
  GET_BLOOD_TEST_FIRST_PID_SUCCESS,
  SET_UPLOADED_FILES,
} from "../../../redux/actionTypes/GetBloodTestFirstActionTypes";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  fetchGetBloodaddProfileData,
  fetchGetBloodProfileData,
  getProfileInfo,
  startBloodTest,
  updateProfile,
  updateTestMetadata,
  uploadImage,
} from "../../../redux/actions/GetBloodTestFirstAction";
import SignUpModal from "../../../components/modal/sign-up/SignUpModal";
import dayjs from "dayjs";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  getPaymentData,
  getPaymentDataForProvider,
  getSubscription,
} from "../../../redux/actions/SettingsAction";
import Notification from "../../../components/notification/notification";
import { getBloodTests } from "../../../redux/actions/firstStepAction";
import Search from "../../../components/search/Search";
import useDebounce from "../../../hooks/useDebounce";
import SectionLoader from "../../../components/loader/SectionLoader";
import moment from "moment";
import { addSearchText } from "../../../redux/actions/GetProfileAction";

const CustomDesktopDatePicker = styled(DesktopDatePicker)(({ theme }) => ({
  borderRadius: "15px",
  minWidth: "284px",
  width: "100%",
  fontFamily: "Poppings",
  "& fieldset.MuiOutlinedInput-notchedOutline": {
    borderColor: "#D1D1D1 !important",
    borderRadius: "15px",
  },

  "& .MuiFormLabel-root": {
    color: "#D1D1D1 !important",
    top: "3px",
    "& ~ .MuiInputBase-root.MuiOutlinedInput-root": {
      height: "60px",
    },
    // "&.Mui-error": {
    //   color: "#d32f2f",
    // },
  },
  "&:hover fieldset.MuiOutlinedInput-notchedOutline": {
    borderColor: "#D1D1D1",
  },
}));
const CustomBirthDatePicker = styled(DesktopDatePicker)(({ theme }) => ({
  borderRadius: "15px",
  fontFamily: "Poppins",
  "& fieldset.MuiOutlinedInput-notchedOutline": {
    borderColor: "#D1D1D1 !important",
    borderRadius: "15px",
  },

  "& .MuiFormLabel-root": {
    color: "#D1D1D1 !important",
    top: "3px",
    "& ~ .MuiInputBase-root.MuiOutlinedInput-root": {
      height: "60px",
    },
    // "&.Mui-error": {
    //   color: "#d32f2f",
    // },
  },
  "&:hover fieldset.MuiOutlinedInput-notchedOutline": {
    borderColor: "#D1D1D1",
  },
}));

const AddAnoutherButton = styled(Button)(({ theme }) => ({
  height: "50px",
  padding: "11px",
  width: "100%",
  border: "1px solid #122d5b",
  color: "white",
  background: "#122d5b",
  fontWeight: "400",
  fontFamily: "Poppins",
  fontSize: "16px",
  borderRadius: "33px",
  maxWidth: "230px",
  textTransform: "none",
  marginTop: "35px",
  "&:hover": {
    backgroundColor: "#122d5b",
  },
  // '& .MuiButton-label': {
  //   fontSize: '14px !important',
  // },
}));

const AddAnoutherButtonDisable = styled(Button)(({ theme }) => ({
  height: "50px",
  padding: "11px",
  width: "100%",
  color: "white",
  background: "rgba(18, 45, 91, 0.6392156863)",
  fontWeight: "400",
  fontFamily: "Poppins",
  fontSize: "16px",
  borderRadius: "33px",
  maxWidth: "230px",
  textTransform: "none",
  marginTop: "35px",
  cursor: "not-allowed",
  "&:hover": {
    backgroundColor: "rgba(18, 45, 91, 0.6392156863)",
  },
  // '& .MuiButton-label': {
  //   fontSize: '14px !important',
  // },
}));

const UploadButton = styled(Button)(({ theme }) => ({
  height: "65px",
  padding: "21px",
  width: "100%",
  border: "1px solid #122d5b",
  color: "#122d5b",
  background: "white",
  fontWeight: "400",
  fontFamily: "Poppins",
  fontSize: "16px",
  borderRadius: "33px",
  maxWidth: "355px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#122d5b",
    color: "white",
  },
}));

const FirstStep = ({ setCurrentStep }) => {
  const { pId, firstPage, loading, fourthPage ,name} = useSelector(
    (state) => state.getBloodTestFirst
  );
  const { bloodTests, bloodTestLoading } = useSelector(
    (state) => state.firstStep
  );
  const { subsciptionPlan } = useSelector((state) => state.settings);
  const { parentProfileId, userInfo, searchInfo, searchText } = useSelector((state) => state.dashboard);
  const [isLabProviderDropdownOpen, setIsLabProviderDropdownOpen] =
    useState(false);
  const [open, setOpen] = useState(false);
  // const [searchText, setSearchText] = useState('');
  const [addOtherLoading, setAddOtherLoading] = useState(false);
  const [addOther, setAddOther] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    lName: "",
    countryCode: "",
    dateOfBirth: "",
    sex: "",
    gender: "",
    ethnicity: "",
    allergies: [],
    illnesses: [],
    medications: [],
    supplements: [],
    height: "",
    weight: "",
    userAccessList: [],
    isDefault: "false",
  });
  useEffect(() => {
    dispatch(addSearchText(name))
  }, [name])
  // console.log("searchtext",searchText)
  const [bloodTestTakenError, setBloodTestTakenError] = useState({
    bloodTestDate: false,
    bloodTestReason: false,
    labProvider: false,
  });
  const [addProfileError, setAddProfileError] = useState({
    dob: false,
    sex: false,
    gender: false,
    ethnicity1: false,
    ethnicity2: false,
  });
  const dispatch = useDispatch();

  const [testTaken, setTestTaken] = useState({
    bloodTestDate:
      firstPage?.updateMetadata?.updatedAttributes?.BloodTestDate || "",
    labProvider:
      firstPage?.updateMetadata?.updatedAttributes?.LabProvider || "",
    bloodTestReason:
      firstPage?.updateMetadata?.updatedAttributes?.BloodTestReason || "",
    parentProfileId: "",
    name: "",
    email: "",
    mobileNumber: "",
    dateOfBirth: "",
    sex: "",
    gender: "",
    ethnicity: "",
  });

  const breadCrumbsArr = [
    {
      title: "Home",
      link: "/home",
    },
    {
      title: "Get Blood test Interpreted",
      link: null,
    },
  ];
  const fileRef = useRef();
  const [notificationData, setNotificationData] = useState({
    message: "",
    openModel: false,
    upgradePlan: false,
    status: "",
    isFirstStep: false,
    paymentFailed: false,
  });
  const [userPlan, setUserPlan] = useState(null);
  const [isReasonDropdownOpen, setIsReasonDropdownOpen] = useState(false);
  const [clickAddProfile, setClickAddProfile] = useState("");
  const [fileError, setFileError] = useState(null);
  const [files, setFiles] = useState(firstPage.files || []);
  const [editIndex, setEditIndex] = useState(null);
  const [isNextBtnDisabled, setIsNextBtnDisabled] = useState(false);
  const [bdate, setBdate] = useState();
  const [isActive, setIsActive] = useState(false);
  const [selected, setIsSelected] = useState("");
  const [isActiveClass, setIsActiveClass] = useState(false);
  const [selectedClass, setIsSelectedClass] = useState("");
  const [isActiveDescribe, setIsActiveDescribe] = useState(false);
  const [selectedDescribe, setSelectedDescribe] = useState("");
  const [isActiveSecond, setIsActiveSecond] = useState(false);
  const [selectedSecond, setSelectedSecond] = useState("");
  const [selectedItemList, setSelectedItemList] = useState();
  const [createPatientModel, setCreatePatientModel] = useState(false);
  const { loader: reviewLoaderSearch } = useDebounce(searchText);
  const suggestions = {
    data: [
      { id: 1, patient_name: "John Doe" },
      { id: 2, patient_name: "Jane Doe" },
      { id: 3, patient_name: "Alice Johnson" },
      { id: 4, patient_name: "Bob Smith" },
      // Add more dummy data as needed
    ],
  };
  const [profileError, setProfileError] = useState({
    isError: false,
    errorMsg: "",
  });
  const acceptedTypes = "image/jpeg, image/png, application/pdf";

  let dataArray = [
    "American Indian Or Alaska Native",
    "East Asian",
    "South Asian",
    "Other Asian",
    "African or Black Or Caribbean",
    "Native Hawaiian Or Other Pacific islander",
    "White Or European",
    "Hispanic/Latinx",
    "Decline To State",
  ];

  const handleBdateChange = (newValue) => {
    const formatedDate = newValue?.format("MM/DD/YYYY");
    setBdate(formatedDate);
    setAddProfileError({ ...addProfileError, date: false });
  };

  const handleChangeSearch = (e) => {
    // setSearchText(e.target.value);
    dispatch(addSearchText(e.target.value))
  };
  const handleClick = (e) => {
    setSelectedDescribe(e.target.textContent);
    setIsActiveDescribe(!isActiveDescribe);
    setAddProfileError({ ...addProfileError, ethnicity1: false });
    setSelectedItemList(
      dataArray.filter((option) => option !== e.target.textContent)
    );
  };
  function base64toFile(base64String, fileName, mimeType) {
    // Convert the base64 string to binary data

    const binaryString = window.atob(base64String?.split(",")?.[1]);

    // Create an ArrayBuffer to hold the binary data
    const arrayBuffer = new ArrayBuffer(binaryString.length);

    // Create a view to manipulate the array buffer as bytes
    const uint8Array = new Uint8Array(arrayBuffer);

    // Fill the array buffer with binary data
    for (let i = 0; i < binaryString.length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
    }

    // Create a Blob from the array buffer
    const blob = new Blob([arrayBuffer], { type: mimeType });

    // Create a File from the Blob
    return new File([blob], fileName, { type: mimeType });
  }
  useEffect(() => {
    if(userInfo?.user?.role?.S == 'Provider'){
      dispatch(getPaymentDataForProvider())
    }else{
      dispatch(getPaymentData());
    }
    dispatch(getSubscription());
  }, [dispatch]);
  useEffect(() => {
    let userPlan = null;
    if (
      subsciptionPlan?.subscriptionPlan &&
      subsciptionPlan?.subscriptionPlan?.userPlan
    ) {
      userPlan = Number(subsciptionPlan?.subscriptionPlan?.userPlan);
    } else if (
      subsciptionPlan?.subscriptionPlan &&
      subsciptionPlan?.subscriptionPlan?.productId
    ) {
      let key = subsciptionPlan?.subscriptionPlan?.productId;
      if (key.includes("one")) {
        userPlan = 1;
      } else if (key.includes("two")) {
        userPlan = 2;
      } else if (key.includes("three")) {
        userPlan = 3;
      } else if (key.includes("four")) {
        userPlan = 4;
      } else if (key.includes("five")) {
        userPlan = 5;
      } else if (key.includes("six")) {
        userPlan = 6;
      } else if (key.includes("seven")) {
        userPlan = 7;
      } else if (key.includes("eight")) {
        userPlan = 8;
      } else if (key.includes("nine")) {
        userPlan = 9;
      } else if (key.includes("ten")) {
        userPlan = 10;
      }
    } else {
      userPlan = null;
    }
    setUserPlan(userPlan);
    dispatch(getBloodTests());
  }, [subsciptionPlan]);
  const handleNext = async () => {
    if (!pId) {
      setProfileError({
        isError: true,
        errorMsg: "Please choose a profile to continue.",
      });
      setIsNextBtnDisabled(true);
    } else {
      let parentProfileIdData;
      for (const iterator of firstPage?.reviewAllData) {
        if (
          iterator?.profile?.ProfileID?.S === pId  &&
          iterator.isShared === true
        ) {
          parentProfileIdData = parentProfileId ? parentProfileId : "";
        }
      }
      if (
        fourthPage &&
        fourthPage?.profileInfo &&
        (!fourthPage?.profileInfo?.profile?.DateOfBirth?.S ||
          !fourthPage?.profileInfo?.profile?.Sex?.S ||
          !fourthPage?.profileInfo?.profile?.Gender?.S ||
          !fourthPage?.profileInfo?.profile?.Ethnicity?.S)
      ) {
        if (
          !testTaken.bloodTestDate ||
          !testTaken.labProvider ||
          !testTaken.bloodTestReason ||
          !files.length ||
          (!bdate &&
            !selectedClass &&
            !selected &&
            !selectedDescribe &&
            !selectedSecond)
        ) {
          if (!testTaken.bloodTestDate) {
            setBloodTestTakenError((prev) => {
              return {
                ...prev,
                bloodTestDate: true,
              };
            });
          }
          if (!testTaken.labProvider) {
            setBloodTestTakenError((prev) => {
              return {
                ...prev,
                labProvider: true,
              };
            });
          }
          if (!testTaken.bloodTestReason) {
            setBloodTestTakenError((prev) => {
              return {
                ...prev,
                bloodTestReason: true,
              };
            });
          }
          if (!files.length) {
            setFileError(true);
          }

          if (!bdate) {
            setAddProfileError((prev) => {
              return {
                ...prev,
                dob: true,
              };
            });
          }
          if (!selectedClass) {
            setAddProfileError((prev) => {
              return {
                ...prev,
                sex: true,
              };
            });
          }
          if (!selected) {
            setAddProfileError((prev) => {
              return {
                ...prev,
                gender: true,
              };
            });
          }
          if (!selectedDescribe) {
            setAddProfileError((prev) => {
              return {
                ...prev,
                ethnicity1: true,
              };
            });
          }
          if (!selectedSecond) {
            setAddProfileError((prev) => {
              return {
                ...prev,
                ethnicity2: true,
              };
            });
          }
        } else {
          let addProfileData;
          if (
            fourthPage?.profileInfo?.profile?.ProfileID?.S === pId  &&
            fourthPage?.profileInfo?.isShared === true
          ) {
            addProfileData = {
              profileId: fourthPage?.profileInfo?.profile?.ProfileID?.S,
              email: fourthPage?.profileInfo?.profile?.Email?.S,
              dateOfBirth: moment(bdate)?.format("MM/DD") || "01/01/1982",
              gender: selected,
              sex: selectedClass,
              ethnicity: selectedDescribe,
              parentProfileId: parentProfileId,
            };
          } else {
            addProfileData = {
              profileId: fourthPage?.profileInfo?.profile?.ProfileID?.S,
              email: fourthPage?.profileInfo?.profile?.Email?.S,
              dateOfBirth: moment(bdate)?.format("MM/DD") || dayjs("01-01-1982"),
              gender: selected,
              sex: selectedClass,
              ethnicity: selectedDescribe,
            };
          }
          dispatch(updateProfile(addProfileData));
          files.forEach((item) => {
            // const payload = {
            //   profileId: firstPage?.startBloodTestData?.profileId,
            //   bloodTestId: firstPage?.startBloodTestData?.bloodTestId,
            //   parentProfileId: parentProfileIdData,
            //   images: base64toFile(item.base64Str, item.name, item.type),
            // };
            // dispatch(uploadImage(payload));
            const file = base64toFile(item.base64Str, item.name, item.type);

            // Create FormData and append the file
            const formData = new FormData();
            formData.append('profileId', firstPage?.startBloodTestData?.profileId);
            formData.append('bloodTestId', firstPage?.startBloodTestData?.bloodTestId);
            formData.append('parentProfileId', parentProfileIdData ? parentProfileIdData : "");
            formData.append('images', file);

            // Dispatch uploadImage action with FormData
            dispatch(uploadImage(formData));
          });
          dispatch({
            type: SET_UPLOADED_FILES,
            payload: files,
          });
          dispatch(updateTestMetadata(testTaken));
          setCurrentStep(2);
          setFileError(false);
          setBloodTestTakenError({
            bloodTestDate: false,
            labProvider: false,
            bloodTestReason: false,
          });
          setAddProfileError({
            dob: false,
            sex: false,
            gender: false,
            ethnicity1: false,
            ethnicity2: false,
          });
        }
      } else {
        if (
          !testTaken.bloodTestDate ||
          !testTaken.labProvider ||
          !testTaken.bloodTestReason ||
          !files.length
        ) {
          if (!testTaken.bloodTestDate) {
            setBloodTestTakenError((prev) => {
              return {
                ...prev,
                bloodTestDate: true,
              };
            });
          }
          if (!testTaken.labProvider) {
            setBloodTestTakenError((prev) => {
              return {
                ...prev,
                labProvider: true,
              };
            });
          }
          if (!testTaken.bloodTestReason) {
            setBloodTestTakenError((prev) => {
              return {
                ...prev,
                bloodTestReason: true,
              };
            });
          }
          if (!files.length) {
            setFileError(true);
          }

          const errorEl = document.querySelector(".first_step_error_msg");
          errorEl?.scrollIntoView({ behavior: "smooth" });
          return;
        } else {
          files.forEach((item) => {
            // console.log("Base 64 convert", base64toFile(item.base64Str, item.name, item.type))
            // const payload = {
            //   profileId: firstPage?.startBloodTestData?.profileId,
            //   bloodTestId: firstPage?.startBloodTestData?.bloodTestId,
            //   parentProfileId: parentProfileIdData,
            //   images: base64toFile(item.base64Str, item.name, item.type),
            // };
            // console.log("File Upload New", payload)
            // dispatch(uploadImage(payload));
            const file = base64toFile(item.base64Str, item.name, item.type);

            // Create FormData and append the file
            const formData = new FormData();
            formData.append('profileId', firstPage?.startBloodTestData?.profileId);
            formData.append('bloodTestId', firstPage?.startBloodTestData?.bloodTestId);
            formData.append('parentProfileId', parentProfileIdData ? parentProfileIdData : "");
            formData.append('images', file);

            // Log the FormData object before dispatching

            // Dispatch uploadImage action with FormData
            dispatch(uploadImage(formData));
          });
          dispatch({
            type: SET_UPLOADED_FILES,
            payload: files,
          });
          dispatch(updateTestMetadata(testTaken));
          setCurrentStep(2);
          setFileError(false);
          setBloodTestTakenError({
            bloodTestDate: false,
            labProvider: false,
            bloodTestReason: false,
          });
          setAddProfileError({
            dob: false,
            sex: false,
            gender: false,
            ethnicity1: false,
            ethnicity2: false,
          });
        }
      }
    }
  };
  useEffect(() => {
    if (pId) {
      const getData =
        pId &&
        firstPage?.reviewAllData &&
        firstPage?.reviewAllData?.map((iterator) => {
          if (
            iterator?.profile?.ProfileID?.S === pId &&
            iterator.isShared === true
          ) {
            return {
              profileId: pId,
              parentProfileId: parentProfileId,
            };
          } else {
            return {
              profileId: pId,
              parentProfileId: "",
            };
          }
        });
      const sharedProfile = getData.find((e) => e?.parentProfileId);
      const unSharedProfile = getData.find((e) => !e?.parentProfileId);
      if (sharedProfile) {
        dispatch(startBloodTest(sharedProfile));
        dispatch(getProfileInfo(sharedProfile));
      } else {
        dispatch(startBloodTest(unSharedProfile));
        dispatch(getProfileInfo(unSharedProfile));
      }
      setProfileError({
        isError: false,
        errorMsg: "",
      });
      setIsNextBtnDisabled(false);
    } else {
      setIsNextBtnDisabled(true);
    }
  }, [pId]);

  useEffect(() => {
    let parentProfileIdData;
    if (firstPage && firstPage?.reviewAllData) {
      for (const iterator of firstPage?.reviewAllData) {
        if (
          iterator?.profile?.ProfileID?.S === pId &&
          iterator.isShared === true
        ) {
          parentProfileIdData = parentProfileId ? parentProfileId : "";
        }
        // else {
        //   parentProfileIdData = "";
        // }
      }
    }
    setTestTaken({
      ...testTaken,
      profileId: firstPage?.startBloodTestData?.profileId,
      bloodTestId: firstPage?.startBloodTestData?.bloodTestId,
      parentProfileId: parentProfileIdData,
    });
  }, [firstPage?.startBloodTestData]);

  const handleClose = () => {
    setOpen(false);
    setAddOtherLoading(false);
  };

  const handleSubmit = (values) => {
    const firstName =
      values.name.charAt(0).toUpperCase() + values.name.slice(1);
    const lastName =
      values.lName.charAt(0).toUpperCase() + values.lName.slice(1);
    const payload = {
      ...values,
      name: `${firstName} ${lastName}`,
    };
    dispatch(fetchGetBloodaddProfileData(payload));
    handleClose();
    setAddOtherLoading(true);
    setClickAddProfile("Add");
  };
  useEffect(() => {
    if (!open) {
      setAddOtherLoading(false);
    }
  }, [open]);
  const handleOpen = () => setOpen(true);
  function handleFileChange(e) {
    const file = e.target.files[0];
    checkFileValidationStatus(file);
  }

  function validateFile(file) {
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.pdf)$/i;
    const isFileTypeValid = allowedExtensions.exec(file?.name);

    if (!isFileTypeValid) {
      return "file type error";
    }
    if (isFileTypeValid) {
      return "valid file";
    }
  }

  function getFileSize(size, decimals = 2) {
    if (size === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(size) / Math.log(k));

    return parseFloat((size / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const getBase64Url = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  async function getSelectedFile(file) {
    const data = {
      name: file.name,
      size: getFileSize(file.size),
      type: file.type,
      base64Str: await getBase64Url(file),
    };
    return data;
  }
  async function getFile(file) {
    const fileData = await getSelectedFile(file);
    if (editIndex !== null) {
      let tempFiles = files;
      tempFiles.splice(editIndex, 1, fileData);
      setFiles(tempFiles);
      setEditIndex(null);
    } else {
      setFiles((prev) => {
        if (prev) {
          return [...prev, fileData];
        } else {
          return fileData;
        }
      });
    }
  }

  function checkFileValidationStatus(file) {
    switch (validateFile(file)) {
      case "valid file":
        getFile(file);
        break;
      case "file type error":
        // snackbarShowMessage(
        //   'Invalid Filetype! Only Excel file is allowed.',
        //   'error'
        // );
        setFiles(files);
        break;
      default:
        break;
    }
  }

async function handleBrowse() {
    if (!userPlan) {
      setNotificationData({
        message: "A Premium plan is required to have blood test interpreted",
        openModel: true,
        upgradePlan: true,
        status: "",
        paymentFailed: false,
        isFirstStep: false,
      });
      return;
    } else {
      const submittedReportsList = bloodTests?.filter((e) => e?.reports > 0);
      const isAvailableProfile = submittedReportsList && submittedReportsList?.some(
        (e) => e?.profileId == pId
      );
      const isSharedProfile = firstPage?.reviewAllData
        ?.filter(
          (item) =>
            !item?.profile?.ProfileID?.S?.includes("DELETED") && item?.isShared
        )
        ?.map((e) => e?.profile?.ProfileID?.S);

      if (
        isAvailableProfile ||
        submittedReportsList?.length < userPlan ||
        isSharedProfile?.includes(pId)
      ) {
        fileRef?.current?.click();
      } else {
        setNotificationData({
          message: `Your ${Number(
            userPlan
          )} user plan is at capacity. Please upgrade to add more users.`,
          openModel: true,
          upgradePlan: true,
          status: "upgrade",
          isFirstStep: true,
          paymentFailed: false,
        });
        return;
      }
    }
  }
const handleDropFile = (index) => {
    setFiles((prev) => {
      const updatedFiles = [...prev];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
  };

  const handleClickpId = (id) => {
    const getData =
      pId &&
      firstPage?.reviewAllData &&
      firstPage?.reviewAllData?.map((iterator) => {
        if (
          iterator?.profile?.ProfileID?.S === id &&
          iterator.isShared === true
        ) {
          return {
            profileId: id,
            parentProfileId: parentProfileId,
          };
        } else {
          return {
            profileId: id,
            parentProfileId: "",
          };
        }
      });
    const sharedProfile = getData?.find((e) => e?.parentProfileId);
    const unSharedProfile = getData?.find((e) => !e?.parentProfileId);
    if (sharedProfile) {
      dispatch(getProfileInfo(sharedProfile));
    } else {
      dispatch(getProfileInfo(unSharedProfile));
    }
    dispatch({
      type: GET_BLOOD_TEST_FIRST_PID_SUCCESS,
      payload: id,
    });
  };
  useEffect(() => {
    dispatch(fetchGetBloodProfileData());
    dispatch(getSubscription());
  }, []);

  const handleDropdowns = (key, value) => {
    setTestTaken({
      ...testTaken,
      [key]: value,
    });
    setBloodTestTakenError((prev) => {
      return {
        ...prev,
        [key]: false,
      };
    });
  };
  useEffect(() => {
    if (clickAddProfile === "Add") {
      const latestProfile = firstPage?.reviewAllData.sort(
        (a, b) => b.profile.CreationDate - a.profile.CreationDate
      );
      dispatch({
        type: GET_BLOOD_TEST_FIRST_PID_SUCCESS,
        payload: latestProfile && latestProfile[0]?.profile?.ProfileID?.S,
      });
    }
  }, [clickAddProfile, firstPage?.reviewAllData]);
 const handleEnterValues = () => {
    if (!userPlan) {
      setNotificationData({
        message: "A Premium plan is required to have blood test interpreted",
        openModel: true,
        upgradePlan: true,
        status: "",
        isFirstStep: false,
        paymentFailed: false,
      });
      return;
    } else {
      const submittedReportsList = bloodTests?.filter((e) => e?.reports > 0);
      const isAvailableProfile = submittedReportsList && submittedReportsList.some(
        (e) => e?.profileId == pId
      );
      if (isAvailableProfile || submittedReportsList.length < userPlan) {
        // if (
        //   !testTaken.bloodTestDate ||
        //   !testTaken.labProvider ||
        //   !testTaken.bloodTestReason
        // ) {
        //   if (!testTaken.bloodTestDate) {
        //     setBloodTestTakenError((prev) => {
        //       return {
        //         ...prev,
        //         bloodTestDate: true,
        //       };
        //     });
        //   }
        //   if (!testTaken.labProvider) {
        //     setBloodTestTakenError((prev) => {
        //       return {
        //         ...prev,
        //         labProvider: true,
        //       };
        //     });
        //   }
        //   if (!testTaken.bloodTestReason) {
        //     setBloodTestTakenError((prev) => {
        //       return {
        //         ...prev,
        //         bloodTestReason: true,
        //       };
        //     });
        //   }
        //   const errorEl = document.querySelector(".first_step_error_msg");
        //   errorEl?.scrollIntoView({ behavior: "smooth" });
        //   return;
        // } else {
        //   let addProfileData;
        //   if (
        //     fourthPage?.profileInfo?.profile?.ProfileID?.S === pId &&
        //     fourthPage?.profileInfo?.isShared === true
        //   ) {
        //     addProfileData = {
        //       profileId: fourthPage?.profileInfo?.profile?.ProfileID?.S,
        //       email: fourthPage?.profileInfo?.profile?.Email?.S,
        //       dateOfBirth: bdate || dayjs("01-01-1982"),
        //       gender: selected,
        //       sex: selectedClass,
        //       ethnicity: selectedDescribe,
        //       parentProfileId: parentProfileId,
        //     };
        //   } else {
        //     addProfileData = {
        //       profileId: fourthPage?.profileInfo?.profile?.ProfileID?.S,
        //       email: fourthPage?.profileInfo?.profile?.Email?.S,
        //       dateOfBirth: bdate || dayjs("01-01-1982"),
        //       gender: selected,
        //       sex: selectedClass,
        //       ethnicity: selectedDescribe,
        //     };
        //   }
        //   dispatch(updateProfile(addProfileData));
        //   setCurrentStep(2);
        //   dispatch(updateTestMetadata(testTaken));
        //   setBloodTestTakenError({
        //     bloodTestDate: false,
        //     labProvider: false,
        //     bloodTestReason: false,
        //   });
        // }
        if (
          fourthPage &&
          fourthPage?.profileInfo &&
          (!fourthPage?.profileInfo?.profile?.DateOfBirth?.S ||
            !fourthPage?.profileInfo?.profile?.Sex?.S ||
            !fourthPage?.profileInfo?.profile?.Gender?.S ||
            !fourthPage?.profileInfo?.profile?.Ethnicity?.S)
        ) {
          if (
            !testTaken.bloodTestDate ||
            !testTaken.labProvider ||
            !testTaken.bloodTestReason ||
            (!bdate &&
              !selectedClass &&
              !selected &&
              !selectedDescribe &&
              !selectedSecond)
          ) {
            if (!testTaken.bloodTestDate) {
              setBloodTestTakenError((prev) => {
                return {
                  ...prev,
                  bloodTestDate: true,
                };
              });
            }
            if (!testTaken.labProvider) {
              setBloodTestTakenError((prev) => {
                return {
                  ...prev,
                  labProvider: true,
                };
              });
            }
            if (!testTaken.bloodTestReason) {
              setBloodTestTakenError((prev) => {
                return {
                  ...prev,
                  bloodTestReason: true,
                };
              });
            }

            if (!bdate) {
              setAddProfileError((prev) => {
                return {
                  ...prev,
                  dob: true,
                };
              });
            }
            if (!selectedClass) {
              setAddProfileError((prev) => {
                return {
                  ...prev,
                  sex: true,
                };
              });
            }
            if (!selected) {
              setAddProfileError((prev) => {
                return {
                  ...prev,
                  gender: true,
                };
              });
            }
            if (!selectedDescribe) {
              setAddProfileError((prev) => {
                return {
                  ...prev,
                  ethnicity1: true,
                };
              });
            }
            if (!selectedSecond) {
              setAddProfileError((prev) => {
                return {
                  ...prev,
                  ethnicity2: true,
                };
              });
            }
          } else {
            let addProfileData;
            if (
              fourthPage?.profileInfo?.profile?.ProfileID?.S === pId &&
              fourthPage?.profileInfo?.isShared === true
            ) {
              addProfileData = {
                profileId: fourthPage?.profileInfo?.profile?.ProfileID?.S,
                email: fourthPage?.profileInfo?.profile?.Email?.S,
                dateOfBirth: moment(bdate)?.format("MM/DD") || dayjs("01-01-1982"),
                gender: selected,
                sex: selectedClass,
                ethnicity: selectedDescribe,
                parentProfileId: parentProfileId,
              };
            } else {
              addProfileData = {
                profileId: fourthPage?.profileInfo?.profile?.ProfileID?.S,
                email: fourthPage?.profileInfo?.profile?.Email?.S,
                dateOfBirth: moment(bdate)?.format("MM/DD") || dayjs("01-01-1982"),
                gender: selected,
                sex: selectedClass,
                ethnicity: selectedDescribe,
              };
            }
            dispatch(updateProfile(addProfileData));
            dispatch(updateTestMetadata(testTaken));
            setCurrentStep(2);
            setFileError(false);
            setBloodTestTakenError({
              bloodTestDate: false,
              labProvider: false,
              bloodTestReason: false,
            });
            setAddProfileError({
              dob: false,
              sex: false,
              gender: false,
              ethnicity1: false,
              ethnicity2: false,
            });
          }
        } else {
          if (
            !testTaken.bloodTestDate ||
            !testTaken.labProvider ||
            !testTaken.bloodTestReason
          ) {
            if (!testTaken.bloodTestDate) {
              setBloodTestTakenError((prev) => {
                return {
                  ...prev,
                  bloodTestDate: true,
                };
              });
            }
            if (!testTaken.labProvider) {
              setBloodTestTakenError((prev) => {
                return {
                  ...prev,
                  labProvider: true,
                };
              });
            }
            if (!testTaken.bloodTestReason) {
              setBloodTestTakenError((prev) => {
                return {
                  ...prev,
                  bloodTestReason: true,
                };
              });
            }
            const errorEl = document.querySelector(".first_step_error_msg");
            errorEl?.scrollIntoView({ behavior: "smooth" });
            return;
          } else {
            dispatch(updateTestMetadata(testTaken));
            setCurrentStep(2);
            setFileError(false);
            setBloodTestTakenError({
              bloodTestDate: false,
              labProvider: false,
              bloodTestReason: false,
            });
            setAddProfileError({
              dob: false,
              sex: false,
              gender: false,
              ethnicity1: false,
              ethnicity2: false,
            });
          }
        }
      } else {
        setNotificationData({
          message: `Your ${Number(
            userPlan
          )} user plan is at capacity. Please upgrade to add more users.`,
          openModel: true,
          upgradePlan: true,
          status: "upgrade",
          isFirstStep: false,
          paymentFailed: false,
        });
        return;
      }
    }
  };
  const handleClickSearch = (pId, name, bId) => {
    dispatch({
      type: GET_BLOOD_TEST_FIRST_PID_SUCCESS,
      payload: pId,
    });
    // setSearchText(name)
  }
  return (
    <div>
      {" "}
      <Header showNavigations={false} />
      <div className={firstStepStyles.contaninerBg}>
        <Loader loading={loading || bloodTestLoading} />
        <BreadCrumbs
          breadCrumbsArr={breadCrumbsArr}
          isNextBtnVisible={true}
          isNextBtnDisabled={isNextBtnDisabled}
          handleNext={handleNext}
        />
        <div
          style={{
            opacity: loading ? 0.4 : 1,
            pointerEvents: loading ? "none" : "all",
          }}
        >
          <div className={userInfo?.user?.role?.S === 'Provider' ? firstStepStyles.pageTitleProvider : firstStepStyles.pageTitle}>Let’s Start</div>
          {userInfo?.user?.role?.S === 'Provider' && <p className={firstStepStyles.pageTitleSubText}>
            Is this for you or someone else
          </p>}
          <div className={firstStepStyles.allSectionWrapper}>
            {userInfo?.user?.role?.S === 'Provider' ? <div className={firstStepStyles.profileSection}>
              <SectionLoader
                loading={reviewLoaderSearch}
                bloodTest={searchInfo}
                findUpload={"patient"}
              />
              <Search
                searchText={searchText}
                // setSearchText={setSearchText}
                onChange={handleChangeSearch}
                suggestions={searchInfo}
                reviewLoaderSearch={reviewLoaderSearch}
                setCreatePatientModel={setCreatePatientModel}
                createPatientModel={createPatientModel}
                // suggestions={suggestions}
                handleClick={handleClickSearch}
              />
            </div> : <div className={firstStepStyles.profileSection}>
              <p className={firstStepStyles.bloodTestTakenText}>
                Who's blood test was taken ?
              </p>
              <div className={firstStepStyles.allProfiles}>
                {firstPage?.reviewAllData &&
                  firstPage?.reviewAllData.length > 0 &&
                  firstPage?.reviewAllData.map((item) => (
                    <>
                      {!item?.profile?.ProfileID?.S?.includes("DELETED") ? (
                        <div
                          className={`${firstStepStyles.eachProfile} ${pId === item?.profile?.ProfileID?.S &&
                            firstStepStyles.selected
                            }`}
                          onClick={() =>
                            handleClickpId(item?.profile?.ProfileID?.S)
                          }
                        >
                          {item?.profile?.FullName?.S
                            ? capitalizeLetter(item?.profile?.FullName?.S)
                            : ""}
                          {pId === item?.profile?.ProfileID?.S && (
                            <span className={firstStepStyles.checkIcon}>
                              <CheckIcon />
                            </span>
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  ))}
              </div>
              {!pId ? (
                <AddAnoutherButton onClick={handleOpen}>
                  + Add Another
                </AddAnoutherButton>
              ) : (
                <AddAnoutherButtonDisable>
                  + Add Another
                </AddAnoutherButtonDisable>
              )}

              {profileError.isError && (
                <label className={firstStepStyles.profileError}>
                  {profileError.errorMsg}
                </label>
              )}
            </div>}

            {fourthPage &&
              fourthPage?.profileInfo &&
              (!fourthPage?.profileInfo?.profile?.DateOfBirth?.S ||
                !fourthPage?.profileInfo?.profile?.Sex?.S ||
                !fourthPage?.profileInfo?.profile?.Gender?.S ||
                !fourthPage?.profileInfo?.profile?.Ethnicity?.S) && (
                <div
                  className={firstStepStyles.bloodTestTakenSection}
                  style={{
                    opacity: !pId ? 0.4 : 1,
                    pointerEvents: !pId ? "none" : "all",
                  }}
                >
                  <p className={firstStepStyles.bloodTestTakenText}>
                    Please provide your information
                  </p>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "auto auto auto",
                      gap: "20px",
                    }}
                  >
                    {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 10,
                      }}
                    > */}
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p
                        className={firstStepStyles.dropdownHeading}
                        style={{ marginBottom: "15px" }}
                      >
                        Date of Birth
                      </p>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        sx={{ minWidth: 0, width: "auto" }}
                      >
                        <CustomBirthDatePicker
                          format="MM/DD/YYYY"
                          label="Date of Birth"
                          maxDate={dayjs("12-31-2005")}
                          name="dateOfBirth"
                          onChange={handleBdateChange}
                          defaultValue={dayjs("01-01-1982")}
                          value={bdate}
                        // defaultValue={defaultDate} // Set the default date
                        />
                      </LocalizationProvider>

                      {addProfileError.date && (
                        <p
                          className={`${firstStepStyles.error_msg} first_step_error_msg`}
                        >
                          Date of Birth (DOB) is a required field.
                        </p>
                      )}
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p
                        className={firstStepStyles.dropdownHeading}
                        style={{ marginBottom: "15px" }}
                      >
                        Sex at birth
                      </p>
                      <div className={firstStepStyles.dropdown}>
                        <div
                          onClick={(e) => {
                            setIsActiveClass(!isActiveClass);
                          }}
                          className={firstStepStyles.dropdown_btn}
                        >
                          {selectedClass || "Sex at birth"}
                          {isActiveClass ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </div>
                        <div
                          className={firstStepStyles.dropdown_content}
                          style={{ display: isActiveClass ? "block" : "none" }}
                        >
                          <div
                            onClick={(e) => {
                              setIsSelectedClass(e.target.textContent);
                              setIsActiveClass(!isActiveClass);
                              setAddProfileError({
                                ...addProfileError,
                                sex: false,
                              });
                            }}
                            className={firstStepStyles.item}
                          >
                            Male
                          </div>
                          <div
                            className={firstStepStyles.item}
                            onClick={(e) => {
                              setIsSelectedClass(e.target.textContent);
                              setIsActiveClass(!isActiveClass);
                              setAddProfileError({
                                ...addProfileError,
                                sex: false,
                              });
                            }}
                          >
                            Female
                          </div>
                          <div
                            className={firstStepStyles.item}
                            onClick={(e) => {
                              setIsSelectedClass(e.target.textContent);
                              setIsActiveClass(!isActiveClass);
                              setAddProfileError({
                                ...addProfileError,
                                sex: false,
                              });
                            }}
                          >
                            Intersex/Other
                          </div>
                        </div>
                        {addProfileError.sex && (
                          <p
                            className={`${firstStepStyles.error_msg} first_step_error_msg`}
                          >
                            Sex is a required field.
                          </p>
                        )}
                      </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p
                        className={firstStepStyles.dropdownHeading}
                        style={{ marginBottom: "15px" }}
                      >
                        Current gender identity
                      </p>
                      <div className={firstStepStyles.dropdown}>
                        <div
                          onClick={(e) => {
                            setIsActive(!isActive);
                          }}
                          className={firstStepStyles.dropdown_btn}
                        >
                          {selected || "Current gender identity"}
                          {isActive ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                          {/* <span
                            className={isActive ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          /> */}
                        </div>
                        <div
                          className={firstStepStyles.dropdown_content}
                          style={{ display: isActive ? "block" : "none" }}
                        >
                          <div
                            onClick={(e) => {
                              setIsSelected(e.target.textContent);
                              setIsActive(!isActive);
                              setAddProfileError({
                                ...addProfileError,
                                gender: false,
                              });
                            }}
                            className={firstStepStyles.item}
                          >
                            Man
                          </div>
                          <div
                            className={firstStepStyles.item}
                            onClick={(e) => {
                              setIsSelected(e.target.textContent);
                              setIsActive(!isActive);
                              setAddProfileError({
                                ...addProfileError,
                                gender: false,
                              });
                            }}
                          >
                            Woman
                          </div>
                          <div
                            className={firstStepStyles.item}
                            onClick={(e) => {
                              setIsSelected(e.target.textContent);
                              setIsActive(!isActive);
                              setAddProfileError({
                                ...addProfileError,
                                gender: false,
                              });
                            }}
                          >
                            Other
                          </div>
                        </div>
                        {addProfileError.gender && (
                          <p
                            className={`${firstStepStyles.error_msg} first_step_error_msg`}
                          >
                            Gender is a required field.
                          </p>
                        )}
                      </div>
                    </div>
                    {/* </div>
                    <div> */}
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p
                        className={firstStepStyles.dropdownHeading}
                        style={{ marginBottom: "15px" }}
                      >
                        How do you ethnically describe yourself?
                      </p>
                      <div className={firstStepStyles.dropdown}>
                        <div
                          onClick={(e) => {
                            setIsActiveDescribe(!isActiveDescribe);
                          }}
                          className={firstStepStyles.dropdown_btn}
                        >
                          {selectedDescribe ||
                            "How do you ethnically describe yourself?"}
                          {isActiveDescribe ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </div>
                        <div
                          className={firstStepStyles.dropdown_content}
                          style={{
                            display: isActiveDescribe ? "block" : "none",
                          }}
                        >
                          {dataArray.map((item) => (
                            <div
                              // onClick={(e) => {
                              //   setSelectedDescribe(e.target.textContent);
                              //   setIsActiveDescribe(!isActiveDescribe);
                              // }}
                              onClick={(e) => handleClick(e)}
                              className={firstStepStyles.item}
                            >
                              {item}
                            </div>
                          ))}
                        </div>
                        {addProfileError.ethnicity1 && (
                          <p
                            className={`${firstStepStyles.error_msg} first_step_error_msg`}
                          >
                            Ethnicity is a required field.
                          </p>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "end",
                      }}
                    >
                      {selectedDescribe && (
                        <>
                          <div className={firstStepStyles.dropdown}>
                            <div
                              onClick={(e) => {
                                setIsActiveSecond(!isActiveSecond);
                                setAddProfileError({
                                  ...addProfileError,
                                  ethnicity2: false,
                                });
                              }}
                              className={firstStepStyles.dropdown_btn}
                            >
                              {selectedSecond || "Second Ethnicity"}
                              {isActiveSecond ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </div>
                            <div
                              className={firstStepStyles.dropdown_content}
                              style={{
                                display: isActiveSecond ? "block" : "none",
                              }}
                            >
                              {selectedItemList &&
                                selectedItemList?.map((item) => (
                                  <div
                                    onClick={(e) => {
                                      setSelectedSecond(e.target.textContent);
                                      setIsActiveSecond(!isActiveSecond);
                                    }}
                                    // onClick={(e)=>handleClick(e)}
                                    className={firstStepStyles.item}
                                  >
                                    {item}
                                  </div>
                                ))}
                            </div>
                            {selectedDescribe && addProfileError.ethnicity2 && (
                              <p
                                className={`${firstStepStyles.error_msg} first_step_error_msg`}
                              >
                                Ethnicity is a required field.
                              </p>
                            )}
                          </div>
                        </>
                      )}
                    </div>

                    {/* </div> */}
                  </div>
                </div>
              )}

            <div
              className={firstStepStyles.bloodTestTakenSection}
              style={{
                opacity: !pId ? 0.4 : 1,
                pointerEvents: !pId ? "none" : "all",
              }}
            >
              <p className={firstStepStyles.bloodTestTakenText}>
                When and where was the blood test taken ?
              </p>
              <div className={firstStepStyles.allDropDownWrapper}>
                <div className={firstStepStyles.datePickerContainer}>
                  <p className={firstStepStyles.dropdownHeading}>
                    Blood Test Date
                  </p>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <CustomDesktopDatePicker
                      format="MM/DD/YYYY"
                      label="MM/DD/YYYY"
                      disableFuture={true}
                      value={dayjs(testTaken.bloodTestDate)}
                      onChange={(newValue) => {
                        if (newValue) {
                          const formatedDate = newValue.format("MM/DD/YYYY");
                          setTestTaken({
                            ...testTaken,
                            bloodTestDate: formatedDate,
                          });
                          setBloodTestTakenError((prev) => {
                            return { ...prev, bloodTestDate: false };
                          });
                        }
                      }}
                    />
                  </LocalizationProvider>
                  {bloodTestTakenError.bloodTestDate && (
                    <p
                      className={`${firstStepStyles.error_msg} first_step_error_msg`}
                      style={{ marginTop: "-5px" }}
                    >
                      Blood test date is required.
                    </p>
                  )}
                </div>
                <div style={{ display: "grid" }}>
                  <CustomDropdown
                    dropdownContainerClass={firstStepStyles.labproviderWrapper}
                    placeholder="Enter value"
                    label="Lab Provider"
                    value={testTaken}
                    setValue={setTestTaken}
                    open={isLabProviderDropdownOpen}
                    setOpen={setIsLabProviderDropdownOpen}
                    dropDownValues={[
                      { value: "Labcorp", name: "Labcorp" },
                      { value: "Quest Diagnostics", name: "Quest Diagnostics" },
                      { value: "Other", name: "Other" },
                    ]}
                    handleDropdowns={handleDropdowns}
                    dKey="labProvider"
                  />
                  {bloodTestTakenError.labProvider && (
                    <p
                      className={`${firstStepStyles.error_msg} first_step_error_msg`}
                    >
                      Lab provider is required.
                    </p>
                  )}
                  {testTaken.labProvider === "Other" && (
                    <input
                      className={firstStepStyles.otherInput}
                      placeholder="Other provider"
                    />
                  )}
                </div>
                <div style={{ display: "grid", width: "100%" }}>
                  <CustomDropdown
                    dropdownContainerClass={firstStepStyles.reasonWrapper}
                    placeholder="Select reason"
                    label="Reason for blood test"
                    value={testTaken}
                    setValue={setTestTaken}
                    open={isReasonDropdownOpen}
                    setOpen={setIsReasonDropdownOpen}
                    dropDownValues={[
                      {
                        value: "Routine health check-up",
                        name: "Routine health check-up",
                      },
                      {
                        value: "Personal health concern",
                        name: "Personal health concern",
                      },
                      {
                        value: "Doctor ordered for diagnosis",
                        name: "Doctor ordered for diagnosis",
                      },
                      {
                        value: "Monitor chronic disease or condition",
                        name: "Monitor chronic disease or condition",
                      },
                      {
                        value: "Determine treatment effectiveness",
                        name: "Determine treatment effectiveness",
                      },
                      {
                        value: "Other",
                        name: "Other",
                      },
                    ]}
                    handleDropdowns={handleDropdowns}
                    dKey="bloodTestReason"
                  />
                  {bloodTestTakenError.bloodTestReason && (
                    <p
                      className={`${firstStepStyles.error_msg} first_step_error_msg`}
                    >
                      Blood test reason is required.
                    </p>
                  )}
                  {testTaken.bloodTestReason === "Other" && (
                    <input
                      className={firstStepStyles.otherInput}
                      placeholder="Other reason"
                    />
                  )}
                </div>
              </div>
            </div>
            <div
              className={firstStepStyles.uploadSection}
              style={{
                opacity: !pId ? 0.4 : 1,
                pointerEvents: !pId ? "none" : "all",
              }}
            >
              <p className={firstStepStyles.uploadTitleText}>
                Please upload the lab’s blood test result
              </p>
              {/* <label className={firstStepStyles.uploadSubText}>
                If it is too difficult to get these, please take picture of the
                results an upload the pictures
              </label> */}
              <div className={firstStepStyles.uploadButtonsContainer}>
                <UploadButton onClick={handleBrowse}>
                  Upload test results
                </UploadButton>
                <TextField
                  type="file"
                  sx={{
                    position: "absolute",
                    height: "0",
                    width: "0",
                    padding: "0",
                    overflow: "hidden",
                    opacity: "0",
                    appearance: "none",
                  }}
                  inputRef={fileRef}
                  onChange={handleFileChange}
                  inputProps={{ accept: acceptedTypes }}
                />
                <span className={firstStepStyles.ORtext}>OR</span>
                <UploadButton onClick={handleEnterValues}>
                  Manually Enter Values
                </UploadButton>
              </div>
              {fileError && (
                <p
                  className={`${firstStepStyles.error_msg} first_step_error_msg`}
                  style={{ marginTop: "10px" }}
                >
                  Please Upload at Least One Lab Blood Test Result
                </p>
              )}
              {files && files.length > 0 && (
                <p className={firstStepStyles.recentlyUploadedText}>
                  Recently Uploaded
                </p>
              )}
              <div className={firstStepStyles.uploadedImagesContainer}>
                {files &&
                  files?.map((eachFile, index) => (
                    <>
                      <div className={firstStepStyles.uploadedImage}>
                        <img
                          className={firstStepStyles.closeIcon}
                          src={CloseBlack}
                          alt="close"
                          onClick={() => handleDropFile(index)}
                        />
                        {eachFile?.name.includes(".pdf") ? (
                          <iframe
                            src={eachFile.base64Str}
                            frameborder="0"
                            height="150px"
                            width="100px"
                            alt="Pdf file"
                          ></iframe>
                        ) : (
                          eachFile?.base64Str && (
                            <img
                              height={150}
                              width={100}
                              style={{
                                objectFit: "cover",
                              }}
                              src={eachFile.base64Str}
                              alt="Preview"
                            />
                          )
                        )}
                        <AddCircleIcon
                          className={firstStepStyles.editIcon}
                          onClick={handleBrowse}
                        />
                        {/* <img
                          className={firstStepStyles.editIcon}
                          src={plusIcon}
                          alt="edit"
                          onClick={() => handleEditFile(index)}
                        /> */}
                      </div>
                    </>
                  ))}
              </div>
            </div>
            <div className={firstStepStyles.BottomNextBtn}>
              <NextButton disabled={isNextBtnDisabled} onClick={handleNext}>
                Next
              </NextButton>
            </div>
          </div>
        </div>
      </div>
      <SignUpModal
        setSignupLoading={setAddOtherLoading}
        signupLoading={addOtherLoading}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        onSubmit={handleSubmit}
        loading={false}
        disabled={false}
        initialData={addOther}
        titleName={"Add Other"}
        signUpName={"Submit"}
      />
      <Notification
        notificationData={notificationData}
        setNotificationData={setNotificationData}
      />
    </div>
  );
};

export default FirstStep;
