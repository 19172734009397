import { withStyles } from "@material-ui/core/styles";
import { InputAdornment, TextField } from "@mui/material";

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-input": {
      padding: "14px 12px",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#D1D1D1",
      },
    },
    "& .MuiOutlinedInput-notchedOutlin": {
      borderWidth: "0.5px",
    },
    "& .MuiInputAdornment-root": {
      minWidth: 21,
      display: "flex",
      justifyContent: "flex-end",
    },
  },
})(TextField);

const IconTextField = ({ iconStart, iconEnd, InputProps, ...props }) => {
  // const classes = useStyles();
  return (
    <CssTextField
      {...props}
      InputProps={{
        ...InputProps,
        startAdornment: iconStart ? (
          <InputAdornment position="start">{iconStart}</InputAdornment>
        ) : null,
        endAdornment: iconEnd ? (
          <InputAdornment position="end">{iconEnd}</InputAdornment>
        ) : null,
        style: {
          borderRadius: "33px",
          borderColor: "#D1D1D1",
          borderWidth: "0.5px",

          "&:hover": {
            borderColor: "#D1D1D1",
          },
        },
      }}
      // className={classes.OutlinedTextField}
    />
  );
};

export default IconTextField;
