import Chart from "chart.js/auto";
import React, { useEffect, useState, memo } from "react";

const Graph = (props) => {
  const {
    chartId,
    yTicksDisplay,
    xTicksDisplay = true,
    min,
    max,
    xAxisFontSize,
    pointWidth,
    plugins,
    points = [],
    xLabels = [],
    yLabels = {},
  } = props;

  const [chartData, setChartData] = useState({
    yLabels: {},
    xLabel: [],
    points: [],
  });

  useEffect(() => {
    const commonData = {
      yLabels: { ...yLabels },
      xLabel: [null, ...xLabels, null],
      points: [null, ...points, null],
    };
    if (!yTicksDisplay) {
      commonData.xLabel.splice(1, 0, null);
      commonData.points.splice(1, 0, null);
    }
    setChartData(commonData);
  }, [xLabels, yLabels, points]);

  useEffect(() => {
    const chartInstance = Chart.getChart(chartId);
    if (chartInstance) {
      chartInstance.destroy();
    }
    new Chart(chartId, {
      type: "line",
      data: {
        labels: chartData?.xLabel,
        datasets: [
          {
            data: chartData?.points,
            borderWidth: 2,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        scales: {
          y: {
            min,
            max,
            beginAtZero: true,
            grid: {
              display: false,
            },
            ticks: {
              display: yTicksDisplay,
              color: "#000000",
              font: {
                size: 12,
              },
              callback: (value) => {
                if (yTicksDisplay) {
                  return chartData?.yLabels[value];
                }
              },
            },
          },
          x: {
            beginAtZero: false,
            grid: {
              display: false,
            },
            border: {
              display: false,
            },
            ticks: {
              display: xTicksDisplay,
              color: "#000000",
              font: {
                size: xAxisFontSize,
              },
            },
          },
        },
        elements: {
          point: {
            radius: pointWidth,
            backgroundColor: "#ffffff",
            borderWidth: pointWidth,
            borderColor: "#182d5b",
            hoverRadius: pointWidth,
            hoverBorderWidth: pointWidth,
            hoverBorderColor: "#182d5b",
          },
          line: {
            borderColor: "rgba(112, 112, 112, 1)",
          },
        },
        animation: {
          duration: 0,
        },
      },
      plugins: plugins?.map((e, i) => {
        return {
          id: `${chartId}-plugin-${i}`,
          beforeDraw: (chart) => {
            const { ctx, chartArea, scales } = chart;
            ctx.save();
            ctx.fillStyle = e?.bgColor;
            ctx.fillRect(
              chartArea.left,
              scales.y.getPixelForValue(e?.start),
              chartArea.right - chartArea.left,
              scales.y.getPixelForValue(e?.end) -
                scales.y.getPixelForValue(e?.start)
            );
          },
        };
      }),
    });

    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [
    chartId,
    chartData?.points,
    chartData?.xLabel,
    chartData?.yLabels,
    min,
    max,
    yTicksDisplay,
    xTicksDisplay,
    xAxisFontSize,
    pointWidth,
    plugins,
  ]);

  return <canvas id={chartId}></canvas>;
};
export default memo(Graph);
