import { useDispatch } from "react-redux";
import { GET_TOKEN_SUCCESS } from "../redux/actionTypes/AuthActionTypes";
import { GET_BIOMARKER_SUCCESS } from "../redux/actionTypes/BiomarkerActionTypes";
import {
  GET_PARENT_PROFILE_ID,
  GET_PROFILE_GRANTED_ACCESS_SUCCESS,
  GET_REVIEW_BLOOD_TEST_REPORT,
} from "../redux/actionTypes/DashboardActionTypes";
import { GET_BLOOD_TEST_SUCCESS } from "../redux/actionTypes/firstStepActionTypes";
import {
  GET_BLOOD_TEST_FIRST_PID_SUCCESS,
  GET_BLOOD_TEST_FIRST_PID_SUCCESS_PROVIDER,
  GET_BLOOD_TEST_FIRST_START_TEST,
  GET_BLOOD_TEST_FIRST_UPDATE_METADATA,
  GET_BLOOD_TEST_FIRST_UPLOAD_IMAGE,
  GET_BLOOD_TEST_INSIGHTS_SUCCESS,
  GET_BLOOD_TEST_ORGAN_SUCCESS,
  GET_BLOOD_TEST_SCAN_STATUS,
  GET_BLOOD_TEST_SECOND_SUCCESS,
  GET_BLOOD_TEST_VALUES,
  GET_PROFILE_INFO_SUCCESS,
  SET_UPLOADED_FILES,
  SUBMIT_BLOOD_TEST,
  UPDATE_TEST_VALUES,
} from "../redux/actionTypes/GetBloodTestFirstActionTypes";
import { GET_PROFILE_SUCCESS } from "../redux/actionTypes/GetProfileActionTypes";
import { GET_SUGGESTIONS_SUCCESS, GET_USER_SUCCESS } from "../redux/actionTypes/GetUserActionTypes";
import { GET_INSIGHTS_SUCCESS } from "../redux/actionTypes/InsightsActionTypes";
import { GET_ORGAN_SUCCESS } from "../redux/actionTypes/OrganSectoinActionTypes";
import {
  CLOSE_ACCOUNT_SUCCESS,
  DELETE_PROFILE_SUCCESS,
  GET_FAIL_PAYMENT,
  GET_PAYMENT_DATA,
  GET_SUBSCRIPTION_PLAN,
  INVITE_OTHERS,
  LIST_PROFILE_SUCCESS,
  REMOVE_PROFILE_ACCESS_SUCCESS,
  RESTORE_PROFILE_SUCCESS,
  SEND_FEEDBACK_SUCCESS,
  SET_SUBSCRIPTION_PLAN,
} from "../redux/actionTypes/SettingsActionTypes";
import {
  GET_SIGNIN_SUCCESS,
  GET_SOCIAL_LOGIN,
  SET_SOCIAL_LOGIN,
} from "../redux/actionTypes/SignInActionTypes";
import { GET_SIGNUP_SUCCESS } from "../redux/actionTypes/SignUpActionTypes";
import { GET_ORGAN_REPORT_SUCCESS } from "../redux/actionTypes/trendsReportActionTypes";
import { ADD_PROFILE_SUCCESS } from "../redux/actionTypes/AddProfileActionTypes";
import { SAMPLE_REPORT_SUCCESS } from "../redux/actionTypes/SampleReportActionTypes";
import {
  GET_BIOMARKER_REPORT_SUCCESS,
  GET_ORGAN_REPORT_ERROR,
  PAGE_CHANGE,
} from "../redux/actionTypes/trendsReportActionTypes";

export const toastStyle = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  theme: "light",
};

export const toastContainerStyle = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  theme: "light",
};

export const getNumber = (value) => {
  if (typeof value === 'string' && value.includes("%")) {
    return Number(value.slice(0, value.length - 1));
  } else if (value === undefined) {
    return 0;
  }
  return Number(value);
};


export const getNumberVal = (value) => {
  if (value !== undefined && value?.includes("%")) {
    return Number(value.slice(0, value.length - 1));
  } else if (value?.includes(",")) {
    return parseFloat(value.replace(/,/g, ''))
  }
  else if (value === 'string') {
    return Number(value)
  }
  else if (value === undefined) {
    return 0;
  }
  return Number(value);
};

export const getErrorMessage = (FullError) => {
  if (FullError.includes("auth/invalid-verification-code")) {
    return "Invalid OTP entered. Please double-check and try again.";
  } else if (FullError.includes("auth/user-not-found")) {
    return "Email address not found in our records. Please check or register an account.";
  } else if (FullError.includes("auth/wrong-password")) {
    return "Incorrect password entered. Please double-check and try again.";
  } else if (FullError.includes("auth/code-expired")) {
    return "The OTP code you entered has expired. Please request a new OTP to continue.";
  } else if (FullError.includes("auth/too-many-requests")) {
    return "Please try after sometime.";
  } else if (FullError.includes("auth/invalid-verification-id")) {
    return "Please try after sometime.";
  }
  return "";
};

export const capitalizeLetter = (letter) => {
  const fullName = letter;
  const words = fullName?.split(" ");

  if (words?.length === 2) {
    const firstWord = words[0]?.charAt(0).toUpperCase() + words[0]?.slice(1);
    const secondWord = words[1]?.charAt(0).toUpperCase() + words[1]?.slice(1);

    return `${firstWord} ${secondWord}`;
  } else {
    const capitalizedFullName =
      fullName?.charAt(0).toUpperCase() + fullName?.slice(1);
    return capitalizedFullName;
  }
};

export function generateAbbreviation(input) {
  if (input) {
    const words = input.split(" ");
    let abbreviation;
    const firstWord = words[0]?.charAt(0).toUpperCase();
    const secondWord = words[1]?.charAt(0).toUpperCase();
    if (words.length < 2) {
      abbreviation = firstWord;
    } else {
      abbreviation = firstWord + secondWord;
    }

    return abbreviation;
  }
}

export const removePersistData = (dispatch) => {
  dispatch({
    type: GET_SIGNIN_SUCCESS,
    payload: null,
  });
  dispatch({
    type: GET_SIGNUP_SUCCESS,
    payload: null,
  });
  dispatch({
    type: GET_TOKEN_SUCCESS,
    payload: null,
  });
  dispatch({
    type: GET_PROFILE_SUCCESS,
    payload: null,
  });
  dispatch({
    type: GET_PROFILE_SUCCESS,
    payload: null,
  });
  dispatch({
    type: INVITE_OTHERS,
    payload: null,
  });
  dispatch({
    type: ADD_PROFILE_SUCCESS,
    payload: null,
  });
  dispatch({
    type: GET_SUBSCRIPTION_PLAN,
    payload: null,
  });
  dispatch({
    type: SET_SUBSCRIPTION_PLAN,
    payload: null,
  });
  dispatch({
    type: GET_USER_SUCCESS,
    payload: null,
  });
  dispatch({
    type: GET_PROFILE_GRANTED_ACCESS_SUCCESS,
    payload: null,
  });
  dispatch({
    type: GET_BLOOD_TEST_FIRST_PID_SUCCESS_PROVIDER,
    payload: null,
  });
  dispatch({
    type: GET_SOCIAL_LOGIN,
    payload: null,
  });
  dispatch({
    type: SET_SOCIAL_LOGIN,
    payload: null,
  });
  dispatch({
    type: GET_ORGAN_SUCCESS,
    payload: null,
  });
  dispatch({
    type: SET_UPLOADED_FILES,
    payload: null,
  });
  dispatch({
    type: CLOSE_ACCOUNT_SUCCESS,
    payload: null,
  });
  dispatch({
    type: RESTORE_PROFILE_SUCCESS,
    payload: null,
  });
  dispatch({
    type: GET_INSIGHTS_SUCCESS,
    payload: null,
  });
  dispatch({
    type: GET_BIOMARKER_SUCCESS,
    payload: null,
  });
  dispatch({
    type: PAGE_CHANGE,
    payload: 1,
  });

  dispatch({
    type: GET_PARENT_PROFILE_ID,
    payload: null,
  });
  dispatch({
    type: GET_BLOOD_TEST_FIRST_PID_SUCCESS,
    payload: null,
  });
  dispatch({
    type: GET_BLOOD_TEST_FIRST_START_TEST,
    payload: null,
  });
  dispatch({
    type: GET_BLOOD_TEST_FIRST_UPDATE_METADATA,
    payload: null,
  });
  dispatch({
    type: GET_BLOOD_TEST_FIRST_UPLOAD_IMAGE,
    payload: null,
  });
  dispatch({
    type: GET_BLOOD_TEST_SECOND_SUCCESS,
    payload: null,
  });
  dispatch({
    type: GET_BLOOD_TEST_VALUES,
    payload: null,
  });
  dispatch({
    type: GET_PROFILE_INFO_SUCCESS,
    payload: null,
  });
  dispatch({
    type: SAMPLE_REPORT_SUCCESS,
    payload: null,
  });
  dispatch({
    type: GET_BLOOD_TEST_SCAN_STATUS,
    payload: null,
  });
  dispatch({
    type: UPDATE_TEST_VALUES,
    payload: null,
  });
  dispatch({
    type: SUBMIT_BLOOD_TEST,
    payload: null,
  });
  dispatch({
    type: GET_BLOOD_TEST_ORGAN_SUCCESS,
    payload: null,
  });
  dispatch({
    type: GET_BLOOD_TEST_INSIGHTS_SUCCESS,
    payload: null,
  });
  dispatch({
    type: LIST_PROFILE_SUCCESS,
    payload: null,
  });
  dispatch({
    type: DELETE_PROFILE_SUCCESS,
    payload: null,
  });
  dispatch({
    type: SEND_FEEDBACK_SUCCESS,
    payload: null,
  });
  dispatch({
    type: REMOVE_PROFILE_ACCESS_SUCCESS,
    payload: null,
  });
  dispatch({
    type: GET_PAYMENT_DATA,
    payload: null,
  });
  dispatch({
    type: GET_FAIL_PAYMENT,
    payload: null,
  });
  dispatch({
    type: GET_ORGAN_REPORT_SUCCESS,
    payload: null,
  });
  dispatch({
    type: GET_BIOMARKER_REPORT_SUCCESS,
    payload: null,
  });
  dispatch({
    type: GET_REVIEW_BLOOD_TEST_REPORT,
    payload: {},
  });
  dispatch({
    type: GET_SUGGESTIONS_SUCCESS,
    payload: null
  })
  dispatch({
    type: GET_BLOOD_TEST_SUCCESS,
    payload: null,
  });
  dispatch({
    type: GET_ORGAN_REPORT_ERROR,
    payload: null,
  });
};
