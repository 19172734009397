export const GET_SETTINGS_REQUEST = "GET_SETTINGS_REQUEST";
export const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS";
export const GET_SETTINGS_FAILURE = "GET_SETTINGS_FAILURE";

export const CLOSE_ACCOUNT_SUCCESS = "CLOSE_ACCOUNT_SUCCESS";

export const RESTORE_PROFILE_SUCCESS = "RESTORE_PROFILE_SUCCESS";

export const LIST_PROFILE_SUCCESS = "LIST_PROFILE_SUCCESS";

export const SHARE_PROFILE_SUCCESS = "SHARE_PROFILE_SUCCESS";

export const DELETE_PROFILE_SUCCESS = "DELETE_PROFILE_SUCCESS";

export const SHARE_LIST_PROFILE_SUCCESS = "SHARE_LISTPROFILE_SUCCESS";

export const REMOVE_PROFILE_ACCESS_SUCCESS = "REMOVE_PROFILE_ACCESS_SUCCESS";
export const SEND_FEEDBACK_SUCCESS = "SEND_FEEDBACK_SUCCESS";

export const GET_PAYMENT_DATA = "GET_PAYMENT_DATA";
export const GET_PAYMENT_DATA_FOR_PROVIDER = "GET_PAYMENT_DATA_FOR_PROVIDER";

export const GET_SUBSCRIPTION_PLAN = "GET_SUBSCRIPTION_PLAN";

export const SET_SUBSCRIPTION_PLAN = "SET_SUBSCRIPTION_PLAN";

export const INVITE_OTHERS = "INVITE_OTHERS";
export const GET_FAIL_PAYMENT = "GET_FAIL_PAYMENT";
