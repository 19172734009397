import { GET_BLOOD_TEST_SUCCESS } from "../actionTypes/GetBloodTestActionTypes";
import axiosInstance from "../../axios/axiosInstance";
import {
  DELETE_BLOOD_TEST_REPORT,
  GET_DASHBOARD_FAILURE,
  GET_DASHBOARD_REQUEST,
} from "../actionTypes/DashboardActionTypes";
import { fetchProfileData } from "./GetProfileAction";

export const fetchBloodTest = (id) => async (dispatch) => {
  dispatch({ type: GET_DASHBOARD_REQUEST });
  try {
    const response = await axiosInstance(`v1/getBloodTests`, "post", id);
    dispatch({
      type: GET_BLOOD_TEST_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({ type: GET_DASHBOARD_FAILURE, payload: error.message });
  }
};

export const deleteBloodTest = (payload,loader) => async (dispatch) => {
  if(loader){
  loader(true)
  }
  try {
    const response = await axiosInstance(`v1/deleteBloodTestReport`, "post", payload);
    dispatch({
      type: DELETE_BLOOD_TEST_REPORT,
      payload: response.data.data,
    });
    
    dispatch(fetchProfileData());
    if(loader){
    loader(false)
    }
  } catch (error) {
    dispatch({ type: GET_DASHBOARD_FAILURE, payload: error.message });
  }
};