import React from 'react'
import loaderCss from './loader.module.scss'

function Loader({ loading }) {
    return (
        <div>
            {loading ?
                <div className={loaderCss.loading}>
                    <div className={loaderCss.uil_ring_css} style={{ transform: 'scale(0.79)' }}>
                        <div></div>
                    </div>
                </div>
                : ""}
        </div>
    )
}

export default Loader