import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import ForgotPasswordModal from "../../components/modal/forgot-password/ForgotPasswordModal";
import { getErrorMessage, toastStyle } from "../../helper";
import firebase from "../../config/FirebaseConfig";
import { useNavigate } from "react-router-dom";

function Forgotpage() {
  const [openPassModal, setOpenPassModal] = useState(false);
  const timer = useRef();
  const navigate = useNavigate();
  const [email, setEmail] = useState({
    email: "",
  });
  const [emailError, setEmailError] = useState("");
  const handleClosePassModal = () => {
    setOpenPassModal(false);
  };
  const [loading, setLoading] = useState(false);

  // handle forgot password button
  const handleSubmitPassword = async (values) => {
    try {
      const response = await firebase
        .auth()
        .sendPasswordResetEmail(values.email);
      // Code to handle success (if needed)
      toast.success("Email has been sent successfully!", toastStyle);
      if (!loading) {
        setLoading(true);
        timer.current = window.setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
      navigate("/sign-in");
      handleClosePassModal();
    } catch (error) {
      setEmailError(getErrorMessage(error.message));
    }
  };

  useEffect(() => {
    setOpenPassModal(true);
  }, []);

  return (
    <div id="login-page-BgImage">
      <div id="recaptcha-container"></div>
      <ForgotPasswordModal
        open={openPassModal}
        onClose={handleClosePassModal}
        onClick={handleClosePassModal}
        initialData={email}
        loading={loading}
        disabled={loading}
        onSubmit={handleSubmitPassword}
        emailError={emailError}
        setEmailError={setEmailError}
      />
    </div>
  );
}

export default Forgotpage;
