export const breadCrumbsArr = [
  {
    title: "Home",
    link: "/home",
  },
  {
    title: "Trends Report",
    link: null,
  },
];

export const biomarkerReportList = {
  CBC: "Complete Blood Count (CBC)",
  CMP: "Comprehensive Metabolic Panel (CMP)",
  Lipid: "Lipid Panel",
  Other: "Common Other Blood Tests",
};

export const colors = [
  "rgb(255,4,4,28%)", //red
  "rgb(193,175,10,28%)", //red
  "rgb(43, 147, 6 , 28%)", //green
  "rgb(193,175,10,28%)", //yellow
  "rgb(255,4,4,28%)", //red
  "",
];
