import { useEffect, useState } from "react";
import { getSuggestions } from "../redux/actions/GetUserAction";
import { useDispatch } from "react-redux";
const useDebounce = (text) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      const payload = {
        patient_name: text,
      };
      if (text?.length > 2) {
        dispatch(getSuggestions(payload, setLoader));
      } else {
        setLoader(false);
      }
    }, 500);
    return () => clearTimeout(timeout);
  }, [text]);

  return {
    loader,
  };
};

export default useDebounce;
