import { Card, Grid } from "@mui/material";
import React from "react";
import patientCss from "./patient.module.scss";
import moment from "moment";

function PatientInfo({
  name,
  dob,
  gen,
  testDate,
  inter,
  latestTestDateText,
  oldestTestDate,
  oldestTestDateText,
  totalReports,
  totalReportsText,
}) {
  return (
    <div>
      <Card className={patientCss.patient_main}>
        <Grid className={patientCss.patient_grid}>
          <div className={patientCss.information}>
            <p>Patient Info</p>
            <div className={patientCss.pt_container}>
              <div className={patientCss.pt_name}>
                <div>
                  <div className={patientCss.name_label}>Name</div>
                  <div className={patientCss.name_label}>Date of Birth</div>
                  <div className={patientCss.name_label}>Sex (at Birth)</div>
                </div>
                <div>
                  <div className={patientCss.patient_name}>{name}</div>
                  <div className={patientCss.patient_name}>
                    {dob ? moment(dob)?.format("MM/DD/YYYY") : ""}
                  </div>
                  <div className={patientCss.patient_name}>
                    {gen === "Woman" ? "Female" : gen === "Man" ? "Male" : gen}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span className={patientCss.cardBorder}></span>

          <div className={patientCss.test_info_main}>
            <p>Test Information</p>
            <div className={patientCss.test_container}>
              <div className={patientCss.test_info}>
                <p className={patientCss.test_date_label}>
                  {latestTestDateText || `Test Date`}
                </p>
                <p className={patientCss.test_date}>{testDate}</p>
              </div>

              <div className={patientCss.test_info}>
                <p className={patientCss.test_date_label}>
                  {oldestTestDateText || ""}
                </p>
                <p className={patientCss.test_date}>{oldestTestDate}</p>
              </div>
              <div className={patientCss.test_info}>
                <p className={patientCss.test_date_label}>
                  {totalReportsText || ""}
                </p>
                <p className={patientCss.test_date}>{totalReports}</p>
              </div>
            </div>
          </div>
          <span className={patientCss.cardBorder}></span>
          <div className={patientCss.inter_main}>
            <p>Interpretation</p>
            <div className={patientCss.inter_container}>
              <div className={patientCss.inter_info}>
                <p className={patientCss.inter_details}>{inter}</p>
              </div>
            </div>
          </div>
        </Grid>
      </Card>
    </div>
  );
}

export default PatientInfo;
