import {
  GET_SIGNUP_FAILURE,
  GET_SIGNUP_REQUEST,
  GET_SIGNUP_SUCCESS,
} from "../actionTypes/SignUpActionTypes";
import axiosInstance from "../../axios/axiosInstance";
import { GET_TOKEN_SUCCESS } from "../actionTypes/AuthActionTypes";

export const fetchSignUpData =
  (data, signUpToken, providerId) => async (dispatch) => {
    dispatch({ type: GET_SIGNUP_REQUEST });
    try {
      const response = await axiosInstance(`v1/login`, "post", data);
      dispatch({
        type: GET_TOKEN_SUCCESS,
        payload: response.data.data.token,
      });
      dispatch({
        type: GET_SIGNUP_SUCCESS,
        payload: {
          ...response.data.data,
          signUpToken: signUpToken,
          providerId: providerId,
        },
      });
    } catch (error) {
      dispatch({ type: GET_SIGNUP_FAILURE, payload: error.message });
    }
  };
