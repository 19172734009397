import { Link } from "react-router-dom";
import { ErrorBoundary as Boundary } from "react-error-boundary";
import { Box, Typography, Link as MUILink, Button } from "@mui/material";
import { removePersistData } from "../../helper";
import firebase from "../../config/FirebaseConfig";
import { useDispatch } from "react-redux";

const FallbackComponent = ({ error, resetErrorBoundary }) => {
  const dispatch = useDispatch();
  const handleBackToSignIn = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        // window.location.replace("https://www.ahealthacademy.com/");
        removePersistData(dispatch);
      })
      .catch(() => {
        console.log("error in logging out firebase");
      });
  };
  return (
    <Box
      sx={{
        p: 3,
        height: "90vh",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Typography variant="h5">Looks like something went wrong!</Typography>
      <Box component="pre">{error.message}</Box>
      <Typography variant="body2">
        We track these errors automatically, but if the problem persists feel
        free to <span className={"cursor-pointer"}>contact us</span>. In the
        meantime, try refreshing.
      </Typography>
      <Typography variant="body2" sx={{ my: 1 }}>
        If the problem persists, reach us on{" "}
        <MUILink href="mailto:Support@AHealthAcademy.com">
          support@AHealthAcademy.com
        </MUILink>
      </Typography>
      <Button onClick={resetErrorBoundary}>Try again</Button>
      <Box sx={{ my: 1 }}>OR</Box>
      <Typography variant="body2" sx={{ my: 1 }}>
        Go Back to{" "}
        <MUILink component={Link} to="/sign-in" onClick={handleBackToSignIn}>
          Sign In Page
        </MUILink>
      </Typography>
    </Box>
  );
};

const ErrorBoundary = ({ children }) => {
  return <Boundary FallbackComponent={FallbackComponent}>{children}</Boundary>;
};

export default ErrorBoundary;
