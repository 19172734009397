import axiosInstance from "../../axios/axiosInstance";
import {
  GET_ORGAN_REPORT_SUCCESS,
  GET_ORGAN_REPORT_ERROR,
  GET_BIOMARKER_REPORT_SUCCESS,
  GET_BIOMARKER_SUCCESS,
  ORGAN_REQUEST,
  PAGE_CHANGE,
} from "../actionTypes/trendsReportActionTypes";

export const getOrganReport = (data) => async (dispatch) => {
  dispatch({ type: ORGAN_REQUEST });
  try {
    const response = await axiosInstance(
      `v1/getOrganTrendsReport`,
      "post",
      data
    );
    dispatch({
      type: GET_ORGAN_REPORT_SUCCESS,
      payload: response.data.data,
    });
    dispatch({
      type: PAGE_CHANGE,
      payload: data.pageNumber,
    });
  } catch (error) {
    dispatch({ type: GET_ORGAN_REPORT_ERROR, payload: error.message });
  }
};

export const getBiomarkerReport = (data) => async (dispatch) => {
  dispatch({ type: ORGAN_REQUEST });
  try {
    const response = await axiosInstance(
      `v1/getBiomarkerTrendsReport`,
      "post",
      data
    );
    dispatch({
      type: GET_BIOMARKER_REPORT_SUCCESS,
      payload: response.data.data,
    });
    dispatch({
      type: PAGE_CHANGE,
      payload: data.pageNumber,
    });
  } catch (error) {
    dispatch({ type: GET_ORGAN_REPORT_ERROR, payload: error.message });
  }
};

export const getBiomarker = () => async (dispatch) => {
  dispatch({ type: ORGAN_REQUEST });
  try {
    const response = await axiosInstance(`v1/getBiomarker`, "post");
    dispatch({
      type: GET_BIOMARKER_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({ type: GET_ORGAN_REPORT_ERROR, payload: error.message });
  }
};
